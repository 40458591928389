import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { themes } from "services/constants";
import { Loader } from "components/Loader";
import * as Mui from "@mui/material";

const keys = ["date", "from_date", "to_date", "total_time", "status"];

export const History = ({ startDate, endDate }) => {
  const { token, domain, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );

  const [expandedRow, setExpandedRow] = useState(null);
  const [leavePage, setLeavePage] = useState(0);
  const [page, setPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [permissionDetails, setPermissionDetails] = useState([]);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);

  const handleChangeLeavePage = (event, newPage) => {
    setLeavePage(newPage);
  };

  const handleChangeLeaveRowsPerPage = (event) => {
    setPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };
  const handleRowClick = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };

  useEffect(() => {
    const getUserDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${domain}individual_permisson_request/?user_id=${userDetails?.id}&start_date=${startDate}&end_date=${endDate}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setPermissionDetails(res);
          setMasterDataForSearch(res);
          setLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setLoading(false);
      }
    };

    getUserDetails();
  }, [domain, endDate, startDate, token, userDetails?.id]);

  useEffect(() => {
    const filteredResults = masterDataForSearch?.filter((item) =>
      keys.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setPermissionDetails(filteredResults);
    setLeavePage(0);
  }, [globalSearchValue, masterDataForSearch]);

  return loading === true ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.Grid container>
        <Mui.TableContainer component={Mui.Paper} sx={{ mt: 2, width: "100%" }}>
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow>
                <Mui.TableCell className="column-cell"></Mui.TableCell>
                <Mui.TableCell className="column-cell">
                  <Mui.Typography fontWeight={"bold"}>Date</Mui.Typography>
                </Mui.TableCell>
                <Mui.TableCell className="column-cell">
                  <Mui.Typography fontWeight={"bold"}>
                    {" "}
                    Start Time
                  </Mui.Typography>
                </Mui.TableCell>
                <Mui.TableCell className="column-cell">
                  <Mui.Typography fontWeight={"bold"}>End Time </Mui.Typography>
                </Mui.TableCell>
                <Mui.TableCell className="column-cell">
                  <Mui.Typography fontWeight={"bold"}> Duration</Mui.Typography>
                </Mui.TableCell>
                <Mui.TableCell className="column-cell">
                  <Mui.Typography fontWeight={"bold"}>Status</Mui.Typography>
                </Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {" "}
              {permissionDetails
                ?.slice(leavePage * page, leavePage * page + page)
                .map((permission) => (
                  <>
                    <Mui.TableRow key={permission.id}>
                      <Mui.TableCell className="column-cell">
                        {expandedRow === permission.id ? (
                          <Mui.IconButton
                            onClick={() => {
                              setExpandedRow(null);
                            }}
                          >
                            <KeyboardArrowDownIcon
                              style={{
                                color: themes.blackColor,
                                fontWeight: "bold",
                              }}
                            />
                          </Mui.IconButton>
                        ) : (
                          <Mui.IconButton
                            onClick={() => handleRowClick(permission.id)}
                          >
                            <KeyboardArrowRightIcon
                              style={{
                                color: themes.blackColor,
                                fontWeight: "bold",
                              }}
                            />
                          </Mui.IconButton>
                        )}
                      </Mui.TableCell>
                      <Mui.TableCell className="column-cell">
                        {permission.date}
                      </Mui.TableCell>
                      <Mui.TableCell className="column-cell">
                        {permission.start_time}
                      </Mui.TableCell>
                      <Mui.TableCell className="column-cell">
                        {permission.end_time}
                      </Mui.TableCell>
                      <Mui.TableCell className="column-cell">
                        {permission.total_time.split(":")[0] +
                          "h " +
                          permission.total_time.split(":")[1] +
                          "m"}
                      </Mui.TableCell>
                      <Mui.TableCell className="column-cell">
                        {permission.status}
                      </Mui.TableCell>
                    </Mui.TableRow>
                    {expandedRow === permission.id && (
                      <Mui.TableRow>
                        <Mui.TableCell colSpan={12}>
                          <Mui.TableContainer
                            sx={{
                              marginTop: 0,
                              marginLeft: "auto",
                              marginRight: "auto",
                              width: "100%px",
                            }}
                            className="w-96 border rounded"
                          >
                            <Mui.Table className="border">
                              <Mui.Typography
                                variant="body1"
                                gutterBottom
                                sx={{ marginLeft: 1, marginTop: 1 }}
                              >
                                <strong>Description :</strong>{" "}
                                <Mui.Typography
                                  fontSize={15}
                                  color="textSecondary"
                                  sx={{ marginLeft: 13 }}
                                >
                                  {permission.description}
                                </Mui.Typography>
                              </Mui.Typography>
                            </Mui.Table>
                          </Mui.TableContainer>
                          {permission.status === "Rejected" ? (
                            <Mui.TableContainer
                              sx={{
                                marginTop: 0.5,
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "100%px",
                              }}
                              className="w-96 border rounded"
                            >
                              <Mui.Table className="border">
                                <Mui.Typography
                                  variant="body1"
                                  gutterBottom
                                  sx={{ marginLeft: 1, marginTop: 1 }}
                                >
                                  <strong>Reason for Rejection :</strong>{" "}
                                  <Mui.Typography
                                    fontSize={15}
                                    color="textSecondary"
                                    sx={{ marginLeft: 13 }}
                                  >
                                    {permission.reason}
                                  </Mui.Typography>
                                </Mui.Typography>
                              </Mui.Table>
                            </Mui.TableContainer>
                          ) : null}
                        </Mui.TableCell>
                      </Mui.TableRow>
                    )}
                  </>
                ))}
            </Mui.TableBody>
          </Mui.Table>
          {permissionDetails?.length > 0 ? null : (
            <Mui.Alert severity="info" sx={{ margin: 2 }}>
              No data available
            </Mui.Alert>
          )}
          {permissionDetails?.length > 10 ? (
            <Mui.TablePagination
              sx={{ marginRight: 7 }}
              className="custom-pagination"
              rowsPerPageOptions={[10, 20, 50, 100]}
              component="div"
              count={permissionDetails?.length}
              rowsPerPage={page}
              page={leavePage}
              onPageChange={handleChangeLeavePage}
              onRowsPerPageChange={handleChangeLeaveRowsPerPage}
            />
          ) : null}
        </Mui.TableContainer>
      </Mui.Grid>
    </React.Fragment>
  );
};
