import * as React from "react";
import { useState } from "react";
import * as Mui from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import dayjs from "dayjs";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { showToast } from "services/utils/Status";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { format, parse } from "date-fns";
import { leaveSuccess } from "services/constants/SuccessMessages";
import { themes } from "services/constants";
import { CustomButton } from "components/CustomComponents/CustomButton";
import sessionHandling from "services/utils/notificationUtils";
import moment from "moment";

export const Leave = () => {
  const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);
  const [departmentDetailsApi, setDepartmentDetailsApi] = useState(true);
  const [selectedHalfDay, setSelectedHalfDay] = useState(null);
  const [checkboxChecked, setcheckboxChecked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [firstHalfChecked, setFirstHalfChecked] = useState(false);
  const [secondHalfChecked, setSecondHalfChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [approverName, setApproverName] = useState("");
  const [leaveDate, setLeaveDate] = useState([]);
  const [filteredLabel, setFilteredLabel] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leaveStartDate, setLeaveStartDate] = useState("");
  const [leaveEndDate, setLeaveEndDate] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    trigger,
    formState,
  } = useForm({ mode: "onBlur" });
  const { errors, isValid } = formState;
  const { token, domain, userDetails } = useSelector(
    (state) => state.tokenReducer
  );

  useEffect(() => {
    const fetchDepartmentId = async () => {
      try {
        const response = await fetch(
          `${domain}get_leads_based_on_departments/?department_id=${userDetails.department_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setApproverName(res[0]?.department_lead_name);
          console.log(approverName);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (departmentDetailsApi) {
      fetchDepartmentId();
      setDepartmentDetailsApi(false);
    }
  }, [
    approverName,
    departmentDetailsApi,
    domain,
    setValue,
    token,
    userDetails.department_id,
  ]);

  useEffect(() => {
    const fetchLeaveTypeOptions = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${domain}leavetypefilter/?user_id=${userDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          Array.isArray(setLeaveTypeOptions(res));
          setIsLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        setIsLoading(false);
      }
    };
    fetchLeaveTypeOptions();
  }, [domain, token, userDetails.id]);

  const formatDate = (dateString) => {
    const date = parse(dateString, "dd-MM-yyyy", new Date());
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  };

  const resetForm = () => {
    reset();
    setcheckboxChecked(false);
    setChecked(false);
    setFirstHalfChecked(false);
    setSecondHalfChecked(false);
    setSecondHalfChecked(false);
    const today = dayjs().format("YYYY-MM-DD");
    setStartDate(today);
    setEndDate(today);
    setValue("from_date", today);
    setValue("to_date", today);
  };

  const handleClearButtonClick = () => {
    resetForm();
  };

  const onSubmit = async (data) => {
    setButtonLoader(true);
    try {
      const halfDay = firstHalfChecked || secondHalfChecked;
      const whichHalf = firstHalfChecked
        ? "1st"
        : secondHalfChecked
        ? "2nd"
        : null;
      const currentLeaveType = leaveTypeOptions?.filter((leave) => {
        return leave.leave_type === data.leave_type;
      });
      const response = await fetch(`${domain}create/leave-request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          ...data,
          is_half_day: halfDay,
          half_day_period: whichHalf,
          user: userDetails.id,
          status: "Pending",
          approver: null,
          applied_date: new Date().toISOString().split("T")[0],
          createdby: userDetails.first_name,
          createdat: new Date().toISOString(),
          modifiedby: userDetails.first_name,
          modifiedat: new Date().toISOString(),
          leave_type_id: currentLeaveType[0].id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", leaveSuccess.leaveRequest);
        setButtonLoader(false);
        reset();
        setcheckboxChecked(false);
        setChecked(false);
        setFirstHalfChecked(false);
        setSecondHalfChecked(false);
        if (
          new Date(leaveStartDate).getFullYear() === new Date().getFullYear() ||
          new Date(leaveEndDate).getFullYear() === new Date().getFullYear()
        ) {
          const today = dayjs().format("YYYY-MM-DD");
          setStartDate(today);
          setEndDate(today);
        } else {
          setStartDate(leaveStartDate);
          setEndDate(leaveEndDate);
        }
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
      setButtonLoader(false);
    }
  };

  useEffect(() => {
    const fetchLeaveDate = async () => {
      try {
        const response = await fetch(`${domain}leave-years/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const leaveDate = res.map((value) => ({
            label: value.name,
            start_date: value.start_date,
            end_date: value.end_date,
            filteredValue: `${value.start_date} - ${value.end_date}`,
          }));
          const today = dayjs().format("YYYY-MM-DD");
          const sDate = formatDate(res[0]?.start_date);
          const eDate = formatDate(res[0]?.end_date);
          setLeaveDate(leaveDate);
          setFilteredLabel(res[0]?.name);
          setFilteredValue(`${res[0]?.start_date} - ${res[0]?.end_date}`);
          setLeaveStartDate(sDate);
          setLeaveEndDate(eDate);
          if (
            new Date(
              res[0]?.start_date.split("-").reverse().join("-")
            ).getFullYear() === new Date().getFullYear() ||
            new Date(
              res[0]?.end_date.split("-").reverse().join("-")
            ).getFullYear() === new Date().getFullYear()
          ) {
            setStartDate(today);
            setEndDate(today);
          } else {
            setStartDate(sDate);
            setEndDate(eDate);
          }
          setValue("from_date", today);
          setValue("to_date", today);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchLeaveDate();
  }, [domain, setValue, token]);

  const updateLeavePeriod = (event, value) => {
    const today = dayjs().format("YYYY-MM-DD");
    if (value && value.filteredValue) {
      setFilteredLabel(value.label);
      setFilteredValue(value.filteredValue);
      setStartDate(formatDate(value.start_date));
      setEndDate(formatDate(value.end_date));
      setLeaveStartDate(formatDate(value.start_date));
      setLeaveEndDate(formatDate(value.end_date));
      if (
        new Date(
          value.start_date.split("-").reverse().join("-")
        ).getFullYear() === new Date().getFullYear() ||
        new Date(
          value.end_date.split("-").reverse().join("-")
        ).getFullYear() === new Date().getFullYear()
      ) {
        let startDateConvertions = moment(new Date()).format("DD-MM-YYYY");
        setStartDate(formatDate(startDateConvertions));
        setEndDate(formatDate(startDateConvertions));
        setValue("from_date", formatDate(startDateConvertions));
        setValue("to_date", formatDate(startDateConvertions));
      } else {
        setStartDate(formatDate(value.start_date));
        setEndDate(formatDate(value.end_date));
        setValue("from_date", formatDate(value.start_date));
        setValue("to_date", formatDate(value.end_date));
      }
    } else {
      setFilteredLabel("");
      setFilteredValue("");
      setLeaveStartDate("");
      setLeaveEndDate("");
      setFilteredLabel("");
      setFilteredValue("");
      setLeaveStartDate(today);
      setLeaveEndDate(today);
      setStartDate(today);
      setEndDate(today);
    }
  };

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setcheckboxChecked(checked);
    const today = dayjs().format("YYYY-MM-DD");
    const tomorrow = dayjs().add(1, "day").format("YYYY-MM-DD");

    if (checked) {
      setStartDate(tomorrow);
      setEndDate(tomorrow);
      setValue("from_date", tomorrow);
      setValue("to_date", tomorrow);
      setSecondHalfChecked(false);
      setFirstHalfChecked(false);
      setChecked(false);
    } else {
      setStartDate(today);
      setEndDate(today);
      setValue("from_date", today);
      setValue("to_date", today);
      setSecondHalfChecked(false);
      setFirstHalfChecked(false);
      setChecked(false);
    }
  };

  const handleCheckboxChanged = (event) => {
    const isChecked = event.target.checked;
    const today = dayjs().format("YYYY-MM-DD");
    setChecked(isChecked);
    if (isChecked) {
      setStartDate(today);
      setEndDate(today);
      setValue("from_date", today);
      setValue("to_date", today);
      setcheckboxChecked(false);
    } else {
      setEndDate(endDate);
      setcheckboxChecked(false);
    }
  };

  const handleStartDateChange = (event) => {
    const date = event.target.value;
    setStartDate(date);
    setEndDate(date);
    setValue("from_date", date);
    setValue("to_date", date);
    trigger("from_date");
  };

  const handleEndDateChange = (event) => {
    const date = event.target.value;
    setEndDate(date);
    setValue("to_date", date);
    trigger("to_date");
  };

  const handleSetValue = () => {
    setValue("from_date", startDate);
    setValue("to_date", endDate);
  };

  const handleFirstHalfCheckboxChanged = (event) => {
    const checked = event.target.checked;
    setFirstHalfChecked(checked);
    if (checked) {
      setSecondHalfChecked(false);
      setSelectedHalfDay("1st-half");
      setcheckboxChecked(false);
    } else {
      setSelectedHalfDay(null);
    }
  };

  const handleSecondHalfCheckboxChanged = (event) => {
    const checked = event.target.checked;
    setSecondHalfChecked(checked);
    if (checked) {
      setFirstHalfChecked(false);
      setSelectedHalfDay("2nd-half");
      setcheckboxChecked(false);
    } else {
      setSelectedHalfDay(null);
    }
  };

  const validateEndDate = (value) => {
    const endDate = format(new Date(value), "yyyy-MM-dd");
    if (startDate && endDate < startDate) {
      return "End date cannot be before the start date";
    }
    return true;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        <>
          <Mui.Card
            content="center"
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
              boxShadow: 5,
              borderRadius: 5,
            }}
          >
            <Mui.CardContent sx={{ marginTop: 2 }}>
              <Mui.Grid container justifyContent={"flex-end"}>
                <Mui.Grid xs={4}>
                  <CustomAutoComplete
                    dataList={leaveDate}
                    selectedValue={filteredValue}
                    updateDataFunction={updateLeavePeriod}
                    placeHolder="Select leave period"
                    label={filteredLabel ? filteredLabel : "Leave Period"}
                  />
                </Mui.Grid>
                <Mui.Grid xs={1.4}></Mui.Grid>
              </Mui.Grid>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Mui.Grid container spacing={2}>
                  <Mui.Grid item xs={12}>
                    <Controller
                      name="leave_type"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Choose a Leave Type.",
                      }}
                      render={({ field }) => (
                        <>
                          <Mui.Typography
                            sx={{ marginTop: 4, marginLeft: 25 }}
                            htmlFor="from_date"
                            style={{
                              fontSize: 20,
                            }}
                          >
                            Leave Type
                          </Mui.Typography>
                          <Mui.TextField
                            {...field}
                            sx={{
                              marginTop: 1,
                              width: 629,
                              marginLeft: 24.5,
                              marginRight: "auto",
                            }}
                            size="medium"
                            label="Select leave type"
                            id="leave_type"
                            variant="outlined"
                            error={!!errors.leave_type}
                            helperText={
                              errors.leave_type && errors.leave_type.message
                            }
                            select
                            SelectProps={{
                              MenuProps: {
                                PaperProps: {
                                  style: {
                                    maxHeight: 300,
                                    width: 280,
                                  },
                                },
                              },
                            }}
                          >
                            {leaveTypeOptions?.map((option) => (
                              <Mui.MenuItem
                                key={option?.id}
                                value={option?.leave_type}
                              >
                                {option?.leave_type}
                              </Mui.MenuItem>
                            ))}
                          </Mui.TextField>
                        </>
                      )}
                    />
                  </Mui.Grid>
                </Mui.Grid>

                <br />
                <Mui.Grid container>
                  <Mui.Grid itex xs={4}>
                    {" "}
                  </Mui.Grid>
                  <Mui.Grid itex xs={4}>
                    <Mui.FormControlLabel
                      sx={{ marginLeft: -15 }}
                      control={<Mui.Checkbox />}
                      label="Only For Tomorrow"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      onClick={handleSetValue}
                    />
                  </Mui.Grid>
                  <Mui.Grid item xs={4}>
                    <Mui.FormControlLabel
                      sx={{ marginLeft: -10 }}
                      control={
                        <Mui.Checkbox
                          checked={checked}
                          onChange={handleCheckboxChanged}
                        />
                      }
                      label="Half Day"
                    />
                  </Mui.Grid>
                  {checked && (
                    <>
                      <Mui.Grid item xs={4}></Mui.Grid>
                      <Mui.Grid item xs={4}>
                        <Mui.FormControlLabel
                          sx={{ marginLeft: -15 }}
                          control={
                            <Mui.Checkbox
                              checked={firstHalfChecked}
                              onChange={handleFirstHalfCheckboxChanged}
                            />
                          }
                          label="1st-half"
                        />
                      </Mui.Grid>
                      <Mui.Grid item xs={4}>
                        <Mui.FormControlLabel
                          sx={{ marginLeft: -10 }}
                          control={
                            <Mui.Checkbox
                              checked={secondHalfChecked}
                              onChange={handleSecondHalfCheckboxChanged}
                            />
                          }
                          label="2nd-half"
                        />
                      </Mui.Grid>
                    </>
                  )}
                </Mui.Grid>
                <br />

                <Mui.Grid container spacing={2}>
                  <Mui.Grid item xs={5.5}>
                    <Mui.Typography
                      sx={{ marginTop: -2, marginLeft: 25 }}
                      htmlFor="from_date"
                      style={{
                        fontSize: 20,
                      }}
                    >
                      Start Date
                    </Mui.Typography>

                    <Mui.TextField
                      sx={{
                        marginTop: 1,
                        width: 280,
                        marginLeft: 25,
                      }}
                      size="medium"
                      type="date"
                      id="from_date"
                      value={startDate}
                      inputProps={{
                        min: leaveStartDate,
                        max: leaveEndDate,
                      }}
                      {...register("from_date", {
                        required: "start date is required.",
                      })}
                      onChange={handleStartDateChange}
                      error={!!errors.from_date}
                      helperText={errors.from_date && errors.from_date.message}
                      disabled={checkboxChecked}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Mui.Grid>
                  <Mui.Grid item xs={1}>
                    <Mui.Typography
                      sx={{ marginTop: 5, marginLeft: 9 }}
                      htmlFor="from_date"
                      style={{
                        fontSize: 20,
                      }}
                    >
                      To
                    </Mui.Typography>
                  </Mui.Grid>

                  <Mui.Grid item xs={5.5}>
                    <Mui.Typography
                      sx={{ marginTop: -2, marginLeft: 5 }}
                      htmlFor="to_date"
                      style={{
                        fontSize: 20,
                      }}
                    >
                      End Date
                    </Mui.Typography>
                    <Mui.TextField
                      sx={{
                        marginTop: 1,
                        width: 280,
                        marginLeft: 5,
                      }}
                      size="medium"
                      type="date"
                      id="to_date"
                      value={endDate}
                      inputProps={{
                        min: leaveStartDate,
                        max: leaveEndDate,
                      }}
                      {...register("to_date", {
                        required: "End date is required.",
                        validate: {
                          dateNotBeforeStart: validateEndDate,
                        },
                      })}
                      onChange={handleEndDateChange}
                      error={!!errors.to_date}
                      helperText={errors.to_date && errors.to_date.message}
                      disabled={checked ? checked : false || checkboxChecked}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Mui.Grid>
                </Mui.Grid>

                <br />
                <br />
                <Mui.Typography
                  sx={{ marginTop: -2, marginLeft: 25 }}
                  htmlFor="from_date"
                  style={{
                    fontSize: 20,
                  }}
                >
                  Reason for Leave
                </Mui.Typography>
                <Mui.TextField
                  sx={{
                    marginTop: 1,
                    width: "68.5%",
                    marginLeft: 25,
                    marginRight: "auto",
                  }}
                  id="description"
                  {...register("description", {
                    required: "description is required",
                  })}
                  label="Provide a reason for your time off"
                  multiline
                  minRows={3}
                  onBlur={() => trigger()}
                />

                <br />
                <br />
                <Mui.Grid container spacing={5}>
                  <Mui.Grid item xs={7.8}></Mui.Grid>
                  <Mui.Grid
                    item
                    xs={4.2}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <CustomButton
                      actionFuntion={handleClearButtonClick}
                      actionName="Clear"
                      typeName="button"
                    />
                    &nbsp;&nbsp;
                    {buttonLoader ? (
                      <CustomButton
                        disableAction={buttonLoader}
                        type="submit"
                        actionName="Apply"
                      />
                    ) : (
                      <Mui.Button
                        sx={{
                          width: "100px",
                          height: "36px",
                          marginRight: 3,
                          fontWeight: 600,
                          display: "block",
                          backgroundColor: themes.primaryButton,
                          "&:hover": {
                            backgroundColor: themes.primaryButton,
                          },
                          textTransform: "capitalize",
                          color: themes.headLine,
                        }}
                        type="submit"
                        variant="contained"
                        disabled={
                          !isValid ||
                          (checked && !selectedHalfDay) ||
                          (checked &&
                            !firstHalfChecked &&
                            !secondHalfChecked) ||
                          isLoading
                        }
                      >
                        Apply
                      </Mui.Button>
                    )}
                  </Mui.Grid>
                </Mui.Grid>
              </form>
            </Mui.CardContent>
          </Mui.Card>
        </>
      </div>
    </>
  );
};
