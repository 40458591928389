import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Loader } from "components/Loader";
import { getColorWorkingHours } from "services/utils/Status";
import dayjs from "dayjs";
import {
  DriveFileRenameOutline,
  Cancel,
  Done,
  Loupe,
} from "@mui/icons-material";
import { leaveManagementError } from "services/constants/ErrorMessages";
import { leaveSuccess } from "services/constants/SuccessMessages";
import moment from "moment";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { useSelector, useDispatch } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";

export const ListView = ({
  editCheckinDetailsId,
  setEditCheckinDetailsId,
  editCheckinCheckOutTime,
  setEditCheckInCheckOutDetails,
  departmentMembers,
  loading,
  filterDepartMemberList,
  expand,
  setExpand,
  setReloadHRMSApi,
  page,
  setPage,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showDetails, setShowDetails] = useState(0);
  const [breakPopup, setBreakPopup] = useState(false);
  const [checkOutTime, setCheckOutTime] = useState(null);
  const [checkInTime, setCheckInTime] = useState(null);
  const [breakDayDetails, setBreakDayDetails] = useState(null);
  const [personExpandableId, setPersonExpandableId] = useState(0);
  const [handleCheckInError, setHandleCheckInError] = useState("");
  const [ediBreakErrors, setEditBreakErrors] = useState("");
  const [editBreakDetails, setEditBreakDetails] = useState(null);
  const [editBreakOutTime, setEditBreakOutTime] = useState(null);
  const [editBreakInTime, setEditBreakInTime] = useState(null);
  const [breakInTime, setBreakInTime] = useState(null);
  const [breakOutTime, setBreakOutTime] = useState(null);
  const [breakInError, setBreakInError] = useState(null);
  const [breakOutError, setBreakOutError] = useState(null);
  const [breakType, setBreakType] = useState(null);
  const [breakId, setBreakId] = useState(0);

  const dispatch = useDispatch();
  const {
    token,
    domain,
    userDetails: currentUserDetails,
  } = useSelector((state) => state.tokenReducer);
  const handleCheckInCheckOutTime = (checkinDetails) => {
    setCheckOutTime(
      checkinDetails?.check_out_time
        ? dayjs()
            .set("hour", checkinDetails?.check_out_time?.split(":")[0])
            .set("minute", checkinDetails?.check_out_time?.split(":")[1])
            .set("second", checkinDetails?.check_out_time?.split(":")[2])
        : null
    );
    setCheckInTime(
      checkinDetails?.check_in_time
        ? dayjs()
            .set("hour", checkinDetails?.check_in_time?.split(":")[0])
            .set("minute", checkinDetails?.check_in_time?.split(":")[1])
            .set("second", checkinDetails?.check_in_time?.split(":")[2])
        : null
    );
    setEditCheckinDetailsId(checkinDetails.id);
    setEditCheckInCheckOutDetails(checkinDetails);
  };

  const getTwentyFourHourTime = (time) => {
    if (time) {
      let hours = Number(time.match(/^(\d+)/)[1]);
      let minutes = Number(time.match(/:(\d+)/)[1]);
      let AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM === "PM" && hours < 12) hours = hours + 12;
      if (AMPM === "AM" && hours === 12) hours = hours - 12;
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes + ":00";
    }
  };
  const handleCloseDialog = () => {
    if (breakType === "Delete") {
      setBreakId(0);
      setBreakType(null);
      setBreakPopup(false);
    } else {
      setBreakDayDetails(null);
      setBreakPopup(false);
      setBreakOutError(null);
      setBreakInError(null);
      setBreakInTime(null);
      setBreakOutTime(null);
      setBreakType(null);
    }
  };
  const validate = () => {
    if (breakInTime === null) {
      setBreakInError(leaveManagementError.breakInError);
    } else {
      setBreakInError("");
    }
    if (breakOutTime === null) {
      setBreakOutError(leaveManagementError.breakOutError);
    } else {
      setBreakOutError("");
    }
  };

  const createNewBreaks = async () => {
    const breakInData = getTwentyFourHourTime(breakInTime);
    const breakOutData = getTwentyFourHourTime(breakOutTime);
    const checkBreakOutTime =
      parseInt(breakOutData?.split(":")[0]) * 60 +
      parseInt(breakOutData?.split(":")[1]);
    const checkBreakInTime =
      parseInt(breakInData?.split(":")[0]) * 60 +
      parseInt(breakInData?.split(":")[1]);
    if (breakInTime && breakOutTime) {
      if (checkBreakInTime > checkBreakOutTime) {
        try {
          const response = await fetch(`${domain}break-user/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              break_in: getTwentyFourHourTime(breakInTime),
              break_out: getTwentyFourHourTime(breakOutTime),
              user: personExpandableId,
              date: breakDayDetails?.date?.split("-").reverse().join("-"),
            }),
          });
          const res = await response.json();
          if (response.ok) {
            handleCloseDialog();
            showToast("success", leaveSuccess.breakInOutTime);
            setEditBreakErrors("");
            dispatch(setReloadHRMSApi(true));
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        }
      } else {
        setEditBreakErrors(leaveManagementError.breakError);
      }
    } else {
      if (breakInTime === null) {
        setBreakInError(leaveManagementError.breakInError);
      } else {
        setBreakOutError(leaveManagementError.breakOutError);
      }
    }
  };
  const updateEditDetails = async () => {
    let validation = false;
    if (
      handleCheckInError === "" ||
      handleCheckInError === "Checkin time is greater then check out time" ||
      handleCheckInError === "Check-in time to be earlier than check-out time."
    ) {
      const checkInData = checkInTime
        ? getTwentyFourHourTime(moment(checkInTime["$d"]).format("hh:mm A"))
        : null;
      const checkOutData = checkOutTime
        ? getTwentyFourHourTime(moment(checkOutTime["$d"]).format("hh:mm A"))
        : null;
      const checkTotalCheckOutTime =
        parseInt(checkOutData?.split(":")[0]) * 60 +
        parseInt(checkOutData?.split(":")[1]);
      const checkTotalCheckInTime =
        parseInt(checkInData?.split(":")[0]) * 60 +
        parseInt(checkInData?.split(":")[1]);
      if (checkTotalCheckInTime < checkTotalCheckOutTime) {
        validation = true;
      } else {
        validation = false;
        setHandleCheckInError(leaveManagementError.checkInError);
      }
    } else {
      if (checkOutTime?._d) {
        validation = false;
      } else if (
        handleCheckInError === "Invalid check-in format" ||
        handleCheckInError === "Invalid check-out format"
      ) {
        validation = false;
      } else {
        validation = true;
      }
    }
    if (validation) {
      if (
        editCheckinCheckOutTime?.status === "A" ||
        editCheckinCheckOutTime?.status === "H" ||
        editCheckinCheckOutTime?.status === "W"
      ) {
        try {
          const response = await fetch(`${domain}check-in-entries/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              check_in_time: checkInTime["$d"]
                ? getTwentyFourHourTime(
                    moment(checkInTime["$d"]).format("hh:mm A")
                  )
                : null,
              check_out_time: checkOutTime["$d"]
                ? getTwentyFourHourTime(
                    moment(checkOutTime["$d"]).format("hh:mm A")
                  )
                : null,
              date: editCheckinCheckOutTime.date
                ?.split("-")
                .reverse()
                .join("-"),
              start_date: editCheckinCheckOutTime.date
                .split("-")
                .reverse()
                .join("-"),
              end_date: editCheckinCheckOutTime.date
                .split("-")
                .reverse()
                .join("-"),
              user: personExpandableId,
              // location:99999,
            }),
          });
          const res = await response.json();
          if (response.ok) {
            setCheckInTime(null);
            setCheckOutTime(null);
            setEditCheckInCheckOutDetails(null);
            showToast("success", leaveSuccess.checkInOutTime);
            setHandleCheckInError("");

            dispatch(setReloadHRMSApi(true));
            setEditCheckinDetailsId(0);
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        }
      } else {
        try {
          const response = await fetch(`${domain}update_checkout_time/`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              id: editCheckinCheckOutTime.id,
              check_out_time: checkOutTime["$d"]
                ? getTwentyFourHourTime(
                    moment(checkOutTime["$d"]).format("hh:mm A")
                  )
                : null,
              check_in_time: checkInTime["$d"]
                ? getTwentyFourHourTime(
                    moment(checkInTime["$d"]).format("hh:mm A")
                  )
                : null,
            }),
          });
          const res = await response.json();
          if (response.ok) {
            setCheckInTime(null);
            setCheckOutTime(null);
            setEditCheckInCheckOutDetails(null);
            showToast("success", leaveSuccess.checkInOutTime);
            setHandleCheckInError("");
            dispatch(setReloadHRMSApi(true));
            setEditCheckinDetailsId(0);
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        }
        setEditCheckInCheckOutDetails(null);
      }
    }
  };

  const updateBreakDetailsDetails = async () => {
    const breakInData = getTwentyFourHourTime(
      moment(editBreakInTime["$d"]).format("hh:mm A")
    );
    const breakOutData = getTwentyFourHourTime(
      moment(editBreakOutTime["$d"]).format("hh:mm A")
    );
    const checkTotalBreakOutTime =
      parseInt(breakOutData?.split(":")[0]) * 60 +
      parseInt(breakOutData?.split(":")[1]);
    const checkTotalBreakInTime =
      parseInt(breakInData?.split(":")[0]) * 60 +
      parseInt(breakInData?.split(":")[1]);
    if (checkTotalBreakOutTime < checkTotalBreakInTime) {
      try {
        const response = await fetch(
          `${domain}break-user/${editBreakDetails?.id}/`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              user: personExpandableId,
              break_in: getTwentyFourHourTime(
                moment(editBreakInTime["$d"]).format("hh:mm A")
              ),
              break_out: getTwentyFourHourTime(
                moment(editBreakOutTime["$d"]).format("hh:mm A")
              ),
              date: editBreakDetails?.date?.split("-").reverse().join("-"),
            }),
          }
        );
        const res = await response.json();
        if (response.ok) {
          setEditBreakDetails(null);
          setEditBreakErrors("");
          dispatch(setReloadHRMSApi(true));
          showToast("success", leaveSuccess.breakInOutTime);
          setEditCheckinDetailsId(0);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    } else {
      setEditBreakErrors(leaveManagementError.editBreakError);
    }
  };

  const handleBreakEnd = (newValue) => {
    if (
      newValue &&
      newValue["$d"] &&
      newValue["$d"].toString() !== "Invalid Date"
    ) {
      setEditBreakInTime(dayjs(newValue["$d"]));
      setEditBreakErrors(null);
    } else {
      setEditBreakErrors("Invalid Break Start format");
    }
  };

  const handleBreakStart = (newValue) => {
    if (
      newValue &&
      newValue["$d"] &&
      newValue["$d"].toString() !== "Invalid Date"
    ) {
      setEditBreakOutTime(dayjs(newValue["$d"]));
      setEditBreakErrors(null);
    } else {
      setEditBreakErrors("Invalid Break Start format");
    }
  };

  const deleteBreakDetails = async (id) => {
    try {
      const response = await fetch(`${domain}break-user/${id}/`, {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });

      if (response.ok) {
        showToast("error", "Break deleted successfully");
        dispatch(setReloadHRMSApi(true));
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        const res = await response.json();
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const updateCheckInTime = (newValue) => {
    if (newValue) {
      if (newValue["$d"].toString() !== "Invalid Date") {
        setCheckInTime(dayjs(newValue["$d"]));
        setHandleCheckInError("");
      } else {
        setHandleCheckInError("Invalid check-in format");
      }
    }
  };

  const updateCheckOutTime = (newValue) => {
    if (newValue) {
      if (newValue["$d"].toString() !== "Invalid Date") {
        setCheckOutTime(dayjs(newValue["$d"]));
        setHandleCheckInError("");
      } else {
        setHandleCheckInError("Invalid check-out format");
      }
    }
  };

  const handleBreakDetails = (breakDetail) => {
    const parseTime = (timeString) => {
      if (!timeString)
        return dayjs().set("hour", 0).set("minute", 0).set("second", 0);

      const [hour = 0, minute = 0, second = 0] = timeString
        .split(":")
        .map(Number);
      return dayjs()
        .set("hour", hour)
        .set("minute", minute)
        .set("second", second);
    };

    setEditBreakInTime(parseTime(breakDetail.break_in));
    setEditBreakOutTime(parseTime(breakDetail.break_out));
    setEditBreakDetails(breakDetail);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (expand) {
      setShowDetails(0);
      setExpand(false);
    }
  }, [expand, setExpand]);

  return loading === true ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      {departmentMembers?.length !== 0 ? (
        <Mui.Stack sx={{ paddingTop: 2 }}>
          <Mui.TableContainer
            sx={{
              paddingLeft: 3,
              width: "100%",
              minHeight: "95%",
              paddingRight: 3,
            }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                }}
              >
                <Mui.TableRow maxWidth="xl" align="left">
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  ></Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Name
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Shift
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Check-In
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Check-Out
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Breaks
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Shift Hours
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Over Time Hours
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Unit
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Total Working hours
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Leave Credit Hours
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Status
                  </Mui.TableCell>
                  {currentUserDetails.role === "Team Lead" ||
                  currentUserDetails.role === "Admin" ? (
                    <Mui.TableCell
                      sx={{
                        color: themes.blackColor,
                        fontWeight: "bold",
                      }}
                    >
                      Action
                    </Mui.TableCell>
                  ) : null}
                  <Mui.TableCell></Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {filterDepartMemberList.length === 0 ? (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={14} sx={{ padding: 1 }}>
                      <Mui.Alert severity="info">
                        There is a no data available
                      </Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  filterDepartMemberList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((item) => (
                      <>
                        <Mui.TableRow maxWidth="xl" align="left">
                          <Mui.TableCell
                            sx={{
                              color: themes.blackColor,
                              fontWeight: "bold",
                            }}
                          >
                            {item?.id === showDetails ? (
                              <Mui.IconButton
                                onClick={() => {
                                  setShowDetails(0);
                                  setEditCheckinDetailsId(0);
                                }}
                              >
                                <KeyboardArrowDownIcon />
                              </Mui.IconButton>
                            ) : (
                              <Mui.IconButton
                                onClick={() => {
                                  setPersonExpandableId(item?.user_id);
                                  setShowDetails(item?.id);
                                  setEditCheckinDetailsId(0);
                                }}
                                style={{
                                  padding: "0px",
                                }}
                              >
                                <ChevronRightIcon />
                              </Mui.IconButton>
                            )}
                          </Mui.TableCell>
                          <Mui.TableCell
                            sx={{
                              color: themes.blackColor,
                              fontWeight: "bold",
                            }}
                          >
                            <Mui.Typography>
                              {item?.user_name ? item?.user_name : "N/A"}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell
                            sx={{
                              color: themes.blackColor,
                              fontWeight: "bold",
                            }}
                          >
                            <Mui.Typography>
                              {item?.shift_name ? item?.shift_name : "N/A"}
                            </Mui.Typography>
                          </Mui.TableCell>

                          {editCheckinDetailsId === item.id ? (
                            <Mui.TableCell>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["TimePicker"]}>
                                  <TimePicker
                                    label="check-in"
                                    size="small"
                                    value={checkInTime}
                                    onChange={updateCheckInTime}
                                    slotProps={{
                                      textField: {
                                        size: "small",
                                        style: {
                                          width: 32,
                                          height: 40,
                                        },
                                      },
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                              <Mui.Typography
                                sx={{
                                  color: themes.redColor,
                                  fontSize: 12,
                                }}
                              >
                                {handleCheckInError ===
                                "Checkin time is greater then check out time"
                                  ? !checkInTime
                                    ? "Please enter check in time"
                                    : "Check-in time is greater then check out time"
                                  : null}

                                {handleCheckInError ===
                                "Check-in time to be earlier than check-out time."
                                  ? !checkInTime
                                    ? "Please enter check in time"
                                    : ""
                                  : null}

                                {handleCheckInError ===
                                "Invalid check-in format"
                                  ? "Invalid check-in format"
                                  : null}
                              </Mui.Typography>
                            </Mui.TableCell>
                          ) : (
                            <Mui.TableCell>
                              {item?.check_in_time_formatted}
                            </Mui.TableCell>
                          )}

                          {editCheckinDetailsId === item.id ? (
                            <Mui.TableCell>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["TimePicker"]}>
                                  <TimePicker
                                    label="check-out"
                                    value={checkOutTime}
                                    onChange={(newValue) =>
                                      updateCheckOutTime(newValue)
                                    }
                                    sx={{ width: 25 }}
                                    slotProps={{
                                      textField: {
                                        size: "small",
                                        style: {
                                          width: 32,
                                          height: 40,
                                        },
                                      },
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                              <Mui.Typography
                                sx={{
                                  color: themes.redColor,
                                  fontSize: 12,
                                }}
                              >
                                {handleCheckInError ===
                                "Invalid check-out format"
                                  ? "Invalid check-out format"
                                  : null}
                                {handleCheckInError ===
                                "Check-in time to be earlier than check-out time."
                                  ? !checkOutTime
                                    ? "Please enter check out time"
                                    : "Check-in time to be earlier than check-out time."
                                  : null}
                              </Mui.Typography>
                            </Mui.TableCell>
                          ) : (
                            <Mui.TableCell>
                              {item?.check_out_time_formatted}
                            </Mui.TableCell>
                          )}
                          {item?.status === "A" || item?.status === "W" ? (
                            <Mui.TableCell></Mui.TableCell>
                          ) : (
                            <Mui.TableCell>
                              {item?.total_break_time
                                ? item?.total_break_time
                                : "N/A"}
                            </Mui.TableCell>
                          )}
                          {item?.status === "A" || item?.status === "W" ? (
                            <Mui.TableCell></Mui.TableCell>
                          ) : (
                            <Mui.TableCell>
                              {item?.shift_hours ? item?.shift_hours : "N/A"}
                            </Mui.TableCell>
                          )}
                          {item?.status === "A" || item?.status === "W" ? (
                            <Mui.TableCell></Mui.TableCell>
                          ) : (
                            <Mui.TableCell>
                              {item?.total_over_time
                                ? item?.total_over_time
                                : "N/A"}
                            </Mui.TableCell>
                          )}
                          <Mui.TableCell>
                            {item?.shift_default_unit}
                          </Mui.TableCell>
                          {item?.status === "A" || item?.status === "W" ? (
                            <Mui.TableCell></Mui.TableCell>
                          ) : (
                            <Mui.TableCell>
                              {item?.total_work_time
                                ? item?.total_work_time
                                : "N/A"}
                            </Mui.TableCell>
                          )}
                          {item?.status === "A" ||
                          item?.status === "W" ||
                          item?.status === "P" ||
                          item?.status === "PR" ? (
                            <Mui.TableCell></Mui.TableCell>
                          ) : (
                            <Mui.TableCell>
                              {item?.Leave_credit_hours}
                            </Mui.TableCell>
                          )}

                          <Mui.TableCell>
                            <Mui.Typography
                              sx={{
                                display: "inline-block",
                                width: "28px",
                                color:
                                  item.status === "W"
                                    ? themes.blackColor
                                    : themes.whiteColor,
                                border:
                                  item.status === "W"
                                    ? "1px solid black"
                                    : "none",
                                height: "28px",
                                textAlign: "center",
                                lineHeight: "29px",
                                backgroundColor: getColorWorkingHours(
                                  item.status
                                ),
                              }}
                            >
                              {item.status === "HL" ? (
                                <Mui.Grid container>
                                  <Mui.Typography
                                    sx={{
                                      width: "14px",
                                      height: "28px",
                                      textAlign: "center",
                                      lineHeight: "29px",
                                      backgroundColor:
                                        item.half_day_leave_period === "2nd"
                                          ? themes.greenColor
                                          : themes.yellowColor,
                                    }}
                                  >
                                    H
                                  </Mui.Typography>
                                  <Mui.Typography
                                    sx={{
                                      width: "14px",
                                      height: "28px",
                                      textAlign: "center",
                                      lineHeight: "29px",
                                      backgroundColor:
                                        item.half_day_leave_period === "2nd"
                                          ? themes.yellowColor
                                          : themes.greenColor,
                                    }}
                                  >
                                    L
                                  </Mui.Typography>
                                </Mui.Grid>
                              ) : item.status === "PL" ||
                                item.status === "LP" ? (
                                <Mui.Grid container>
                                  {item.status === "PL" && (
                                    <>
                                      <Mui.Typography
                                        sx={{
                                          width: "14px",
                                          height: "28px",
                                          textAlign: "center",
                                          lineHeight: "29px",
                                          backgroundColor: themes.greenColor,
                                        }}
                                      >
                                        P
                                      </Mui.Typography>
                                      <Mui.Typography
                                        sx={{
                                          width: "14px",
                                          height: "28px",
                                          textAlign: "center",
                                          lineHeight: "29px",
                                          backgroundColor: themes.yellowColor,
                                        }}
                                      >
                                        L
                                      </Mui.Typography>
                                    </>
                                  )}
                                  {item.status === "LP" && (
                                    <>
                                      <Mui.Typography
                                        sx={{
                                          width: "14px",
                                          height: "28px",
                                          textAlign: "center",
                                          lineHeight: "29px",
                                          backgroundColor: themes.yellowColor,
                                        }}
                                      >
                                        L
                                      </Mui.Typography>
                                      <Mui.Typography
                                        sx={{
                                          width: "14px",
                                          height: "28px",
                                          textAlign: "center",
                                          lineHeight: "29px",
                                          backgroundColor: themes.greenColor,
                                        }}
                                      >
                                        P
                                      </Mui.Typography>
                                    </>
                                  )}
                                </Mui.Grid>
                              ) : (
                                item.status
                              )}
                            </Mui.Typography>
                          </Mui.TableCell>
                          {(item?.department_lead_id !== item?.user_id &&
                            item?.department_lead_id ===
                              currentUserDetails.id &&
                            currentUserDetails.role === "Team Lead") ||
                          currentUserDetails.role === "Admin" ? (
                            <Mui.TableCell>
                              {editCheckinDetailsId === item.id ? (
                                <>
                                  <Mui.Grid container xs={12}>
                                    <Mui.Grid item xs={3}>
                                      <Mui.IconButton
                                        onClick={() => updateEditDetails()}
                                      >
                                        <Done
                                          sx={{
                                            color: themes.greenColor,
                                            strokeWidth: 2,
                                          }}
                                        />
                                      </Mui.IconButton>
                                    </Mui.Grid>
                                    <Mui.Grid item xs={6}></Mui.Grid>
                                    <Mui.Grid item xs={3}>
                                      <Mui.IconButton
                                        onClick={() => {
                                          setEditCheckinDetailsId(0);
                                          setEditCheckInCheckOutDetails(null);
                                          setHandleCheckInError("");
                                        }}
                                      >
                                        <Cancel
                                          sx={{
                                            color: themes.redColor,
                                            strokeWidth: 2,
                                          }}
                                        />
                                      </Mui.IconButton>
                                    </Mui.Grid>
                                  </Mui.Grid>
                                </>
                              ) : (
                                <>
                                  <Mui.Grid container xs={12}>
                                    <Mui.Grid item xs={5}>
                                      <Mui.IconButton
                                        onClick={() => {
                                          handleCheckInCheckOutTime(item);
                                          setPersonExpandableId(item?.user_id);
                                        }}
                                      >
                                        <DriveFileRenameOutline />
                                      </Mui.IconButton>
                                    </Mui.Grid>

                                    <Mui.Grid item xs={5}>
                                      <Mui.IconButton
                                        onClick={() => {
                                          setPersonExpandableId(item?.user_id);
                                          setBreakDayDetails(item);
                                          setBreakType("Add");
                                          setBreakPopup(true);
                                        }}
                                      >
                                        <Loupe />
                                      </Mui.IconButton>
                                    </Mui.Grid>
                                  </Mui.Grid>
                                </>
                              )}
                            </Mui.TableCell>
                          ) : null}
                          <Mui.TableCell></Mui.TableCell>
                        </Mui.TableRow>
                        {showDetails === item?.id && (
                          <Mui.TableRow>
                            <Mui.TableCell colSpan={13}>
                              <Mui.TableContainer
                                sx={{
                                  marginTop: 0,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  width: "100%px",
                                }}
                                className="w-96 border rounded"
                              >
                                {item?.break_details?.length !== 0 ? (
                                  <Mui.Stack>
                                    <Mui.Table className="border">
                                      <Mui.TableHead
                                        sx={{
                                          backgroundColor: themes.primary,
                                        }}
                                      >
                                        <Mui.TableRow>
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.blackColor,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Break Start
                                          </Mui.TableCell>
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.blackColor,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Break End
                                          </Mui.TableCell>
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.blackColor,
                                              fontWeight: "bold",
                                            }}
                                          >
                                            Break-Time
                                          </Mui.TableCell>
                                          {(item?.department_lead_id !==
                                            item?.user_id &&
                                            item?.department_lead_id ===
                                              currentUserDetails.id &&
                                            currentUserDetails.role ===
                                              "Team Lead") ||
                                          currentUserDetails.role ===
                                            "Admin" ? (
                                            <Mui.TableCell
                                              sx={{
                                                color: themes.blackColor,
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Action
                                            </Mui.TableCell>
                                          ) : null}
                                        </Mui.TableRow>
                                      </Mui.TableHead>
                                      <Mui.TableBody>
                                        {item?.break_details?.map(
                                          (breaks, index) => (
                                            <Mui.TableRow key={index}>
                                              {editBreakDetails?.id ===
                                              breaks?.id ? (
                                                <Mui.TableCell>
                                                  <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                  >
                                                    <DemoContainer
                                                      components={[
                                                        "TimePicker",
                                                      ]}
                                                    >
                                                      <TimePicker
                                                        label="Break Start"
                                                        size={5}
                                                        value={editBreakOutTime}
                                                        onChange={(newValue) =>
                                                          handleBreakStart(
                                                            newValue
                                                          )
                                                        }
                                                      />
                                                    </DemoContainer>
                                                    <Mui.Typography
                                                      sx={{
                                                        color: "red",
                                                        fontSize: 12,
                                                      }}
                                                    >
                                                      {ediBreakErrors ===
                                                      "Invalid break time format"
                                                        ? "Invalid break start/end time format"
                                                        : ediBreakErrors ===
                                                          "Invalid Break Start format"
                                                        ? ediBreakErrors
                                                        : null}
                                                      {ediBreakErrors ===
                                                      "Breakout time to be earlier than break start time"
                                                        ? ediBreakErrors
                                                        : null}
                                                    </Mui.Typography>
                                                  </LocalizationProvider>
                                                </Mui.TableCell>
                                              ) : (
                                                <Mui.TableCell>
                                                  {breaks?.break_out_formatted}
                                                </Mui.TableCell>
                                              )}
                                              {editBreakDetails?.id ===
                                              breaks?.id ? (
                                                <Mui.TableCell>
                                                  <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                  >
                                                    <DemoContainer
                                                      components={[
                                                        "TimePicker",
                                                      ]}
                                                    >
                                                      <TimePicker
                                                        label="Break End"
                                                        size={5}
                                                        value={editBreakInTime}
                                                        onChange={(newValue) =>
                                                          handleBreakEnd(
                                                            newValue
                                                          )
                                                        }
                                                      />
                                                    </DemoContainer>
                                                    <Mui.Typography
                                                      sx={{
                                                        color: "red",
                                                        fontSize: 12,
                                                      }}
                                                    >
                                                      {ediBreakErrors ===
                                                      "Invalid break time format"
                                                        ? "Invalid break start/end time format"
                                                        : ediBreakErrors ===
                                                          "Invalid Break Start format"
                                                        ? ediBreakErrors
                                                        : null}
                                                      {ediBreakErrors ===
                                                      "Breakout time to be earlier than break start time"
                                                        ? ediBreakErrors
                                                        : null}
                                                    </Mui.Typography>
                                                  </LocalizationProvider>
                                                </Mui.TableCell>
                                              ) : (
                                                <Mui.TableCell>
                                                  {breaks?.break_in_formatted}
                                                </Mui.TableCell>
                                              )}
                                              <Mui.TableCell>
                                                {breaks?.individual_break_time}
                                              </Mui.TableCell>
                                              {(item?.department_lead_id !==
                                                item?.user_id &&
                                                item?.department_lead_id ===
                                                  currentUserDetails.id &&
                                                currentUserDetails.role ===
                                                  "Team Lead") ||
                                              currentUserDetails.role ===
                                                "Admin" ? (
                                                <Mui.TableCell>
                                                  {editBreakDetails?.id ===
                                                  breaks?.id ? (
                                                    <>
                                                      <Mui.IconButton
                                                        onClick={() =>
                                                          updateBreakDetailsDetails()
                                                        }
                                                      >
                                                        <Done
                                                          sx={{
                                                            color:
                                                              themes.greenColor,
                                                            strokeWidth: 2,
                                                          }}
                                                        />
                                                      </Mui.IconButton>
                                                      <Mui.IconButton
                                                        onClick={() => {
                                                          setEditBreakDetails(
                                                            null
                                                          );
                                                          setEditBreakErrors();
                                                        }}
                                                      >
                                                        <Cancel
                                                          sx={{
                                                            color:
                                                              themes.redColor,
                                                            strokeWidth: 2,
                                                          }}
                                                        />
                                                      </Mui.IconButton>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <Mui.IconButton
                                                        onClick={() => {
                                                          handleBreakDetails(
                                                            breaks
                                                          );
                                                        }}
                                                      >
                                                        <DriveFileRenameOutline size="small" />
                                                      </Mui.IconButton>

                                                      <Mui.IconButton
                                                        onClick={() => {
                                                          setBreakType(
                                                            "Delete"
                                                          );
                                                          setBreakPopup(true);
                                                          setBreakId(breaks.id);
                                                        }}
                                                      >
                                                        <DeleteSharpIcon
                                                          size="large"
                                                          sx={{
                                                            color:
                                                              themes.redColor,
                                                            strokeWidth: 2,
                                                          }}
                                                        />
                                                      </Mui.IconButton>
                                                    </>
                                                  )}
                                                </Mui.TableCell>
                                              ) : null}
                                            </Mui.TableRow>
                                          )
                                        )}
                                      </Mui.TableBody>
                                      <Mui.TableBody>
                                        <Mui.TableRow>
                                          <Mui.TableCell></Mui.TableCell>
                                          <Mui.TableCell>
                                            {" "}
                                            <Mui.Typography>
                                              Total
                                            </Mui.Typography>
                                          </Mui.TableCell>
                                          <Mui.TableCell>
                                            {item?.total_break_time
                                              ? item?.total_break_time
                                              : "N/A"}
                                          </Mui.TableCell>
                                        </Mui.TableRow>
                                      </Mui.TableBody>
                                    </Mui.Table>
                                  </Mui.Stack>
                                ) : (
                                  <Mui.Stack
                                    sx={{ padding: 0.5 }}
                                    spacing={2}
                                    fullWidth
                                  >
                                    <Mui.Alert severity="info">
                                      There is a no available breaks
                                    </Mui.Alert>
                                  </Mui.Stack>
                                )}
                              </Mui.TableContainer>
                            </Mui.TableCell>
                          </Mui.TableRow>
                        )}
                      </>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
            {filterDepartMemberList?.length > 10 && (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[10, 20, 50, 100]}
                count={filterDepartMemberList?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Mui.TableContainer>
        </Mui.Stack>
      ) : (
        <Mui.Alert severity="info" sx={{ width: "100%", margin: 2 }}>
          No members are there in this group
        </Mui.Alert>
      )}
      <GlobalDialogBox
        key={breakType}
        handleCloseDialog={handleCloseDialog}
        open={breakPopup}
        title={breakType === "Delete" ? "Delete Break" : "Add Breaks"}
      >
        {breakType === "Delete" ? (
          <>
            <Mui.Grid container>
              <Mui.Grid item xs={12}>
                <Mui.Typography fontSize={24}>
                  Are you sure you want to delete ?
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid></Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid item xs={8}></Mui.Grid>
              <Mui.Grid item xs={2}>
                <CustomButton
                  actionFuntion={handleCloseDialog}
                  actionName="No"
                  typeName="submit"
                />
              </Mui.Grid>
              <Mui.Grid item xs={2}>
                <CustomButton
                  actionFuntion={() => {
                    deleteBreakDetails(breakId);
                  }}
                  actionName="Yes"
                  typeName="submit"
                />
              </Mui.Grid>
            </Mui.Grid>
          </>
        ) : breakType === "Add" ? (
          <>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid item xs={5.5}>
                <Mui.Typography>Date</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={1} sx={{ paddingLeft: 2 }}>
                <Mui.Typography>:</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={5.5}>
                <Mui.Typography>
                  {breakDayDetails ? breakDayDetails?.date : null}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid item xs={5.5}>
                <Mui.Typography>Break Start Time</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={1}></Mui.Grid>
              <Mui.Grid item xs={5.5}>
                <Mui.Typography>Break End Time</Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid item xs={5.5}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      label="Break Start"
                      onChange={(newValue) => {
                        setBreakOutTime(
                          moment(newValue["$d"]).format("hh:mm A")
                        );
                        validate();
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {breakOutError === "Please enter breakout time" ? (
                  <Mui.Typography sx={{ color: themes.redColor, fontSize: 12 }}>
                    {breakOutError}
                  </Mui.Typography>
                ) : null}
              </Mui.Grid>
              <Mui.Grid item xs={1} sx={{ paddingTop: 3, paddingLeft: 2 }}>
                <Mui.Typography> To</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={5.5}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      label="Break End"
                      onChange={(newValue) => {
                        setBreakInTime(
                          moment(newValue["$d"]).format("hh:mm A")
                        );
                        validate();
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {breakInError === "Please enter breakin time" ? (
                  <Mui.Typography sx={{ color: "red" }}>
                    {breakInError}
                  </Mui.Typography>
                ) : null}
              </Mui.Grid>
              <Mui.Typography sx={{ color: themes.redColor, fontSize: 12 }}>
                {ediBreakErrors === "Breakout time is lesser then breakin time"
                  ? ediBreakErrors
                  : null}{" "}
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingTop: 2 }}>
              <Mui.Grid item xs={10}></Mui.Grid>
              <Mui.Grid item xs={2}>
                <Mui.Button
                  sx={{
                    backgroundColor: themes.button,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.button,
                    },
                  }}
                  onClick={() => createNewBreaks()}
                >
                  Create
                </Mui.Button>
              </Mui.Grid>
            </Mui.Grid>
          </>
        ) : null}
      </GlobalDialogBox>
    </React.Fragment>
  );
};
