import React, { useState } from "react";
import * as Mui from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";
import { themes } from "services/constants";
import { reportNames } from "services/constants";
import { WorkingHours } from "./WorkingHours";
import AnomaliesReport from "./Anomalies";

export const Reports = () => {
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [listViewPage, setListViewPage] = useState(0);
  const [reportName, setReportName] = useState("Summarized working hours");
  const [showReports, setShowReport] = useState(false);
  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  return (
    <React.Fragment>
      {!showReports ? (
        <Mui.TableContainer component={Mui.Paper} sx={{ mt: 8, width: "100%" }}>
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Report Name
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Action
                </Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {reportNames
                ?.slice(
                  listViewPage * rowsPerPage,
                  listViewPage * rowsPerPage + rowsPerPage
                )
                .map((name) => (
                  <Mui.TableRow>
                    <Mui.TableCell sx={{ color: themes.blackColor }}>
                      <Mui.Typography>{name.label}</Mui.Typography>
                    </Mui.TableCell>

                    <Mui.TableCell sx={{ color: themes.blackColor }}>
                      <Mui.IconButton
                        onClick={() => {
                          setShowReport(true);
                          setReportName(name.label);
                        }}
                      >
                        <RemoveRedEye />
                      </Mui.IconButton>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ))}
            </Mui.TableBody>
          </Mui.Table>
          {reportNames.length > 20 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[20, 50, 100]}
              count={reportNames.length}
              rowsPerPage={rowsPerPage}
              page={listViewPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.TableContainer>
      ) : reportName !== "Attendance Anomalies Report" ? (
        <WorkingHours setShowReport={setShowReport} reportName={reportName} />
      ) : reportName === "Attendance Anomalies Report" ? (
        <AnomaliesReport setShowReport={setShowReport} />
      ) : null}
    </React.Fragment>
  );
};
