import React, { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import {
  MenuItem,
  Tooltip,
  ListItemIcon,
  IconButton,
  Menu,
  Avatar,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { themes } from "services/constants";
import { CustomButton } from "components/CustomComponents/CustomButton";
import Logout from "@mui/icons-material/Logout";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import {
  setEditUserDetailsApi,
  setProfileEditBack,
  setEditUserId,
  setSidebarExpand,
} from "services/Redux/userManagament";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
export const DropdownMenu = () => {
  let userDetails = useSelector((state) => state.tokenReducer.userDetails);
  const role =
    userDetails?.role === "User" && userDetails?.teamlead === true
      ? "TeamLead"
      : userDetails?.role;

  let firstLetter = userDetails?.first_name?.charAt(0).toUpperCase();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const history = useHistory();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const dispatch = useDispatch();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const moveToProfile = () => {
    setAnchorEl(null);
    dispatch(setSidebarExpand(false));
    dispatch(setEditUserId(userDetails?.id));
    dispatch(setEditUserDetailsApi(true));
    dispatch(setProfileEditBack(false));
    dispatch(setGlobalSearchValue(""));
    history.push("/admin/edit/profile/");
  };

  const logoutCurrentUser = () => {
    setAnchorEl(null);
    localStorage.clear();
    localStorage.setItem("LoginTag", "Email");
    window.location.reload();
  };

  const moveToChangePwd = () => {
    setAnchorEl(null);
    dispatch(setGlobalSearchValue(""));
    history.push("/admin/changepassword");
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar
            sx={{
              width: 32,
              height: 32,
              bgcolor: "purple",
            }}
            alt={firstLetter}
            src={userDetails?.image_url}
          />
          <Tooltip title={userDetails?.first_name}>
            <Typography
              sx={{
                color: themes.headLine,
                paddingLeft: 1,
              }}
            >
              {userDetails?.first_name
                ? userDetails?.first_name.length >= 15
                  ? userDetails?.first_name.substr(0, 15) + "..."
                  : userDetails?.first_name
                : "N/A"}
              <Typography sx={{ fontSize: 10, color: themes.headLine }}>
                {role}
              </Typography>
            </Typography>
          </Tooltip>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",

            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={moveToProfile}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          My Profile &nbsp;&nbsp;
        </MenuItem>
        <MenuItem onClick={moveToChangePwd}>
          <ListItemIcon>
            <SyncLockIcon fontSize="small" />
          </ListItemIcon>
          Change Password &nbsp;&nbsp;
        </MenuItem>
        <MenuItem onClick={() => setOpenDialog(true)}>
          {/* logoutCurrentUser */}
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openDialog}
        title="Logout"
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography fontSize={24}>
              Are you sure you want to log out?
            </Typography>
          </Grid>
        </Grid>
        <Grid></Grid>
        <Grid container>
          <Grid item xs={8}></Grid>
          <Grid item xs={2}>
            <CustomButton
              actionFuntion={handleCloseDialogBox}
              actionName="No"
              typeName="submit"
            />
          </Grid>
          <Grid item xs={2}>
            <CustomButton
              actionFuntion={logoutCurrentUser}
              actionName="Yes"
              typeName="submit"
            />
          </Grid>
        </Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
