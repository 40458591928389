import React, { useState } from "react";
import * as Mui from "@mui/material";
import { Info } from "@mui/icons-material";
import moment from "moment";
import { themes } from "services/constants";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { onlyNumberRegex } from "services/constants/patterns";
import { useSelector } from "react-redux";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";

export const RotationalShift = ({
  checked,
  shift_grade_id,
  shift_category_name,
  setAllShifts,
  noShift,
  setNoShift,
  setStartTime,
  startTime,
  setRotationalError,
  rotationalError,
  breakMinutes,
  setBreakMinutes,
  setCheckInPolicy,
  checkInPolicy,
  setStartTimeFormat,
  startTimeFormat,
  overTimeStartStartEarly,
  overTimeendEndDelay,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [startStartEarly, setStartStartEarly] = useState("15");
  const [endStartDelay, setEndStartDelay] = useState("15");
  const [startEndEarly, setStartEndEarly] = useState("15");
  const [endEndDelay, setEndEndDelay] = useState("15");

  const getShiftDetails = async (time) => {
    if (time && time !== "Invalid Date") {
      try {
        const response = await fetch(
          `${domain}shift-over-time-details/?shift_category_name=${shift_category_name}&shift_grade_id=${shift_grade_id}&start_time=${time}&break_time=${breakMinutes}&no_of_shifts=${
            noShift ? noShift : 0
          }&start_start_early=${
            startStartEarly ? startStartEarly : 0
          }&start_end_delay=${
            endStartDelay ? endStartDelay : 0
          }&end_end_early=${startEndEarly ? startEndEarly : 0}&end_end_delay=${
            endEndDelay ? endEndDelay : 0
          }&start_delay=${
            overTimeStartStartEarly ? overTimeStartStartEarly : 0
          }&end_early=${overTimeendEndDelay ? overTimeendEndDelay : 0}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          let overAllData = res?.shifts.map((element) => {
            return {
              ...element,
              startTimeDateFormat: dayjs()
                .set("hour", element?.start_time.split(":")[0])
                .set("minute", element?.start_time.split(":")[1])
                .set("second", "00"),
              endTimeDateFormat: dayjs()
                .set("hour", element?.end_time.split(":")[0])
                .set("minute", element?.end_time.split(":")[1])
                .set("second", "00"),
            };
          });
          setAllShifts(overAllData);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    }
  };

  const handleStartTime = (newValue) => {
    if (newValue && newValue.format) {
      const timeWithDate = newValue ? newValue.toDate() : new Date();
      const time = newValue.format("HH:mm");
      setStartTime(time);
      getShiftDetails(time);
      let minTime = moment.utc(timeWithDate);
      setStartTimeFormat(
        dayjs()
          .set("hour", minTime._d.getHours())
          .set("minute", minTime._d.getMinutes())
          .set("second", minTime._d.getSeconds())
      );
      setRotationalError(false);
    }
  };

  return (
    <>
      <Mui.Grid
        container
        component={Mui.Paper}
        sx={{ padding: 2, marginTop: 1.5 }}
        elevation={2}
      >
        <Mui.Grid container fullWidth sx={{ padding: 1, borderRadius: 3 }}>
          <Mui.Grid item xs={4} sx={{ paddingTop: 1.5 }}>
            <Mui.Typography
              sx={{
                fontSize: 18,
                color: themes.headLine,
                fontWeight: "bold",
              }}
            >
              No of Shifts &nbsp;
              <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                <Info />
              </Mui.Tooltip>
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
            :
          </Mui.Grid>
          <Mui.Grid item xs={6}>
            <Mui.TextField
              value={noShift}
              placeHolder="Enter the no of shift"
              size="small"
              onChange={(event) => {
                const value = event.target.value;
                const isValidNumber = /^[1-6]$/.test(value);
                if (value === "" || isValidNumber) {
                  setNoShift(event.target.value);
                }
              }}
              onBlur={() => {
                getShiftDetails(startTime);
              }}
              helperText={
                rotationalError && (noShift === "" || noShift === "0") ? (
                  <h5 style={{ color: themes.txtErrorColor }}>
                    No of shift is required
                  </h5>
                ) : null
              }
              error={
                rotationalError && (noShift === "" || noShift === "0")
                  ? true
                  : false
              }
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container fullWidth sx={{ padding: 1, borderRadius: 3 }}>
          <Mui.Grid item xs={4} sx={{ paddingTop: 1.5 }}>
            <Mui.Typography
              sx={{
                fontSize: 18,
                color: themes.headLine,
                fontWeight: "bold",
              }}
            >
              First Shift Start Time &nbsp;
              <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                <Info />
              </Mui.Tooltip>
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
            :
          </Mui.Grid>
          <Mui.Grid container xs={6}>
            <Mui.Grid item xs={4.5}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <div sx={{ width: 4 }}>
                    <TimePicker
                      ampm={false}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      value={startTimeFormat || null}
                      slotProps={{
                        textField: {
                          size: "small",
                          width: 15,
                        },
                      }}
                      onChange={(newValue) => {
                        handleStartTime(newValue);
                        // if (
                        //   errorHandling === "Use different time " ||
                        //   errorHandling === "Use different time"
                        // ) {
                        //   validateFields();
                        // }
                      }}
                    />
                  </div>
                </DemoContainer>
              </LocalizationProvider>
              {(!startTime ||
                startTime === "00:00" ||
                startTime !== "Invalid Date") &&
              rotationalError ? (
                <h5 style={{ color: themes.helperTextColor }}>
                  Shift start time is required
                </h5>
              ) : null}
            </Mui.Grid>

            <Mui.Grid xs={0.5}></Mui.Grid>
            <Mui.Grid container xs={3.5} sx={{ paddingTop: 2 }}>
              <Mui.Typography
                sx={{
                  fontSize: 18,
                  color: themes.headLine,
                  fontWeight: "bold",
                }}
              >
                Shift Break (Min){" "}
              </Mui.Typography>
              <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                <Info />
              </Mui.Tooltip>
            </Mui.Grid>
            <Mui.Grid xs={0.5}></Mui.Grid>

            <Mui.Grid containter xs={3} sx={{ paddingTop: 1 }}>
              <Mui.TextField
                size="small"
                value={breakMinutes}
                sx={{ textAlign: "center" }}
                inputProps={{ style: { textAlign: "center" } }}
                onChange={(event) => {
                  if (
                    onlyNumberRegex.test(event.target.value) &&
                    event.target.value.length <= 2
                  ) {
                    setBreakMinutes(event.target.value);
                  }
                }}
                onBlur={() => {
                  getShiftDetails(startTime);
                }}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container fullWidth sx={{ borderRadius: 3, paddingTop: 1 }}>
            <Mui.Grid item xs={4} sx={{ paddingTop: 1.5 }}>
              <Mui.Typography
                sx={{
                  fontSize: 18,
                  color: themes.headLine,
                  fontWeight: "bold",
                }}
              >
                Checkin/Out Policy &nbsp;
                <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                  <Info />
                </Mui.Tooltip>
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
              :
            </Mui.Grid>
            <Mui.Grid container xs={6}>
              <Mui.Grid xs={5.9} component={Mui.Paper}>
                <Mui.Grid
                  xs={12}
                  sx={{
                    backgroundColor: themes.primary,
                    textAlign: "center",
                    padding: 1,
                  }}
                >
                  <Mui.Typography
                    sx={{ fontSize: 17, fontWeight: 600, font: "bold" }}
                  >
                    Start (Minutes)
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid container sx={{ padding: 1 }}>
                  <Mui.Grid item xs={5.7} sx={{ padding: 1 }}>
                    {" "}
                    <Mui.Grid
                      sx={{
                        backgroundColor: themes.primary,
                        textAlign: "center",
                      }}
                    >
                      <Mui.Typography
                        sx={{ fontSize: 15, fontWeight: 600, font: "bold" }}
                      >
                        Early Start
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid container sx={{ paddingBottom: 1 }}></Mui.Grid>
                    <Mui.TextField
                      size="small"
                      value={startStartEarly}
                      inputProps={{ style: { textAlign: "center" } }}
                      onChange={(event) => {
                        if (
                          onlyNumberRegex.test(event.target.value) &&
                          event.target.value.length <= 2
                        ) {
                          setStartStartEarly(event.target.value);
                          setCheckInPolicy({
                            ...checkInPolicy,
                            start_early: event.target.value,
                          });
                        }
                      }}
                      onBlur={() => {
                        getShiftDetails(startTime);
                      }}
                    />
                  </Mui.Grid>
                  <Mui.Grid xs={0.5}></Mui.Grid>

                  <Mui.Grid item xs={5.7} sx={{ padding: 1 }}>
                    {" "}
                    <Mui.Grid
                      xs={12}
                      sx={{
                        backgroundColor: themes.primary,
                        textAlign: "center",
                      }}
                    >
                      <Mui.Typography
                        sx={{ fontSize: 15, fontWeight: 600, font: "bold" }}
                      >
                        Start Late
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid container sx={{ paddingBottom: 1 }}></Mui.Grid>
                    <Mui.TextField
                      size="small"
                      value={endStartDelay}
                      sx={{ textAlign: "center" }}
                      inputProps={{ style: { textAlign: "center" } }}
                      onChange={(event) => {
                        if (
                          onlyNumberRegex.test(event.target.value) &&
                          event.target.value.length <= 2
                        ) {
                          setEndStartDelay(event.target.value);
                          setCheckInPolicy({
                            ...checkInPolicy,
                            start_delay: event.target.value,
                          });
                        }
                      }}
                      onBlur={() => {
                        getShiftDetails(startTime);
                      }}
                    />
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid xs={0.2}></Mui.Grid>
              <Mui.Grid xs={5.9} component={Mui.Paper}>
                <Mui.Grid
                  xs={12}
                  sx={{
                    backgroundColor: themes.primary,
                    textAlign: "center",
                    padding: 1,
                  }}
                >
                  <Mui.Typography
                    sx={{ fontSize: 17, fontWeight: 600, font: "bold" }}
                  >
                    End (Minutes)
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid container sx={{ padding: 1 }}>
                  <Mui.Grid item xs={5.7} sx={{ padding: 1 }}>
                    {" "}
                    <Mui.Grid
                      sx={{
                        backgroundColor: themes.primary,
                        textAlign: "center",
                      }}
                    >
                      <Mui.Typography
                        sx={{ fontSize: 15, fontWeight: 600, font: "bold" }}
                      >
                        Early End
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid container sx={{ paddingBottom: 1 }}></Mui.Grid>
                    <Mui.TextField
                      size="small"
                      value={startEndEarly}
                      inputProps={{ style: { textAlign: "center" } }}
                      sx={{ textAlign: "center" }}
                      onChange={(event) => {
                        if (
                          onlyNumberRegex.test(event.target.value) &&
                          event.target.value.length <= 2
                        ) {
                          setStartEndEarly(event.target.value);
                          setCheckInPolicy({
                            ...checkInPolicy,
                            end_early: event.target.value,
                          });
                        }
                      }}
                      onBlur={() => {
                        getShiftDetails(startTime);
                      }}
                    />
                  </Mui.Grid>
                  <Mui.Grid xs={0.5}></Mui.Grid>

                  <Mui.Grid item xs={5.7} sx={{ padding: 1 }}>
                    {" "}
                    <Mui.Grid
                      xs={12}
                      sx={{
                        backgroundColor: themes.primary,
                        textAlign: "center",
                      }}
                    >
                      <Mui.Typography
                        sx={{ fontSize: 15, fontWeight: 600, font: "bold" }}
                      >
                        End Late
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid container sx={{ paddingBottom: 1 }}></Mui.Grid>
                    <Mui.TextField
                      size="small"
                      value={endEndDelay}
                      inputProps={{ style: { textAlign: "center" } }}
                      sx={{ textAlign: "center" }}
                      onChange={(event) => {
                        if (
                          onlyNumberRegex.test(event.target.value) &&
                          event.target.value.length <= 2
                        ) {
                          setEndEndDelay(event.target.value);
                          setCheckInPolicy({
                            ...checkInPolicy,
                            end_delay: event.target.value,
                          });
                        }
                      }}
                      onBlur={() => {
                        getShiftDetails(startTime);
                      }}
                    />
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
};
