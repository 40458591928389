import * as React from "react";
import { useState } from "react";
import LoginImage from "../../../assets/img/brand/loginHalf.png";
import LoginLogo from "../../../assets/img/brand/organflow 2.0 logo.png";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import * as Mui from "@mui/material";
import { InputAdornment } from "@material-ui/core";
import { LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import sessionHandling from "services/utils/notificationUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { showToast } from "services/utils/Status";
export const NewPassword = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [verified, setVerified] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = async (data) => {
    const urlLink = localStorage.getItem("resetPasswordLink");
    try {
      const response = await fetch(urlLink, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: data.password,
          confirmpassword: data.cpassword,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        console.log(response);
        setVerified(true);
        reset();
        setTimeout(function () {
          history.push("/auth/login");
        }, 10000);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowCPassword = () => {
    setShowCPassword(!showCPassword);
  };

  return (
    <>
      <Mui.Grid
        container
        style={{
          overflowY: "hidden",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        <Mui.Grid item xs={6}>
          <Mui.Paper style={{ height: "100%" }}>
            <img
              src={LoginImage}
              alt="Login"
              style={{
                height: "100%",
                width: "100%",
              }}
            />
            <a href="https://stringconsultants.com/">
              <div
                style={{
                  position: "absolute",
                  left: "25%",
                  bottom: "0.2%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                © 2024 String Consultants. All Rights Reserved
              </div>
            </a>
          </Mui.Paper>
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={5} lg={6}>
          <Mui.Paper style={{ height: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Mui.Grid
                container
                justifyContent="flex-end"
                sx={{ paddingTop: 2, paddingRight: 2 }}
              >
                <img
                  src={LoginLogo}
                  alt="LoginLogo"
                  style={{ height: "22%", width: "22%" }}
                />
              </Mui.Grid>
              {verified ? (
                <Mui.Grid
                  container
                  spacing={2}
                  direction="column"
                  dispay="flex"
                  alignItems="center"
                >
                  <Mui.Grid
                    item
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ minHeight: "14vh" }}
                  ></Mui.Grid>
                  <Mui.Grid item>
                    <Mui.Typography
                      sx={{
                        fontWeight: 800,
                        fontSize: "38px",
                      }}
                    >
                      Ready to Explore !
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item>
                    <Mui.Typography
                      sx={{
                        fontSize: "18px",
                        color: themes.colorShadow,
                        textAlign: "center",
                      }}
                    >
                      Your account password has been changed <br />
                      successfully
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid
                    item
                    xs={12}
                    sx={{
                      marginTop: 5,
                      minWidth: 407,
                      marginLeft: 1,
                    }}
                  >
                    <Mui.Grid justifyContent="center">
                      <Mui.Button
                        variant="contained"
                        type="button"
                        size="large"
                        sx={{
                          minWidth: 407,
                          borderRadius: 3,
                          backgroundColor: themes.loginImage,
                          "&:hover": {
                            border: "none",
                            background: themes.loginImage,
                          },
                          color: themes.whiteColor,
                          textTransform: "none",
                          fontSize: 20,
                        }}
                        onClick={() => {
                          setVerified(true);
                          history.push("/auth/login");
                        }}
                      >
                        Back to Login
                      </Mui.Button>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
              ) : (
                <Mui.Grid
                  container
                  spacing={2}
                  direction="column"
                  dispay="flex"
                  alignItems="center"
                >
                  <Mui.Grid
                    item
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ minHeight: "14vh" }}
                  ></Mui.Grid>
                  <Mui.Grid item>
                    <Mui.Typography
                      sx={{
                        fontWeight: 800,
                        fontSize: "38px",
                      }}
                    >
                      {localStorage.getItem("PasswordHandling") ===
                      "ForgotPassword"
                        ? "Set New Password"
                        : "Create New Password"}{" "}
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} sx={{ marginTop: 2 }}>
                    <Mui.Typography
                      sx={{ fontSize: 20, paddingRight: 15, fontWeight: 600 }}
                    >
                      Password{" "}
                    </Mui.Typography>
                    <Mui.TextField
                      sx={{
                        minWidth: 400,
                      }}
                      size="medium"
                      placeholder="Please enter password"
                      type={showPassword ? "text" : "password"}
                      {...register("password", {
                        required: "Password is required",
                        validate: {
                          lowerCaseCheck: (value) => {
                            if (value.match(/[a-z]/g)) {
                              return true;
                            } else {
                              return "☒ A lowercase letter";
                            }
                          },
                          upperCaseCheck: (value) => {
                            if (value.match(/[A-Z]/g)) {
                              return true;
                            } else {
                              return "☒ A uppercase letter";
                            }
                          },
                          numberCheck: (value) => {
                            if (value.match(/[0-9]/g)) {
                              return true;
                            } else {
                              return "☒ A number";
                            }
                          },
                          totalNumberCheck: (value) => {
                            if (value.trim().length < 8) {
                              return "☒ A minimum 8 characters";
                            } else {
                              return true;
                            }
                          },
                          specialCharacter: (value) => {
                            if (
                              value.match(/^(?=.*[!@#$%^&*(),.?":{}|<>]).*$/g)
                            ) {
                              return true;
                            } else {
                              return "☒ A special character";
                            }
                          },
                        },
                      })}
                      onKeyDown={(event) => {
                        if (event.code === "Space") {
                          event.preventDefault();
                        }
                      }}
                      error={errors.password}
                      helperText={errors.password?.message}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Mui.Badge color="primary" badgeContent={0}>
                              <LockOutlined color="primary" />
                            </Mui.Badge>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Mui.Badge color="primary" badgeContent={0}>
                              <Mui.IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </Mui.IconButton>
                            </Mui.Badge>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Mui.Stack sx={{ paddingTop: 2 }}> </Mui.Stack>
                    <Mui.Typography
                      sx={{ fontSize: 20, paddingRight: 10, fontWeight: 600 }}
                    >
                      Confirm Password{" "}
                    </Mui.Typography>
                    <Mui.TextField
                      sx={{
                        minWidth: 400,
                      }}
                      size="medium"
                      placeholder="Please enter password"
                      type={showCPassword ? "text" : "password"}
                      {...register("cpassword", {
                        required: "Password is required",
                        validate: {
                          lowerCaseCheck: (value) => {
                            if (value.match(/[a-z]/g)) {
                              return true;
                            } else {
                              return "☒ A lowercase letter";
                            }
                          },
                          upperCaseCheck: (value) => {
                            if (value.match(/[A-Z]/g)) {
                              return true;
                            } else {
                              return "☒ A uppercase letter";
                            }
                          },
                          numberCheck: (value) => {
                            if (value.match(/[0-9]/g)) {
                              return true;
                            } else {
                              return "☒ A number";
                            }
                          },
                          passwordValidation: (value) => {
                            if (value === getValues("password")) {
                              return true;
                            } else {
                              return "Password not matched";
                            }
                          },
                          totalNumberCheck: (value) => {
                            if (value.trim().length < 8) {
                              return "☒ A minimum 8 characters";
                            } else {
                              return true;
                            }
                          },
                          specialCharacter: (value) => {
                            if (
                              value.match(/^(?=.*[!@#$%^&*(),.?":{}|<>]).*$/g)
                            ) {
                              return true;
                            } else {
                              return "☒ A special character";
                            }
                          },
                        },
                      })}
                      onKeyDown={(event) => {
                        if (event.code === "Space") {
                          event.preventDefault();
                        }
                      }}
                      error={errors.cpassword}
                      helperText={errors.cpassword?.message}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Mui.Badge color="primary" badgeContent={0}>
                              <LockOutlined color="primary" />
                            </Mui.Badge>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Mui.Badge color="primary" badgeContent={0}>
                              <Mui.IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowCPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </Mui.IconButton>
                            </Mui.Badge>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Mui.Grid
                      item
                      xs={12}
                      sx={{
                        marginTop: 5,
                        minWidth: 407,
                        marginLeft: 1,
                        marginRight: "auto",
                      }}
                    >
                      <Mui.Grid justifyContent="center">
                        <Mui.Button
                          variant="contained"
                          type="submit"
                          size="large"
                          sx={{
                            minWidth: 407,
                            borderRadius: 3,
                            backgroundColor: themes.loginImage,
                            "&:hover": {
                              border: "none",
                              background: themes.loginImage,
                            },
                            color: themes.whiteColor,
                            textTransform: "none",
                            fontSize: 20,
                          }}
                          // onClick={() => {
                          //   setVerified(true);
                          // }}
                        >
                          Save
                        </Mui.Button>
                      </Mui.Grid>
                    </Mui.Grid>

                    <Mui.Grid
                      container
                      xs={12}
                      sx={{ paddingTop: 4, marginTop: 3 }}
                    >
                      <Mui.Grid
                        sx={{
                          borderRight: "3px solid green",
                        }}
                      ></Mui.Grid>
                      <Mui.Grid item xs={6}>
                        <a href="https://organflow.app/about/">
                          <Mui.Typography
                            sx={{
                              fontWeight: 600,
                              borderRight: "3px solid green",
                              textAlign: "center",
                            }}
                          >
                            {" "}
                            About us
                          </Mui.Typography>
                        </a>
                      </Mui.Grid>
                      <Mui.Grid item xs={5.9}>
                        <a href="https://organflow.app/privacy-policy/">
                          <Mui.Typography
                            sx={{
                              fontWeight: 600,
                              borderRight: "3px solid green",
                              textAlign: "center",
                            }}
                          >
                            Privacy Policy
                          </Mui.Typography>
                        </a>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
              )}
            </form>
          </Mui.Paper>
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
};
