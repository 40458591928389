import React, { useState, useEffect } from "react";
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import sessionHandling from "services/utils/notificationUtils";
import { PayRollEmployeeHeader } from "../PayRollHeaders";
import { Loader } from "components/Loader";
import { MoreVert } from "@mui/icons-material";
const filterFields = ["employee_id", "user_name", "status"];

export const PayRollEmployee = () => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const { result: companyList } = useFetch(`${domain}get-active-company/`);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState(99999);
  const [employeeList, setEmployeeList] = useState([]);
  const [masterEmployee, setMasterEmployee] = useState([]);
  const [filterEmployee, setFilterEmployee] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (companyList.length !== 0) {
      const data = companyList.filter((company) => {
        return company.isprimary === true;
      });
      setCompanyId(data[0].id);
      setCompanyName(data[0].companyname);
      setFilterEmployee(true);
    }
  }, [companyList]);

  const handleCompanyName = (event, data) => {
    if (data) {
      setCompanyName(data.companyname);
      setCompanyId(data.id);
    }
  };

  useEffect(() => {
    const fetchemployeeList = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${domain}list/employee?company_id=${companyId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setLoading(false);
          setEmployeeList(res);
          setMasterEmployee(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (filterEmployee) {
      fetchemployeeList();
    }
  }, [companyId, domain, filterEmployee, token]);

  useEffect(() => {
    const filteredResults = masterEmployee.filter((item) =>
      filterFields.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );

    setEmployeeList(filteredResults);
    setPage(globalSearchValue ? 0 : page);
  }, [globalSearchValue, masterEmployee, page]);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <Mui.Grid xs={9}></Mui.Grid>
        <Mui.Grid
          xs={3}
          sx={{
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingRight: 1,
          }}
        >
          <Mui.Typography>Company Name</Mui.Typography>
          <CustomAutoComplete
            dataList={companyList.map((obj) => ({
              ...obj,
              label: obj.companyname,
            }))}
            selectedValue={companyName}
            updateDataFunction={handleCompanyName}
            placeHolder="Please select the company name"
            fullWith
          />
        </Mui.Grid>
      </Mui.Grid>
      <Mui.TableContainer
        component={Mui.Paper}
        sx={{ width: "100%", maxHeight: 850 }}
      >
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
              position: "sticky",
              top: -1,
              zIndex: 10,
            }}
          >
            <Mui.TableRow maxWidth="xl" align="left">
              {PayRollEmployeeHeader.map((header) => (
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  {header.header}
                </Mui.TableCell>
              ))}
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {employeeList.length === 0 ? (
              <Mui.TableRow>
                <Mui.TableCell colSpan={8}>
                  <Mui.Alert severity="info">
                    There is a no available employees
                  </Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              employeeList
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <Mui.TableRow key={data.id}>
                    <>
                      <Mui.TableCell align="left">
                        <Mui.Stack direction="Row">
                          <Mui.Avatar
                            sx={{ width: 25, height: 25 }}
                            alt="Remy Sharp"
                            src={data.image_url}
                          />
                          <Mui.Typography
                            sx={{
                              paddingLeft: 1,
                            }}
                          >
                            {data.user_name ? data.user_name : "N/A"}
                          </Mui.Typography>
                        </Mui.Stack>
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.employee_id ? data?.employee_id : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.ctc ? data?.ctc : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.salary_group ? data?.salary_group : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.status ? data?.status : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        <Mui.IconButton
                          onClick={(e) => {
                            // setEditDetails(data);
                            setAnchorEl(e.currentTarget);
                          }}
                        >
                          <MoreVert
                            sx={{ color: themes.blackColor, strokeWidth: 2 }}
                          />
                        </Mui.IconButton>
                        <Mui.Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          elevation={1}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <Mui.MenuItem
                          // onClick={() => editDetailsConfigurations()}
                          >
                            Activate for payroll
                          </Mui.MenuItem>
                          <Mui.MenuItem
                            onClick={() => {
                              setAnchorEl(null);
                            }}
                          >
                            Inactivate from payroll
                          </Mui.MenuItem>
                          <Mui.MenuItem
                            onClick={() => {
                              setAnchorEl(null);
                            }}
                          >
                            View salary history
                          </Mui.MenuItem>
                        </Mui.Menu>
                      </Mui.TableCell>
                    </>
                  </Mui.TableRow>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>

      {employeeList.length > 10 && (
        <Mui.TablePagination
          className="custom-pagination"
          component="div"
          rowsPerPageOptions={[10, 20, 50, 100]}
          count={employeeList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};
