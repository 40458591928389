import * as React from "react";
import { useState } from "react";
import LoginImage from "../../../assets/img/brand/loginHalf.png";
import LoginLogo from "../../../assets/img/brand/organflow 2.0 logo.png";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import * as Mui from "@mui/material";
import { InputAdornment } from "@material-ui/core";
import {
  Mail,
  LockOutlined,
  Visibility,
  VisibilityOff,
  Phone,
  Info,
} from "@mui/icons-material";
import { showToast } from "services/utils/Status";
import { useSelector } from "react-redux";
import { userManagementError } from "services/constants/ErrorMessages";
import "./Login.css";

export const Login = () => {
  const domainUrl = useSelector((state) => state.tokenReducer.domain);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [groupButtonActiveTap, setGroupButtonActiveTap] = useState(
    localStorage.getItem("LoginTag")
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = async (data) => {
    let url =
      groupButtonActiveTap === "Email"
        ? `${domainUrl}login/email_verfication/?email=${data.email}`
        : `${domainUrl}phone_verfication/?phone_no=${data.phoneno}`;
    setIsLoading(true);
    const emailResponse = await fetch(url, {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    });
    const emailReturnResponse = await emailResponse.json();
    if (
      emailResponse.status === 200 &&
      emailReturnResponse.user_status === "Active"
    ) {
      const userId = emailReturnResponse.user_id;
      const userResponse = await fetch(`${domainUrl}custom-token-auth/`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          username: emailReturnResponse.emailuser.username,
          password: data.password,
        }),
      });
      const userReturnResponse = await userResponse.json();
      if (userReturnResponse?.error) {
        showToast("error", userReturnResponse?.error);
        setIsLoading(false);
      }
      if (userResponse.status === 200) {
        const userData = {
          id: userId,
          first_name: emailReturnResponse.emailuser.first_name,
          username: emailReturnResponse.emailuser.username,
          last_name: emailReturnResponse.emailuser.last_name,
          email: emailReturnResponse.emailuser.email,
          role: emailReturnResponse.role,
          phonenumber: emailReturnResponse.role.phonenumber,
          approver: emailReturnResponse.approver,
          teamlead: emailReturnResponse.teamlead,
          firmName: emailReturnResponse.firm_name,
          approver_id: emailReturnResponse.approver_id,
          image_url: emailReturnResponse.image_url,
          department_id: emailReturnResponse.department_id,
          department_name: emailReturnResponse.department_name,
          employee_id: emailReturnResponse.employee_id,
        };
        await getProductAccess(userReturnResponse.token, userId);
        localStorage.setItem("currentuser", JSON.stringify(userData));
        localStorage.setItem("token", userReturnResponse.token);
        localStorage.setItem("sessionToken", userReturnResponse.session_token);
        if (emailReturnResponse.role === "Admin") {
          getCompanyDetails(userReturnResponse.token);
        } else {
          localStorage.setItem("currentScreen", "My Zone");
          localStorage.setItem("currentSubMenu", "Calendar");
          if (emailReturnResponse.role === "Team Lead") {
            reset();
            window.location.href = "/admin/teamzone/attendance";
          } else {
            reset();
            window.location.href = "/admin/myzone/status";
          }
        }
      }
    } else if (
      emailReturnResponse.exists === false ||
      emailResponse.status === 400
    ) {
      showToast("error", emailReturnResponse.error);
      setIsLoading(false);
    } else if (emailReturnResponse.user_status === "Inactive") {
      showToast("error", userManagementError.emailReturnResponseError);
      setIsLoading(false);
    } else if (emailResponse.status === 200) {
      showToast("error", emailReturnResponse.error);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const getProductAccess = async (token, userId) => {
    try {
      const response = await fetch(`${domainUrl}products/?user_id=${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("productAccess", JSON.stringify(data));
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      showToast(error.message);
    }
  };

  const getCompanyDetails = async (token) => {
    try {
      const response = await fetch(`${domainUrl}company/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        if (data.length === 0) {
          localStorage.setItem("currentScreen", "Administration");
          localStorage.setItem("currentSubMenu", "Users");
          reset();
          window.location.href = "/admin/user";
        } else {
          localStorage.setItem("currentScreen", "My Zone");
          localStorage.setItem("currentSubMenu", "Calendar");
          reset();
          window.location.href = "/admin/teamzone/attendance";
        }
      } else {
        throw new Error(data.error);
      }
    } catch (error) {
      showToast(error.message);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Mui.Grid
        container
        style={{
          overflowY: "hidden",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        <Mui.Grid item xs={6}>
          <Mui.Paper style={{ height: "100%" }}>
            <img
              src={LoginImage}
              alt="Login"
              style={{
                height: "100%",
                width: "100%",
              }}
            />
            <a href="https://stringconsultants.com/">
              <div
                style={{
                  position: "absolute",
                  left: "25%",
                  bottom: "0.2%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                © 2024 String Consultants. All Rights Reserved
              </div>
            </a>
          </Mui.Paper>
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={5} lg={6}>
          <Mui.Paper style={{ height: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Mui.Grid
                container
                justifyContent="flex-end"
                sx={{ paddingTop: 2, paddingRight: 2 }}
              >
                <img
                  src={LoginLogo}
                  alt="LoginLogo"
                  style={{ height: "22%", width: "22%" }}
                />
              </Mui.Grid>
              <Mui.Grid
                container
                spacing={2}
                direction="column"
                dispay="flex"
                alignItems="center"
              >
                <Mui.Grid
                  item
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ minHeight: "14vh" }}
                ></Mui.Grid>
                <Mui.Grid item>
                  {" "}
                  <Mui.Typography
                    sx={{
                      fontWeight: 800,
                      fontSize: "38px",
                      paddingLeft: 6,
                    }}
                  >
                    Welcome to Organflow
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={12}>
                  <Mui.ButtonGroup
                    size="large"
                    aria-label="Large button group"
                    sx={{ border: `1px solid ${themes.loginImage}` }}
                  >
                    <Mui.Button
                      variant={
                        groupButtonActiveTap === "Email"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => {
                        reset();
                        setGroupButtonActiveTap("Email");
                        localStorage.setItem("LoginTag", "Email");
                      }}
                      sx={{
                        background:
                          groupButtonActiveTap === "Email"
                            ? themes.loginImage
                            : "outlined",
                        textTransform: "capitalize",
                        border: "none",
                        "&:hover": {
                          border: "none",
                          background:
                            groupButtonActiveTap === "Email"
                              ? themes.loginImage
                              : "outlined",
                        },
                        color:
                          groupButtonActiveTap === "Email"
                            ? themes.whiteColor
                            : themes.headLine,
                        width: 203,
                      }}
                    >
                      Email
                    </Mui.Button>
                    <Mui.Button
                      variant={
                        groupButtonActiveTap === "PhoneNo"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => {
                        reset();
                        setGroupButtonActiveTap("PhoneNo");
                        localStorage.setItem("LoginTag", "PhoneNo");
                      }}
                      sx={{
                        background:
                          groupButtonActiveTap === "PhoneNo"
                            ? themes.loginImage
                            : "outlined",
                        textTransform: "capitalize",
                        border: "none",
                        "&:hover": {
                          border: "none",
                          background:
                            groupButtonActiveTap === "PhoneNo"
                              ? themes.loginImage
                              : "outlined",
                        },
                        color:
                          groupButtonActiveTap === "Email"
                            ? themes.headLine
                            : themes.whiteColor,
                        width: 203,
                      }}
                    >
                      Phone No
                    </Mui.Button>
                  </Mui.ButtonGroup>
                </Mui.Grid>
                <Mui.Grid item xs={12} sx={{ marginTop: 2 }}>
                  {groupButtonActiveTap === "Email" ? (
                    <React.Fragment>
                      <Mui.Typography
                        sx={{ fontSize: 20, paddingRight: 43, fontWeight: 600 }}
                      >
                        Email
                      </Mui.Typography>
                      <Mui.TextField
                        sx={{
                          minWidth: 407,
                        }}
                        size="medium"
                        type="text"
                        placeholder="Please enter e-mail"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        error={errors.email}
                        helperText={errors.email?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Mui.Badge color="secondary" badgeContent={0}>
                                <Mail color="primary" />
                              </Mui.Badge>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Mui.Typography
                        sx={{ fontSize: 20, paddingRight: 35, fontWeight: 600 }}
                      >
                        Phone No
                      </Mui.Typography>
                      <Mui.TextField
                        sx={{
                          minWidth: 407,
                        }}
                        size="medium"
                        type="text"
                        placeholder="Please enter phone number"
                        {...register("phoneno", {
                          required:
                            groupButtonActiveTap === "Email"
                              ? false
                              : "Phone No is required",
                          minLength: {
                            value: 10,
                            message: "Minimum length is 10 digits",
                          },
                          maxLength: {
                            value: 15,
                            message: "Maximum length is 15 digits",
                          },
                        })}
                        error={errors?.phoneno}
                        helperText={errors?.phoneno?.message}
                        onKeyPress={handleKeyPress}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Mui.Badge color="secondary" badgeContent={0}>
                                <Phone color="primary" />
                              </Mui.Badge>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </React.Fragment>
                  )}
                  <Mui.Grid item xs={12} sx={{ marginTop: 2 }}>
                    <Mui.Typography
                      sx={{ fontSize: 20, paddingRight: 38, fontWeight: 600 }}
                    >
                      Password{" "}
                    </Mui.Typography>
                    <Mui.TextField
                      sx={{
                        minWidth: 407,
                      }}
                      size="medium"
                      placeholder="Please enter password"
                      type={showPassword ? "text" : "password"}
                      {...register("password", {
                        required: "Password is required",
                      })}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Mui.Badge color="primary" badgeContent={0}>
                              <LockOutlined color="primary" />
                            </Mui.Badge>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Mui.Badge color="primary" badgeContent={0}>
                              <Mui.IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </Mui.IconButton>
                            </Mui.Badge>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Mui.Grid>
                  <Mui.Grid container sx={{ marginTop: 1 }}>
                    <Mui.Grid>
                      <a
                        className="text-dark"
                        href="/auth/send/otp"
                        onClick={() => {
                          localStorage.setItem(
                            "PasswordHandling",
                            "ForgotPassword"
                          );
                        }}
                      >
                        <Mui.Typography
                          fontSize={15}
                          style={{ fontWeight: "bold", paddingLeft: 270 }}
                        >
                          Forgot Password?
                        </Mui.Typography>
                      </a>
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid
                    item
                    xs={12}
                    sx={{
                      marginTop: 2,
                      minWidth: 407,
                      marginRight: "auto",
                    }}
                  >
                    <Mui.Grid justifyContent="center">
                      <Mui.Button
                        variant="contained"
                        type="submit"
                        size="large"
                        sx={{
                          minWidth: 407,
                          borderRadius: 3,
                          backgroundColor: themes.loginImage,
                          "&:hover": {
                            border: "none",
                            background: themes.loginImage,
                          },
                          color: themes.whiteColor,
                          textTransform: "none",
                          fontSize: 20,
                        }}
                      >
                        {isLoading ? (
                          <Mui.CircularProgress size={24} color="inherit" />
                        ) : (
                          "Login"
                        )}
                      </Mui.Button>
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid container xs={12} sx={{ paddingTop: 2 }}>
                    <Mui.Grid xs={1}>
                      <Info size="large" />
                    </Mui.Grid>
                    <Mui.Grid xs={11}>
                      <Mui.Stack direction="row">
                        <Mui.Typography
                          fontSize={15}
                          style={{ fontWeight: "bold" }}
                        >
                          First time login:
                        </Mui.Typography>{" "}
                        <Mui.Typography>
                          {" "}
                          If you are logging in for the
                        </Mui.Typography>
                      </Mui.Stack>
                      <Mui.Stack direction="row">
                        first time, click &nbsp;
                        <a
                          style={{ fontWeight: "bold" }}
                          href="/auth/send/otp"
                          onClick={() => {
                            localStorage.setItem(
                              "PasswordHandling",
                              "First Time Handle"
                            );
                          }}
                        >
                          {" "}
                          here
                        </a>{" "}
                        &nbsp;to set password
                      </Mui.Stack>
                    </Mui.Grid>
                  </Mui.Grid>
                  <Mui.Grid container xs={12} sx={{ paddingTop: 2 }}></Mui.Grid>
                  <Mui.Grid
                    container
                    xs={12}
                    sx={{ paddingTop: 4, marginTop: 3 }}
                  >
                    <Mui.Grid
                      sx={{
                        borderRight: "3px solid green",
                      }}
                    ></Mui.Grid>
                    <Mui.Grid item xs={6}>
                      <a href="https://organflow.app/about/">
                        <Mui.Typography
                          sx={{
                            fontWeight: 600,
                            borderRight: "3px solid green",
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          About us
                        </Mui.Typography>
                      </a>
                    </Mui.Grid>
                    <Mui.Grid item xs={5.9}>
                      <a href="https://organflow.app/privacy-policy/">
                        <Mui.Typography
                          sx={{
                            fontWeight: 600,
                            borderRight: "3px solid green",
                            textAlign: "center",
                          }}
                        >
                          Privacy Policy
                        </Mui.Typography>
                      </a>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </form>
          </Mui.Paper>
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
};
