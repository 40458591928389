import React, { useState, useEffect } from "react";
import { Leaves } from "./Leaves";
import { Permissions } from "./Permissions";
import { OverTime } from "./OverTime";
import { Loans } from "./Loans";
import { AdvanceSalary } from "./AdvanceSalary";
import { Expense } from "./Expense";
import { WeekOff } from "./Weekoff";
import { SettingsMenu } from "./SettingsMenu";
import { showToast } from "services/utils/Status";
import { Grid, Paper } from "@mui/material";

import sessionHandling from "services/utils/notificationUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";

export const Approve = () => {
  const { approveSettingsScreens } = useSelector((state) => state.teamZoneReducer);
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const { departmentId } = useSelector((state) => state.userReducer);
  const [currentScreen, setCurrentScreen] = useState(approveSettingsScreens);
  const [countDetails, setCountDetails] = useState({
    expense:0,
    leave_count: 0,
    permission_count: 0,
    over_time: 0,
    advance_salary: 0,
    loans: 0,
    week_off: 0,
  });
  const [reloadData, setReloadData] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDepartmentName("All"));
    dispatch(setDepartmentId(99999));
    setReloadData(true);
  }, [dispatch]);

  const renderComponent = () => {
    switch (currentScreen) {
      case "Leaves":
        return <Leaves setReloadData={setReloadData} />;
      case "Permissions":
        return <Permissions setReloadData={setReloadData} />;
      case "Over time":
        return <OverTime setReloadData={setReloadData} />;
      case "Loans":
        return <Loans setReloadData={setReloadData} />;
      case "Advance Salary":
        return <AdvanceSalary setReloadData={setReloadData} />;
      case "Expense":
        return <Expense setReloadData={setReloadData} />;
      case "WeekOff":
        return <WeekOff setReloadData={setReloadData} />;
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const response = await fetch(
          `${domain}approval-counts/?user_id=${userDetails.id}&department_id=${departmentId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setCountDetails(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (reloadData) {
      fetchDepartmentData();
      setReloadData(false);
    }
  }, [departmentId, domain, reloadData, token, userDetails.id]);

  return (
    <React.Fragment>
      <Grid container>
        <Grid xs={1.5}>
          <SettingsMenu
            setReloadData={setReloadData}
            setCurrentScreen={setCurrentScreen}
            currentScreen={currentScreen}
            countDetails={countDetails}
          />
        </Grid>
        <Grid xs={10.5} sx={{ padding: 2 }}>
          <Paper
            sx={{
              boxShadow: 3,
              minHeight: 890,
            }}
          >
            {renderComponent()}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
