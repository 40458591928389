import React, { useState, useEffect, useContext } from "react";
import * as Mui from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { themes } from "services/constants";
import { showToast } from "services/utils/Status";
import { MemberDetails } from "./MemberDetails";
import { SprintTasks } from "./SprintTasks";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CreateSprint } from "../CreateSprint";
import PropTypes from "prop-types";
import { projectSuccess } from "services/constants/SuccessMessages";
import { SprintDashboardContext } from "../DashboardContext/sprintindex";
import { Close, RemoveRedEye } from "@mui/icons-material";
import sessionHandling from "services/utils/notificationUtils";

const BootstrapDialog = Mui.styled(Mui.Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    borderRadius: "20px",
  },
}));

function FuncDialogTitle(props) {
  const { children, onClose, title, ...other } = props;

  return (
    <Mui.DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <Mui.IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close sx={{ color: themes.blackColor }} />
        </Mui.IconButton>
      ) : null}
    </Mui.DialogTitle>
  );
}

FuncDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const SprDashboard = () => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const history = useHistory();
  const editProject = useSelector((state) => state.leaveReducer.editproject);
  const { sprintDetails, setReloadSprDash, memberCount, dataLoading } =
    useContext(SprintDashboardContext);
  const [showMemberDetails, setShowMemberDetails] = useState(false);
  const [showSprintTaskDetails, setSprintTaskDetails] = useState(true);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [cmpSprLoading, setCmpSprLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const unCompletedSprint = editProject?.sprints?.filter((element) => {
    return element.is_completed === false;
  });

  useEffect(() => {
    setShowMemberDetails(false);
    setSprintTaskDetails(true);
  }, [sprintDetails]);

  const handleCloseDialog = () => {
    setOpen(false);
    setCmpSprLoading(false);
  };

  const setStartSprint = async () => {
    try {
      const response = await fetch(`${domain}start_sprint/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          sprint_id: sprintDetails?.id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", projectSuccess.sprintStart);
        setReloadSprDash(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setCmpSprLoading(false);
    }
  };

  const completeTheSprint = async () => {
    try {
      const response = await fetch(`${domain}complete_sprint/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          sprint_id: sprintDetails?.id,
          action: null,
          target_sprint_id: null,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", projectSuccess.completeSprint);
        handleMenuClose();
        handleCloseDialog();
        setReloadSprDash(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setCmpSprLoading(false);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const moveSprint = async (sprint) => {
    try {
      const response = await fetch(`${domain}complete_sprint/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          sprint_id: sprintDetails?.id,
          action: "move_to_existing_sprint",
          target_sprint_id: sprint.id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", projectSuccess.moveSprint);
        handleMenuClose();
        handleCloseDialog();
        setReloadSprDash(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setCmpSprLoading(false);
    }
  };

  return (
    <React.Fragment>
      {dataLoading ? (
        <Loader info="Loading..." />
      ) : (
        <>
          <Mui.Grid container sx={{ paddingTop: 2 }}>
            <Mui.Grid xs={9}>
              <Mui.Stack direction="row">
                <BackArrow
                  actionFunction={() => {
                    localStorage.removeItem("sprDashData");
                    history.goBack();
                  }}
                />
                &nbsp;
                <Mui.Typography
                  sx={{
                    color: themes.outTap,
                    fontSize: 28,
                    fontWeight: "bold",
                    paddingLeft: 3,
                    paddingTop: 0.5,
                  }}
                >
                  {sprintDetails?.name} Dashboard
                </Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid xs={3} sx={{ paddingTop: 1 }}>
              {!sprintDetails?.is_default &&
              sprintDetails?.status === "Current" ? (
                <Mui.Stack direction="row" spacing={1}>
                  <Mui.Button
                    variant="contained"
                    size="large"
                    type="submit"
                    sx={{
                      background: themes.primaryIndicator,
                      color: themes.headLine,
                      textTransform: "capitalize",
                      "&:hover": {
                        border: "none",
                        backgroundColor: themes.primaryIndicator,
                      },
                    }}
                    onClick={() => setEditOpen(true)}
                    disabled={
                      sprintDetails?.is_completed ||
                      editProject?.status?.status === "Completed" ||
                      editProject?.status?.status === "Inactive"
                        ? true
                        : false
                    }
                  >
                    Edit Sprint
                  </Mui.Button>
                  <LoadingButton
                    loading={cmpSprLoading}
                    size="large"
                    variant="contained"
                    sx={{
                      backgroundColor: themes.primaryIndicator,
                      color: themes.headLine,
                      textTransform: "capitalize",
                      "&:hover": {
                        border: "none",
                        backgroundColor: themes.primaryIndicator,
                      },
                    }}
                    onClick={() => {
                      setCmpSprLoading(true);
                      setOpen(true);
                    }}
                    disabled={
                      sprintDetails?.is_completed ||
                      editProject?.status?.status === "Completed" ||
                      editProject?.status?.status === "Inactive"
                        ? true
                        : false
                    }
                  >
                    Complete Sprint
                  </LoadingButton>
                </Mui.Stack>
              ) : null}
              <CreateSprint
                open={editOpen}
                setOpen={setEditOpen}
                sprintDetails={sprintDetails}
                option="Edit"
                setReloadSprDash={setReloadSprDash}
              />
              {sprintDetails?.status !== null &&
              sprintDetails?.status !== "Current" ? (
                <>
                  <Mui.Stack direction="row" spacing={1}>
                    <Mui.Button
                      variant="contained"
                      size="large"
                      type="submit"
                      sx={{
                        background: themes.primaryIndicator,
                        color: themes.headLine,
                        textTransform: "capitalize",
                        "&:hover": {
                          border: "none",
                          backgroundColor: themes.primaryIndicator,
                        },
                      }}
                      onClick={() => setEditOpen(true)}
                      disabled={
                        sprintDetails?.is_completed ||
                        editProject?.status?.status === "Completed" ||
                        editProject?.status?.status === "Inactive"
                          ? true
                          : false
                      }
                    >
                      Edit Sprint
                    </Mui.Button>
                    <LoadingButton
                      loading={cmpSprLoading}
                      size="large"
                      variant="contained"
                      sx={{
                        backgroundColor: themes.primaryIndicator,
                        color: themes.headLine,
                        textTransform: "capitalize",
                        "&:hover": {
                          border: "none",
                          backgroundColor: themes.primaryIndicator,
                        },
                      }}
                      onClick={() => {
                        setCmpSprLoading(true);
                        setStartSprint();
                      }}
                      disabled={
                        sprintDetails?.is_completed ||
                        editProject?.status?.status === "Completed" ||
                        editProject?.status?.status === "Inactive"
                          ? true
                          : false
                      }
                    >
                      Start Sprint
                    </LoadingButton>
                  </Mui.Stack>
                </>
              ) : null}
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Stack sx={{ paddingLeft: 6, paddingRight: 6, paddingTop: 2 }}>
            <Mui.Card sx={{ borderRadius: 5 }} elevation={5}>
              {sprintDetails?.name === "Backlog" ? null : (
                <Mui.Grid container sx={{ padding: 3.5 }}>
                  <Mui.Grid sm={12} md={4} lg={4}>
                    <Mui.Stack direction="row">
                      <Mui.Grid container>
                        <Mui.Grid xs={5.5}>
                          <Mui.Typography
                            sx={{
                              fontWeight: "bold",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            Start date
                          </Mui.Typography>
                        </Mui.Grid>
                        <Mui.Grid xs={1}>
                          <Mui.Typography>:</Mui.Typography>
                        </Mui.Grid>
                        <Mui.Grid xs={5.5}>
                          <Mui.Typography
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {sprintDetails?.start_date
                              ? sprintDetails?.start_date
                              : "N/A"}
                          </Mui.Typography>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Stack>
                  </Mui.Grid>
                  <Mui.Grid sm={12} md={4} lg={4}>
                    <Mui.Stack direction="row">
                      <Mui.Grid container>
                        <Mui.Grid xs={5.5}>
                          <Mui.Typography
                            sx={{
                              fontWeight: "bold",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            End date
                          </Mui.Typography>
                        </Mui.Grid>
                        <Mui.Grid xs={1}>
                          <Mui.Typography>:</Mui.Typography>
                        </Mui.Grid>
                        <Mui.Grid xs={5.5}>
                          <Mui.Typography
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {sprintDetails?.end_date
                              ? sprintDetails?.end_date
                              : "N/A"}
                          </Mui.Typography>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Stack>
                  </Mui.Grid>
                  <Mui.Grid sm={12} md={4} lg={4}>
                    <Mui.Stack direction="row">
                      <Mui.Grid container>
                        <Mui.Grid xs={5.5}>
                          <Mui.Typography
                            sx={{
                              fontWeight: "bold",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            Created by
                          </Mui.Typography>
                        </Mui.Grid>
                        <Mui.Grid xs={1}>
                          <Mui.Typography>:</Mui.Typography>
                        </Mui.Grid>
                        <Mui.Grid xs={5.5}>
                          <Mui.Typography
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {sprintDetails?.createdby
                              ? sprintDetails?.createdby
                              : "N/A"}
                          </Mui.Typography>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Stack>
                  </Mui.Grid>
                </Mui.Grid>
              )}

              <Mui.Divider />
              <Mui.Grid container sx={{ padding: 3.5 }}>
                <Mui.Grid sm={12} md={4} lg={4}>
                  <Mui.Stack direction="row">
                    <Mui.Grid container>
                      <Mui.Grid xs={5.5}>
                        <Mui.Typography
                          sx={{
                            fontWeight: "bold",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Tasks
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={1}>
                        <Mui.Typography>:</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={5.5}>
                        <Mui.IconButton
                          onClick={() => {
                            setShowMemberDetails(false);
                            setSprintTaskDetails(true);
                          }}
                          sx={{ marginTop: -1 }}
                        >
                          <Mui.Typography
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              color: showSprintTaskDetails
                                ? themes.primaryIndicator
                                : "",
                              fontWeight: showSprintTaskDetails ? 700 : "",
                            }}
                          >
                            {sprintDetails?.completed_tasks}/
                            {sprintDetails?.total_tasks}
                          </Mui.Typography>
                        </Mui.IconButton>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.Stack>
                </Mui.Grid>
                <Mui.Grid sm={12} md={4} lg={4}>
                  <Mui.Stack direction="row">
                    <Mui.Grid container>
                      <Mui.Grid xs={5.5}>
                        <Mui.Typography
                          sx={{
                            fontWeight: "bold",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Members
                        </Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={1}>
                        <Mui.Typography>:</Mui.Typography>
                      </Mui.Grid>
                      <Mui.Grid xs={5.5}>
                        <Mui.IconButton
                          onClick={() => {
                            setSprintTaskDetails(false);
                            setShowMemberDetails(true);
                          }}
                          sx={{
                            marginTop: -1,
                            color: showMemberDetails
                              ? themes.primaryIndicator
                              : "",
                          }}
                        >
                          <Mui.Stack direction="row">
                            <RemoveRedEye />
                            <Mui.Typography
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                ml: 1,
                                color: showMemberDetails
                                  ? themes.primaryIndicator
                                  : "",
                                fontWeight: showMemberDetails ? 700 : "",
                              }}
                            >
                              {memberCount}
                            </Mui.Typography>
                          </Mui.Stack>
                        </Mui.IconButton>
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.Stack>
                </Mui.Grid>
                <Mui.Grid sm={12} md={4} lg={4}>
                  {sprintDetails?.name === "Backlog" ? null : (
                    <Mui.Stack direction="row">
                      <Mui.Grid container>
                        <Mui.Grid xs={5.5}>
                          <Mui.Typography
                            sx={{
                              fontWeight: "bold",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            Completed by
                          </Mui.Typography>
                        </Mui.Grid>
                        <Mui.Grid xs={1}>
                          <Mui.Typography>:</Mui.Typography>
                        </Mui.Grid>
                        <Mui.Grid xs={5.5}>
                          <Mui.Typography
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {sprintDetails?.is_completed
                              ? sprintDetails?.modifiedby
                              : "N/A"}
                          </Mui.Typography>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Stack>
                  )}
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Divider />
            </Mui.Card>
          </Mui.Stack>

          {sprintDetails ? (
            <>
              {showMemberDetails ? (
                <MemberDetails sprintDetails={sprintDetails} />
              ) : null}
              {showSprintTaskDetails ? (
                <SprintTasks unCompletedSprint={unCompletedSprint} />
              ) : null}
            </>
          ) : null}
        </>
      )}

      <div>
        <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
          <FuncDialogTitle
            id="customized-dialog-title"
            align="center"
            sx={{
              minWidth: 580,
              color: themes.headLine,
              fontWeight: 1000,
              fontSize: "23px",
              background: themes.primary,
            }}
            onClose={handleCloseDialog}
            title={"Complete Sprint"}
          >
            {sprintDetails?.completed_tasks !== sprintDetails?.total_tasks
              ? "Warning!"
              : "Complete Sprint"}
          </FuncDialogTitle>
          <Mui.DialogContent dividers>
            <Mui.Grid xs={12} sx={{ paddingBottom: 3 }}>
              <Mui.Typography sx={{ fontSize: 24 }}>
                {sprintDetails?.completed_tasks !== sprintDetails?.total_tasks
                  ? "There are uncompleted tasks in " +
                    sprintDetails?.name +
                    ". Would you like to move these tasks to another sprint?"
                  : "Do you want to complete the " + sprintDetails?.name + "?"}
              </Mui.Typography>
            </Mui.Grid>

            <Mui.Grid container>
              <Mui.Grid xs={5}></Mui.Grid>
              <Mui.Grid xs={3}>
                <Mui.Button
                  variant="contained"
                  size="large"
                  sx={{
                    background: themes.primaryIndicator,
                    color: themes.headLine,
                    float: "right",
                    width: 150,
                    textTransform: "capitalize",
                    "&:hover": {
                      border: "none",
                      backgroundColor: themes.primaryIndicator,
                    },
                  }}
                  onClick={handleCloseDialog}
                >
                  {sprintDetails?.completed_tasks !== sprintDetails?.total_tasks
                    ? "Cancel"
                    : "No"}
                </Mui.Button>
              </Mui.Grid>
              <Mui.Grid xs={4}>
                {sprintDetails?.completed_tasks !==
                sprintDetails?.total_tasks ? (
                  <Mui.Button
                    variant="contained"
                    size="large"
                    type="submit"
                    sx={{
                      background: themes.primaryIndicator,
                      color: themes.headLine,
                      float: "right",
                      width: 150,
                      textTransform: "capitalize",
                      "&:hover": {
                        border: "none",
                        backgroundColor: themes.primaryIndicator,
                      },
                    }}
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    Move
                  </Mui.Button>
                ) : (
                  <Mui.Button
                    variant="contained"
                    size="large"
                    type="submit"
                    sx={{
                      background: themes.primaryIndicator,
                      color: themes.headLine,
                      float: "right",
                      width: 150,
                      textTransform: "capitalize",
                      "&:hover": {
                        border: "none",
                        backgroundColor: themes.primaryIndicator,
                      },
                    }}
                    onClick={completeTheSprint}
                  >
                    Yes
                  </Mui.Button>
                )}
                <Mui.Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  elevation={1}
                  transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                  }}
                  anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom",
                  }}
                  sx={{ marginLeft: 11, marginTop: -3 }}
                  key={sprintDetails}
                >
                  {unCompletedSprint?.map((option) =>
                    sprintDetails?.name !== option?.name ? (
                      <Mui.Stack>
                        <Mui.MenuItem
                          key={option}
                          onClick={() => moveSprint(option)}
                        >
                          {option.name}
                        </Mui.MenuItem>
                      </Mui.Stack>
                    ) : null
                  )}
                </Mui.Menu>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.DialogContent>
        </BootstrapDialog>
      </div>
    </React.Fragment>
  );
};
