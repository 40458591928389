import React, { useState } from "react";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { getContrastingTextColor } from "services/utils/colorChecker";
import dayjs from "dayjs";
import moment from "moment";
import { Loader } from "components/Loader";
import { themes } from "services/constants";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { leaveManagementError } from "services/constants/ErrorMessages";
import { CustomButton } from "components/CustomComponents/CustomButton";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
export const AttendanceDetails = ({
  editUserDetails,
  viewPayrunDetails,
  status,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [editedDay, setEditedDay] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [checkOutTime, setCheckOutTime] = useState(null);
  const [checkInTime, setCheckInTime] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [handleCheckInError, setHandleCheckInError] = useState("");
  let {
    result: attendanceDetails,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    `${domain}pay-schedule/attendance-report/?pay_schedule_id=${viewPayrunDetails.id}&user_id=${editUserDetails.user_id}`
  );

  const updateCheckInTime = (newValue) => {
    if (newValue && newValue["$d"].toString() !== "Invalid Date") {
      setCheckInTime(dayjs(newValue["$d"]));
      setHandleCheckInError("");
    } else {
      setHandleCheckInError("Invalid check-in format");
    }
  };

  const updateCheckOutTime = (newValue) => {
    if (newValue && newValue["$d"].toString() !== "Invalid Date") {
      setCheckOutTime(dayjs(newValue["$d"]));
      setHandleCheckInError("");
    } else {
      setHandleCheckInError("Invalid check-out format");
    }
  };

  const getTwentyFourHourTime = (time) => {
    if (time) {
      let hours = Number(time.match(/^(\d+)/)[1]);
      let minutes = Number(time.match(/:(\d+)/)[1]);
      let AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM === "PM" && hours < 12) hours = hours + 12;
      if (AMPM === "AM" && hours === 12) hours = hours - 12;
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes + ":00";
    }
  };

  const updateTheModifiedDay = async () => {
    if (checkInTime && checkOutTime) {
      let validation = false;
      if (
        (handleCheckInError === "" ||
          handleCheckInError ===
            "Checkin time is greater then check out time" ||
          handleCheckInError ===
            "Check-in time to be earlier than check-out time.") &&
        checkInTime["$d"]
      ) {
        const checkInData = getTwentyFourHourTime(
          moment(checkInTime["$d"]).format("hh:mm A")
        );
        const checkOutData = getTwentyFourHourTime(
          moment(checkOutTime["$d"]).format("hh:mm A")
        );
        const checkTotalCheckOutTime =
          parseInt(checkOutData?.split(":")[0]) * 60 +
          parseInt(checkOutData?.split(":")[1]);
        const checkTotalCheckInTime =
          parseInt(checkInData?.split(":")[0]) * 60 +
          parseInt(checkInData?.split(":")[1]);
        if (checkTotalCheckInTime < checkTotalCheckOutTime) {
          validation = true;
        } else {
          validation = false;
          setHandleCheckInError(leaveManagementError.checkInError);
        }
      } else {
        if (checkOutTime?._d) {
          validation = false;
        } else if (
          handleCheckInError === "Invalid check-out format" ||
          handleCheckInError === "Invalid check-out format"
        ) {
          validation = false;
        } else {
          validation = true;
        }
      }
      if (validation) {
        setButtonDisabled(true);
        try {
          const response = await fetch(`${domain}payrun/attendance-timeline/`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              check_in_time: checkInTime["$d"]
                ? getTwentyFourHourTime(
                    moment(checkInTime["$d"]).format("hh:mm A")
                  )
                : null,
              check_out_time: checkOutTime["$d"]
                ? getTwentyFourHourTime(
                    moment(checkOutTime["$d"]).format("hh:mm A")
                  )
                : null,
              date: editedDay?.date,
              user_id: editUserDetails.user_id,
              pay_schedule_id: viewPayrunDetails.id,
              start_date: editedDay?.date,
              end_date: editedDay?.date,
            }),
          });
          const res = await response.json();
          if (response.ok) {
            setCheckInTime(null);
            setCheckOutTime(null);
            setOpenDialog(false);
            reCallApi(true);
            showToast("success", res.message);
            setHandleCheckInError("");
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        } finally {
          setButtonDisabled(false);
        }
      }
    } else {
      if (!checkInTime) {
        setHandleCheckInError("Invalid check-in format");
      }
      if (checkInTime && !checkOutTime) {
        setHandleCheckInError("Invalid check-out format");
      }
    }
  };

  return (
    <React.Fragment>
      {status === "Draft" ? (
        <Mui.Alert severity="info" fullWidth>
          Payroll run has not been initiated to the user yet.
        </Mui.Alert>
      ) : (
        <Mui.Grid container sx={{ paddingLeft: 5 }}>
          {attendanceDetails?.attendance_data?.map((item) =>
            item?.attendance?.map((data) => (
              <Mui.Stack direction="row">
                {data?.map((details) => (
                  <div style={{ paddingLeft: 30, paddingBottom: 15 }}>
                    <Mui.Stack>
                      <div
                        style={{
                          display: "inline-block",
                          width: "40px",
                          height: "50px",
                          backgroundColor: themes.whiteColor,
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Mui.Typography
                          sx={{
                            fontSize: 15,
                            textAlign: "center",
                          }}
                        >
                          {details.day_number}
                        </Mui.Typography>
                        <Mui.Typography
                          sx={{
                            fontSize: 15,
                            textAlign: "center",
                          }}
                        >
                          {details.day_name}
                        </Mui.Typography>
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          width: "40px",
                          height: "35px",
                          backgroundColor: details.colour_code,
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Mui.Typography
                          sx={{
                            fontSize: 20,
                            textAlign: "center",
                            color: getContrastingTextColor(
                              details?.colour_code ? details?.colour_code : null
                            ),
                          }}
                          onClick={() => {
                            if (
                              openDialog &&
                              editedDay.day_number === details.day_number
                            ) {
                              setOpenDialog(false);
                            } else {
                              if (
                                (details.legend === "A" ||
                                  details.legend === "W" ||
                                  details.legend === "M" ||
                                  details.legend === "H") &&
                                viewPayrunDetails?.status !== "Approved"
                              ) {
                                let checkInTime = details?.check_in_time
                                  ? dayjs()
                                      .set(
                                        "hour",
                                        details?.check_in_time?.split(":")[0]
                                      )
                                      .set(
                                        "minute",
                                        details?.check_in_time?.split(":")[1]
                                      )
                                      .set(
                                        "second",
                                        details?.check_in_time?.split(":")[2]
                                      )
                                  : details?.check_in_time;
                                setHandleCheckInError("");
                                setCheckOutTime(null);
                                setCheckInTime(checkInTime);
                                setOpenDialog(true);
                                setEditedDay(details);
                              }
                            }
                          }}
                        >
                          {details.legend}
                        </Mui.Typography>
                      </div>
                    </Mui.Stack>
                  </div>
                ))}
              </Mui.Stack>
            ))
          )}
        </Mui.Grid>
      )}
      <Mui.Grid container alignItems="center" sx={{ paddingTop: 10 }}>
        {loading === true ? <Loader info="Loading..." /> : null}
      </Mui.Grid>
      {/* Edit Option */}
      {openDialog ? (
        <Mui.Stack sx={{ padding: 2 }}>
          <Mui.Stack sx={{ paddingBottom: 2 }}>
            <Mui.Typography
              sx={{ fontWeight: "bold", textTransform: "capitalize" }}
            >
              Edit Attendance
            </Mui.Typography>
          </Mui.Stack>
          <Mui.Stack>
            <Mui.Typography>Name</Mui.Typography>
            <Mui.TextField
              defaultValue={editUserDetails.user_name}
              size="small"
              disabled={true}
              fullWidth
            />
            <Mui.Typography sx={{ paddingTop: 0.5 }}>Date</Mui.Typography>
            <Mui.TextField
              value={editedDay?.date}
              size="small"
              type="date"
              disabled={true}
              fullWidth
            />
          </Mui.Stack>
          <Mui.Typography sx={{ paddingTop: 0.5 }}>
            Check-In Time
          </Mui.Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                // label="check-in"
                size="small"
                value={checkInTime}
                onChange={updateCheckInTime}
                slotProps={{
                  textField: {
                    size: "small",
                    style: {
                      width: "100%",
                    },
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
          <Mui.Typography
            sx={{
              color: themes.redColor,
              fontSize: 12,
            }}
          >
            {handleCheckInError ===
            "Checkin time is greater then check out time"
              ? "Checkin time is greater then check out time"
              : null}
            {handleCheckInError === "Invalid check-in format"
              ? "Invalid check-in format"
              : null}
          </Mui.Typography>
          <Mui.Typography sx={{ paddingTop: 0.5 }}>
            Check-Out Time
          </Mui.Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                // label="check-out"
                value={checkOutTime}
                onChange={(newValue) => updateCheckOutTime(newValue)}
                sx={{ width: 25 }}
                slotProps={{
                  textField: {
                    size: "small",
                    style: {
                      width: "100%",
                    },
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
          <Mui.Typography
            sx={{
              color: themes.redColor,
              fontSize: 12,
            }}
          >
            {handleCheckInError === "Invalid check-out format"
              ? "Invalid check-out format"
              : null}
            {handleCheckInError ===
            "Check-in time to be earlier than check-out time."
              ? "Check-in time to be earlier than check-out time."
              : null}
          </Mui.Typography>
          <Mui.Grid
            container
            sx={{
              paddingTop: 3,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setOpenDialog(false);
              }}
              actionName="Cancel"
              typeName="submit"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={updateTheModifiedDay}
              actionName="Update"
              typeName="submit"
              disableAction={
                openDialog && buttonDisabled ? buttonDisabled : false
              }
            />
          </Mui.Grid>
        </Mui.Stack>
      ) : null}
    </React.Fragment>
  );
};
