import { useState } from "react";
import { showToast } from "services/utils/Status";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import { IconButton, Tooltip, CircularProgress } from "@mui/material";
import { Loader } from "components/Loader";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Alert,
  Box,
  TextField,
  Avatar,
} from "@mui/material";
import { themes } from "services/constants";
import { DialogBox } from "../DialogBox";
import { useSelector } from "react-redux";
import { ViewHistory } from "./ViewHistory";
import { leaveManagamentPlaceholder } from "services/constants/PlaceHolder";
import sessionHandling from "services/utils/notificationUtils";
import { CustomButton } from "components/CustomComponents/CustomButton";
const Pending = ({
  data,
  loading,
  onApproveClick,
  setUpdateLeaveCard,
  setReloadData,
}) => {
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [rejectReason, setRejectReason] = useState("");
  const [pastLeaveData, setPastLeaveData] = useState(null);
  const [showViewHistory, setShowViewHistory] = useState(null);
  const [rejectedReasonValidation, setRejectedReasonValidation] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [actionId, setActionId] = useState(0);
  const [expandId, setExpendId] = useState(0);
  const handleDeleteCancel = (event) => {
    setRejectReason("");
    setDeleteConfirmation(null);
    setRejectedReasonValidation("");
  };

  const handleDeleteConfirmation = (leaveId) => {
    setDeleteConfirmation(leaveId);
  };

  if (loading) {
    return (
      <div>
        <Loader info="Loading..." />
      </div>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Alert severity="info" sx={{ mt: 2, width: "100%" }}>
        No data available
      </Alert>
    );
  }

  const handleApproveClick = async (item, status, reason) => {
    let apiUrl = "";
    if (item.title === "Permission") {
      apiUrl = `${domain}permission_update/`;
    } else if (item.title === "Leave") {
      apiUrl = `${domain}leave_request_update/`;
    } else {
      apiUrl = `${domain}leave_request_cancel_update/`;
    }
    setButtonLoader(true);
    try {
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          id: item.id,
          approver_id: userDetails.id,
          request_status: status,
          approver_name: userDetails.first_name,
          ...(status === "Rejected" && { reason: reason }),
        }),
      });
      const res = await response.json();
      if (response.ok) {
        if (status === "Approved") {
          showToast("success", res.message);
          setReloadData(true);
        } else if (status === "Rejected") {
          showToast("error", res.message);
          setReloadData(true);
        }
        onApproveClick(item);
        setUpdateLeaveCard(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };

  const sortedData = [...data].sort(
    (a, b) =>
      new Date(a?.from_date.split("-").reverse().join("-")) -
      new Date(b?.from_date.split("-").reverse().join("-"))
  );

  const handleViewHistoryClick = (item) => {
    setPastLeaveData(item);
    setShowViewHistory(true);
    setDeleteConfirmation(null);
  };

  const onSubmit = async () => {
    if (rejectReason !== "" && rejectReason.length > 5) {
      await handleApproveClick(deleteConfirmation, "Rejected", rejectReason);
      setDeleteConfirmation(null);
      setRejectedReasonValidation("");
    } else {
      if (rejectReason === "") {
        setRejectedReasonValidation("Reason");
      } else {
        setRejectedReasonValidation("Less then 5 charactor");
      }
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Permission":
        return themes.secondryButtonColor;
      case "Leave":
        return themes.onleaveTap;
      case "Cancel":
        return themes.primaryButtonColor;
      default:
        return "black";
    }
  };

  return (
    <>
      <Grid container spacing={0} sx={{ justifyContent: "center" }}>
        {sortedData.map((item, index) => (
          <Grid item key={item.id}>
            <Card
              className=" max-w-fit mt-5 w-96 flex space-x-1 shadow-lg  "
              variant="outlined"
              sx={{
                borderRadius: 2,
                marginLeft: 3,
                marginTop: 5,
              }}
            >
              <CardContent>
                <Grid container sx={{ paddingBottom: 1 }}>
                  <Grid item xs={2}>
                    <Avatar
                      sx={{
                        backgroundColor: "#8A4402",
                      }}
                      alt={item.username?.charAt(0).toUpperCase()}
                      src={item.image_url}
                    ></Avatar>
                  </Grid>
                  <Grid item xs={7} sx={{ paddingTop: 1 }}>
                    <Tooltip title={item.username}>
                      <Typography
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.username}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      sx={{
                        width: 80,
                        height: 15,
                        backgroundColor: getStatusColor(item.title),
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: themes.backgroundWhite,
                        p: 2,
                        fontSize: 13,
                      }}
                    >
                      {item.title === "Leave"
                        ? "Leave"
                        : item.title === "Permission"
                        ? "Permission"
                        : item.title}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={3.5}>
                    <Typography fontWeight="bold">Department</Typography>
                  </Grid>
                  <Grid item xs={0.4}>
                    <Typography fontWeight="bold">:</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={8.1}
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item?.department_name}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={3.5}>
                    <Typography fontWeight="bold">Requested</Typography>
                  </Grid>
                  <Grid item xs={0.4}>
                    <Typography fontWeight="bold">:</Typography>
                  </Grid>
                  <Grid item xs={8.1}>
                    {item.title === "Permission" ? (
                      <Typography>{item.from_date}</Typography>
                    ) : (
                      <Typography>
                        {item.from_date + " To " + item.to_date}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container style={{ paddingBottom: 3 }}>
                  <Grid item xs={3.5}>
                    <Typography fontWeight="bold">Duration</Typography>
                  </Grid>
                  <Grid item xs={0.4}>
                    <Typography fontWeight="bold">:</Typography>
                  </Grid>
                  {item.title === "Leave" ? (
                    <Grid item xs={8.1}>
                      <Typography>
                        {" "}
                        {parseInt(item.business_days) > 1
                          ? item.business_days + " days"
                          : item.business_days + " day"}{" "}
                        {item?.half_day_period === "1st"
                          ? "  Morning"
                          : item?.half_day_period === "2nd"
                          ? "  Noon"
                          : null}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid>
                      <Typography sx={{ fontSize: 14.7, paddingTop: 0.2 }}>
                        {item.start_time + " To " + item.end_time},{" "}
                        {item.total_time + " hr"}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                {expandId === item.id ? (
                  <>
                    <Grid container sx={{ paddingBottom: 1 }}>
                      <Grid item xs={3.5}>
                        <Typography fontWeight="bold">Description</Typography>
                      </Grid>
                      <Grid item xs={0.4}>
                        <Typography fontWeight="bold">:</Typography>
                      </Grid>
                      <Grid item xs={8.1}></Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sx={{ paddingBottom: 1 }}>
                        <div style={{ maxWidth: 300 }}>
                          <Typography display="flex">
                            {item.description ? item.description : null}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <IconButton
                      size="small"
                      sx={{ marginRight: 2 }}
                      onClick={() =>
                        setExpendId(item.id === expandId ? 0 : item.id)
                      }
                    >
                      {expandId === item.id ? (
                        <KeyboardArrowUpIcon
                          size="small"
                          sx={{ stroke: 2, color: themes.blackColor }}
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          size="small"
                          sx={{ stroke: 2, color: themes.blackColor }}
                        />
                      )}
                    </IconButton>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        marginRight: 2,
                        textTransform: "capitalize",
                        borderColor: themes.dividerBorder,
                        color: themes.blackColor,
                        fontWeight: "bold",
                      }}
                      onClick={() => handleViewHistoryClick(item)}
                    >
                      View History
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        marginRight: 2,
                        textTransform: "capitalize",
                        borderColor: themes.dividerBorder,
                        color: themes.blackColor,
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        handleDeleteConfirmation(item, "Rejected");
                      }}
                    >
                      Reject
                    </Button>

                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      sx={{
                        textTransform: "capitalize",
                        borderColor: themes.dividerBorder,
                        color: themes.blackColor,
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        setActionId(item.id);
                        handleApproveClick(item, "Approved");
                      }}
                      disabled={actionId === item.id ? true : false}
                    >
                      {actionId === item.id && buttonLoader ? (
                        <CircularProgress
                          sx={{
                            color: themes.lightGray,
                          }}
                          size={20}
                        />
                      ) : (
                        <Typography
                          sx={{
                            color: themes.blackColor,
                            fontWeight: "bold",
                            fontSize: 12,
                          }}
                        >
                          Approve
                        </Typography>
                      )}
                    </Button>
                  </Box>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <br />
      <Grid container sx={{ paddingTop: 10 }}></Grid>
      <DialogBox
        open={Boolean(deleteConfirmation)}
        onClose={handleDeleteCancel}
        title="Reason for Rejection"
      >
        <TextField
          multiline
          minRows={3}
          sx={{
            marginTop: 1,
            width: "95%",
            marginLeft: 2,
            marginRight: "auto",
          }}
          placeholder={leaveManagamentPlaceholder.rejectionLeave}
          onChange={(e) => setRejectReason(e.target.value)}
        />
        {rejectedReasonValidation === "Reason" ? (
          <Typography color="red" sx={{ paddingLeft: 3 }}>
            Please enter the rejected reason
          </Typography>
        ) : null}
        {rejectedReasonValidation === "Less then 5 charactor" ? (
          <Typography color="red" sx={{ paddingLeft: 3 }}>
            Minium 5 character
          </Typography>
        ) : null}

        <Grid container sx={{ paddingTop: 1 }}>
          <Grid xs={8}></Grid>
          <Grid
            xs={4}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={handleDeleteCancel}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={onSubmit}
              disableAction={
                deleteConfirmation && buttonLoader ? buttonLoader : false
              }
              actionName="Yes"
              typeName="submit"
            />
          </Grid>
        </Grid>
      </DialogBox>
      {showViewHistory && (
        <ViewHistory
          historyData={pastLeaveData}
          onClose={() => setShowViewHistory(false)}
        />
      )}
    </>
  );
};

export default Pending;
