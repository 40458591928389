import React from "react";
import { Grid, Paper } from "@mui/material";
import { themes } from "services/constants";
import { useDispatch } from "react-redux";
import { setGlobalSearchValue } from "services/Redux/userToken";

export const SettingsMenu = ({
  setCurrentScreen,
  currentScreen,
  setAddNewSalaryTemplate,
  setEditTemplate,
  setEditSalary,
  setEditBankDetails,
}) => {
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <Grid conatiner spacing={2} sx={{ marginTop: 5 }}>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "General" ? themes.primary : null,
            }}
            onClick={() => setCurrentScreen("General")}
          >
            General
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Salary Component" ? themes.primary : null,
            }}
            onClick={() => {
              setCurrentScreen("Salary Component");
              dispatch(setGlobalSearchValue(""));
              setAddNewSalaryTemplate(false);
              setEditTemplate(false);
              setEditSalary(false);
              setEditBankDetails(false);
            }}
          >
            Salary Component
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Payment Bank" ? themes.primary : null,
            }}
            onClick={() => {
              setCurrentScreen("Payment Bank");
              dispatch(setGlobalSearchValue(""));
              setAddNewSalaryTemplate(false);
              setEditTemplate(false);
              setEditSalary(false);
              setEditBankDetails(false);
            }}
          >
            Payment Bank
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Salary Group" ? themes.primary : null,
            }}
            onClick={() => {
              setCurrentScreen("Salary Group");
              dispatch(setGlobalSearchValue(""));
              setAddNewSalaryTemplate(false);
              setEditTemplate(false);
              setEditSalary(false);
              setEditBankDetails(false);
            }}
          >
            Salary Group
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Salary Template" ? themes.primary : null,
            }}
            onClick={() => {
              setCurrentScreen("Salary Template");
              dispatch(setGlobalSearchValue(""));
              setAddNewSalaryTemplate(false);
              setEditTemplate(false);
              setEditSalary(false);
              setEditBankDetails(false);
            }}
          >
            Salary Template
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color: themes.blackColor,
              backgroundColor:
                currentScreen === "Incentive" ? themes.primary : null,
            }}
            onClick={() => {
              setCurrentScreen("Incentive");
              dispatch(setGlobalSearchValue(""));
            }}
          >
            Incentive
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
