import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import { RemoveRedEye } from "@mui/icons-material";

import { Loader } from "components/Loader";
import { PayingUnitHeader } from "pages/Administration/AdministrationHeaders";
import { AddWorkUnit } from "./AddWorkUnit";
import { EditWorkUnit } from "./EditWorkUnit";

const keys = [
  "work_unit_name",
  "department_name",
  "total_number_of_work_unit",
  "unit_type",
  "amount",
  "default_unit",
  "effective_from",
  "department_name",
];

export const PayingUnit = ({
  companyId,
  payUnit,
  setPayUnit,
  editWorkUnitPay,
  setEditWorkUnitPay,
  setShowTopBar,
}) => {
  const { domain, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [deleteWorkUnitPayDetails, setDeleteWorkUnitPayDetails] = useState({});
  const [workUnitDataDetails, setWorkUnitDataDetails] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const dispatch = useDispatch();

  const {
    result: workUnitData,
    setLoad: reCallApi,
    loading,
  } = useFetch(`${domain}work_unit_pay/`);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (updateData && workUnitData?.length !== 0 && workUnitData) {
      setWorkUnitDataDetails(workUnitData);
      setUpdateData(false);
    }
  }, [workUnitData, updateData]);

  useEffect(() => {
    if (workUnitData?.length !== 0 || workUnitData?.length === 0) {
      const filteredResults = workUnitData?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setWorkUnitDataDetails(filteredResults);
      setPage(globalSearchValue ? 0 : page);
    }
  }, [globalSearchValue, page, workUnitData]);

  return (
    <React.Fragment>
      {payUnit ? (
        <AddWorkUnit
          companyId={companyId}
          setPayUnit={setPayUnit}
          reCallApi={reCallApi}
          setShowTopBar={setShowTopBar}
        />
      ) : loading ? (
        <Loader />
      ) : editWorkUnitPay ? (
        <EditWorkUnit
          reCallApi={reCallApi}
          deleteWorkUnitPayDetails={deleteWorkUnitPayDetails}
          setEditWorkUnitPay={setEditWorkUnitPay}
          workUnitDataDetails={workUnitDataDetails}
        />
      ) : (
        <React.Fragment>
          <Mui.TableContainer
            sx={{ mt: 2, width: "100%", paddingLeft: 1, maxHeight: 850 }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      height: "10px",
                      padding: "3px 15px",
                    },
                  }}
                >
                  {PayingUnitHeader.map((head) => (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      {head.name}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {workUnitDataDetails.length === 0 ? (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={8} align="center">
                      <Mui.Alert severity="info">No data available</Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  workUnitDataDetails
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((account, index) => (
                      <React.Fragment>
                        <Mui.TableRow
                          sx={{
                            "& .MuiTableCell-root": {
                              height: "10px",
                              padding: "6px 35px",
                            },
                          }}
                        >
                          {PayingUnitHeader.map((head) =>
                            head.name === "Actions" ? (
                              <Mui.TableCell>
                                <Mui.IconButton
                                  onClick={() => {
                                    setDeleteWorkUnitPayDetails(account);
                                    setEditWorkUnitPay(true);
                                    dispatch(setGlobalSearchValue(""));
                                  }}
                                >
                                  <RemoveRedEye sx={{ fontSize: "10" }} />
                                </Mui.IconButton>
                              </Mui.TableCell>
                            ) : (
                              <Mui.TableCell>
                                {account[head.key] ? account[head.key] : "N/A"}
                              </Mui.TableCell>
                            )
                          )}
                        </Mui.TableRow>
                      </React.Fragment>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          <Mui.Grid
            container
            sx={{
              paddingTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            {workUnitDataDetails?.length > 10 && (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[25, 50, 75, 100]}
                count={workUnitDataDetails?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Mui.Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
