export const loanHistory = [
  { name: "Date", key: "applied_date" },
  //   { name: "Employee Name" },
  { name: "Loan Amount", key: "principal_amount" },
  { name: "Tenure", key: "tenure_months" },
  { name: "Interest", key: "interest_rate" },
  { name: "Installment Amount", key: "monthly_installment" },
  { name: "Loan Type", key: "loan_type" },
  { name: "Interest Type", key: "interest_type" },
  { name: "Status", key: "status" },
  { name: "Action" },
];

export const viewTableData = [
  { name: "Payment Date", key: "payment_month" },
  { name: "Interest", key: "interest" },
  { name: "Installment Amount", key: "principal" },
  { name: "Total Amount", key: "amount" },
  { name: "Status", key: "status" },
  { name: "Balance", key: "remaining_balance" },
];

export const loanTypeArray = [
  {
    label: "Personal Loan",
  },
  {
    label: "Home Loan",
  },
  {
    label: "Vehicle Loan",
  },
  {
    label: "Education Loan",
  },
  {
    label: "Medical Loan",
  },
];

export const interestType = [
  {
    label: "Flat Rate",
  },
  {
    label: "Reducing Balance",
  },
];

export const tenureType = [
  {
    label: "Monthly",
  },
  {
    label: "Weekly",
  },
  {
    label: "Biweekly",
  },
];
