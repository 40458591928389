import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";

const keys = [
  "status",
  "user_name",
  "date_incurred",
  "amount",
  "expense_type",
  "description",
  "reason",
];

export const History = () => {
  const { domain, globalSearchValue, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [loanData, setLoanData] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { result: expenseDetails, loading } = useFetch(
    `${domain}expense/?selected_user_id=${userDetails?.id}`
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const advanceSalaryHeader = [
    { name: "" },
    { name: "Date" },
    { name: "Expense Type" },
    { name: "Expense Amount" },
    { name: "Reason For Expense" },
    { name: "Status" },
  ];

  useEffect(() => {
    if (updateData && expenseDetails?.length !== 0 && expenseDetails) {
      setLoanData(expenseDetails);
      setUpdateData(false);
    }
  }, [expenseDetails, updateData]);

  useEffect(() => {
    const filteredResults = expenseDetails?.filter((item) =>
      keys.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setLoanData(filteredResults);
    setPage(0);
  }, [globalSearchValue, expenseDetails]);

  return loading === true ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingRight: 1 }}>
        <Mui.TableContainer
          sx={{
            marginTop: 2,
            marginLeft: "auto",
            marginRight: "auto",
            overflow: "auto",
          }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow>
                {advanceSalaryHeader?.map((value) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {value?.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {loanData?.length === 0 ? (
                <Mui.TableRow>
                  <Mui.TableCell colSpan={8}>
                    <Mui.Alert
                      severity="info"
                      sx={{ margin: 2, width: "100%" }}
                    >
                      No data available
                    </Mui.Alert>
                  </Mui.TableCell>
                </Mui.TableRow>
              ) : (
                loanData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((value) => (
                    <Mui.TableRow>
                      <Mui.TableCell></Mui.TableCell>
                      <Mui.TableCell>
                        {value?.date_incurred ? value?.date_incurred : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        {value?.expense_type ? value?.expense_type : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        {value?.amount ? value?.amount : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        {value?.description ? value?.description : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        {value?.status ? value?.status : "N/A"}
                      </Mui.TableCell>
                    </Mui.TableRow>
                  ))
              )}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      </Mui.Grid>

      {loanData?.length > 10 && (
        <Mui.TablePagination
          className="custom-pagination"
          rowsPerPageOptions={[25, 50, 75, 100]}
          component="div"
          count={loanData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};
