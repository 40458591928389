import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { showToast } from "services/utils/Status";
import { themes, yearMonth } from "services/constants";
import {
  minimum3Characters,
  minimum10Digits,
} from "services/constants/ErrorMessages";
import {
  // emailValidationPatterns,
  specialCharacterRestrict,
  specialCharacterRest,
  onlyNumberRegex,
} from "services/constants/patterns";
import { useSelector } from "react-redux";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useFetch } from "services/hooks/useFetch";
import sessionHandling from "services/utils/notificationUtils";
import { CustomButton } from "components/CustomComponents/CustomButton";

export const BasicDetailsForm = ({
  setPageNo,
  getSelectedUserDetails,
  existingUserDetails,
  updateBasicDetailsData,
  setUpdateBasicDetailsData,
  setExistingUserDetails,
  openDialogBox,
  selectedUserMailId,
  employeeIdExisting,
  selectedUserPhoneNumber,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const { employeeScreenAction } = useSelector((state) => state.teamZoneReducer);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    // trigger,
    formState: { errors },
  } = useForm();
  //Api State
  const { result: users } = useFetch(
    employeeScreenAction === "Edit Employee"
      ? `${domain}list/existing-employee`
      : `${domain}list/existing-users`
  );
  const { result: department } = useFetch(`${domain}department/`);
  const { result: designation } = useFetch(`${domain}designation/`);
  const { result: employmentType } = useFetch(`${domain}employment-type/`);
  const { result: userGroup } = useFetch(`${domain}leave-groups-basics/`);
  const { result: companyList } = useFetch(`${domain}get-active-company/`);
  const { result: lastInsertedEmployeeId } = useFetch(
    `${domain}employee-id/last-used`
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [saveAndExit, setSaveAndExit] = useState(false);
  const [checkBoxValue, setCheckBoxValue] = useState(false);
  //Text and autocomplete States
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedUserCode, setSelectedUserCode] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [designationName, setDesignationName] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState(
    moment(new Date()).format(yearMonth)
  );
  const [employeeId, setEmployeeId] = useState("");
  const [leaveGroupName, setLeaveGroupName] = useState("");
  const [leaveCount, setLeaveCount] = useState(0);
  const [employmentTypeName, setEmploymentTypeName] = useState("");
  const [viewOtherDetails, setViewOtherDetails] = useState(false);
  const [userNameDetails, setUserNameDetails] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  useEffect(() => {
    if (updateBasicDetailsData) {
      setSelectedUserName(existingUserDetails?.user_name);
      setUserNameDetails(existingUserDetails?.user_name);
      setValue("changeUserName", existingUserDetails?.user_name);
      setSelectedUserCode(existingUserDetails?.user_code);
      setValue("userCode", existingUserDetails?.user_code);
      setValue("userName", existingUserDetails?.user_name);
      setValue("departmentName", existingUserDetails?.department_name);
      setDepartmentName(existingUserDetails?.department_name);
      setValue("email", existingUserDetails?.email_id);
      setPhoneNumber(existingUserDetails?.phone_number);
      setValue("mobileNumber", existingUserDetails?.phone_number);
      setValue(
        "employee",
        existingUserDetails?.employee_id ? existingUserDetails?.employee_id : ""
      );
      setEmployeeId(
        existingUserDetails?.employee_id ? existingUserDetails?.employee_id : ""
      );
      setValue("company", existingUserDetails?.company_name);
      setCompanyName(existingUserDetails?.company_name);
      setValue("designation", existingUserDetails?.designation);
      setDesignationName(existingUserDetails?.designation);
      setValue(
        "dateofJoined",
        existingUserDetails?.date_of_joined?.split("-").reverse().join("-")
      );
      setDateOfJoining(
        existingUserDetails?.date_of_joined?.split("-").reverse().join("-")
      );
      setLeaveCount(existingUserDetails?.overall_leave_count);
      setValue("leaveGroup", existingUserDetails?.leave_group_name);
      setLeaveGroupName(existingUserDetails?.leave_group_name);
      setEmploymentTypeName(existingUserDetails?.employment_type);
      setValue("employmentType", existingUserDetails?.employment_type);
      setValue("user_id", existingUserDetails?.user_id);
      setUpdateBasicDetailsData(false);
      setViewOtherDetails(true);
      setCheckBoxValue(existingUserDetails.portal_access);
      //update Ids
      setValue("selectedCompanyId", existingUserDetails?.company_id);
      setValue("departmentLeadId", existingUserDetails?.department_lead_id);
      setValue("departmentNameId", existingUserDetails?.department_id);
      setValue("designationId", existingUserDetails?.designation_id);
      setValue("employmentId", existingUserDetails?.employment_type_id);
      setValue("leaveGroupId", existingUserDetails?.leave_group_id);
    }
  }, [
    existingUserDetails,
    setUpdateBasicDetailsData,
    setValue,
    updateBasicDetailsData,
  ]);

  const onSubmit = async (data) => {
    setExistingUserDetails({
      ...existingUserDetails,
      email_id: data.email === "" ? null : data.email,
      employee_id: data.employee,
      company_name: companyName,
      department_name: departmentName,
      designation: designationName,
      date_of_joined: data.dateofJoined?.split("-").reverse().join("-"),
      employment_type: employmentTypeName,
      leave_group_name: leaveGroupName,
      company_id: data.selectedCompanyId,
      department_id: data.departmentNameId,
      designation_id: data.designationId,
      employment_type_id: data.employmentId,
      leave_group_id: data.leaveGroupId,
      portal_access: checkBoxValue,
      overall_leave_count: leaveCount,
      phone_number: phoneNumber,
      user_name: data.userName,
      edit_user_name: data.changeUserName,
    });
    if (saveAndExit) {
      updateEmployeeDetails(data);
      setSaveAndExit(false);
    } else {
      setPageNo(2);
      reset();
    }
  };

  const updateEmployeeDetails = async (data) => {
    setButtonDisabled(true);
    try {
      const response = await fetch(`${domain}employee-onboard`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          user_id: existingUserDetails.user_id,
          user_name: existingUserDetails?.user_name,
          email: data.email === "" ? null : data.email,
          company_id: data.selectedCompanyId,
          portal_access: checkBoxValue,
          employee_id: data.employee,
          date_joined: data.dateofJoined,
          leave_group_id: data.leaveGroupId,
          department_id: data.departmentNameId,
          designation_id: data.designationId,
          employment_type_id: data.employmentId,
          parent_name: existingUserDetails.parent_name,
          parent_mobile_number: existingUserDetails.parent_mobile_number,
          bankname: existingUserDetails.bank_name,
          account_holder_name: existingUserDetails.account_holder_name,
          accountno: existingUserDetails.accountno,
          ifsc: existingUserDetails.ifsc,
          branch_name: existingUserDetails.branch_name,
          pan: existingUserDetails.pan_number,
          aadhar: existingUserDetails.aadhar_number,
          address: existingUserDetails.address,
          permanent_address: existingUserDetails.permanent_address,
          pf: existingUserDetails.pf_number,
          uan: existingUserDetails.uan_number,
          esi: existingUserDetails.esi_number,
          dob: existingUserDetails.dob?.split("-").reverse().join("-"),
          emergency_contacts: existingUserDetails.emergency_contacts,
          phone_number: phoneNumber,
          transport_route_no: existingUserDetails?.transport_route_no,
          transport_type: existingUserDetails?.transport_type,
          block_name: existingUserDetails?.block_name,
          room_no: existingUserDetails?.room_no,
          salary_group: existingUserDetails?.salary_group_id,
          earnings: existingUserDetails?.earnings,
          deductions: existingUserDetails?.deductions,
          salary_type: existingUserDetails?.salary_type,
          amount: existingUserDetails.amount,
          edit_user_name: data.changeUserName,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        openDialogBox();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  const updateUserCode = (event, data) => {
    if (data) {
      setValue("userCode", data?.label);
      setValue("userName", data?.user_name);
      setSelectedUserName(data?.user_name);
      setUserNameDetails(data?.user_name);
      setSelectedUserCode(data?.label);
      getSelectedUserDetails(data?.user_id);
      setViewOtherDetails(true);
      setValue("user_id", data?.user_id);
    } else {
      setValue("userCode", null);
      setValue("userName", null);
      setSelectedUserName(null);
      setSelectedUserCode(null);
      setViewOtherDetails(false);
      setValue("user_id", null);
    }
  };

  const updateUserName = (event, data) => {
    if (data) {
      setValue("userCode", data?.user_code);
      setValue("userName", data?.label);
      setValue("changeUserName", data?.label);
      setSelectedUserName(data?.label);
      setSelectedUserName(data?.label);
      setSelectedUserCode(data?.user_code);
      getSelectedUserDetails(data?.user_id);
      setViewOtherDetails(true);
    } else {
      setValue("userCode", null);
      setValue("userName", null);
      setSelectedUserName(null);
      setSelectedUserCode(null);
      setViewOtherDetails(false);
    }
  };

  const handleEmployeeId = (event) => {
    if (
      specialCharacterRest.test(event.target.value) &&
      event.target.value.length <= 12
    ) {
      setValue("employee", event.target.value);
      setEmployeeId(event.target.value);
    }
  };

  const handleCompanyName = (event, data) => {
    if (data) {
      setValue("company", data?.label);
      setValue("selectedCompanyId", data?.id);
      setCompanyName(data?.label);
    } else {
      setValue("company", "");
      setValue("selectedCompanyId", null);
      setCompanyName("");
    }
  };

  const handleDepartment = (event, data) => {
    if (data) {
      setValue("departmentName", data?.label);
      setValue("departmentNameId", data?.id);
      setDepartmentName(data?.label);
    } else {
      setValue("departmentName", "");
      setValue("departmentNameId", null);
      setDepartmentName("");
    }
  };

  const handleDesignation = (event, data) => {
    if (data) {
      setValue("designation", data?.label);
      setValue("designationId", data?.id);
      setDesignationName(data?.label);
    } else {
      setValue("designation", "");
      setValue("designationId", null);
      setDesignationName("");
    }
  };

  const handleDateOfJoined = (event) => {
    setValue("dateofJoined", event.target.value);
    setDateOfJoining(event.target.value);
  };

  const handleEmploymentType = (event, data) => {
    if (data) {
      setValue("employmentType", data?.label);
      setValue("employmentId", data?.id);
      setEmploymentTypeName(data?.label);
    } else {
      setValue("employmentType", "");
      setValue("employmentId", null);
      setEmploymentTypeName("");
    }
  };

  const handleUserLeaveGroup = (event, data) => {
    if (data) {
      setValue("leaveGroup", data?.label);
      setValue("leaveGroupId", data?.id);
      setValue("leaveGroupCount", data?.overall_leave_count);
      setLeaveCount(data?.overall_leave_count);
      setLeaveGroupName(data.label);
    } else {
      setValue("leaveGroup", "");
      setValue("leaveGroupId", null);
      setValue("leaveGroupCount", 0);
      setLeaveCount(0);
      setLeaveGroupName("");
    }
  };

  const handleLeaveGroupCount = (event) => {
    event.preventDefault();
  };

  const updateCheckBoxValue = (event) => {
    setCheckBoxValue(event.target.checked);
  };

  const updatePhoneNumber = (event) => {
    if (
      event.target.value.length <= 15 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setPhoneNumber(event.target.value);
      setValue("mobileNumber", event.target.value);
    }
  };

  const updateUserNameChange = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 32
    ) {
      setValue("changeUserName", event.target.value);
      setUserNameDetails(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: 10 }}>
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "flex-start",
            paddingRight: 2,
          }}
        >
          <Mui.Typography
            sx={{ fontSize: 25, fontWeight: "bold", paddingLeft: 1.2 }}
          >
            Please select user code or username
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid container fullWidth>
          <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              Name
            </Mui.Typography>
            <CustomAutoComplete
              dataList={users?.map((obj) => ({
                ...obj,
                label: obj.user_name,
              }))}
              selectedValue={selectedUserName}
              rules={{
                ...register("userName", {
                  required: "User name is required",
                }),
              }}
              updateDataFunction={updateUserName}
              placeHolder="Please select the name"
              errorMessage={
                errors?.userName?.message ? errors?.userName?.message : ""
              }
              textBoxError={errors.userName ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid item xs={0.4}></Mui.Grid>
          <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              User Code
            </Mui.Typography>
            <CustomAutoComplete
              dataList={users.map((obj) => ({
                ...obj,
                label: obj?.user_code?.toString(),
              }))}
              selectedValue={selectedUserCode}
              rules={{
                ...register("userCode", {
                  required: "User code is required",
                }),
              }}
              updateDataFunction={updateUserCode}
              placeHolder="Please select the user code"
              errorMessage={
                errors?.userCode?.message ? errors?.userCode?.message : ""
              }
              textBoxError={errors?.userCode ? true : false}
            />
          </Mui.Grid>
        </Mui.Grid>
        {viewOtherDetails ? (
          <>
            <Mui.Grid container fullWidth>
              <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
                <Mui.Grid container>
                  <Mui.Grid xs={5}>
                    <Mui.Typography
                      sx={{
                        fontSize: 15,
                        color: themes.colorShadow,
                        paddingBottom: 0.5,
                      }}
                    >
                      Employee ID
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid
                    xs={7}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <Mui.Typography sx={{ color: themes.primaryIndicator }}>
                      Last Used : {lastInsertedEmployeeId.latest_employee_id}
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>

                <CustomTextBox
                  selectedValue={employeeId}
                  rules={{
                    ...register("employee", {
                      required: "Employee Id is required",
                      validate: {
                        minLengthNoSpaces: (value) => {
                          if (value?.trim().length < 6 && value !== "") {
                            return "Minimum 6 characters with no spaces";
                          } else {
                            return true;
                          }
                        },
                        employeeIdAvailable: async (fieldValue) => {
                          if (
                            fieldValue !== "" &&
                            employeeIdExisting !== fieldValue
                          ) {
                            const response = await fetch(
                              `${domain}employee-id/check?employee_id=${fieldValue}`,
                              {
                                method: "GET",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: `token ${token}`,
                                },
                              }
                            );
                            const resp = await response.json();
                            if (resp.exists) {
                              return "Employee Id Already Exists";
                            }
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={handleEmployeeId}
                  placeHolder="Enter the Employee Id"
                  errorMessage={
                    errors?.employee?.message ? errors?.employee?.message : ""
                  }
                  textBoxError={errors.employee ? true : false}
                />
              </Mui.Grid>

              <Mui.Grid item xs={0.4}></Mui.Grid>
              <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.colorShadow,
                    paddingBottom: 0.5,
                  }}
                >
                  Company
                </Mui.Typography>

                <CustomAutoComplete
                  dataList={companyList.map((obj) => ({
                    ...obj,
                    label: obj.companyname,
                  }))}
                  selectedValue={companyName}
                  rules={{
                    ...register("company", {
                      required: "Company name is required",
                    }),
                  }}
                  updateDataFunction={handleCompanyName}
                  placeHolder="Please select the company name"
                  errorMessage={
                    errors?.company?.message ? errors?.company?.message : ""
                  }
                  textBoxError={errors.company ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container fullWidth>
              <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.colorShadow,
                    paddingBottom: 0.5,
                  }}
                >
                  Department
                </Mui.Typography>
                <CustomAutoComplete
                  dataList={department.map((obj) => ({
                    ...obj,
                    label: obj?.department?.toString(),
                  }))}
                  selectedValue={departmentName}
                  rules={{
                    ...register("departmentName", {
                      required: "Department name is required",
                    }),
                  }}
                  updateDataFunction={handleDepartment}
                  placeHolder="Please select the department name"
                  errorMessage={
                    errors?.departmentName?.message
                      ? errors?.departmentName?.message
                      : ""
                  }
                  textBoxError={errors?.departmentName ? true : false}
                />
              </Mui.Grid>
              <Mui.Grid item xs={0.4}></Mui.Grid>
              <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.colorShadow,
                    paddingBottom: 0.5,
                  }}
                >
                  Date of Joining
                </Mui.Typography>
                <CustomTextBox
                  type="date"
                  selectedValue={dateOfJoining}
                  rules={{
                    ...register("dateofJoined", {
                      required: "Date of joined is required",
                    }),
                  }}
                  updateDataFunction={handleDateOfJoined}
                  errorMessage={
                    errors?.dateofJoined?.message
                      ? errors?.dateofJoined?.message
                      : ""
                  }
                  textBoxError={errors.dateofJoined ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container fullWidth>
              <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.colorShadow,
                    paddingBottom: 0.5,
                  }}
                >
                  Designation
                </Mui.Typography>
                <CustomAutoComplete
                  dataList={designation.map((obj) => ({
                    ...obj,
                    label: obj.designation,
                  }))}
                  selectedValue={designationName}
                  rules={{
                    ...register("designation", {
                      required: "Designation is required",
                    }),
                  }}
                  updateDataFunction={handleDesignation}
                  placeHolder="Please select the designation"
                  errorMessage={
                    errors?.designation?.message
                      ? errors?.designation?.message
                      : ""
                  }
                  textBoxError={errors?.designation ? true : false}
                />
              </Mui.Grid>
              <Mui.Grid item xs={0.4}></Mui.Grid>
              <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.colorShadow,
                    paddingBottom: 0.5,
                  }}
                >
                  Employment Type
                </Mui.Typography>
                <CustomAutoComplete
                  dataList={employmentType.map((obj) => ({
                    ...obj,
                    label: obj.employment_type,
                  }))}
                  selectedValue={employmentTypeName}
                  rules={{
                    ...register("employmentType", {
                      required: "Employment Type is required",
                    }),
                  }}
                  updateDataFunction={handleEmploymentType}
                  placeHolder="Please select the employment type"
                  errorMessage={
                    errors?.employmentType?.message
                      ? errors?.employmentType?.message
                      : ""
                  }
                  textBoxError={errors.employmentType ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container fullWidth>
              <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.colorShadow,
                    paddingBottom: 0.5,
                  }}
                >
                  Phone Number
                </Mui.Typography>
                <CustomTextBox
                  selectedValue={phoneNumber}
                  rules={{
                    ...register("mobileNumber", {
                      required: "Phone number is required",
                      minLength: {
                        value: 10,
                        message: minimum10Digits,
                      },
                      validate: {
                        employeePhonenumberAvailable: async (fieldValue) => {
                          if (
                            fieldValue !== "" &&
                            selectedUserPhoneNumber !== fieldValue
                          ) {
                            const response = await fetch(
                              `${domain}unique/phone-number/?phone_no=${fieldValue}`,
                              {
                                method: "GET",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: `token ${token}`,
                                },
                              }
                            );
                            const resp = await response.json();
                            if (resp.exists) {
                              return "Phone number already exists";
                            }
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={updatePhoneNumber}
                  placeHolder="Enter Phone Number"
                  errorMessage={
                    errors?.mobileNumber?.message
                      ? errors?.mobileNumber?.message
                      : ""
                  }
                  textBoxError={errors.mobileNumber ? true : false}
                />
              </Mui.Grid>
              <Mui.Grid item xs={0.4}></Mui.Grid>
              <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.colorShadow,
                    paddingBottom: 0.5,
                  }}
                >
                  Employee Name
                </Mui.Typography>
                <CustomTextBox
                  selectedValue={userNameDetails}
                  rules={{
                    ...register("changeUserName", {
                      required: "userName is required",
                      maxLength: {
                        value: 32,
                        message: "Maximum 32 characters are required",
                      },
                      validate: {
                        minLengthNoSpaces: (value) => {
                          if (value?.trim().length < 3 && value !== "") {
                            return minimum3Characters;
                          } else {
                            return true;
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={updateUserNameChange}
                  placeHolder="Enter the Name"
                  errorMessage={
                    errors?.changeUserName?.message
                      ? errors?.changeUserName?.message
                      : ""
                  }
                  textBoxError={errors.changeUserName ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingBottom: 5 }}>
              <Mui.Grid container sx={{ padding: 1 }}>
                <Mui.Grid item xs={5.8}>
                  <Mui.Typography
                    sx={{
                      fontSize: 15,
                      color: themes.colorShadow,
                      paddingBottom: 0.5,
                    }}
                  >
                    Email Id
                  </Mui.Typography>
                  <Mui.TextField
                    size="small"
                    {...register("email", {
                      required: false,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid e-mail address",
                      },
                      validate: {
                        emailAvailable: async (fieldValue) => {
                          if (
                            fieldValue !== "" &&
                            selectedUserMailId !== fieldValue
                          ) {
                            try {
                              const response = await fetch(
                                `${domain}unique/email/?email=${fieldValue}`,
                                {
                                  method: "GET",
                                  headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `token ${token}`,
                                  },
                                }
                              );
                              const res = await response.json();
                              if (response.ok) {
                                if (res.exists) {
                                  return "Email Already Exists";
                                } else {
                                  return true;
                                }
                              } else if (response.status === 409) {
                                sessionHandling();
                              } else {
                                throw new Error(res.error);
                              }
                            } catch (error) {
                              showToast("error", error.message);
                            }
                          }
                        },
                      },
                    })}
                    error={Boolean(errors.email)}
                    helperText={errors.email ? errors.email.message : null}
                    placeholder="Enter your email"
                    fullWidth
                  />
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "flex-start",
                paddingRight: 2,
              }}
            >
              <Mui.Typography
                sx={{ fontSize: 25, fontWeight: "bold", paddingLeft: 1.2 }}
              >
                Leave Details
              </Mui.Typography>
            </Mui.Grid>
            <br />
            <Mui.Grid container>
              <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.colorShadow,
                    paddingBottom: 0.5,
                  }}
                >
                  Leave Group
                </Mui.Typography>
                <CustomAutoComplete
                  dataList={userGroup.map((obj) => ({
                    ...obj,
                    label: obj.name,
                  }))}
                  selectedValue={leaveGroupName}
                  rules={{
                    ...register("leaveGroup", {
                      required: "Leave group is required",
                    }),
                  }}
                  updateDataFunction={handleUserLeaveGroup}
                  placeHolder="Please select the leave group"
                  errorMessage={
                    errors?.leaveGroup?.message
                      ? errors?.leaveGroup?.message
                      : ""
                  }
                  textBoxError={errors?.leaveGroup ? true : false}
                />
              </Mui.Grid>
              <Mui.Grid item xs={0.4}></Mui.Grid>
              <Mui.Grid item xs={5.8} sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.colorShadow,
                    paddingBottom: 0.5,
                  }}
                >
                  Count
                </Mui.Typography>
                <CustomTextBox
                  selectedValue={leaveCount}
                  rules={{
                    ...register("leaveGroupCount", {
                      required: false,
                    }),
                  }}
                  updateDataFunction={handleLeaveGroupCount}
                  placeHolder="Leave Count"
                  errorMessage={
                    errors?.leaveGroupCount?.message
                      ? errors?.leaveGroupCount?.message
                      : ""
                  }
                  textBoxError={errors.leaveGroupCount ? true : false}
                />
              </Mui.Grid>
              <Mui.Grid item xs={12} sm={6} md={3} lg={5.5}></Mui.Grid>
            </Mui.Grid>
            <br />
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "flex-start",
                paddingRight: 2,
              }}
            >
              <Mui.Typography
                sx={{ fontSize: 25, fontWeight: "bold", paddingLeft: 1.2 }}
              >
                Enable Portal Access
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid item xs={2} sm={2} md={1} lg={1}>
                <Mui.FormControl component="fieldset">
                  <Mui.FormGroup aria-label="position" row>
                    <Mui.FormControlLabel
                      control={
                        <Mui.Checkbox
                          checked={checkBoxValue}
                          color="primary"
                          size="large"
                          onChange={updateCheckBoxValue}
                          // disabled={getSelectedUserDetails.role === "Admin"}
                          // sx={{
                          //   "&.Mui-disabled": {
                          //     color: themes.blueColor,
                          //   },
                          // }}
                        />
                      }
                      labelPlacement="top"
                    />
                  </Mui.FormGroup>
                </Mui.FormControl>
              </Mui.Grid>
              <Mui.Grid item xs={8} sm={8} md={9} lg={9}>
                <Mui.Typography
                  Warp
                  sx={{ fontSize: 20, color: themes.colorShadow }}
                >
                  The employee will be able to submit their leaves
                  ,reimbursement claims ,view and generate payslips through the
                  employee portal
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={2} sm={2} md={2} lg={2}></Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container>
              <Mui.Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  alignItems: "flex-start",
                  paddingRight: 2,
                }}
              ></Mui.Grid>
              <Mui.Grid
                xs={8}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  alignItems: "flex-end",
                  paddingRight: 2,
                }}
              >
                <CustomButton
                  actionFuntion={() => {
                    setSaveAndExit(true);
                  }}
                  actionName="Save & Exit"
                  typeName="submit"
                  disableAction={buttonDisabled}
                />
                &nbsp;&nbsp;
                <CustomButton
                  actionFuntion={() => {
                    setSaveAndExit(false);
                  }}
                  actionName="Next"
                  typeName="submit"
                />
              </Mui.Grid>
            </Mui.Grid>
          </>
        ) : null}
        <Mui.Grid container sx={{ paddingTop: 2 }}></Mui.Grid>
      </form>
    </React.Fragment>
  );
};
