import React, { useState } from "react";
import * as Mui from "@mui/material";
import { Info } from "@mui/icons-material";
import moment from "moment";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { customAPI } from "services/utils/CustomAPI";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { shiftTypes } from "pages/Administration/AdministrationHeaders";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import Switch from "@mui/material/Switch";
import { NonRotationalShift } from "./ShiftType/NonRotationalShift";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { RotationalShift } from "./ShiftType/RotationalShift";
import { RandomShift } from "./ShiftType/RandomShift";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useFetch } from "services/hooks/useFetch";
import { onlyNumberRegex } from "services/constants/patterns";
import { AddShiftCategoryHeader } from "pages/Administration/AdministrationHeaders";
import dayjs from "dayjs";
import sessionHandling from "services/utils/notificationUtils";
export const AddShiftCategory = ({
  setshiftCategory,
  reCallApi,
  setShowTopBar,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const { domain, token } = useSelector((state) => state.tokenReducer);
  const { result: shiftGradeData } = useFetch(`${domain}shift-grade/`);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectType, setSelectType] = useState("hours");
  const [penalty, setPenalty] = useState("hours");
  const [allShifts, setAllShifts] = useState([]);
  const [shiftType, setShiftType] = useState("");
  const [startTime, setStartTime] = useState("00:00");
  const [checked, setChecked] = useState(false);
  const [penaltyPolicy, setPenaltyPolicy] = useState(false);
  const [shiftCategoryName, setShiftCategoryName] = useState("");
  const [shiftGrade, setShiftGrade] = useState([]);
  const [otDuration, setOtDuration] = useState(
    dayjs().set("hour", "01").set("minute", "00")
  );
  const [weekOffPaid, setWeekOffPaid] = useState(false);
  const [paymentFactor, setPaymentFactor] = useState("1");
  const [startStartEarly, setStartStartEarly] = useState("15");
  const [endEndDelay, setEndEndDelay] = useState("15");
  const [penaltyPaymentFactor, setPenaltyPaymentFactor] = useState("1");
  const [thersold, setThersold] = useState("1");
  const [therSoldPoint, setTherSoldPoint] = useState("0.55");
  const [thersold1, setThersold1] = useState("1");
  const [therSoldPoint1, setTherSoldPoint1] = useState("0.55");
  const [noShift, setNoShift] = useState("");
  const [rotationalError, setRotationalError] = useState(false);
  const [randomShiftError, setRandomShiftError] = useState(false);
  const [minimumOTValueError, setMinimumOTValueError] = useState(false);
  const [shiftStartTime, setShiftStartTime] = useState(null);
  const [shiftEndTime, setShiftEndTime] = useState(null);
  const [shiftStartTimeFormated, setShiftStartTimeFormated] = useState(null);
  const [shiftEndTimeFormated, setShiftEndTimeFormated] = useState(null);
  const [breakMinutes, setBreakMinutes] = useState("00");
  const [otDurationValue, setotDurationValue] = useState(
    dayjs().set("hour", "01").set("minute", "00")
  );
  const [startTimeFormat, setStartTimeFormat] = useState(null);
  const [weekOffCount, setWeekOffCount] = useState(0);
  const [shiftTimeArrayError, setShiftTimeArrayError] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    setPaymentFactor("1");
    setStartStartEarly("15");
    setEndEndDelay("15");
    setotDurationValue(dayjs().set("hour", "01").set("minute", "00"));
  };

  const handlePenaltyChange = (event) => {
    setPenaltyPolicy(event.target.checked);
    setTherSoldPoint("0.55");
    setThersold("1");
    setThersold1("1");
    setTherSoldPoint1("0.55");
    setPenaltyPaymentFactor("1");
  };

  const [checkInPolicy, setCheckInPolicy] = useState({
    start_early: "15",
    start_delay: "15",
    end_early: "15",
    end_delay: "15",
  });

  const [workingDays, setWorkingDays] = useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  });

  const handleCheckboxChange = (event) => {
    let trueCount = 7 - weekOffCount;
    const trueCount1 = Object.values(workingDays).filter(
      (value) => value === true
    ).length;
    if (trueCount1 !== trueCount || !event.target.checked) {
      setWorkingDays({
        ...workingDays,
        [event.target.name]: event.target.checked,
      });
    } else {
      showToast(
        "error",
        `Only ${trueCount} working days are permitted dependent on the shift grade.`
      );
    }
  };

  const selectTypeChanges = (event) => {
    setSelectType(event.target.value);
  };

  const selectPenaltyChanges = (event) => {
    setPenalty(event.target.value);
  };

  const onSubmit = async (data) => {
    if (data) {
      if (shiftType === "Rotational Shift") {
        setButtonDisabled(true);
        validateRotationalShift(data);
      } else if (shiftType === "Non Rotational Shift") {
        setButtonDisabled(true);
        validateNonRotationalShift(data);
      } else if (shiftType === "Random Shift") {
        setButtonDisabled(true);
        validateRandomShift(data);
      }
    }
  };

  const validateRotationalShift = async (data) => {
    const falseCount = Object.values(workingDays).filter(
      (value) => value === false
    ).length;
    const rowsWithNull = allShifts.filter((row) =>
      Object.values(row).includes(null)
    );
    if (
      noShift !== "" &&
      noShift !== "0" &&
      startTime &&
      startTime !== "00:00" &&
      startTime !== "Invalid Date" &&
      otDurationValue &&
      otDurationValue !== "Invalid Date" &&
      falseCount === parseInt(weekOffCount) &&
      rowsWithNull.length === 0
    ) {
      setMinimumOTValueError(false);
      setRotationalError(false);
      let payload = {
        ...data,
        paid_week_off: weekOffPaid,
        no_of_shift: noShift,
        ot_allowed: checked,
        shift_penalty_deduction: penaltyPolicy,
        shift_start_time: startTime,
        shift_break_time: breakMinutes,
        working_days: workingDays,
        shift_timings: allShifts,
        over_time_policies: checked
          ? [
              {
                payment_based_on: selectType,
                payment_factor: paymentFactor ? paymentFactor : 0,
                min_ot_duration: otDuration?._d
                  ? otDuration._d?.getHours() +
                    ":" +
                    otDuration._d?.getMinutes()
                  : otDuration["$d"]
                  ? otDuration["$d"]?.getHours() +
                    ":" +
                    otDuration["$d"]?.getMinutes()
                  : "00:00",
                start_delay: startStartEarly ? startStartEarly : 0,
                end_early: endEndDelay ? endEndDelay : 0,
              },
            ]
          : [],
        penalty_policies: penaltyPolicy
          ? [
              {
                payment_deduction_mode: penalty,
                payment_factor: penaltyPaymentFactor ? penaltyPaymentFactor : 0,
                threshold_count: thersold ? thersold : 0,
                threshold_deduction: therSoldPoint ? therSoldPoint : 0,
                additional_count: thersold1 ? thersold1 : 0,
                additional_deduction: therSoldPoint1 ? therSoldPoint1 : 0,
              },
            ]
          : [],
      };
      const response = await customAPI(
        `${domain}create_shift/`,
        "POST",
        token,
        payload
      );
      if (response.ok) {
        setMinimumOTValueError(false);
        setRotationalError(false);
        showToast("success", "Shift grade created successfully");
        setshiftCategory(false);
        setShowTopBar(true);
        reCallApi(true);
        reset();
      } else {
        setButtonDisabled(false);
      }
    } else {
      if (falseCount === parseInt(weekOffCount)) {
        if (!otDurationValue || otDurationValue === "Invalid Date") {
          setMinimumOTValueError(true);
          setButtonDisabled(false);
        } else {
          setRotationalError(rowsWithNull.length === 0 ? true : false);
          setButtonDisabled(false);
          setShiftTimeArrayError(rowsWithNull.length !== 0 ? true : false);
        }
      } else {
        setButtonDisabled(false);
        showToast(
          "error",
          `Only ${weekOffCount} week off permitted, dependent on the shift grade.`
        );
      }
    }
  };

  const validateNonRotationalShift = async (data) => {
    const falseCount = Object.values(workingDays).filter(
      (value) => value === false
    ).length;
    const rowsWithNull = allShifts.filter((row) =>
      Object.values(row).includes(null)
    );
    if (
      startTime &&
      startTime !== "00:00" &&
      startTime !== "Invalid Date" &&
      falseCount === parseInt(weekOffCount) &&
      rowsWithNull.length === 0
    ) {
      let payload = {
        ...data,
        paid_week_off: weekOffPaid,
        no_of_shift: noShift,
        ot_allowed: checked,
        shift_penalty_deduction: penaltyPolicy,
        shift_start_time: startTime,
        shift_break_time: breakMinutes,
        working_days: workingDays,
        shift_timings: allShifts,
        over_time_policies: checked
          ? [
              {
                payment_based_on: selectType,
                payment_factor: paymentFactor ? paymentFactor : 0,
                min_ot_duration: otDuration?._d
                  ? otDuration._d?.getHours() +
                    ":" +
                    otDuration._d?.getMinutes()
                  : otDuration["$d"]
                  ? otDuration["$d"]?.getHours() +
                    ":" +
                    otDuration["$d"]?.getMinutes()
                  : "00:00",
                start_delay: startStartEarly ? startStartEarly : 0,
                end_early: endEndDelay ? endEndDelay : 0,
              },
            ]
          : [],
        penalty_policies: penaltyPolicy
          ? [
              {
                payment_deduction_mode: penalty,
                payment_factor: penaltyPaymentFactor ? penaltyPaymentFactor : 0,
                threshold_count: thersold ? thersold : 0,
                threshold_deduction: therSoldPoint ? therSoldPoint : 0,
                additional_count: thersold1 ? thersold1 : 0,
                additional_deduction: therSoldPoint1 ? therSoldPoint1 : 0,
              },
            ]
          : [],
      };
      const response = await customAPI(
        `${domain}create_shift/`,
        "POST",
        token,
        payload
      );
      if (response.ok) {
        showToast("success", "Shift grade created successfully");
        setshiftCategory(false);
        setShowTopBar(true);
        reCallApi(true);
        setRotationalError(false);
        reset();
      } else {
        setButtonDisabled(false);
      }
    } else {
      if (falseCount === parseInt(weekOffCount)) {
        setRotationalError(rowsWithNull.length === 0 ? true : false);
        setButtonDisabled(false);
        setShiftTimeArrayError(rowsWithNull.length !== 0 ? true : false);
      } else {
        setButtonDisabled(false);
        showToast(
          "error",
          `Only ${weekOffCount} week off permitted, dependent on the shift grade.`
        );
      }
    }
  };

  const validateRandomShift = async (data) => {
    const falseCount = Object.values(workingDays).filter(
      (value) => value === false
    ).length;
    if (
      shiftStartTime &&
      shiftEndTime &&
      shiftStartTime !== "Invalid Date" &&
      shiftEndTime !== "Invalid Date" &&
      falseCount === parseInt(weekOffCount)
    ) {
      let payload = {
        ...data,
        paid_week_off: weekOffPaid,
        ot_allowed: checked,
        shift_start_time: shiftStartTime,
        shift_end_time: shiftEndTime,
        working_days: workingDays,
        over_time_policies: checked
          ? [
              {
                payment_factor: paymentFactor ? paymentFactor : 0,
                min_ot_duration: otDuration?._d
                  ? otDuration._d?.getHours() +
                    ":" +
                    otDuration._d?.getMinutes()
                  : otDuration["$d"]
                  ? otDuration["$d"]?.getHours() +
                    ":" +
                    otDuration["$d"]?.getMinutes()
                  : "00:00",
              },
            ]
          : [],
      };
      const response = await customAPI(
        `${domain}create_shift/`,
        "POST",
        token,
        payload
      );
      if (response.ok) {
        showToast("success", "Shift grade created successfully");
        setshiftCategory(false);
        setShowTopBar(true);
        reCallApi(true);
        setRandomShiftError(false);
        reset();
      } else {
        setButtonDisabled(false);
      }
    } else {
      if (falseCount === parseInt(weekOffCount)) {
        setRandomShiftError(true);
        setButtonDisabled(false);
      } else {
        setButtonDisabled(false);
        showToast(
          "error",
          `Only ${weekOffCount} week off permitted, dependent on the shift grade.`
        );
      }
    }
  };

  const renderComponent = () => {
    switch (shiftType) {
      case "Rotational Shift":
        return (
          <RotationalShift
            checked={checked}
            shift_grade_id={getValues("shift_grade_id")}
            shift_category_name={getValues("shift_category_name")}
            setAllShifts={setAllShifts}
            noShift={noShift}
            setNoShift={setNoShift}
            setStartTime={setStartTime}
            startTime={startTime}
            rotationalError={rotationalError}
            setRotationalError={setRotationalError}
            breakMinutes={breakMinutes}
            setBreakMinutes={setBreakMinutes}
            setCheckInPolicy={setCheckInPolicy}
            checkInPolicy={checkInPolicy}
            startTimeFormat={startTimeFormat}
            setStartTimeFormat={setStartTimeFormat}
            overTimeStartStartEarly={startStartEarly}
            overTimeendEndDelay={endEndDelay}
          />
        );
      case "Non Rotational Shift":
        return (
          <NonRotationalShift
            shift_grade_id={getValues("shift_grade_id")}
            shift_category_name={getValues("shift_category_name")}
            setAllShifts={setAllShifts}
            setStartTime={setStartTime}
            startTime={startTime}
            setCheckInPolicy={setCheckInPolicy}
            checkInPolicy={checkInPolicy}
            rotationalError={rotationalError}
            startTimeFormat={startTimeFormat}
            setStartTimeFormat={setStartTimeFormat}
            overTimeStartStartEarly={startStartEarly}
            overTimeendEndDelay={endEndDelay}
            setRotationalError={setRotationalError}
          />
        );
      case "Random Shift":
        return (
          <RandomShift
            setShiftStartTime={setShiftStartTime}
            shiftStartTime={shiftStartTime}
            setShiftEndTime={setShiftEndTime}
            shiftEndTime={shiftEndTime}
            setRandomShiftError={setRandomShiftError}
            randomShiftError={randomShiftError}
            shiftStartTimeFormated={shiftStartTimeFormated}
            shiftEndTimeFormated={shiftEndTimeFormated}
            setShiftStartTimeFormated={setShiftStartTimeFormated}
            setShiftEndTimeFormated={setShiftEndTimeFormated}
            shift_grade_id={getValues("shift_grade_id")}
            shift_category_name={getValues("shift_category_name")}
          />
        );

      default:
        break;
    }
  };

  const updateShiftCategoryName = (event) => {
    if (event.target.value.length < 32) {
      setValue("shift_category_name", event.target.value);
      setShiftCategoryName(event.target.value);
    }
  };

  const updateShiftGrade = (event, data) => {
    if (data) {
      setValue("shift_grade_name", data?.label);
      setValue("shift_grade_id", data?.shift_grade_id);
      setShiftGrade(data?.label);
      setWeekOffCount(data?.weekoff_days);
      console.log(data?.weekoff_days);
      setWorkingDays({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
      });
    }
  };

  const handleShiftType = (event, data) => {
    if (data) {
      setValue("shift_type", data?.label);
      setValue("shift_type_id", data?.id);
      setShiftType(data?.label);
      setAllShifts([]);
      setChecked(false);
      setPenaltyPolicy(false);

      if (data?.label === "Non Rotational Shift") {
        setNoShift("1");
      }
    } else {
      setValue("shift_type", null);
      setValue("shift_type_id", null);
      setShiftType("");
    }
  };

  const updateBreak = (value, details) => {
    let allShiftData = allShifts;
    let updatedShiftDetails = allShiftData?.map((element) => {
      if (element?.shift_number === details?.shift_number) {
        return {
          ...element,
          break_time: value,
        };
      } else {
        return element;
      }
    });
    setAllShifts(updatedShiftDetails);
  };

  // const updateEndTimeDetails = (value, details) => {
  //   if (value) {
  //   let allShiftData = allShifts;
  //   const timeWithDate = value ? value.toDate() : new Date();
  //   let minTime = moment.utc(timeWithDate);
  //   minTime.subtract(parseInt(checkInPolicy.end_early), "minutes");
  //   let maxTime = moment.utc(timeWithDate);
  //   maxTime.add(parseInt(checkInPolicy.end_delay), "minutes");
  //   let updatedShiftDetails = allShiftData?.map((element) => {
  //     if (element?.shift_number === details.shift_number) {
  //       return {
  //         ...element,
  //         end_time: value.format("HH:mm"),
  //         max_end_time:
  //           maxTime?._d?.getHours() + ":" + maxTime?._d?.getMinutes(),
  //         max_start_time:
  //           minTime?._d?.getHours() + ":" + minTime?._d?.getMinutes(),
  //         endTimeDateFormat: moment.utc(timeWithDate),
  //       };
  //     } else {
  //       return element;
  //     }
  //   });
  //   setAllShifts(updatedShiftDetails);
  // }
  // };

  const updateStartTimeDetails = (value, details) => {
    if (value) {
      const timeWithDate = value ? value.toDate() : new Date();
      let minTime = moment.utc(timeWithDate);
      minTime.subtract(parseInt(checkInPolicy.start_early), "minutes");
      let maxTime = moment.utc(timeWithDate);
      maxTime.add(parseInt(checkInPolicy.start_delay), "minutes");
      getShiftDetails(
        getValues("shift_grade_id"),
        getValues("shift_category_name"),
        value.format("HH:mm"),
        details
      );
    } else {
      let updatedShiftDetails = allShifts?.map((element) => {
        if (element?.shift_number === details?.shift_number) {
          return {
            ...element,
            start_time: null,
            min_start_time: element?.min_start_time,
            max_start_time: element?.max_start_time,
          };
        } else {
          return element;
        }
      });
      setAllShifts(updatedShiftDetails);
    }
  };

  const getShiftDetails = async (
    shift_grade_id,
    shift_category_name,
    time,
    details
  ) => {
    if (time && time !== "Invalid Date") {
      try {
        const response = await fetch(
          `${domain}shift-over-time-details/?shift_category_name=${shift_category_name}&shift_grade_id=${shift_grade_id}&start_time=${time}&break_time=${
            details.break_time ? details.break_time : "00"
          }&no_of_shifts=1&start_start_early=${
            checkInPolicy?.start_early ? checkInPolicy?.start_early : 0
          }&start_end_delay=${
            checkInPolicy?.start_delay ? checkInPolicy?.start_delay : 0
          }&end_end_early=${
            checkInPolicy?.end_early ? checkInPolicy?.end_early : 0
          }&end_end_delay=${
            checkInPolicy?.end_delay ? checkInPolicy?.end_delay : 0
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          let updatedShiftDetails = allShifts?.map((element) => {
            if (element?.shift_number === details.shift_number) {
              return {
                ...element,
                start_time: res.shifts[0]?.start_time,
                min_start_time: res.shifts[0]?.min_start_time,
                max_start_time: res.shifts[0]?.max_start_time,
                startTimeDateFormat: dayjs()
                  .set("hour", res?.shifts[0]?.start_time.split(":")[0])
                  .set("minute", res?.shifts[0]?.start_time.split(":")[1])
                  .set("second", "00"),
                min_end_time: res.shifts[0]?.min_end_time,
                end_time: res.shifts[0]?.end_time,
                max_end_time: res.shifts[0]?.max_end_time,
              };
            } else {
              return element;
            }
          });
          setAllShifts(updatedShiftDetails);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    }
  };

  const updateColorCode = (value, details) => {
    let allShiftData = allShifts;
    let updatedShiftDetails = allShiftData?.map((element) => {
      if (element?.shift_number === details?.shift_number) {
        return {
          ...element,
          color_code: value,
        };
      } else {
        return element;
      }
    });
    setAllShifts(updatedShiftDetails);
  };

  const handleWeekofPaid = (event) => {
    setWeekOffPaid(event.target.checked);
  };

  return (
    <>
      <Mui.Grid container>
        <BackArrow
          actionFunction={() => {
            setshiftCategory(false);
            setShowTopBar(true);
          }}
        />
        <Mui.Typography
          sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 1 }}
        >
          Shift / Create Shift Category
        </Mui.Typography>
      </Mui.Grid>
      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 20 }}>
        <Mui.Grid
          container
          sx={{ paddingLeft: 15, paddingRight: 15, paddingTop: 1 }}
        >
          <Mui.Grid container component={Mui.Paper} elevation={2}>
            <Mui.Grid
              xs={12}
              sx={{
                backgroundColor: themes.primary,
                padding: 2,
                textAlign: "center",
              }}
            >
              <Mui.Typography
                sx={{ fontSize: 24, fontWeight: 800, font: "bold" }}
              >
                Create Shift Category
              </Mui.Typography>
            </Mui.Grid>

            <Mui.Grid container component={Mui.Paper} sx={{ padding: 2 }}>
              <Mui.Card sx={{ width: "100%", padding: 4 }} elevation={2}>
                <Mui.Grid
                  container
                  fullWidth
                  sx={{ padding: 1, borderRadius: 3 }}
                >
                  <Mui.Grid item xs={4} sx={{ paddingTop: 1.5 }}>
                    <Mui.Typography
                      sx={{
                        fontSize: 18,
                        color: themes.headLine,
                        fontWeight: "bold",
                      }}
                    >
                      Shift Category Name &nbsp;
                      <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                        <Info />
                      </Mui.Tooltip>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
                    :
                  </Mui.Grid>
                  <Mui.Grid item xs={6}>
                    <CustomTextBox
                      selectedValue={shiftCategoryName}
                      rules={{
                        ...register("shift_category_name", {
                          required: "Shift category name is required",
                          minLength: {
                            value: 3,
                            message: "Minium length is 3 characters",
                          },
                          validate: {
                            atleastOneCharacterWithSpaceReg: (value) => {
                              if (
                                atleastOneCharacterWithSpace.test(value) ||
                                value === ""
                              ) {
                                return true;
                              } else {
                                return "Atleast one alphabet required";
                              }
                            },
                          },
                        }),
                      }}
                      updateDataFunction={updateShiftCategoryName}
                      placeHolder="Enter shift category name"
                      errorMessage={
                        errors?.shift_category_name?.message
                          ? errors?.shift_category_name?.message
                          : ""
                      }
                      textBoxError={errors.shift_category_name ? true : false}
                    />
                  </Mui.Grid>
                </Mui.Grid>

                <Mui.Grid container fullWidth sx={{ padding: 1 }}>
                  <Mui.Grid item xs={4}>
                    <Mui.Typography
                      sx={{
                        fontSize: 18,
                        color: themes.headLine,
                        paddingBottom: 0.5,
                        fontWeight: "bold",
                      }}
                    >
                      Shift Grade &nbsp;
                      <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                        <Info />
                      </Mui.Tooltip>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={1} sx={{ paddingTop: 1 }}>
                    :
                  </Mui.Grid>
                  <Mui.Grid xs={6}>
                    <CustomAutoComplete
                      dataList={shiftGradeData?.map((obj) => ({
                        ...obj,
                        label: obj?.shift_grade_name,
                      }))}
                      rules={{
                        ...register("shift_grade_name", {
                          required: "Shift grade is required",
                        }),
                      }}
                      selectedValue={shiftGrade}
                      updateDataFunction={updateShiftGrade}
                      placeHolder="Select shift grade"
                      errorMessage={
                        errors?.shift_grade_name?.message
                          ? errors?.shift_grade_name?.message
                          : ""
                      }
                      textBoxError={errors?.shift_grade_name ? true : false}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container fullWidth sx={{ padding: 1 }}>
                  <Mui.Grid item xs={3.5}>
                    <Mui.Typography
                      sx={{
                        fontSize: 18,
                        color: themes.headLine,
                        paddingBottom: 0.5,
                        fontWeight: "bold",
                      }}
                    >
                      Shift Type &nbsp;
                      <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                        <Info />
                      </Mui.Tooltip>
                    </Mui.Typography>{" "}
                  </Mui.Grid>
                  <Mui.Grid xs={0.5}></Mui.Grid>
                  <Mui.Grid item xs={1} sx={{ paddingTop: 1 }}>
                    :
                  </Mui.Grid>
                  <Mui.Grid item xs={6}>
                    <CustomAutoComplete
                      dataList={shiftTypes}
                      selectedValue={shiftType}
                      rules={{
                        ...register("shift_type", {
                          required: "Shift type is required",
                        }),
                      }}
                      updateDataFunction={handleShiftType}
                      placeHolder="Select the shift type"
                      errorMessage={
                        errors?.shift_type?.message
                          ? errors?.shift_type?.message
                          : ""
                      }
                      textBoxError={errors?.shift_type ? true : false}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container sx={{ padding: 1 }}>
                  <Mui.Grid xs={4}>
                    <Mui.Typography
                      sx={{
                        fontSize: 18,
                        color: themes.headLine,
                        paddingBottom: 0.5,
                        fontWeight: "bold",
                      }}
                    >
                      Working Days &nbsp;
                      <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                        <Info />
                      </Mui.Tooltip>
                    </Mui.Typography>
                  </Mui.Grid>
                  <Mui.Grid item xs={1} sx={{ paddingTop: 1 }}>
                    :
                  </Mui.Grid>
                  <Mui.Grid xs={6} component={Mui.Paper}>
                    <Mui.FormControl component="fieldset">
                      <Mui.FormGroup aria-label="position" row>
                        <Mui.Grid Item>
                          <Mui.FormControlLabel
                            control={
                              <Mui.Checkbox
                                checked={workingDays.sunday}
                                onChange={handleCheckboxChange}
                                name="sunday"
                                color="error"
                              />
                            }
                            label="Sun"
                            labelPlacement="bottom"
                          />
                        </Mui.Grid>
                        <Mui.Grid Item>
                          <Mui.FormControlLabel
                            control={
                              <Mui.Checkbox
                                checked={workingDays.monday}
                                onChange={handleCheckboxChange}
                                name="monday"
                                color="error"
                              />
                            }
                            label="Mon"
                            labelPlacement="bottom"
                          />
                        </Mui.Grid>
                        <Mui.Grid Item>
                          <Mui.FormControlLabel
                            control={
                              <Mui.Checkbox
                                checked={workingDays.tuesday}
                                onChange={handleCheckboxChange}
                                name="tuesday"
                                color="error"
                              />
                            }
                            label="Tue"
                            labelPlacement="bottom"
                          />
                        </Mui.Grid>
                        <Mui.Grid Item>
                          <Mui.FormControlLabel
                            control={
                              <Mui.Checkbox
                                checked={workingDays.wednesday}
                                onChange={handleCheckboxChange}
                                name="wednesday"
                                color="error"
                              />
                            }
                            label="Wed"
                            labelPlacement="bottom"
                          />
                        </Mui.Grid>
                        <Mui.Grid Item>
                          <Mui.FormControlLabel
                            control={
                              <Mui.Checkbox
                                checked={workingDays.thursday}
                                onChange={handleCheckboxChange}
                                name="thursday"
                                color="error"
                              />
                            }
                            label="Thu"
                            labelPlacement="bottom"
                          />
                        </Mui.Grid>
                        <Mui.Grid Item>
                          <Mui.FormControlLabel
                            control={
                              <Mui.Checkbox
                                checked={workingDays.friday}
                                onChange={handleCheckboxChange}
                                name="friday"
                                color="error"
                              />
                            }
                            label="Fri"
                            labelPlacement="bottom"
                          />
                        </Mui.Grid>
                        <Mui.Grid Item>
                          <Mui.FormControlLabel
                            control={
                              <Mui.Checkbox
                                checked={workingDays.saturday}
                                onChange={handleCheckboxChange}
                                name="saturday"
                                color="error"
                              />
                            }
                            label="Sat"
                            labelPlacement="bottom"
                          />
                        </Mui.Grid>
                      </Mui.FormGroup>
                    </Mui.FormControl>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container sx={{ padding: 1 }}>
                  <Mui.Grid xs={4}>
                    <Mui.Typography
                      sx={{
                        fontSize: 18,
                        color: themes.headLine,
                        paddingBottom: 0.5,
                        fontWeight: "bold",
                      }}
                    >
                      Is the week off included as part of the paid salary?
                      &nbsp;
                      <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                        <Info />
                      </Mui.Tooltip>
                    </Mui.Typography>{" "}
                  </Mui.Grid>
                  <Mui.Grid xs={1} sx={{ paddingTop: 1 }}>
                    :
                  </Mui.Grid>
                  <Mui.Grid xs={6}>
                    <Switch
                      checked={weekOffPaid}
                      onChange={handleWeekofPaid}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container sx={{ padding: 1 }}>
                  <Mui.Grid xs={4}>
                    <Mui.Typography
                      sx={{
                        fontSize: 18,
                        color: themes.headLine,
                        paddingBottom: 0.5,
                        fontWeight: "bold",
                      }}
                    >
                      Is Over Time Allowed &nbsp;
                      <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                        <Info />
                      </Mui.Tooltip>
                    </Mui.Typography>{" "}
                  </Mui.Grid>
                  <Mui.Grid xs={1} sx={{ paddingTop: 1 }}>
                    :
                  </Mui.Grid>
                  <Mui.Grid xs={6}>
                    <Switch
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Mui.Grid>
                </Mui.Grid>

                {checked && (
                  <div
                    style={{
                      border: `1px solid ${themes.sidebarDivider}`,
                      padding: 2,
                    }}
                  >
                    <Mui.Grid container sx={{ padding: 1 }}>
                      <Mui.Grid container fullWidth>
                        <Mui.Grid item xs={4} sx={{ paddingTop: 1.5 }}>
                          <Mui.Typography
                            sx={{
                              fontSize: 18,
                              color: themes.headLine,
                              paddingBottom: 0.5,
                              fontWeight: "bold",
                            }}
                          >
                            OverTime Payment BasedOn &nbsp;
                            <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                              <Info />
                            </Mui.Tooltip>
                          </Mui.Typography>
                        </Mui.Grid>
                        <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
                          :
                        </Mui.Grid>
                        <Mui.Grid
                          container
                          xs={shiftType === "Random Shift" ? 1 : 2}
                          component={Mui.Paper}
                          sx={{
                            paddingTop: 1,
                            paddingLeft: 2,
                            borderRadius: 3,
                          }}
                        >
                          <RadioGroup
                            row
                            onChange={selectTypeChanges}
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={selectType}
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              label="Hours"
                              value="hours"
                              control={<Radio />}
                            />
                            {shiftType === "Random Shift" ? null : (
                              <FormControlLabel
                                label="Shift"
                                value="shift"
                                control={<Radio />}
                              />
                            )}
                          </RadioGroup>
                        </Mui.Grid>
                      </Mui.Grid>
                      <Mui.Grid container fullWidth sx={{ paddingTop: 1 }}>
                        <Mui.Grid item xs={4} sx={{ paddingTop: 1.5 }}>
                          <Mui.Typography
                            sx={{
                              fontSize: 18,
                              color: themes.headLine,
                              fontWeight: "bold",
                            }}
                          >
                            {selectType === "hours"
                              ? "Minimum OT Duration For Consideration"
                              : "Over time check in / out policy"}
                            &nbsp;
                            <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                              <Info />
                            </Mui.Tooltip>
                          </Mui.Typography>
                        </Mui.Grid>
                        <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
                          :
                        </Mui.Grid>

                        <Mui.Grid container xs={6}>
                          <Mui.Grid item xs={4}>
                            {selectType === "hours" ? (
                              <>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer
                                    components={[
                                      "TimeField",
                                      "TimeField",
                                      "TimeField",
                                    ]}
                                  >
                                    <TimeField
                                      sx={{
                                        height: "45px",
                                        width: "150px",
                                        "& input": {
                                          padding: "10px",
                                        },
                                      }}
                                      inputProps={{
                                        style: { textAlign: "center" },
                                      }}
                                      defaultValue={otDuration}
                                      format="HH:mm"
                                      onChange={(newValue) => {
                                        const timeWithDate = newValue
                                          ? newValue.toDate()
                                          : new Date();
                                        let minTime = moment.utc(timeWithDate);
                                        setotDurationValue(newValue);
                                        setOtDuration(minTime);
                                      }}
                                      // onBlur={() => {
                                      //   if (
                                      //     errorHandling === "Please enter time"
                                      //   ) {
                                      //     validateFields();
                                      //   }
                                      // }}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                                {(!otDurationValue ||
                                  otDurationValue !== "Invalid Date") &&
                                minimumOTValueError ? (
                                  <h5 style={{ color: themes.helperTextColor }}>
                                    OT minumum duration is required
                                  </h5>
                                ) : null}
                              </>
                            ) : (
                              // <Mui.TextField
                              //   size="small"
                              //   value={otDuration}
                              //   inputProps={{ style: { textAlign: "center" } }}
                              //   onChange={(event) => {
                              //     if (
                              //       onlyNumberRegex.test(event.target.value) &&
                              //       event.target.value.length <= 2
                              //     ) {
                              //       setOtDuration(event.target.value);
                              //     }
                              //   }}
                              // />
                              <Mui.Grid container spacing={2}>
                                <Mui.Grid item xs={6}>
                                  <Mui.Grid
                                    sx={{
                                      backgroundColor: themes.primary,
                                      textAlign: "center",
                                    }}
                                  >
                                    <Mui.Typography
                                      sx={{
                                        fontSize: 15,
                                        fontWeight: 600,
                                        font: "bold",
                                      }}
                                    >
                                      Start Early
                                    </Mui.Typography>
                                  </Mui.Grid>
                                  <Mui.Grid
                                    container
                                    sx={{ paddingBottom: 1 }}
                                  ></Mui.Grid>
                                  <Mui.TextField
                                    size="small"
                                    value={startStartEarly}
                                    inputProps={{
                                      style: { textAlign: "center" },
                                    }}
                                    onChange={(event) => {
                                      if (
                                        onlyNumberRegex.test(
                                          event.target.value
                                        ) &&
                                        event.target.value.length <= 2
                                      ) {
                                        setStartStartEarly(event.target.value);
                                      }
                                    }}
                                  />
                                </Mui.Grid>
                                <Mui.Grid item xs={6}>
                                  <Mui.Grid
                                    xs={12}
                                    sx={{
                                      backgroundColor: themes.primary,
                                      textAlign: "center",
                                    }}
                                  >
                                    <Mui.Typography
                                      sx={{
                                        fontSize: 15,
                                        fontWeight: 600,
                                        font: "bold",
                                      }}
                                    >
                                      End Delay
                                    </Mui.Typography>
                                  </Mui.Grid>
                                  <Mui.Grid
                                    container
                                    sx={{ paddingBottom: 1 }}
                                  ></Mui.Grid>
                                  <Mui.TextField
                                    size="small"
                                    value={endEndDelay}
                                    inputProps={{
                                      style: { textAlign: "center" },
                                    }}
                                    sx={{ textAlign: "center" }}
                                    onChange={(event) => {
                                      if (
                                        onlyNumberRegex.test(
                                          event.target.value
                                        ) &&
                                        event.target.value.length <= 2
                                      ) {
                                        setEndEndDelay(event.target.value);
                                      }
                                    }}
                                  />
                                </Mui.Grid>
                              </Mui.Grid>
                            )}
                          </Mui.Grid>
                          <Mui.Grid xs={1}></Mui.Grid>

                          <Mui.Grid container xs={6}>
                            <Mui.Grid item xs={6} sx={{ paddingTop: 1.3 }}>
                              <Mui.Typography
                                sx={{
                                  fontSize: 18,
                                  color: themes.headLine,
                                  fontWeight: "bold",
                                }}
                              >
                                Payment Factor &nbsp;
                              </Mui.Typography>
                            </Mui.Grid>
                            <Mui.Grid item xs={6}>
                              <Mui.TextField
                                size="small"
                                {...register("payment_factor", {
                                  required: checked
                                    ? "Payment factor is required"
                                    : false,
                                })}
                                error={errors.payment_factor ? true : false}
                                helperText={errors.payment_factor?.message}
                                value={paymentFactor}
                                inputProps={{ style: { textAlign: "center" } }}
                                onChange={(event) => {
                                  const rgxWithDot = /^([0-9]$|[0-9]\.5?)$/;
                                  if (
                                    (rgxWithDot.test(event.target.value) &&
                                      event.target.value.length <= 5) ||
                                    event.target.value === ""
                                  ) {
                                    setPaymentFactor(event.target.value);
                                    setValue(
                                      "payment_factor",
                                      event.target.value
                                    );
                                  }
                                }}
                              />
                            </Mui.Grid>
                          </Mui.Grid>
                        </Mui.Grid>
                      </Mui.Grid>
                    </Mui.Grid>
                  </div>
                )}
                {shiftType !== "Random Shift" ? (
                  <Mui.Grid container sx={{ padding: 1 }}>
                    <Mui.Grid xs={4}>
                      <Mui.Typography
                        sx={{
                          fontSize: 18,
                          color: themes.headLine,
                          paddingBottom: 0.5,
                          fontWeight: "bold",
                        }}
                      >
                        Apply Shift Penalty Policy &nbsp;
                        <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                          <Info />
                        </Mui.Tooltip>
                      </Mui.Typography>{" "}
                    </Mui.Grid>
                    <Mui.Grid xs={1} sx={{ paddingTop: 1 }}>
                      :
                    </Mui.Grid>
                    <Mui.Grid xs={6}>
                      <Switch
                        checked={penaltyPolicy}
                        onChange={handlePenaltyChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                ) : null}
                {penaltyPolicy && (
                  <div
                    style={{
                      border: `1px solid ${themes.sidebarDivider}`,
                      paddingTop: 10,
                      padding: 2,
                    }}
                  >
                    <Mui.Grid container elevation={2}>
                      <Mui.Grid container fullWidth>
                        <Mui.Grid item xs={4}>
                          <Mui.Typography
                            sx={{
                              fontSize: 18,
                              color: themes.headLine,
                              fontWeight: "bold",
                              paddingLeft: 1,
                            }}
                          >
                            Penalty Deduction Based On &nbsp;
                            <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                              <Info />
                            </Mui.Tooltip>
                          </Mui.Typography>
                        </Mui.Grid>
                        <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
                          :
                        </Mui.Grid>
                        <Mui.Grid
                          container
                          xs={2}
                          component={Mui.Paper}
                          sx={{
                            paddingTop: 1,
                            paddingLeft: 2,
                            borderRadius: 3,
                          }}
                        >
                          <RadioGroup
                            row
                            onChange={selectPenaltyChanges}
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={penalty}
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              label="Hours"
                              value="hours"
                              control={<Radio />}
                            />
                            <FormControlLabel
                              label="Shift"
                              value="shift"
                              control={<Radio />}
                            />
                          </RadioGroup>
                        </Mui.Grid>
                      </Mui.Grid>
                      {penalty === "hours" ? (
                        <Mui.Grid
                          container
                          fullWidth
                          sx={{ padding: 1, borderRadius: 3 }}
                        >
                          <Mui.Grid item xs={4} sx={{ paddingTop: 1.5 }}>
                            <Mui.Typography
                              sx={{
                                fontSize: 18,
                                color: themes.headLine,
                                fontWeight: "bold",
                              }}
                            >
                              Payment Factor &nbsp;
                              <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                                <Info />
                              </Mui.Tooltip>
                            </Mui.Typography>
                          </Mui.Grid>
                          <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
                            :
                          </Mui.Grid>
                          <Mui.Grid container xs={6}>
                            <Mui.Grid item xs={6}>
                              <Mui.TextField
                                size="small"
                                {...register("penalty_payment_factor", {
                                  required: penaltyPolicy
                                    ? "Payment factor is required"
                                    : false,
                                })}
                                error={
                                  errors.penalty_payment_factor ? true : false
                                }
                                helperText={
                                  errors.penalty_payment_factor?.message
                                }
                                value={penaltyPaymentFactor}
                                inputProps={{ style: { textAlign: "center" } }}
                                onChange={(event) => {
                                  const rgxWithDot = /^([0-9]$|[0-9]\.5?)$/;
                                  if (
                                    (rgxWithDot.test(event.target.value) &&
                                      event.target.value.length <= 5) ||
                                    event.target.value === ""
                                  ) {
                                    setPenaltyPaymentFactor(event.target.value);
                                    setValue(
                                      "penalty_payment_factor",
                                      event.target.value
                                    );
                                  }
                                }}
                              />
                            </Mui.Grid>
                          </Mui.Grid>
                        </Mui.Grid>
                      ) : (
                        <Mui.Grid
                          container
                          fullWidth
                          sx={{ padding: 1, borderRadius: 3 }}
                        >
                          <Mui.Grid
                            item
                            xs={5}
                            sx={{ paddingTop: 1.5 }}
                          ></Mui.Grid>
                          <Mui.Grid container xs={7}>
                            <Mui.Grid
                              xs={12}
                              sx={{
                                border: `1px solid ${themes.sidebarDivider}`,
                                padding: 1.5,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  fontSize: 18,
                                  color: themes.headLine,
                                  fontWeight: "bold",
                                }}
                              >
                                Shift Early/Late Threshold Count breach of{" "}
                                <Mui.TextField
                                  size="small"
                                  value={thersold}
                                  inputProps={{
                                    style: {
                                      textAlign: "center",
                                      width: "15px",
                                      height: "12px",
                                    },
                                  }}
                                  onChange={(event) => {
                                    if (
                                      onlyNumberRegex.test(
                                        event.target.value
                                      ) &&
                                      event.target.value.length <= 1
                                    ) {
                                      setThersold(event.target.value);
                                    }
                                  }}
                                />{" "}
                                deduct{" "}
                                <Mui.TextField
                                  size="small"
                                  value={therSoldPoint}
                                  inputProps={{
                                    style: {
                                      textAlign: "center",
                                      width: "45px",
                                      height: "12px",
                                    },
                                  }}
                                  onChange={(event) => {
                                    if (
                                      onlyNumberRegex.test(
                                        event.target.value
                                      ) &&
                                      event.target.value.length <= 3
                                    ) {
                                      setTherSoldPoint(event.target.value);
                                    }
                                  }}
                                />{" "}
                                shift
                              </Mui.Typography>
                            </Mui.Grid>
                            <Mui.Grid
                              xs={12}
                              sx={{
                                border: `1px solid ${themes.sidebarDivider}`,
                                padding: 1.5,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  fontSize: 18,
                                  color: themes.headLine,
                                  fontWeight: "bold",
                                }}
                              >
                                Each Additional Early/Late Count of{" "}
                                <Mui.TextField
                                  size="small"
                                  value={thersold1}
                                  inputProps={{
                                    style: {
                                      textAlign: "center",
                                      width: "15px",
                                      height: "12px",
                                    },
                                  }}
                                  onChange={(event) => {
                                    if (
                                      onlyNumberRegex.test(
                                        event.target.value
                                      ) &&
                                      event.target.value.length <= 1
                                    ) {
                                      setThersold1(event.target.value);
                                    }
                                  }}
                                />{" "}
                                Deduct{" "}
                                <Mui.TextField
                                  size="small"
                                  value={therSoldPoint1}
                                  inputProps={{
                                    style: {
                                      textAlign: "center",
                                      width: "45px",
                                      height: "12px",
                                    },
                                  }}
                                  onChange={(event) => {
                                    if (
                                      onlyNumberRegex.test(
                                        event.target.value
                                      ) &&
                                      event.target.value.length <= 3
                                    ) {
                                      setTherSoldPoint1(event.target.value);
                                    }
                                  }}
                                />{" "}
                                shift
                              </Mui.Typography>
                            </Mui.Grid>
                          </Mui.Grid>
                        </Mui.Grid>
                      )}
                    </Mui.Grid>
                  </div>
                )}
              </Mui.Card>

              <Mui.Grid>&nbsp;</Mui.Grid>
              <Mui.Grid container sx={{ padding: 2 }}>
                {renderComponent()}
              </Mui.Grid>
              {allShifts.length !== 0 &&
              (shiftType === "Rotational Shift" ||
                shiftType === "Non Rotational Shift") ? (
                <Mui.TableContainer
                  sx={{
                    mt: 2,
                    paddingLeft: 1,
                  }}
                >
                  <Mui.Table>
                    <Mui.TableHead
                      sx={{
                        backgroundColor: themes.primary,
                      }}
                    >
                      <Mui.TableRow
                        sx={{
                          "& .MuiTableCell-root": {
                            height: "10px",
                            padding: "3px 15px",
                          },
                        }}
                      >
                        {AddShiftCategoryHeader.map((head) => (
                          <Mui.TableCell
                            sx={{
                              color: themes.blackColor,
                              fontWeight: "bold",
                            }}
                          >
                            <Mui.Typography sx={{ paddingLeft: 2 }}>
                              {head.name}
                            </Mui.Typography>
                          </Mui.TableCell>
                        ))}
                      </Mui.TableRow>
                    </Mui.TableHead>
                    <Mui.TableBody>
                      {allShifts?.map((account, index) => (
                        <React.Fragment>
                          <Mui.TableRow
                            sx={{
                              "& .MuiTableCell-root": {
                                height: "10px",
                                padding: "6px 35px",
                              },
                            }}
                          >
                            <Mui.TableCell>
                              {account?.name ? account?.name : "N/A"}
                            </Mui.TableCell>
                            <Mui.TableCell>
                              {account?.min_start_time
                                ? account?.min_start_time
                                : "N/A"}
                            </Mui.TableCell>
                            <Mui.TableCell>
                              <div style={{ width: 140 }}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["TimePicker"]}>
                                    <div sx={{ width: 2 }}>
                                      <TimePicker
                                        ampm={false}
                                        viewRenderers={{
                                          hours: renderTimeViewClock,
                                          minutes: renderTimeViewClock,
                                          seconds: renderTimeViewClock,
                                        }}
                                        value={
                                          account.startTimeDateFormat || null
                                        }
                                        slotProps={{
                                          textField: {
                                            size: "small",
                                            width: 10,
                                          },
                                        }}
                                        onChange={(newValue) => {
                                          updateStartTimeDetails(
                                            newValue,
                                            account
                                          );
                                        }}
                                      />
                                    </div>
                                  </DemoContainer>
                                </LocalizationProvider>
                                {shiftTimeArrayError && !account?.start_time ? (
                                  <h5 style={{ color: themes.helperTextColor }}>
                                    Shift start time is required
                                  </h5>
                                ) : null}
                              </div>
                            </Mui.TableCell>
                            <Mui.TableCell>
                              {account?.max_start_time
                                ? account?.max_start_time
                                : "N/A"}
                            </Mui.TableCell>
                            <Mui.TableCell>
                              <div style={{ width: 70 }}>
                                <Mui.TextField
                                  size="small"
                                  value={account?.break_time}
                                  sx={{ textAlign: "center" }}
                                  inputProps={{
                                    style: { textAlign: "center" },
                                  }}
                                  onChange={(event) => {
                                    if (
                                      onlyNumberRegex.test(
                                        event.target.value
                                      ) &&
                                      event.target.value.length <= 2
                                    ) {
                                      updateBreak(event.target.value, account);
                                    }
                                  }}
                                />
                              </div>
                            </Mui.TableCell>
                            <Mui.TableCell>
                              {account?.min_end_time
                                ? account?.min_end_time
                                : "N/A"}
                            </Mui.TableCell>
                            <Mui.TableCell>
                              {account?.end_time ? account?.end_time : "N/A"}
                            </Mui.TableCell>
                            <Mui.TableCell>
                              {account?.max_end_time
                                ? account?.max_end_time
                                : "N/A"}
                            </Mui.TableCell>
                            <Mui.TableCell sx={{ justifyContent: "left" }}>
                              <Mui.TextField
                                type="color"
                                value={account?.color_code}
                                onChange={(event) =>
                                  updateColorCode(event.target.value, account)
                                }
                                style={{ width: 70 }}
                                sx={{
                                  "& fieldset": {
                                    border: "none",
                                  },
                                }}
                                size="small"
                                fullWidth
                              />
                            </Mui.TableCell>
                          </Mui.TableRow>
                          {checked && selectType === "shift" ? (
                            <Mui.TableRow
                              sx={{
                                "& .MuiTableCell-root": {
                                  height: "10px",
                                  padding: "6px 35px",
                                },
                              }}
                            >
                              <Mui.TableCell>Overtime</Mui.TableCell>
                              <Mui.TableCell></Mui.TableCell>
                              <Mui.TableCell>
                                {account?.overtime?.ot_start
                                  ? account?.overtime?.ot_start
                                  : "N/A"}
                              </Mui.TableCell>
                              <Mui.TableCell>
                                {account?.overtime?.ot_max_start
                                  ? account?.overtime?.ot_max_start
                                  : "N/A"}
                              </Mui.TableCell>
                              <Mui.TableCell>
                                {/* {account.break_time} */}
                              </Mui.TableCell>

                              <Mui.TableCell>
                                {account?.overtime?.ot_min_end
                                  ? account?.overtime?.ot_min_end
                                  : "N/A"}
                              </Mui.TableCell>
                              <Mui.TableCell>
                                {account?.overtime?.ot_end
                                  ? account?.overtime?.ot_end
                                  : "N/A"}
                              </Mui.TableCell>
                              <Mui.TableCell></Mui.TableCell>
                              <Mui.TableCell></Mui.TableCell>
                            </Mui.TableRow>
                          ) : null}
                        </React.Fragment>
                      ))}
                    </Mui.TableBody>
                  </Mui.Table>
                </Mui.TableContainer>
              ) : null}
              <Mui.Grid
                container
                sx={{
                  paddingTop: 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 10,
                }}
              >
                <CustomButton
                  actionName="Cancel"
                  typeName="button"
                  actionFuntion={() => {
                    setshiftCategory(false);
                    setShowTopBar(true);
                  }}
                />
                &nbsp;&nbsp;
                <CustomButton
                  actionName="Create"
                  typeName="submit"
                  disableAction={buttonDisabled}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </>
  );
};
