import React, { useEffect, createContext, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showToast } from "services/utils/Status";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { setEditProject } from "services/Redux/leaveManagement";
import sessionHandling from "services/utils/notificationUtils";

export const ProjectDashboardContext = createContext({});

export const ProjectDashboardContextProvider = ({ children }) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const editProjectScreenData = useSelector(
    (state) => state.projectReducer.editProjectScreenData
  );
  const [reloadProDash, setReloadProDash] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [projectData, setProjectData] = useState([]);

  const fetchTaskData = useCallback(
    async (pro_data) => {
      try {
        const url = `${domain}project-dashboard/?project_id=${pro_data?.id}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          if (res?.length > 0) {
            dispatch(setEditProject(res[0]));
          }
          setProjectData(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setReloadProDash(false);
        setDataLoading(false);
      }
    },
    [domain, token, dispatch]
  );

  useEffect(() => {
    const location_isValid = localStorage.getItem("proDashboard");
    const projectDet = JSON.parse(localStorage.getItem("projectData"))
      ? JSON.parse(localStorage.getItem("projectData"))
      : null;
    if (location_isValid === "false" || reloadProDash) {
      fetchTaskData(projectDet);
    } else {
      fetchTaskData(editProjectScreenData);
      localStorage.setItem("proDashboard", false);
    }
  }, [history, editProjectScreenData, fetchTaskData, reloadProDash]);

  return (
    <ProjectDashboardContext.Provider
      value={{
        token,
        domain,
        projectData,
        dataLoading,
        setReloadProDash,
        reloadProDash,
      }}
    >
      {children}
    </ProjectDashboardContext.Provider>
  );
};
