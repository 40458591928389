import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";

export const SettingsContext = React.createContext({});

export const SettingsContextProvider = ({ children }) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [getCompanyDetails, setGetCompanyDetails] = useState(true);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const getCompany = async () => {
      setLoader(true);
      try {
        const response = await fetch(`${domain}company/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setCompanyDetails(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setLoader(false);
      }
    };
    if (getCompanyDetails) {
      getCompany();
      setGetCompanyDetails(false);
    }
  }, [domain, getCompanyDetails, token]);
  return (
    <React.Fragment>
      <SettingsContext.Provider
        value={{
          loader,
          companyDetails,
          domain,
          token,
          setGetCompanyDetails,
        }}
      >
        {children}
      </SettingsContext.Provider>
    </React.Fragment>
  );
};
