import React from "react";
import { Grid, Paper } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import { themes, tableCellFontSize } from "services/constants";
export const SettingsMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <Grid conatiner spacing={2} sx={{ marginTop: 5 }}>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              padding: 2,
              fontSize: tableCellFontSize,
              fontWeight: "bold",
              cursor: "pointer",
              color:
                location.pathname === "/admin/master/designation"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                location.pathname === "/admin/master/designation"
                  ? themes.primary
                  : null,
            }}
            onClick={() => {
              dispatch(setGlobalSearchValue(""));
              localStorage.setItem("currentSubMenu", "Master");
              localStorage.setItem("currentScreen", "Administration");
              history.push("/admin/master/designation");
            }}
          >
            Designation
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              padding: 2,
              fontSize: tableCellFontSize,
              fontWeight: "bold",
              cursor: "pointer",
              color:
                location.pathname === "/admin/master/employment/type"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                location.pathname === "/admin/master/employment/type"
                  ? themes.primary
                  : null,
            }}
            onClick={() => {
              dispatch(setGlobalSearchValue(""));
              localStorage.setItem("currentSubMenu", "Master");
              localStorage.setItem("currentScreen", "Administration");
              history.push("/admin/master/employment/type");
            }}
          >
            Employment Type
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              padding: 2,
              fontSize: tableCellFontSize,
              fontWeight: "bold",
              cursor: "pointer",
              color:
                location.pathname === "/admin/master/unit"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                location.pathname === "/admin/master/unit"
                  ? themes.primary
                  : null,
            }}
            onClick={() => {
              dispatch(setGlobalSearchValue(""));
              localStorage.setItem("currentSubMenu", "Employement Type");
              localStorage.setItem("currentScreen", "Administration");
              history.push("/admin/master/unit");
            }}
          >
            Unit
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
