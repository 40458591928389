import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { PayRunTable } from "./PayRunTable";
import { ViewPayRun } from "./ViewPayRun";
import { Loader } from "components/Loader";
const keys = [
  "pay_schedule_name",
  "start_date",
  "end_date",
  "run_date",
  "status",
];
export const PayHistory = ({
  companyName,
  companyId,
  viewPayrunScreen,
  setViewPayRunScreen,
}) => {
  const { domain, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [viewPayrunDetails, setViewPayRunDetails] = useState([]);
  const [listViewPage, setListViewPage] = useState(0);
  const [payRunHistoryDetailsState, setPayRunHistoryDetailsState] = useState(
    []
  );
  const [updateData, setUpdateData] = useState(true);
  const { result: payrunDetails, loading } = useFetch(
    companyId !== 0 ? `${domain}payrun/history/?company=${companyId}` : null
  );
  useEffect(() => {
    if (updateData && payrunDetails?.history?.length !== 0 && payrunDetails) {
      setPayRunHistoryDetailsState(payrunDetails?.history);
      setUpdateData(false);
    }
  }, [payrunDetails, updateData]);

  useEffect(() => {
    if (payrunDetails?.length !== 0 || payrunDetails?.length === 0) {
      const filteredResults = payrunDetails?.history?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setPayRunHistoryDetailsState(filteredResults);
      setListViewPage(0);
    }
  }, [globalSearchValue, payrunDetails]);

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      {viewPayrunScreen ? (
        <ViewPayRun
          setViewPayRunScreen={setViewPayRunScreen}
          companyName={companyName}
          companyId={companyId}
          viewPayrunDetails={viewPayrunDetails}
          setViewPayRunDetails={setViewPayRunDetails}
        />
      ) : (
        <PayRunTable
          payrunDetails={payRunHistoryDetailsState}
          listViewPage={listViewPage}
          setListViewPage={setListViewPage}
          setViewPayRunScreen={setViewPayRunScreen}
          viewPayrunScreen={viewPayrunScreen}
          setViewPayRunDetails={setViewPayRunDetails}
        />
      )}
    </React.Fragment>
  );
};
