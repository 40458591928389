import React, { useContext, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DroppableRow from "./DroppableRow";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { TaskContext } from "./TaskContext";
import { projectPlaceholder } from "services/constants/PlaceHolder";
import { Loader } from "components/Loader";
import sessionHandling from "services/utils/notificationUtils";

export const ListView = ({ setActiveTap }) => {
  const {
    projectTasks: tasks,
    currentPlayTaskId,
    setIsActive,
    domain,
    token,
    allUsers,
    setFetchTask,
    setCurrentPlayTaskId,
    editProject,
    isTaskLoading,
  } = useContext(TaskContext);
  const [taskDetails, setTask] = useState(null);
  const [newStatusDetails, setNewStatus] = useState(null);
  const [open, setOpen] = useState(false);
  const statuses = useSelector((state) => state.leaveReducer.projectStatus);
  const [assignee, setAssingee] = useState(null);
  const [assigneeId, setAssigneeId] = useState(null);
  const [priProjMembers, setPriProjMembers] = useState([]);

  const moveCard = async (task, newStatus) => {
    if (!task.is_running) {
      if (task.work_flow_status.name !== newStatus) {
        if (newStatus === "Completed" && task?.current_assignee?.username) {
          updateTask(task, newStatus);
        } else {
          setTask(task);
          setAssingee(task?.current_assignee?.username);
          setAssigneeId(task?.current_assignee?.id);
          setNewStatus(newStatus);
          setOpen(true);
        }
      }
    } else {
      showToast(
        "error",
        "The task is currently running, so it cannot be moved.!"
      );
    }
  };

  const filterCommonValues = (arr1, arr2) => {
    const valuesInArr2 = arr2.map((item) => item["username"]);
    return arr1.filter((item) => valuesInArr2.includes(item["username"]));
  };

  useEffect(() => {
    if (editProject?.projectType === "Private") {
      const filteredMembers = filterCommonValues(
        allUsers,
        editProject?.members
      );
      setPriProjMembers(filteredMembers);
    }
  }, [editProject?.members, allUsers, editProject?.projectType]);

  const updateTask = async (task, newStatus) => {
    const task_assigneeId =
      task?.current_assignee?.id === assigneeId &&
      (newStatus === "InProgress" || newStatus === "ToDo")
        ? null
        : assigneeId;
    if (currentPlayTaskId === task.id) {
      setIsActive(false);
      setCurrentPlayTaskId(0);
    }
    try {
      const response = await fetch(`${domain}edit_task/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          task_id: task.id,
          status: newStatus,
          new_assignee: task_assigneeId,
          workflow_status_id: task.work_flow_status.workflow,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setFetchTask(true);
        setAssingee(null);
        setAssigneeId(null);
        setOpen(false);
        showToast("success", res.message);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setAssingee(null);
    setAssigneeId(null);
  };

  return (
    <React.Fragment>
      {isTaskLoading ? (
        <Loader info="Loading..." />
      ) : (
        <>
          <DndProvider backend={HTML5Backend}>
            {statuses?.map((status) => (
              <DroppableRow
                key={status}
                status={status}
                setActiveTap={setActiveTap}
                tasks={
                  tasks
                    ? tasks.filter(
                        (task) => task.work_flow_status.name === status
                      )
                    : []
                }
                moveCard={moveCard}
              />
            ))}
          </DndProvider>
        </>
      )}
      <GlobalDialogBox
        open={open}
        handleCloseDialog={handleClose}
        title="Select Assignee"
        minWidth={550}
      >
        <Mui.Grid container sx={{ paddingBottom: 1 }}>
          <Mui.Grid xs={1}></Mui.Grid>
          <Mui.Grid xs={10}>
            <Mui.Typography sx={{ fontSize: 20, textAlign: "center" }}>
              Do you wish to change the assignee? Select the user below
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={1}></Mui.Grid>
        </Mui.Grid>
        <Mui.Autocomplete
          disablePortal
          id="combo-box-demo"
          fullWidth
          value={assignee}
          options={
            editProject?.projectType === "Private" ? priProjMembers : allUsers
          }
          filterOptions={(options, state) => {
            return options.filter((option) =>
              option.username
                ?.toLowerCase()
                .includes(state.inputValue.toLowerCase())
            );
          }}
          ListboxProps={{
            style: {
              maxHeight: "120px",
            },
          }}
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <Mui.Avatar
                  sx={{ width: 25, height: 25 }}
                  alt={option.username.charAt(0).toUpperCase()}
                  src={option.image_url}
                />
                &nbsp;{option.username}
              </li>
            );
          }}
          onChange={(event, value) => {
            if (value) {
              setAssingee(value.username);
              setAssigneeId(value.id);
            } else {
              setAssingee(null);
              setAssigneeId(null);
            }
          }}
          sx={{ minWidth: "100%" }}
          renderInput={(params) => (
            <Mui.TextField
              {...params}
              value={assignee}
              size="small"
              sx={{ background: themes.whiteColor }}
              name="username"
              placeholder={projectPlaceholder.selectUser}
              autoFocus
            />
          )}
        />
        <Mui.Grid container sx={{ paddingTop: 6, paddingBottom: 3 }}>
          <Mui.Grid xs={6}></Mui.Grid>
          <Mui.Grid xs={2.5}>
            <Mui.Button
              sx={{
                color: themes.whiteColor,
                float: "right",
                width: 150,
                border: "none",
                textTransform: "capitalize",
                backgroundColor: themes.primaryIndicator,
                "&:hover": {
                  border: "none",
                  backgroundColor: themes.primaryIndicator,
                },
              }}
              size="large"
              variant="varient"
              onClick={() => handleClose()}
            >
              Cancel
            </Mui.Button>
          </Mui.Grid>
          <Mui.Grid xs={1}></Mui.Grid>
          <Mui.Grid xs={2.5}>
            <Mui.Button
              sx={{
                color: themes.whiteColor,
                float: "right",
                width: 150,
                border: "none",
                textTransform: "capitalize",
                backgroundColor: themes.primaryIndicator,
                "&:hover": {
                  border: "none",
                  backgroundColor: themes.primaryIndicator,
                },
              }}
              size="large"
              variant="varient"
              onClick={() => updateTask(taskDetails, newStatusDetails)}
              disabled={assignee ? false : true}
            >
              Update
            </Mui.Button>
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
