import React, { useState, useEffect } from "react";
import { useFetch } from "services/hooks/useFetch";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { Apply } from "./Apply";
import { Approve } from "./Approve";
import { History } from "./History";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { defaultAllSelection } from "services/constants";
import { useSelector, useDispatch } from "react-redux";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";
export const Expense = ({ setReloadData }) => {
  const [userName, setUserName] = useState("All");
  const [departmentChange, setDepartmentChange] = useState(true);
  const [userChange, setUserChange] = useState(true);
  const [userId, setUserId] = useState(defaultAllSelection);
  const [departmentData, setDepartmentData] = useState([]);
  const [expenseScreen, setexpenseScreen] = useState("Approve");
  const [selectComponent, setSelectComponent] = useState("Approve");
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );

  const dispatch = useDispatch();
  const { departmentId, departmentName } = useSelector(
    (state) => state.userReducer
  );
  const handleChange = (event, newAlignment) => {
    setexpenseScreen(newAlignment);
    dispatch(setGlobalSearchValue(""));
  };
  const { result: membersData, setLoad: reCallMemberApi } = useFetch(
    `${domain}get-department-based-user/?department_id=${departmentId}&user_id=${userDetails.id}`
  );

  const renderComponent = () => {
    switch (selectComponent) {
      case "Approve":
        return (
          <Approve
            setReloadData={setReloadData}
            departmentId={departmentId}
            departmentChange={departmentChange}
            setDepartmentChange={setDepartmentChange}
            expenseScreen={expenseScreen}
          />
        );
      case "History":
        return (
          <History
            expenseScreen={expenseScreen}
            departmentId={departmentId}
            departmentChange={departmentChange}
            setDepartmentChange={setDepartmentChange}
            userChange={userChange}
            setUserChange={setUserChange}
            userId={userId}
          />
        );
      case "Apply":
        return <Apply />;
      default:
        break;
    }
  };
  useEffect(() => {
    dispatch(setDepartmentName("All"));
    dispatch(setDepartmentId(99999));
  }, [dispatch]);

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const response = await fetch(
          `${domain}get-department/?user_id=${userDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const allDepartment = {
            id: defaultAllSelection,
            department_name: "All",
          };
          setDepartmentData([allDepartment, ...res]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchDepartmentData();
  }, [domain, token, userDetails.id]);

  const updateDepartmentName = (event, data) => {
    if (data) {
      dispatch(setDepartmentName(data.label));
      dispatch(setDepartmentId(data.id));
      localStorage.setItem("approveLeaveDepartmentId", data.id);
      localStorage.setItem("approveLeaveDepartmentName", data.label);
      setUserName("All");
      setUserId(defaultAllSelection);
      setDepartmentChange(true);
      setReloadData(true);
    }
  };

  const updateUserName = (event, data) => {
    if (data) {
      setUserName(data.label);
      setUserId(data.user_id);
      setUserChange(true);
      reCallMemberApi(true);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 5, paddingLeft: 3 }}>
        <Mui.Grid item xs={5}>
          <Mui.Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: 500,
              height: 55,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Mui.ToggleButtonGroup
              value={expenseScreen}
              exclusive
              onChange={handleChange}
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                position: "absolute",
                borderRadius: "30px",
                backgroundColor: themes.lightBrown,
              }}
            >
              <Mui.ToggleButton
                value="Approve"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  borderRadius: "30px",
                  fontWeight: 600,
                  border: "none",
                  color:
                    expenseScreen === "Approve"
                      ? themes.whiteColor
                      : themes.blackColor,
                  backgroundColor:
                    expenseScreen === "Approve"
                      ? themes.blackColor
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      expenseScreen === "Approve"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      expenseScreen === "Approve"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                }}
                onClick={() => setSelectComponent("Approve")}
              >
                Approve
              </Mui.ToggleButton>
              <Mui.ToggleButton
                value="History"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  fontWeight: 600,
                  border: "none",
                  color:
                    expenseScreen === "History"
                      ? themes.whiteColor
                      : themes.blackColor,
                  backgroundColor:
                    expenseScreen === "History"
                      ? themes.blackColor
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      expenseScreen === "History"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      expenseScreen === "History"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                }}
                onClick={() => setSelectComponent("History")}
              >
                History
              </Mui.ToggleButton>
              <Mui.ToggleButton
                value="Apply"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  borderRadius: "30px",
                  fontWeight: 600,
                  border: "none",
                  color:
                    expenseScreen === "Apply"
                      ? themes.whiteColor
                      : themes.blackColor,
                  backgroundColor:
                    expenseScreen === "Apply"
                      ? themes.blackColor
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      expenseScreen === "Apply"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      expenseScreen === "Apply"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                  "&:focus": {
                    backgroundColor:
                      expenseScreen === "Apply"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      expenseScreen === "Apply"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                }}
                onClick={() => setSelectComponent("Apply")}
              >
                Apply
              </Mui.ToggleButton>
            </Mui.ToggleButtonGroup>
          </Mui.Box>
        </Mui.Grid>

        {selectComponent === "Approve" ? (
          <Mui.Grid item xs={4.6}></Mui.Grid>
        ) : (
          <Mui.Grid item xs={2.3}></Mui.Grid>
        )}
        <Mui.Grid item xs={2.4} sx={{ padding: 1 }}>
          {selectComponent === "Approve" || selectComponent === "History" ? (
            <CustomAutoComplete
              dataList={departmentData?.map((obj) => ({
                ...obj,
                label: obj.department_name,
              }))}
              selectedValue={departmentName}
              updateDataFunction={updateDepartmentName}
              placeHolder="Select department name"
              label="Select Department Name"
            />
          ) : null}
        </Mui.Grid>
        <Mui.Grid
          item
          xs={selectComponent === "History" ? 2.3 : 2.4}
          sx={{ padding: 1 }}
        >
          {selectComponent === "History" ? (
            <CustomAutoComplete
              dataList={membersData?.map((obj) => ({
                ...obj,
                label: obj?.user_name,
              }))}
              selectedValue={userName}
              updateDataFunction={updateUserName}
              placeHolder="Select employee name"
              label="Select User Name"
            />
          ) : null}
        </Mui.Grid>
      </Mui.Grid>
      {renderComponent()}
    </React.Fragment>
  );
};
