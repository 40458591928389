import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Alert,
  IconButton,
  Grid,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TablePagination from "@mui/material/TablePagination";
import { showToast } from "services/utils/Status";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "components/Loader";
import { useSelector } from "react-redux";
import "./style.css";
import { themes } from "services/constants";
import sessionHandling from "services/utils/notificationUtils";

const Approved = ({
  setReloadApprovedData,
  reloadApprovedData,
  userId,
  startDate,
  endDate,
}) => {
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [loading, setLoading] = useState(false);
  const { departmentId } = useSelector((state) => state.userReducer);
  const [expandedRow, setExpandedRow] = React.useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const handleRowClick = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };

  useEffect(() => {
    const fetchApprovedData = async () => {
      setLoading(true);
      try {
        const permissionRequestApi = `${domain}permisson_request/?user_id=${userDetails.id}&approved_reject_status=Approved&department_id=${departmentId}&selected_user_id=${userId}&start_date=${startDate}&end_date=${endDate}`;
        const response2 = await fetch(permissionRequestApi, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res2 = await response2.json();
        if (response2.ok) {
          const totalData = res2;
          totalData?.sort(
            (a, b) =>
              new Date(b?.from_date.split("-").reverse().join("-")) -
              new Date(a?.from_date.split("-").reverse().join("-"))
          );
          setMasterDataForSearch([...totalData]);
          setFilteredData(totalData);
        } else {
          if (!response2.ok) {
            if (response2.status === 409) {
              sessionHandling();
            } else {
              throw new Error(res2.error);
            }
          }
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setLoading(false);
      }
    };
    if (reloadApprovedData) {
      fetchApprovedData();
      setReloadApprovedData(false);
    }
  }, [
    token,
    userDetails.id,
    domain,
    userDetails.role,
    reloadApprovedData,
    setReloadApprovedData,
    departmentId,
    userId,
    startDate,
    endDate,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const FilterDockets = () => {
      const cols =
        masterDataForSearch[0] && Object.keys(masterDataForSearch[0]);
      return masterDataForSearch.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toString().toLowerCase()) > -1
            : null
        )
      );
    };
    setFilteredData(FilterDockets());
    setPage(0);
  }, [globalSearchValue, masterDataForSearch]);

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Grid container sx={{ paddingRight: 1 }}>
        <TableContainer
          sx={{
            marginTop: 3,
            marginLeft: "auto",
            marginRight: "auto",
            overflow: "auto",
          }}
          className="w-96 border rounded"
        >
          <Table className="border">
            <TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    height: "15px",
                    padding: "6px 35px",
                  },
                }}
              >
                <TableCell></TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Department</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">From</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">To</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Duration</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Approver</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Status</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            {filteredData.length > 0 ? (
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <>
                      <TableRow>
                        <TableCell>
                          {expandedRow === item.id ? (
                            <IconButton
                              onClick={() => {
                                setExpandedRow(null);
                              }}
                            >
                              <KeyboardArrowDownIcon
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => handleRowClick(item.id)}>
                              <KeyboardArrowRightIcon
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell key={item.id}>{item.username}</TableCell>
                        <TableCell>{item?.department_name}</TableCell>
                        <TableCell> {item.from_date}</TableCell>
                        <TableCell>{item.to_date}</TableCell>
                        <TableCell>
                          {item.title === "Leave"
                            ? item.business_days +
                              `${item.business_days === 1 ? "day" : "days"}`
                            : item.total_time + " hr"}
                        </TableCell>
                        <TableCell>{item.approver_name}</TableCell>
                        <TableCell>{item.status}</TableCell>
                      </TableRow>
                      {expandedRow === item.id && (
                        <TableRow>
                          <TableCell colSpan={9}>
                            <TableContainer
                              sx={{
                                marginTop: 0.5,
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "100%px",
                              }}
                              className="w-96 border rounded"
                            >
                              <Table className="border">
                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  sx={{ marginLeft: 1, marginTop: 0.5 }}
                                >
                                  <strong>Description :</strong>{" "}
                                  <Typography
                                    fontSize={15}
                                    color="textSecondary"
                                    sx={{ marginLeft: 13 }}
                                  >
                                    {item.description}
                                  </Typography>
                                </Typography>
                              </Table>
                            </TableContainer>
                            <TableContainer
                              sx={{
                                marginTop: 0.5,
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "100%px",
                              }}
                              className="w-96 border rounded"
                            >
                              <Table className="border">
                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  sx={{ marginLeft: 1, marginTop: 0.5 }}
                                >
                                  <strong>Reason for Rejection :</strong>{" "}
                                  <Typography
                                    fontSize={15}
                                    color="textSecondary"
                                    sx={{ marginLeft: 13 }}
                                  >
                                    {item.reason}
                                  </Typography>
                                </Typography>
                              </Table>
                            </TableContainer>
                            {item.title === "Leave" ? null : (
                              <TableContainer
                                sx={{
                                  marginTop: 0.5,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  width: "100%px",
                                }}
                                className="w-96 border rounded"
                              >
                                <Table className="border">
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    sx={{ marginLeft: 1, marginTop: 0.5 }}
                                  >
                                    <strong>
                                      Time Period :{" "}
                                      {item.start_time + " To " + item.end_time}
                                    </strong>
                                  </Typography>
                                </Table>
                              </TableContainer>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  ))}
              </TableBody>
            ) : null}
          </Table>
          {filteredData.length === 0 && loading === false ? (
            <Alert sx={{ marginTop: 3 }} severity="info">
              No data available
            </Alert>
          ) : null}
        </TableContainer>
      </Grid>

      {filteredData.length > 10 && (
        <TablePagination
          className="custom-pagination"
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};

export default Approved;
