import React, { useState } from "react";
import { BackArrow } from "components/CustomComponents/BackArrow";
import * as Mui from "@mui/material";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { defaultAllSelection } from "services/constants";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { CustomButton } from "components/CustomComponents/CustomButton";
export const EditAssignGroup1 = ({
  viewAssignDetails,
  setShowTopBar,
  setRenderComponentName,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [departmentName, setDepartmentName] = useState("");
  const [groupName, setGroupName] = useState("");
  const [leftSideEmployee, setLeftSideEmployee] = useState([]);
  const [rightSideEmployee, setRightSideEmployee] = useState([]);
  const [leftChecked, setLeftChecked] = useState(false);
  const [rightChecked, setRightChecked] = useState(false);
  const { result: workUnitGroup } = useFetch(`${domain}work_unit_groups/`);
  const { result: shiftCategory } = useFetch(
    `${domain}workunit-based-shiftcategory/`
  );

  const { result: department, setLoad: reCallShiftApi } = useFetch(
    `${domain}department-based-shiftcategory/?shift_category_id=${
      getValues("shift_category_id")
        ? getValues("shift_category_id")
        : defaultAllSelection
    }`
  );

  const [shiftCategoryName, setShiftCategoryName] = useState("");

  const getEmployees = async (department_id) => {
    try {
      const response = await fetch(
        `${domain}list/employee?department_id=${department_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        let checkKey = res.map((data) => {
          return {
            ...data,
            checked: false,
          };
        });
        setLeftSideEmployee(checkKey);
        setRightSideEmployee([]);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleDepartment = (event, data) => {
    if (data) {
      setValue("department_name", data?.label);
      setValue("id", data?.id);
      setDepartmentName(data?.label);
      getEmployees(data?.id);
    } else {
      setValue("department_name", "");
      setValue("id", null);
      setDepartmentName("");
    }
  };

  const handleShiftCategory = (event, data) => {
    if (data) {
      setValue("shift_categoryname", data?.label);
      setShiftCategoryName(data?.label);
      setValue("shift_category_id", data?.shift_category_id);
      reCallShiftApi(true);
    } else {
      setValue("shift_categoryname", "");
      setValue("id", null);
      setShiftCategoryName("");
    }
  };

  const handleCheckedRight = () => {
    const data = leftSideEmployee.filter((user) => {
      return user.checked === true;
    });
    const remaingData = leftSideEmployee.filter((user) => {
      return user.checked === false;
    });
    let updateAsFalse = data.map((users) => {
      return {
        ...users,
        checked: false,
      };
    });
    let updatedData = [...rightSideEmployee, ...updateAsFalse];
    setRightSideEmployee(updatedData);
    setLeftSideEmployee(remaingData);
    setLeftChecked(false);
    setRightChecked(false);
  };

  const handleCheckedLeft = () => {
    const data = rightSideEmployee.filter((user) => {
      return user.checked === true;
    });
    const remaingData = rightSideEmployee.filter((user) => {
      return user.checked === false;
    });
    let updateAsFalse = data.map((users) => {
      return {
        ...users,
        checked: false,
      };
    });
    let updatedData = [...leftSideEmployee, ...updateAsFalse];
    setLeftSideEmployee(updatedData);
    setRightSideEmployee(remaingData);
    setLeftChecked(false);
    setRightChecked(false);
  };

  const handlAssignedTo = (event, data) => {
    if (data) {
      setValue("id", data?.id);
      setGroupName(data?.label);
      setValue("workunit_group", data?.label);
    } else {
      setValue("workunit_group", "");
      setValue("id", null);
      setGroupName("");
    }
  };

  const onSubmit = async (data) => {
    if (rightSideEmployee.length !== 0) {
      let userId = [];
      rightSideEmployee.forEach((element) => {
        userId.push(element?.user_id);
      });
      try {
        const response = await fetch(`${domain}unit/assign/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            users: userId, // User IDs
            work_unit_group: data.id, // Work Unit Group ID
            start_date: data.effective_from,
            end_date: data.effective_to,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          setRenderComponentName("ListView");
          setShowTopBar(true);
          showToast("success", res.message);
          reset();
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    }
  };

  const handleToggleAllLeft = (value) => {
    setLeftChecked(false);
    setRightChecked(true);
    let updatedData = leftSideEmployee.map((data) => {
      return {
        ...data,
        checked: value,
      };
    });
    setLeftSideEmployee(updatedData);
  };

  const numberOfChecked = () => {
    let count = 0;
    leftSideEmployee.forEach((data) => {
      if (data.checked) {
        count = count + 1;
      }
    });
    return count;
  };

  const handleToggleLeft = (value) => () => {
    setLeftChecked(false);
    setRightChecked(true);
    let updatedData = leftSideEmployee.map((data) =>
      data.user_id === value.user_id
        ? { ...data, checked: !value.checked }
        : data
    );
    setLeftSideEmployee(updatedData);
  };

  const handleToggleRigh = (value) => () => {
    setLeftChecked(true);
    setRightChecked(false);
    let updatedData = rightSideEmployee.map((data) =>
      data.user_id === value.user_id
        ? { ...data, checked: !value.checked }
        : data
    );
    setRightSideEmployee(updatedData);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.Grid container>
          <BackArrow
            actionFunction={() => {
              setRenderComponentName("ListView");
              setShowTopBar(true);
            }}
          />
          <Mui.Typography
            sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 1 }}
          >
            Work Unit / Add WorkUnit Pay
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            paddingTop: 1.5,
            paddingBottom: 1,
            borderBottom: `2px solid ${themes.sidebarDivider}`,
          }}
        >
          <Mui.Grid xs={1}></Mui.Grid>
          <Mui.Grid xs={2.5}>
            <Mui.Typography
              sx={{
                fontSize: 18,
                color: themes.headLine,
                paddingBottom: 0.5,
                fontWeight: "bold",
              }}
            >
              Shift Category
            </Mui.Typography>
            <CustomAutoComplete
              dataList={shiftCategory?.map((obj) => ({
                ...obj,
                label: obj?.shift_category_name,
              }))}
              selectedValue={shiftCategoryName}
              rules={{
                ...register("shift_categoryname", {
                  required: "Shift category name is required",
                }),
              }}
              updateDataFunction={handleShiftCategory}
              placeHolder="Please select the shift category name"
              errorMessage={
                errors?.shift_categoryname?.message
                  ? errors?.shift_categoryname?.message
                  : ""
              }
              textBoxError={errors?.shift_categoryname ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid xs={0.1}></Mui.Grid>
          <Mui.Grid xs={2.5}>
            <Mui.Typography
              sx={{
                fontSize: 18,
                color: themes.headLine,
                paddingBottom: 0.5,
                fontWeight: "bold",
              }}
            >
              Department
            </Mui.Typography>
            <CustomAutoComplete
              dataList={department.map((obj) => ({
                ...obj,
                label: obj.department_name,
              }))}
              selectedValue={departmentName}
              rules={{
                ...register("department_name", {
                  required: "Department name is required",
                }),
              }}
              updateDataFunction={handleDepartment}
              placeHolder="Please select the department name"
              errorMessage={
                errors?.department_name?.message
                  ? errors?.department_name?.message
                  : ""
              }
              textBoxError={errors?.department_name ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid xs={0.7}></Mui.Grid>
          <Mui.Grid xs={2}>
            {" "}
            <Mui.Typography
              sx={{
                fontSize: 18,
                color: themes.headLine,
                paddingBottom: 0.5,
                fontWeight: "bold",
              }}
            >
              Effective From
            </Mui.Typography>
            <Mui.TextField
              id="from-date"
              type="date"
              size="small"
              {...register("effective_from", {
                required: "Date is required.",
              })}
              error={Boolean(errors.effective_from)}
              helperText={
                errors.effective_from?.message && errors.effective_from.message
              }
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              disableOpenPicker
            />
          </Mui.Grid>
          <Mui.Grid xs={0.2}></Mui.Grid>
          <Mui.Grid xs={2}>
            <Mui.Typography
              sx={{
                fontSize: 18,
                color: themes.headLine,
                paddingBottom: 0.5,
                fontWeight: "bold",
              }}
            >
              Effective To
            </Mui.Typography>
            <Mui.TextField
              id="from-date"
              type="date"
              size="small"
              {...register("effective_to", {
                required: "Date is required.",
              })}
              error={Boolean(errors.effective_to)}
              helperText={
                errors.effective_to?.message && errors.effective_to.message
              }
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              disableOpenPicker
            />
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "Center",
            alignItems: "Center",
            paddingLeft: 25,
            paddingRight: 25,
          }}
        >
          <Mui.Grid
            container
            component={Mui.Paper}
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "Center",
              alignItems: "Center",
              padding: 2,
              backgroundColor: "#f7f5f6",
            }}
          >
            <Mui.Grid item xs={12} sm={12} md={6} lg={5}>
              <>
                <Mui.Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "Center",
                    alignItems: "Center",
                  }}
                >
                  <Mui.Grid
                    component={Mui.Paper}
                    sx={{
                      backgroundColor: themes.darkPrimary,
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    <Mui.Typography
                      sx={{
                        padding: 1,
                        fontSize: 20,
                        fontWeight: 700,
                      }}
                    >
                      Un Assigned
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid sx={{ paddingTop: 2 }}></Mui.Grid>
                <Mui.FormControl fullWidth>
                  <Mui.Grid
                    sx={{
                      paddingTop: 2,
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                    component={Mui.Paper}
                  >
                    <Mui.Card>
                      <Mui.CardHeader
                        sx={{ px: 2, py: 1 }}
                        avatar={
                          <Mui.Checkbox
                            onClick={(event) =>
                              handleToggleAllLeft(event.target.checked)
                            }
                            inputProps={{
                              "aria-label": "all items selected",
                            }}
                          />
                        }
                        title={"Selected Employees"}
                        subheader={`${numberOfChecked()}/${
                          leftSideEmployee.length
                        }`}
                      />
                      <Mui.Divider />
                      <Mui.List
                        sx={{
                          width: "100%",
                          height: {
                            xs: 200,
                            sm: 300,
                            md: 300,
                            lg: 430,
                          },
                          bgcolor: "background.paper",
                          overflow: "auto",
                        }}
                        dense
                        component="div"
                        role="list"
                      >
                        {leftSideEmployee.map((value) => {
                          const labelId = `transfer-list-all-item-${value.user_id}-label`;

                          return (
                            <Mui.ListItemButton
                              key={value.user_id}
                              role="listitem"
                              onClick={handleToggleLeft(value)}
                            >
                              <Mui.ListItemIcon>
                                <Mui.Checkbox
                                  checked={value.checked}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </Mui.ListItemIcon>
                              <Mui.ListItemAvatar>
                                <Mui.Avatar
                                  alt={value?.user_name?.charAt(0)}
                                  src={value?.image_url}
                                />
                              </Mui.ListItemAvatar>
                              <Mui.ListItemText
                                id={labelId}
                                primary={`${value.user_name}`}
                              />
                            </Mui.ListItemButton>
                          );
                        })}
                      </Mui.List>
                    </Mui.Card>
                  </Mui.Grid>
                </Mui.FormControl>
              </>
            </Mui.Grid>
            <Mui.Grid item xs={12} sm={12} md={1.5} lg={1}>
              <Mui.Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <Mui.IconButton
                  onClick={handleCheckedRight}
                  disabled={leftChecked}
                  sx={{
                    textTransform: "capitalize",
                    color: themes.headLine,
                  }}
                  variant="contained"
                  size="medium"
                >
                  <ArrowCircleRight color={themes.headLine} fontSize="large" />
                </Mui.IconButton>
                <Mui.IconButton
                  onClick={handleCheckedLeft}
                  disabled={rightChecked}
                  sx={{
                    textTransform: "capitalize",
                    color: themes.headLine,
                  }}
                  variant="contained"
                  size="medium"
                >
                  <ArrowCircleLeft color={themes.headLine} fontSize="large" />
                </Mui.IconButton>
              </Mui.Grid>
            </Mui.Grid>
            {/* Right Side */}
            <Mui.Grid item xs={12} sm={12} md={6} lg={5}>
              <>
                <Mui.Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "Center",
                    alignItems: "Center",
                  }}
                >
                  <Mui.Grid
                    component={Mui.Paper}
                    sx={{
                      backgroundColor: themes.darkPrimary,
                      paddingLeft: 5.5,
                      paddingRight: 5.5,
                    }}
                  >
                    <Mui.Typography
                      sx={{
                        padding: 1,
                        fontSize: 20,
                        fontWeight: 700,
                      }}
                    >
                      Assigned To
                    </Mui.Typography>
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.FormControl fullWidth>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    WorkUnit Group
                  </Mui.Typography>
                  <CustomAutoComplete
                    dataList={workUnitGroup.map((obj) => ({
                      ...obj,
                      label: obj.name,
                    }))}
                    selectedValue={groupName}
                    rules={{
                      ...register("workunit_group", {
                        required: "Work unit group is required",
                      }),
                    }}
                    updateDataFunction={handlAssignedTo}
                    placeHolder="Please select workunit group"
                    errorMessage={
                      errors?.workunit_group?.message
                        ? errors?.workunit_group?.message
                        : ""
                    }
                    textBoxError={errors?.workunit_group ? true : false}
                  />
                </Mui.FormControl>
                <Mui.FormControl fullWidth>
                  <Mui.Grid
                    sx={{
                      paddingTop: 2,
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                    component={Mui.Paper}
                  >
                    <Mui.FormControl fullWidth>
                      <Mui.Grid
                        sx={{
                          //   paddingTop: 2,
                          width: "100%",
                          bgcolor: "background.paper",
                        }}
                        component={Mui.Paper}
                      >
                        <Mui.Card>
                          <Mui.List
                            sx={{
                              width: "100%",
                              height: {
                                xs: 200,
                                sm: 300,
                                md: 300,
                                lg: 430,
                              },
                              bgcolor: "background.paper",
                              overflow: "auto",
                            }}
                            dense
                            component="div"
                            role="list"
                          >
                            {rightSideEmployee.map((value) => {
                              const labelId = `transfer-list-all-item-${value.user_id}-label`;

                              return (
                                <Mui.ListItemButton
                                  key={value.user_id}
                                  role="listitem"
                                  onClick={handleToggleRigh(value)}
                                >
                                  <Mui.ListItemIcon>
                                    <Mui.Checkbox
                                      checked={value.checked}
                                      tabIndex={-1}
                                      disableRipple
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                    />
                                  </Mui.ListItemIcon>
                                  <Mui.ListItemAvatar>
                                    <Mui.Avatar
                                      alt={value?.user_name?.charAt(0)}
                                      src={value?.image_url}
                                    />
                                  </Mui.ListItemAvatar>
                                  <Mui.ListItemText
                                    id={labelId}
                                    primary={`${value.user_name}`}
                                  />
                                </Mui.ListItemButton>
                              );
                            })}
                          </Mui.List>
                        </Mui.Card>
                      </Mui.Grid>
                    </Mui.FormControl>
                  </Mui.Grid>
                </Mui.FormControl>
              </>
            </Mui.Grid>
            <Mui.Grid container sx={{ mt: 2 }}>
              <Mui.Grid xs={8.5}></Mui.Grid>
              <Mui.Grid
                xs={3}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <CustomButton actionName="Add" typeName="submit" />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
