import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import { showToast } from "services/utils/Status";
import { MoreVert, Info } from "@mui/icons-material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { Loader } from "components/Loader";
import { useForm } from "react-hook-form";
import * as Mui from "@mui/material";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import sessionHandling from "services/utils/notificationUtils";
export const Designation = () => {
  const { globalSearchValue, domain, token } = useSelector(
    (state) => state.tokenReducer
  );
  const [fetchDesignationData, setFetchDesignationData] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [designation, setDesignation] = useState([]);
  const [popupTitle, setPopupTitle] = useState("");
  const [editDesignation, setEditDesignation] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [masterDesignationList, setMasterDesignationList] = useState([]);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const handleClose = () => {
    setOpenDialog(false);
    setEditDesignation("");
    setDeletePopup(false);
    setButtonDisabled(false);
  };

  useEffect(() => {
    const getDesignationDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${domain}designation/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setDesignation(res);
          setMasterDesignationList(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setLoading(false);
      }
    };
    if (fetchDesignationData) {
      getDesignationDetails();
      setFetchDesignationData(false);
    }
  }, [domain, fetchDesignationData, setFetchDesignationData, token]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const searchFields = ["designation", "description"];
    const filteredResults = masterDesignationList.filter((item) =>
      searchFields.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setDesignation(filteredResults);
    setPage(globalSearchValue ? 0 : page);
  }, [globalSearchValue, masterDesignationList, page]);

  const handleMenuClose = () => {
    setAnchorEl(false);
  };

  const checkSpecialChar = (e) => {
    if (!/[a-zA-Z ]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const checkSpecialCharWithDigits = (e) => {
    if (!/[a-zA-Z\d ]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const createNewDesignation = async (data) => {
    if (popupTitle === "Add Designation") {
      if (!buttonDisabled) {
        setButtonDisabled(true);
        let design = data.designation;
        let desc = data.description;
        try {
          const response = await fetch(`${domain}designation/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              designation: design,
              description: desc,
            }),
          });
          const res = await response.json();
          if (response.ok) {
            setFetchDesignationData(true);
            showToast("success", "Designation created successfully.");
            setOpenDialog(false);
            setTimeout(function () {
              setButtonDisabled(false);
            }, 1000);
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            setValue("designation", design);
            setValue("description", desc);
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        }
      }
    } else {
      let design = data.designation;
      let desc = data.description;
      if (!buttonDisabled) {
        setButtonDisabled(true);
        try {
          const response = await fetch(`${domain}designation/${data.id}/`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              designation: design,
              description: desc,
            }),
          });
          const res = await response.json();
          if (response.ok) {
            setFetchDesignationData(true);
            setEditDesignation("");
            showToast("success", "Designation updated successfully.");
            setOpenDialog(false);
            setTimeout(function () {
              setButtonDisabled(false);
            }, 1000);
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            setValue("designation", design);
            setValue("description", desc);
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        }
      }
    }
  };

  const deleteDesignation = async () => {
    const id = getValues("id");
    try {
      const response = await fetch(`${domain}designation/${id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          isdeleted: true,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setFetchDesignationData(true);
        showToast("error", "Designation deleted successfully");
        handleClose();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const moveToAddDesignation = () => {
    setPopupTitle("Add Designation");
    setOpenDialog(true);
    setDeletePopup(false);
    reset();
  };

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      {loading === false ? (
        <Mui.TableContainer
          sx={{
            paddingLeft: 1,
            paddingRight: 1,
            width: "100%",
            minHeight: "95%",
            paddingTop: 2,
            borderRadius: 4,
            border: `1px solid ${themes.buttonBorder}`,
            justifyContent: "center",
          }}
        >
          <Mui.Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingBottom: 2,
            }}
          >
            <CustomButton
              actionFuntion={moveToAddDesignation}
              actionName="Add Designation"
              typeName="button"
            />
          </Mui.Grid>
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow maxWidth="xl" align="left">
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Designation
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Description
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Actions
                </Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {designation
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <Mui.TableRow maxWidth="xl" align="left">
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      <Mui.Typography>
                        {item?.designation ? item?.designation : "N/A"}
                      </Mui.Typography>
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      <Mui.Typography>
                        {item?.description ? item?.description : "N/A"}
                      </Mui.Typography>
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      <Mui.IconButton
                        onClick={(event) => {
                          reset();
                          setAnchorEl(event.currentTarget);
                          setValue("designation", item.designation);
                          setValue("description", item.description);
                          setValue("id", item.id);
                        }}
                      >
                        <MoreVert />
                      </Mui.IconButton>
                      <Mui.Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        elevation={1}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <Mui.MenuItem
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setPopupTitle("Edit Designation");
                            setEditDesignation(getValues("designation"));
                            setOpenDialog(true);
                            setDeletePopup(false);
                            setAnchorEl(false);
                          }}
                        >
                          Edit
                        </Mui.MenuItem>
                        <Mui.MenuItem
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setAnchorEl(false);
                            setDeletePopup(true);
                            setOpenDialog(true);
                            setPopupTitle("Delete Designation");
                          }}
                        >
                          Delete
                        </Mui.MenuItem>
                      </Mui.Menu>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ))}
            </Mui.TableBody>
          </Mui.Table>
          {designation.length === 0 && loading === false ? (
            <Mui.Alert sx={{ marginTop: 3 }} severity="info">
              No data available
            </Mui.Alert>
          ) : null}
          {designation.length > 10 ? (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[25, 50, 75, 100]}
              count={designation.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </Mui.TableContainer>
      ) : null}

      <GlobalDialogBox
        handleCloseDialog={handleClose}
        open={openDialog}
        title={popupTitle}
      >
        {deletePopup ? (
          <Mui.Grid container>
            <Mui.Grid xs={12}>
              <Mui.Typography sx={{ fontSize: 22, paddingBottom: 2 }}>
                Do you want to remove the designation?
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={12}>
              <Mui.Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <CustomButton
                  key={"Delete Designation"}
                  actionFuntion={() => {
                    setOpenDialog(false);
                    handleClose();
                  }}
                  actionName="No"
                  typeName="submit"
                />
                &nbsp;&nbsp;
                <CustomButton
                  actionFuntion={() => {
                    setOpenDialog(false);
                    deleteDesignation();
                  }}
                  actionName="Yes"
                  typeName="submit"
                />
              </Mui.Stack>
            </Mui.Grid>
          </Mui.Grid>
        ) : popupTitle === "Add Designation" ||
          popupTitle === "Edit Designation" ? (
          <form onSubmit={handleSubmit(createNewDesignation)} noValidate>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={3} sx={{ justifyContent: "flex-end" }}>
                <Mui.Stack
                  direction="Row"
                  sx={{
                    paddingTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "flex-start",
                    paddingRight: 1,
                  }}
                >
                  <Mui.Typography>Designation</Mui.Typography> &nbsp;&nbsp;
                  <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={9}>
                <Mui.TextField
                  placeholder="Enter the designation"
                  {...register("designation", {
                    required: "Designation is required",
                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are required",
                    },
                    validate: {
                      designationAvailable: async (fieldValue) => {
                        if (popupTitle !== "Edit Designation") {
                          if (fieldValue.trim().length < 3) {
                            return "Minimum 3 characters are required";
                          } else {
                            try {
                              const response = await fetch(
                                `${domain}check/designation?designation_name=${fieldValue.trim()}`,
                                {
                                  method: "GET",
                                  headers: {
                                    "content-type": "application/json",
                                    Authorization: `token ${token}`,
                                  },
                                }
                              );
                              const res = await response.json();
                              if (response.ok) {
                                if (res.exists) {
                                  return "Designation name is already exists";
                                } else {
                                  return true;
                                }
                              } else if (response.status === 409) {
                                sessionHandling();
                              } else {
                                throw new Error(res.error);
                              }
                            } catch (error) {
                              showToast("error", error.message);
                            }
                          }
                        }
                      },
                      editDesignation: async (fieldValue) => {
                        if (
                          popupTitle === "Edit Designation" &&
                          editDesignation !== getValues("designation")
                        ) {
                          if (fieldValue.trim().length < 3) {
                            return "Minimum 3 characters are required";
                          } else {
                            try {
                              const response = await fetch(
                                `${domain}check/designation?designation_name=${fieldValue.trim()}`,
                                {
                                  method: "GET",
                                  headers: {
                                    "content-type": "application/json",
                                    Authorization: `token ${token}`,
                                  },
                                }
                              );
                              const res = await response.json();
                              if (response.ok) {
                                if (res.exists) {
                                  return "Designation name is already exists";
                                } else {
                                  return true;
                                }
                              } else if (response.status === 409) {
                                sessionHandling();
                              } else {
                                throw new Error(res.error);
                              }
                            } catch (error) {
                              showToast("error", error.message);
                            }
                          }
                        }
                      },
                    },
                  })}
                  size="small"
                  InputProps={{
                    inputProps: { maxLength: 32 },
                  }}
                  error={Boolean(errors.designation)}
                  helperText={errors.designation?.message || ""}
                  onKeyPress={checkSpecialChar}
                  fullWidth
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingBottom: 1 }}>
              <Mui.Grid xs={3} sx={{ justifyContent: "flex-end" }}>
                <Mui.Stack
                  direction="Row"
                  sx={{
                    paddingTop: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "flex-start",
                    paddingRight: 1,
                  }}
                >
                  <Mui.Typography>Description</Mui.Typography> &nbsp;&nbsp;
                  <Mui.Tooltip title="Here, you can add more infor about the roles and responsibilities the designation holds.            ">
                    <Info />
                  </Mui.Tooltip>
                </Mui.Stack>
              </Mui.Grid>
              <Mui.Grid xs={9}>
                <Mui.TextField
                  multiline
                  rows={3}
                  maxRows={5}
                  placeholder="Enter the description"
                  {...register("description", {
                    required: "Description is required",
                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters are required",
                    },
                    validate: {
                      designationAvailable: async (fieldValue) => {
                        if (fieldValue.trim().length < 3) {
                          return "Minimum 3 characters are required";
                        } else {
                          return true;
                        }
                      },
                    },
                  })}
                  size="small"
                  InputProps={{
                    inputProps: { maxLength: 128 },
                  }}
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message || ""}
                  onKeyPress={checkSpecialCharWithDigits}
                  fullWidth
                />
              </Mui.Grid>
              <Mui.Grid xs={1}></Mui.Grid>
            </Mui.Grid>
            <Mui.Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <CustomButton
                actionFuntion={handleClose}
                actionName="Cancel"
                typeName="button"
              />
              &nbsp;&nbsp;
              <Mui.Button
                variant="contained"
                type="submit"
                onclick={() => {
                  handleSubmit(createNewDesignation);
                }}
                sx={{
                  background: themes.primaryButton,
                  fontWeight: 700,
                  color: themes.headLine,
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: themes.primaryButton,
                  },
                }}
                disabled={buttonDisabled}
              >
                {popupTitle === "Add Designation" ? "Add" : "Update"}
              </Mui.Button>
            </Mui.Stack>
          </form>
        ) : null}
      </GlobalDialogBox>
    </React.Fragment>
  );
};
