import React, { useContext, useState, useEffect } from "react";
import { TaskContext } from "./TaskContext";
import { themes } from "services/constants";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { projectPlaceholder } from "services/constants/PlaceHolder";
import { showToast } from "services/utils/Status";
import * as Mui from "@mui/material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import sessionHandling from "services/utils/notificationUtils";

export const ChangeAssignee = ({
  open,
  status,
  handleClose,
  editTask,
  option,
  setDoAnyAct,
}) => {
  const {
    currentPlayTaskId,
    setIsActive,
    domain,
    token,
    allUsers,
    setFetchTask,
    setCurrentPlayTaskId,
    editProject,
  } = useContext(TaskContext);
  const [assignee, setAssingee] = useState(
    editTask?.current_assignee?.username
  );
  const [assigneeId, setAssigneeId] = useState(editTask?.current_assignee?.id);
  const [priProjMembers, setPriProjMembers] = useState([]);

  const filterCommonValues = (arr1, arr2) => {
    const valuesInArr2 = arr2.map((item) => item["username"]);
    return arr1.filter((item) => valuesInArr2.includes(item["username"]));
  };

  useEffect(() => {
    if (editProject?.projectType === "Private") {
      const filteredMembers = filterCommonValues(
        allUsers,
        editProject?.members
      );
      setPriProjMembers(filteredMembers);
    }
  }, [editProject?.members, allUsers, editProject?.projectType]);

  const updateTask = async (task, newStatus) => {
    const task_assigneeId =
      task?.current_assignee?.id === assigneeId &&
      (newStatus === "InProgress" || newStatus === "ToDo")
        ? null
        : assigneeId;
    if (currentPlayTaskId === task.id) {
      setIsActive(false);
      setCurrentPlayTaskId(0);
    }
    try {
      if (option !== "Task") {
        setDoAnyAct(true);
      }
      const response = await fetch(`${domain}edit_task/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          task_id: task.id,
          status: newStatus,
          new_assignee: task_assigneeId,
          workflow_status_id: task.work_flow_status.workflow,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        if (option === "Task") {
          setFetchTask(true);
        }
        setAssingee(null);
        setAssigneeId(null);
        showToast("success", res.message);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <GlobalDialogBox
        open={open}
        handleCloseDialog={handleClose}
        title="Select Assignee"
        minWidth={550}
      >
        <Mui.Grid container sx={{ paddingBottom: 1 }}>
          <Mui.Grid xs={1}></Mui.Grid>
          <Mui.Grid xs={10}>
            <Mui.Typography sx={{ fontSize: 20, textAlign: "center" }}>
              Do you wish to change the assignee? Select the user below
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={1}></Mui.Grid>
        </Mui.Grid>
        <Mui.Autocomplete
          disablePortal
          id="combo-box-demo"
          fullWidth
          value={assignee}
          options={
            editProject?.projectType === "Private" ? priProjMembers : allUsers
          }
          filterOptions={(options, state) => {
            return options.filter((option) =>
              option.username
                ?.toLowerCase()
                .includes(state.inputValue.toLowerCase())
            );
          }}
          ListboxProps={{
            style: {
              maxHeight: "120px",
            },
          }}
          renderOption={(props, option) => {
            return (
              <li {...props}>
                <Mui.Avatar
                  sx={{ width: 25, height: 25 }}
                  alt={option.username.charAt(0).toUpperCase()}
                  src={option.image_url}
                />
                &nbsp;{option.username}
              </li>
            );
          }}
          onChange={(event, value) => {
            if (value) {
              setAssingee(value.username);
              setAssigneeId(value.id);
            } else {
              setAssingee(null);
              setAssigneeId(null);
            }
          }}
          sx={{ minWidth: "100%" }}
          renderInput={(params) => (
            <Mui.TextField
              {...params}
              value={assignee}
              size="small"
              sx={{ background: themes.whiteColor }}
              name="username"
              placeholder={projectPlaceholder.selectUser}
              autoFocus
            />
          )}
        />
        <Mui.Grid container sx={{ paddingTop: 6, paddingBottom: 3 }}>
          <Mui.Grid xs={6}></Mui.Grid>
          <Mui.Grid
            xs={6}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={() => handleClose()}
              actionName="Cancel"
              typeName="button"
            />
            &nbsp;
            <CustomButton
              actionFuntion={() => updateTask(editTask, status)}
              actionName="Update"
              typeName="button"
              disableAction={assignee ? false : true}
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
      ;
    </React.Fragment>
  );
};
