import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddTaskIcon from "@mui/icons-material/AddTask";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";
import { showToast } from "services/utils/Status";
import { DialogBox } from "../DialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import sessionHandling from "services/utils/notificationUtils";
let multiApiCallRestriction = true;
const keys = [
  "status",
  "user_name",
  "amount",
  "date_incurred",
  "expense_type",
  "description",
  "reason",
];
export const Approve = ({
  departmentId,
  departmentChange,
  setDepartmentChange,
  expenseScreen,
  setReloadData,
}) => {
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rejectReason, setRejectReason] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [rejectedReasonValidation, setRejectedReasonValidation] = useState("");
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expenseSalary, setExpenseSalary] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);
  const {
    result: expenseDetails,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    `${domain}expense/?user_id=${userDetails.id}&status=Pending&department_id=${departmentId}`
  );

  useEffect(() => {
    if (departmentChange === true && expenseScreen === "Approve") {
      reCallApi(true);
      setDepartmentChange(false);
    }
  }, [departmentChange, expenseScreen, reCallApi, setDepartmentChange]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDeleteCancel = () => {
    setRejectReason("");
    setOpenRejectDialog(false);
    setRejectedReasonValidation("");
  };

  const onSubmit = async () => {
    if (rejectReason.trim() === "") {
      setRejectedReasonValidation("Reason");
      return;
    } else if (rejectReason.length < 5) {
      setRejectedReasonValidation("Less than 5 character");
      return;
    }

    try {
      await handleReject();
      setOpenRejectDialog(false);
      setRejectedReasonValidation("");
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleApprove = async (item) => {
    if (multiApiCallRestriction) {
      setButtonLoader(true);
      multiApiCallRestriction = false;
      try {
        const response = await fetch(`${domain}expense/${item.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            status: "Approved",
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", res.status);
          multiApiCallRestriction = true;
          reCallApi(true);
          setReloadData(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        multiApiCallRestriction = true;
      } finally {
        setButtonLoader(false);
      }
    }
  };

  const handleReject = async () => {
    setButtonLoader(true);
    if (!selectedItem) return;
    try {
      const response = await fetch(`${domain}expense/${selectedItem.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          status: "Rejected",
          reason: rejectReason,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("error", res.status);
        reCallApi(true);
        setReloadData(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };

  useEffect(() => {
    if (updateData && expenseDetails?.length !== 0 && expenseDetails) {
      setExpenseSalary(expenseDetails);
      setUpdateData(false);
    }
  }, [expenseDetails, updateData]);

  useEffect(() => {
    if (expenseDetails?.length !== 0 || expenseDetails?.length === 0) {
      const filteredResults = expenseDetails?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setExpenseSalary(filteredResults);
      setPage(globalSearchValue ? 0 : page);
    }
  }, [expenseDetails, globalSearchValue, page]);

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.TableContainer
        sx={{
          padding: 0.5,
          marginTop: 2,
          marginLeft: "auto",
          marginRight: "auto",
          overflow: "auto",
        }}
      >
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
              position: "sticky",
              top: -1,
              zIndex: 10,
            }}
          >
            <Mui.TableRow>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Date</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Employee Name</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Expense Type</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">
                  Expense Amount
                </Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">
                  Reason For Expense
                </Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold" sx={{ textAlign: "center" }}>
                  Actions
                </Mui.Typography>
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>

          <Mui.TableBody>
            {expenseSalary?.length === 0 ? (
              <Mui.TableRow>
                <Mui.TableCell colSpan={8}>
                  <Mui.Alert sx={{ paddingTop: 1 }} severity="info">
                    No data available
                  </Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              expenseSalary
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <Mui.TableRow>
                    <Mui.TableCell>{item?.date_incurred}</Mui.TableCell>
                    <Mui.TableCell>{item?.user_name}</Mui.TableCell>
                    <Mui.TableCell>{item?.expense_type}</Mui.TableCell>
                    <Mui.TableCell>{item?.amount}</Mui.TableCell>
                    <Mui.TableCell>{item?.description}</Mui.TableCell>
                    <Mui.TableCell sx={{ textAlign: "center" }}>
                      <Mui.Tooltip title="Approve" arrow>
                        <Mui.IconButton
                          onClick={() => {
                            handleApprove(item);
                          }}
                        >
                          <AddTaskIcon
                            sx={{
                              color: themes.primaryIndicator,
                              strokeWidth: 2,
                            }}
                          />
                        </Mui.IconButton>
                      </Mui.Tooltip>
                      <Mui.Tooltip title="Reject" arrow>
                        <Mui.IconButton
                          onClick={() => {
                            setOpenRejectDialog(true);
                            setSelectedItem(item);
                          }}
                        >
                          <DeleteIcon
                            sx={{
                              color: themes.redColor,
                              strokeWidth: 2,
                            }}
                          />
                        </Mui.IconButton>
                      </Mui.Tooltip>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
        {expenseSalary.length > 10 && (
          <Mui.TablePagination
            className="custom-pagination"
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={expenseSalary.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.TableContainer>

      <DialogBox
        open={openRejectDialog}
        onClose={handleDeleteCancel}
        title="Reason for Rejection"
      >
        <Mui.TextField
          multiline
          minRows={3}
          sx={{
            marginTop: 1,
            width: "95%",
            marginLeft: 2,
            marginRight: "auto",
          }}
          placeholder="please enter reason of rejection"
          onChange={(e) => setRejectReason(e.target.value)}
        />
        {rejectedReasonValidation === "Reason" ? (
          <Mui.Typography
            variant="body2"
            color="error"
            sx={{ fontSize: 13, paddingLeft: 3, paddingTop: 0.5 }}
          >
            Please enter the rejected reason
          </Mui.Typography>
        ) : null}
        {rejectedReasonValidation === "Less than 5 character" ? (
          <Mui.Typography
            variant="body2"
            color="error"
            sx={{ fontSize: 13, paddingLeft: 3, paddingTop: 0.5 }}
          >
            Minium 5 character required
          </Mui.Typography>
        ) : null}

        <Mui.Grid container sx={{ paddingTop: 1 }}>
          <Mui.Grid xs={8}></Mui.Grid>
          <Mui.Grid
            xs={4}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={handleDeleteCancel}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={onSubmit}
              actionName="Yes"
              disableAction={
                openRejectDialog && buttonLoader ? buttonLoader : false
              }
              typeName="submit"
            />
          </Mui.Grid>
        </Mui.Grid>
      </DialogBox>
    </React.Fragment>
  );
};
