import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { themes } from "services/constants";
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import { minimum3Characters } from "services/constants/ErrorMessages";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { useForm, Controller } from "react-hook-form";
import { useFetch } from "services/hooks/useFetch";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { useSelector } from "react-redux";
import { customAPI } from "services/utils/CustomAPI";
import { showToast } from "services/utils/Status";
const minDate = new Date("2020-01-01");
export const EditPaySchedule = ({
  companyId,
  companyName,
  setEditPaySchedule,
  editPayScheduleDetails,
  reCallApi,
  buttonNotify,
  reCallApiWithApproval,
}) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [payrunName, setPayrunName] = useState(editPayScheduleDetails.name);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([
    editPayScheduleDetails.salary_group_detail,
  ]);
  const [payRunDate, setPayRunDate] = useState(
    editPayScheduleDetails?.run_date?.split("-").reverse().join("-")
  );
  const [startPayRunPeriod, setStartPayRunPeriod] = useState(
    editPayScheduleDetails?.start_date?.split("-").reverse().join("-")
  );
  const [endPayRunPeriod, setEndPayRunPeriod] = useState(
    editPayScheduleDetails?.end_date?.split("-").reverse().join("-")
  );
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const { result: members } = useFetch(
    companyId !== 0 ? `${domain}salary-group/?company=${companyId}` : null
  );

  useEffect(() => {
    setValue("name", editPayScheduleDetails.name);
    setValue(
      "run_date",
      editPayScheduleDetails.run_date?.split("-").reverse().join("-")
    );
    setValue(
      "start_date",
      editPayScheduleDetails.start_date?.split("-").reverse().join("-")
    );
    setValue(
      "end_date",
      editPayScheduleDetails.end_date?.split("-").reverse().join("-")
    );
    setValue("salaryGroup", editPayScheduleDetails.salary_group_detail);
  }, [editPayScheduleDetails, setValue]);

  const updateUserName = (event) => {
    if (event.target.value.length <= 32) {
      setValue("name", event.target.value);
      setPayrunName(event.target.value);
    }
  };

  const handlePayrunStartDate = (event) => {
    setValue("start_date", event.target.value);
    setStartPayRunPeriod(event.target.value);
  };

  const handlePayrunEndDate = (event) => {
    setValue("end_date", event.target.value);
    setEndPayRunPeriod(event.target.value);
  };

  const handlePayDate = (event) => {
    setValue("run_date", event.target.value);
    setPayRunDate(event.target.value);
  };

  const onSubmit = async (data) => {
    let salaryGroup = [];
    data.salaryGroup.forEach((element) => {
      salaryGroup.push(element?.id);
    });
    setButtonDisabled(true);
    let url =
      buttonNotify === "Upcoming PaySchedule"
        ? `${domain}payschedule/${editPayScheduleDetails?.id}/`
        : `${domain}past-pay-schedules/${editPayScheduleDetails?.id}/`;
    let payload = {
      ...data,
      schedule_type: "",
      company: editPayScheduleDetails.company,
      salary_group: salaryGroup,
    };
    const response = await customAPI(url, "PUT", token, payload);
    if (response.code === 200) {
      if (buttonNotify === "Upcoming PaySchedule") {
        reCallApi(true);
      } else {
        reCallApiWithApproval(true);
      }
      setEditPaySchedule(false);
      showToast("success", "Pay schedule details updated successfully");
    }
    setButtonDisabled(false);
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 1.5 }}>
        <BackArrow actionFunction={() => setEditPaySchedule(false)} />
        &nbsp;
        <Mui.Typography
          sx={{
            fontSize: 25,
            fontWeight: "bold",
            color: themes.blackColor,
            paddingTop: 1,
          }}
        >
          Edit Pay Schedule
        </Mui.Typography>
      </Mui.Grid>

      <Mui.Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "50vh" }}
        elavation={5}
      >
        <Mui.Paper elevation={5} sx={{ width: 900, height: 600 }}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ paddingTop: 10, padding: 20 }}
          >
            <Mui.Grid xs={12} sx={{ paddingBottom: 2 }}>
              <Mui.Typography sx={{ fontSize: 30, textAlign: "center" }}>
                Pay schedule for {companyName}
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={12} sx={{ paddingBottom: 2 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.colorShadow,
                  paddingBottom: 1,
                  fontWeight: "bold",
                }}
              >
                Payrun Name
              </Mui.Typography>
              <CustomTextBox
                selectedValue={payrunName}
                rules={{
                  ...register("name", {
                    required: "payrunname is required",
                    maxLength: {
                      value: 32,
                      message: "Maximum 32 characters are required",
                    },
                    validate: {
                      atleastOneCharacterWithSpaceReg: (value) => {
                        if (
                          atleastOneCharacterWithSpace.test(value) ||
                          value === ""
                        ) {
                          return true;
                        } else {
                          return "Atleast one alphabet required";
                        }
                      },
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else {
                          return true;
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updateUserName}
                placeHolder="Enter the payrun name"
                errorMessage={
                  errors?.name?.message ? errors?.name?.message : ""
                }
                textBoxError={errors.name ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={12} sx={{ paddingBottom: 2 }}>
              <Mui.Grid container>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.colorShadow,
                    paddingBottom: 1,
                    fontWeight: "bold",
                  }}
                >
                  Payrun period
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid container>
                <Mui.Grid xs={6}>
                  <CustomTextBox
                    type="date"
                    selectedValue={startPayRunPeriod}
                    rules={{
                      ...register("start_date", {
                        required: "Payrun start date is required",
                        validate: {
                          minLengthNoSpaces: (value) => {
                            const startDate = new Date(value);
                            const minDate = new Date("2020-01-01");
                            const endDate = new Date(getValues("end_date"));
                            if (startDate < minDate) {
                              return "Start date must be 01/01/2020 or later";
                            }
                            if (startDate <= endDate) {
                              return true;
                            } else {
                              return "Start date is lesser then end date";
                            }
                          },
                        },
                      }),
                    }}
                    updateDataFunction={handlePayrunStartDate}
                    errorMessage={
                      errors?.start_date?.message
                        ? errors?.start_date?.message
                        : ""
                    }
                    textBoxError={errors.start_date ? true : false}
                  />
                </Mui.Grid>
                <Mui.Grid xs={6} sx={{ paddingLeft: 2 }}>
                  <CustomTextBox
                    type="date"
                    selectedValue={endPayRunPeriod}
                    rules={{
                      ...register("end_date", {
                        required: "Payrun end date is required",
                        validate: {
                          minLengthNoSpaces: (value) => {
                            const endDate = new Date(value);
                            const minDate = new Date("2020-01-01");
                            const startDate = new Date(getValues("start_date"));
                            if (endDate < minDate) {
                              return "End date must be 01/01/2020 or later";
                            } else if (endDate >= startDate) {
                              return true;
                            } else {
                              return "End date is greater then start date";
                            }
                          },
                        },
                      }),
                    }}
                    updateDataFunction={handlePayrunEndDate}
                    errorMessage={
                      errors?.end_date?.message ? errors?.end_date?.message : ""
                    }
                    textBoxError={errors.end_date ? true : false}
                  />
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid xs={12} sx={{ paddingBottom: 2 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.colorShadow,
                  paddingBottom: 1,
                  fontWeight: "bold",
                }}
              >
                Paydate
              </Mui.Typography>
              <CustomTextBox
                type="date"
                selectedValue={payRunDate}
                rules={{
                  ...register("run_date", {
                    required: "Pay date is required",
                    validate: {
                      minLengthNoSpaces: (value) => {
                        const endDate = new Date(getValues("end_date"));
                        const date = new Date(value);
                        if (date < endDate) {
                          return "Payment date is lesser then payrun period end date";
                        } else {
                          return true;
                        }
                      },
                      dateValidation: (value) => {
                        const selectedDate = new Date(value);
                        if (isNaN(selectedDate.getTime())) {
                          return "Invalid date";
                        }
                        if (selectedDate < minDate) {
                          return "Pay date cannot be before 01/01/2020, 2020";
                        }
                        return true;
                      },
                    },
                  }),
                }}
                updateDataFunction={handlePayDate}
                errorMessage={
                  errors?.run_date?.message ? errors?.run_date?.message : ""
                }
                textBoxError={errors.run_date ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={12} sx={{ paddingBottom: 2 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.colorShadow,
                  paddingBottom: 1,
                  fontWeight: "bold",
                }}
              >
                Salary Group
              </Mui.Typography>
              <Controller
                name="salaryGroup"
                control={control}
                defaultValue={selectedMembers}
                rules={{
                  required: "Please select at least one salary group",
                }}
                render={({ field }) => (
                  <Mui.Autocomplete
                    {...field}
                    size="small"
                    fullWidth
                    multiline
                    multiple
                    limitTags={1}
                    disableCloseOnSelect
                    options={members}
                    getOptionLabel={(option) =>
                      option?.name ? option?.name : ""
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    ListboxProps={{ style: { maxHeight: "150px" } }}
                    onChange={(event, value) => {
                      if (value) {
                        setSelectedMembers(value?.name);
                        field.onChange(value);
                      } else {
                        setSelectedMembers([]);
                        field.onChange([]);
                      }
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Mui.Chip
                          variant="outlined"
                          label={option?.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderOption={(props, option, { selected }) => {
                      return (
                        <li {...props} style={{ width: 800 }}>
                          <Mui.Checkbox
                            icon={<CheckBoxOutlineBlank />}
                            checkedIcon={<CheckBox />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          &nbsp;{option.name}
                        </li>
                      );
                    }}
                    componentsProps={{
                      popper: { style: { width: "fit-content" } },
                    }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select the salary group"
                        error={!!errors.salaryGroup}
                        helperText={
                          errors.salaryGroup ? errors.salaryGroup.message : ""
                        }
                      />
                    )}
                  />
                )}
              />
            </Mui.Grid>
            <Mui.Grid
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingTop: 10,
              }}
            >
              <CustomButton
                actionFuntion={() => setEditPaySchedule(false)}
                actionName="Cancel"
                typeName="button"
              />
              &nbsp;
              <CustomButton
                actionName="Update"
                typeName="submit"
                disableAction={buttonDisabled}
              />
            </Mui.Grid>
          </form>
        </Mui.Paper>
      </Mui.Grid>
    </React.Fragment>
  );
};
