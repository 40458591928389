import React from "react";
import * as Mui from "@mui/material";
import { Info } from "@mui/icons-material";
import { themes } from "services/constants";
import moment from "moment";
import dayjs from "dayjs";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useSelector } from "react-redux";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
export const RandomShift = ({
  shift_grade_id,
  shift_category_name,
  setShiftStartTime,
  shiftStartTime,
  setShiftEndTime,
  shiftEndTime,
  randomShiftError,
  shiftStartTimeFormated,
  shiftEndTimeFormated,
  setShiftStartTimeFormated,
  setShiftEndTimeFormated,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const handleStartTime = (newValue) => {
    setShiftStartTime(newValue.format("HH:mm"));
    const timeWithDate = newValue ? newValue.toDate() : new Date();
    let minTime = moment.utc(timeWithDate);
    setShiftStartTimeFormated(
      dayjs()
        .set("hour", minTime._d.getHours())
        .set("minute", minTime._d.getMinutes())
        .set("second", minTime._d.getSeconds())
    );
  };

  const handleEndTime = (newValue) => {
    setShiftEndTime(newValue.format("HH:mm"));
    const timeWithDate = newValue ? newValue.toDate() : new Date();
    let minTime = moment.utc(timeWithDate);
    setShiftEndTimeFormated(
      dayjs()
        .set("hour", minTime._d.getHours())
        .set("minute", minTime._d.getMinutes())
        .set("second", minTime._d.getSeconds())
    );
  };

  const getShiftDetails = async (time) => {
    if (time && time !== "Invalid Date") {
      try {
        const response = await fetch(
          `${domain}shift-over-time-details/?shift_category_name=${shift_category_name}&shift_grade_id=${shift_grade_id}&start_time=${time}&break_time=00&no_of_shifts=1&start_start_early=00&start_end_delay=00&end_end_early=0&end_end_delay=00&start_delay=00&end_early=00`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          res?.shifts.forEach((element) => {
            setShiftEndTime(element.end_time);
            setShiftEndTimeFormated(
              dayjs()
                .set("hour", element.end_time.split(":")[0])
                .set("minute", element.end_time.split(":")[1])
                .set("second", "00")
            );
          });
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    }
  };

  return (
    <Mui.Grid container>
      <Mui.Grid
        container
        component={Mui.Paper}
        sx={{ padding: 4 }}
        elevation={2}
      >
        <Mui.Grid container>
          <Mui.Grid xs={4}>
            <Mui.Typography
              sx={{
                paddingTop: 1,
                fontSize: 18,
                color: themes.headLine,
                fontWeight: "bold",
              }}
            >
              Shift Start Time&nbsp;
              <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                <Info />
              </Mui.Tooltip>
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
            :
          </Mui.Grid>
          <Mui.Grid xs={7}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <div sx={{ width: 4 }}>
                  <TimePicker
                    ampm={false}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    value={shiftStartTimeFormated || null}
                    slotProps={{
                      textField: {
                        size: "small",
                        width: 15,
                      },
                    }}
                    onChange={(newValue) => {
                      handleStartTime(newValue);
                      const time = newValue.format("HH:mm");
                      getShiftDetails(time);
                    }}
                  />
                </div>
              </DemoContainer>
            </LocalizationProvider>
            {(!shiftStartTime || shiftStartTime === "00:00") &&
            randomShiftError ? (
              <h5 style={{ color: themes.helperTextColor }}>
                Shift start time is required
              </h5>
            ) : null}
            {/* {randomShiftError} */}
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container>
          <Mui.Grid xs={4}>
            <Mui.Typography
              sx={{
                paddingTop: 1,
                fontSize: 18,
                color: themes.headLine,
                fontWeight: "bold",
              }}
            >
              Shift End Time&nbsp;
              <Mui.Tooltip title="The designation refers to the job title and role the user holds, which can vary across industries.            ">
                <Info />
              </Mui.Tooltip>
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
            :
          </Mui.Grid>
          <Mui.Grid xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <div sx={{ width: 4 }}>
                  <TimePicker
                    ampm={false}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    value={shiftEndTimeFormated || null}
                    slotProps={{
                      textField: {
                        size: "small",
                        width: 15,
                      },
                    }}
                    onChange={(newValue) => {
                      handleEndTime(newValue);
                    }}
                    // disabled
                  />
                </div>
              </DemoContainer>
            </LocalizationProvider>
            {(!shiftEndTime || shiftEndTime === "00:00") && randomShiftError ? (
              <h5 style={{ color: themes.helperTextColor }}>
                Shift end time is required
              </h5>
            ) : null}
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </Mui.Grid>
  );
};
