import { createSlice } from "@reduxjs/toolkit";

export const HRPortalReducer = createSlice({
  name: "hrportal",
  initialState: {
    pageno: localStorage.getItem("pageno") ? localStorage.getItem("pageno") : 1,
    userid: localStorage.getItem("hrScreenUserId")
      ? localStorage.getItem("hrScreenUserId")
      : 99999,
  },
  reducers: {
    setEditPage: (state, action) => {
      state.pageno = action.payload;
    },
    setScreenUserId: (state, action) => {
      state.userid = action.payload;
    },
  },
});

export const { setEditPage, setScreenUserId } = HRPortalReducer.actions;

export default HRPortalReducer.reducer;
