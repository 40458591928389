import React, { useState } from "react";
import * as Mui from "@mui/material";
import { showToast } from "services/utils/Status";
import { themes } from "services/constants";
import { DeductionDetails } from "./SalaryComponent/DeductionDetails";
import { salaryType } from "services/constants";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { EarningDetails } from "./SalaryComponent/EarningDetails";
import { onlyNumberRegex } from "services/constants/patterns";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { useFetch } from "services/hooks/useFetch";
import { setEditPage } from "services/Redux/HRPortal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { customAPI } from "services/utils/CustomAPI";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export const SalaryInfo = ({
  pageno,
  employeeDetails,
  setUpdateUserDetails,
  getSelectedUserDetails,
  updateUserDetails,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [updateEarningDetails, setUpdateEarningDetails] = useState(true);
  const { result: salaryGroupData } = useFetch(
    employeeDetails.company_id !== 0
      ? `${domain}salary-group/?company=${employeeDetails.company_id}`
      : null
  );
  const { result: salaryTemplate } = useFetch(
    employeeDetails.company_id !== 0
      ? `${domain}salary-templates/?company=${employeeDetails.company_id}`
      : null
  );
  const [errorHandling, setErrorHandling] = useState(false);
  const [specialAllowance, setSpecialAllowance] = useState(0);
  const [updateDeductionDetails, setUpdateDeductionDetails] = useState(true);
  // New updates
  const [salaryComponentDetails, setSalaryComponentDetails] = useState(
    employeeDetails?.deductionDataDetails
      ? employeeDetails?.deductionDataDetails
      : []
  );

  const [salaryTemplateName, setSalaryTemplateName] = useState(
    employeeDetails?.salary_template_name
      ? employeeDetails?.salary_template_name
      : ""
  );
  const [salaryTemplateId, setSalaryTemplateId] = useState(
    employeeDetails?.salary_template_id
      ? employeeDetails?.salary_template_id
      : null
  );
  const [emptyEarning, setEmptyEarning] = useState(false);
  const [emptyDeduction, setEmptyDedution] = useState(false);
  const [salaryGroupName, setSalaryGroupName] = useState(
    employeeDetails?.salary_group_name ? employeeDetails?.salary_group_name : ""
  );
  const [salaryGroupId, setSalaryGroupId] = useState(
    employeeDetails?.salary_group ? employeeDetails?.salary_group : 0
  );
  //
  const [otherSalaryComponents, setOtherSalaryComponents] = useState(
    employeeDetails?.earnings ? employeeDetails?.earnings : []
  );
  const [userDeductionDetails, setUserDeductionDetails] = useState(
    employeeDetails?.deductions ? employeeDetails?.deductions : []
  );
  const [monthlySalary, setMonthlySalary] = useState(
    employeeDetails?.monthlySalary ? employeeDetails?.monthlySalary : 0
  );
  const [salaryTypeName, setSalaryTypeName] = useState(
    employeeDetails?.salary_type_name
      ? employeeDetails?.salary_type_name
      : "Daily"
  );
  const [amount, setAmount] = useState(
    employeeDetails?.amount ? employeeDetails?.amount : 0
  );

  const selectedSalaryTemplate = (event, data) => {
    if (data) {
      setSalaryTemplateName(data?.name);
      setSalaryTemplateId(data?.id);
      setSalaryGroupName(data?.salary_group_name);
      setSalaryGroupId(data?.salary_group);
      setUserDeductionDetails(data?.deductions);
      setOtherSalaryComponents(data?.earnings);
      setAmount(0);
      setErrorHandling(false);
      setUpdateDeductionDetails(true);
    } else {
      setSalaryTemplateName(null);
      setSalaryTemplateId(null);
    }
  };

  const handleSalaryGroup = (event, data) => {
    if (data) {
      setSalaryTypeName(data?.label);
      setAmount(0);
    }
  };

  const handleAmount = (event) => {
    if (onlyNumberRegex.test(event.target.value)) {
      let salary = 0;
      setAmount(event.target.value);
      if (salaryTypeName === "Daily") {
        salary = parseInt(event.target.value) * 30;
        setMonthlySalary(salary);
      } else if (salaryTypeName === "Weekly") {
        salary = parseInt(event.target.value) * 4;
        setMonthlySalary(parseInt(event.target.value) * 4);
      } else {
        salary = parseInt(event.target.value);
        setMonthlySalary(parseInt(event.target.value));
      }
      if (otherSalaryComponents.length !== 0) {
        let updateData = otherSalaryComponents?.map((element) => {
          return {
            ...element,
            amount:
              element.name === "Special Allowance" ? (salary ? salary : 0) : 0,
          };
        });
        setOtherSalaryComponents(updateData);
      }
    }
  };

  const handleSalaryGroupName = (event, data) => {
    if (data) {
      setSalaryGroupName(data?.label);
      setSalaryGroupId(data?.id);
    }
  };

  const onSubmit = async () => {
    const zeroAmoutEarnings = otherSalaryComponents.filter((data) => {
      return data?.amount === 0 || data?.amount === "0" || data.amount === "";
    });
    const zeroAmoutDeductions = userDeductionDetails.filter((data) => {
      return (
        data.deduction_type !== "F" &&
        data.employeeContributionAmount === 0 &&
        data.companyContributionAmount === 0
      );
    });
    if (
      salaryTypeName !== "" &&
      salaryGroupName !== "" &&
      amount !== "" &&
      amount !== 0 &&
      zeroAmoutEarnings.length === 0 &&
      zeroAmoutDeductions.length === 0
    ) {
      let payload = {
        salary_template_id: salaryTemplateId,
        salary_group: salaryGroupId,
        earnings: otherSalaryComponents,
        deductions: userDeductionDetails,
        salary_type:
          salaryTypeName === "Daily"
            ? 1
            : salaryTypeName === "Weekly"
            ? 2
            : salaryTypeName === "Monthly"
            ? 3
            : 1,
        amount: amount,
        user_id: employeeDetails.user_id,
      };
      const response = await customAPI(
        `${domain}employee-onboard`,
        "PUT",
        token,
        payload
      );
      if (response.ok) {
        showToast("success", "Employee details updated successfully");
        history.push("/admin/hr/employee");
      }
    } else {
      setErrorHandling(true);
      setEmptyEarning(true);
      setEmptyDedution(true);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid
        container
        sx={{
          border: `1px solid ${themes.sidebarDivider}`,
          borderRadius: 3,
          paddingBottom: 1,
        }}
      >
        <Mui.Grid
          container
          sx={{
            borderBottom: `1px solid ${themes.sidebarDivider}`,
            padding: { xs: 0.4, sm: 0.4, md: 0.4, lg: 0.6, xl: 1, xxl: 1 },
          }}
        >
          <Mui.Typography
            sx={{
              fontSize: 20,
              fontWeight: "bold",
              color: themes.headLine,
              paddingLeft: 1,
            }}
          >
            Salary Informations
          </Mui.Typography>
        </Mui.Grid>

        <Mui.Grid
          container
          // sx={{ borderBottom: `1px solid ${themes.sidebarDivider}` }}
        >
          <Mui.Grid container>
            <Mui.Typography
              sx={{ paddingLeft: 2, fontSize: 22, fontWeight: "bold" }}
            >
              Salary Template
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Salary Template
              </Mui.Typography>
              <CustomAutoComplete
                dataList={salaryTemplate?.map((obj) => ({
                  ...obj,
                  label: obj.name,
                }))}
                selectedValue={salaryTemplateName}
                updateDataFunction={selectedSalaryTemplate}
                placeHolder="Please select the name"
                // errorMessage={
                //   salaryTemplateName === "" && errorHandling
                //     ? "Please select the salary template"
                //     : ""
                // }
                // textBoxError={
                //   salaryTemplateName === "" && errorHandling ? true : false
                // }
              />
            </Mui.Grid>
            <Mui.Grid xs={8} sx={{ padding: 1 }}></Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid
        container
        sx={{
          paddingBottom: { xs: 2, sm: 4, md: 4, lg: 5, xl: 5.5, xxl: 10 },
        }}
      />
      <Mui.Grid
        container
        sx={{
          border: `1px solid ${themes.sidebarDivider}`,
          borderRadius: 3,
        }}
      >
        <Mui.Grid
          container
          sx={{ borderBottom: `1px solid ${themes.sidebarDivider}` }}
        >
          <Mui.Typography
            sx={{
              fontSize: 20,
              fontWeight: "bold",
              color: themes.headLine,
              paddingLeft: 1,
            }}
          >
            Cost to Company
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{ borderRight: `1px solid ${themes.sidebarDivider}` }}
        >
          <Mui.Grid xs={4} sx={{ padding: 1 }}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.headLine,
                paddingBottom: 0.5,
                fontWeight: "bold",
              }}
            >
              Salary Type
            </Mui.Typography>
            <CustomAutoComplete
              dataList={salaryType}
              selectedValue={salaryTypeName}
              updateDataFunction={handleSalaryGroup}
              placeHolder="Please select the salaryType"
              errorMessage={
                errorHandling && salaryTypeName === ""
                  ? "Please select salary type"
                  : ""
              }
              textBoxError={
                errorHandling && salaryTypeName === "" ? true : false
              }
            />
          </Mui.Grid>
          <Mui.Grid xs={4} sx={{ padding: 1 }}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              {salaryTypeName}
            </Mui.Typography>
            <CustomTextBox
              selectedValue={amount}
              updateDataFunction={handleAmount}
              placeHolder="Enter the amount"
              errorMessage={
                errorHandling && (amount === "" || amount === 0)
                  ? "Please enter amount"
                  : ""
              }
              textBoxError={
                errorHandling && (amount === "" || amount === 0) ? true : false
              }
            />
          </Mui.Grid>
          <Mui.Grid xs={4} sx={{ padding: 1 }}>
            <Mui.Typography
              sx={{
                fontSize: 15,
                color: themes.colorShadow,
                paddingBottom: 0.5,
              }}
            >
              Salary group name
            </Mui.Typography>
            <CustomAutoComplete
              dataList={salaryGroupData.map((obj) => ({
                ...obj,
                label: obj.name,
              }))}
              selectedValue={salaryGroupName}
              updateDataFunction={handleSalaryGroupName}
              placeHolder="Please select the salary group"
              errorMessage={
                errorHandling && salaryGroupName === ""
                  ? "Please select salary group"
                  : ""
              }
              textBoxError={
                errorHandling && salaryGroupName === "" ? true : false
              }
            />
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid
        container
        sx={{
          paddingBottom: { xs: 2, sm: 4, md: 4, lg: 5, xl: 5.5, xxl: 10 },
        }}
      />
      <EarningDetails
        otherSalaryComponents={otherSalaryComponents}
        salaryComponentDetails={salaryComponentDetails}
        setOtherSalaryComponents={setOtherSalaryComponents}
        existingUserDetails={employeeDetails}
        setSalaryComponentDetails={setSalaryComponentDetails}
        monthlySalary={monthlySalary}
        setUpdateDeductionDetails={setUpdateDeductionDetails}
        setSpecialAllowance={setSpecialAllowance}
        specialAllowance={specialAllowance}
        setUpdateEarningDetails={setUpdateEarningDetails}
        updateEarningDetails={updateEarningDetails}
        emptyEarning={emptyEarning}
        salaryTemplateId={salaryTemplateId}
      />
      <DeductionDetails
        otherSalaryComponents={otherSalaryComponents}
        existingUserDetails={employeeDetails}
        userDeductionDetails={userDeductionDetails}
        setUserDeductionDetails={setUserDeductionDetails}
        setUpdateDeductionDetails={setUpdateDeductionDetails}
        updateDeductionDetails={updateDeductionDetails}
        emptyDeduction={emptyDeduction}
        salaryTemplateId={salaryTemplateId}
      />
      <Mui.Grid container sx={{ padding: 2 }}>
        <Mui.Grid xs={2}>
          <CustomButton
            actionFuntion={() => {
              dispatch(setEditPage(2));
            }}
            actionName="Previous"
            typeName="button"
          />
        </Mui.Grid>
        <Mui.Grid xs={8}></Mui.Grid>
        <Mui.Grid
          xs={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <CustomButton
            actionName="Update"
            typeName="button"
            actionFuntion={() => {
              onSubmit();
            }}
          />
        </Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
};
