import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { dateFormat } from "services/constants";
import { TaskContext } from "../TaskContext";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { showToast } from "services/utils/Status";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import moment from "moment";
import { useForm } from "react-hook-form";
import { MoreVert } from "@mui/icons-material";
import { projectError } from "services/constants/ErrorMessages";
import { projectPlaceholder } from "services/constants/PlaceHolder";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { projectSuccess } from "services/constants/SuccessMessages";
import { useFetch } from "services/hooks/useFetch";
import sessionHandling from "services/utils/notificationUtils";

dayjs.extend(utc);
dayjs.extend(timezone);

const drpdwnOption = ["Edit", "Delete"];

export const TaskTimeDetails = ({
  selectedTaskid,
  selectedTask,
  fetchTask,
}) => {
  const { domain, token, allUsers, editProject } = useContext(TaskContext);
  const [createNewTime, setCreateNewTime] = useState(false);
  const [userName, setUserName] = useState("");
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [spr_startDate, setSprStartDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [spr_endDate, setSprEndDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [userId, setUserId] = useState(0);
  const [startTimeValidationError, setStartTimeValidationError] = useState("");
  const [endTimeValidationError, setEndTimeValidationError] = useState("");
  const [overallTimeValidation, setOverallTimeValidations] = useState("");
  const [priProjMembers, setPriProjMembers] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [editTimeDetails, setEditTimeDetails] = useState([]);
  const [delExtTime, setDelExtTime] = useState(false);
  const [optName, setOptName] = useState("");
  const [isDisAddTime, setIsDisAddTime] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onBlur",
  });
  let userDetails = useSelector((state) => state.tokenReducer.userDetails);

  const filterCommonValues = (arr1, arr2) => {
    const valuesInArr2 = arr2.map((item) => item["username"]);
    return arr1.filter((item) => valuesInArr2.includes(item["username"]));
  };

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const spr_startDate =
      selectedTask?.sprint?.start_date !== null
        ? new Date(formatDate(selectedTask?.sprint?.start_date))
            .toISOString()
            .substring(0, 10)
        : new Date().toISOString().substring(0, 10);
    const spr_endDate =
      selectedTask?.sprint?.start_date !== null
        ? new Date(formatDate(selectedTask?.sprint?.end_date))
            .toISOString()
            .substring(0, 10)
        : new Date().toISOString().substring(0, 10);
    setSprStartDate(spr_startDate);
    setSprEndDate(spr_endDate);
  }, [selectedTask]);

  useEffect(() => {
    if (editProject?.projectType === "Private") {
      const filteredMembers = filterCommonValues(
        allUsers,
        editProject?.members
      );
      setPriProjMembers(filteredMembers);
    }
  }, [editProject?.members, allUsers, editProject?.projectType]);

  const { result: taskTimeDetails, setLoad: setFetchTaskTime } = useFetch(
    `${domain}task_time_entry/?task=${selectedTaskid}`
  );

  const handleCloseDialogBox = () => {
    setCreateNewTime(false);
    reset();
    setEndTime(null);
    setStartTime(null);
    setUserName("");
    setUserId(null);
    setOverallTimeValidations("");
    setStartTimeValidationError("");
    setEndTimeValidationError("");
    setDate(new Date().toISOString().substring(0, 10));
    setOptName("");
  };

  const onSubmit = async (data) => {
    if (startTime && endTime) {
      const startTimeMinute = getTwentyFourHourTime(
        moment(startTime["$d"]).format("hh:mm A")
      );
      const endTimeMinute = getTwentyFourHourTime(
        moment(endTime["$d"]).format("hh:mm A")
      );
      if (startTimeMinute < endTimeMinute) {
        setIsDisAddTime(true);
        const apiActionMethod = optName === "Edit" ? "PUT" : "POST";
        const apiUrl =
          optName === "Edit"
            ? `${domain}task_time_entry/${editTimeDetails?.id}/`
            : `${domain}task_time_entry/`;

        try {
          const response = await fetch(apiUrl, {
            method: apiActionMethod,
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              date: data.stardate,
              start_time: new Date(startTime["$d"])
                .toTimeString()
                .split(" ")[0],
              end_time: new Date(endTime["$d"]).toTimeString().split(" ")[0],
              status: "paused",
              task: selectedTaskid,
              user: userId,
              task_status:
                selectedTask?.work_flow_status?.name === "ToDo" ||
                selectedTask?.work_flow_status?.name === "Completed"
                  ? "InProgress"
                  : selectedTask?.work_flow_status?.name,
            }),
          });
          const res = await response.json();
          if (response.ok) {
            if (optName === "Edit") {
              showToast("success", projectSuccess.updateTime);
            } else {
              showToast("success", projectSuccess.createTime);
            }
            setFetchTaskTime(true);
            handleCloseDialogBox();
            fetchTask();
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        } finally {
          setIsDisAddTime(false);
        }
      } else {
        setOverallTimeValidations(projectError.endTime);
      }
    } else {
      if (startTime === null)
        setStartTimeValidationError(projectError.invalidStartTime);
      if (endTime === null)
        setEndTimeValidationError(projectError.invalidEndTime);
    }
  };

  const handleStartTimeChange = (time) => {
    if (time["$d"].toString() !== "Invalid Date") {
      setStartTime(time);
      setStartTimeValidationError("");
      calculateTimeDifference();
    } else {
      setStartTimeValidationError(projectError.invalidStartTime);
    }
  };

  const handleEndTimeChange = (time) => {
    if (time["$d"].toString() !== "Invalid Date") {
      setEndTime(time);
      setEndTimeValidationError("");
      calculateTimeDifference();
    } else {
      setEndTimeValidationError(projectError.invalidEndTime);
    }
  };

  const getTwentyFourHourTime = (time) => {
    if (time) {
      let hours = Number(time.match(/^(\d+)/)[1]);
      let minutes = Number(time.match(/:(\d+)/)[1]);
      let AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM === "PM" && hours < 12) hours = hours + 12;
      if (AMPM === "AM" && hours === 12) hours = hours - 12;
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      return parseInt(sHours) * 60 + parseInt(sMinutes);
    }
  };

  const getMinutes = (time) => {
    if (time) {
      let hours = Number(time.match(/^(\d+)/)[1]);
      let minutes = Number(time.match(/:(\d+)/)[1]);
      let AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM === "PM" && hours < 12) hours = hours + 12;
      if (AMPM === "AM" && hours === 12) hours = hours - 12;
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      return parseInt(sHours) * 60 + parseInt(sMinutes);
    }
  };

  const calculateTimeDifference = () => {
    if (startTime && endTime) {
      const startTimeMin = getMinutes(
        moment(startTime["$d"]).format("hh:mm A")
      );
      const endTimeMin = getMinutes(moment(endTime["$d"]).format("hh:mm A"));
      if (endTimeMin - startTimeMin > 0) {
        setStartTimeValidationError("");
        const hours = Math.floor((endTimeMin - startTimeMin) / 60);
        const minutes = (endTimeMin - startTimeMin) % 60;
        const total = hours + ":" + minutes;
        console.log(total);
      } else {
        setStartTimeValidationError(projectError.startTime);
      }
    }
  };

  const handleMenuOpen = (event, data) => {
    setActionAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setActionAnchorEl(null);
  };

  const handleDelCloseDialogBox = () => {
    setFetchTaskTime(true);
    setDelExtTime(false);
    reset();
    setEditTimeDetails([]);
    setEndTime(null);
    setStartTime(null);
    setUserName("");
    setUserId(null);
    setOverallTimeValidations("");
    setStartTimeValidationError("");
    setEndTimeValidationError("");
    setDate(new Date().toISOString().substring(0, 10));
    setOptName("");
  };

  const handleDeleteTime = async () => {
    try {
      const response = await fetch(
        `${domain}task_time_entry/${editTimeDetails?.id}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            start_time: editTimeDetails?.start_time
              ?.split("T")[1]
              .split("Z")[0],
            task: selectedTaskid,
            isdeleted: true,
            task_status:
              selectedTask?.work_flow_status?.name === "ToDo" ||
              selectedTask?.work_flow_status?.name === "Completed"
                ? "InProgress"
                : selectedTask?.work_flow_status?.name,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        showToast("success", projectSuccess.deleteTime);
        handleDelCloseDialogBox();
        setFetchTaskTime(true);
        fetchTask();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const convertToIST = (utcDateString) =>
    dayjs.utc(utcDateString).tz("Asia/Kolkata");

  const updateEditDetails = (data) => {
    setUserName(data?.user?.username);
    setValue("username", data?.user?.username);
    const istDate = convertToIST(data?.start_time).format("YYYY-MM-DD");
    setDate(istDate);
    setStartTime(dayjs.utc(data?.start_time).tz("Asia/Kolkata"));
    setEndTime(dayjs.utc(data?.end_time).tz("Asia/Kolkata"));
    setUserId(data?.user?.id);
  };

  return (
    <React.Fragment>
      {userDetails.role === "Team Lead" || userDetails.role === "Admin" ? (
        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <CustomButton
              actionFuntion={() => {
                reset();
                setCreateNewTime(true);
              }}
              actionName="Add Time"
              typeName="button"
            />
          </Mui.Grid>
        </Mui.Grid>
      ) : null}
      <Mui.TableContainer
        sx={{
          mt: 2,
          width: "100%",
          paddingLeft: 2,
          paddingRight: 1,
        }}
      >
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
            }}
          >
            <Mui.TableRow maxWidth="xl" align="left">
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                User Name
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Date
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Start Time
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                End Time
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Time
              </Mui.TableCell>
              <Mui.TableCell
                sx={{
                  color: themes.blackColor,
                  fontWeight: "bold",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                }}
              >
                Action
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {taskTimeDetails.map((data, index) => (
              <Mui.TableRow key={data.id} hover={true}>
                <>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: 100,
                    }}
                  >
                    <Mui.Stack direction="row">
                      <Mui.Avatar
                        sx={{ width: 25, height: 25 }}
                        alt={data?.user?.username.charAt(0).toUpperCase()}
                        src={data?.user?.image_url}
                      />
                      <Mui.Typography
                        sx={{
                          paddingLeft: 1,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {data.user?.username ? data?.user.username : "N/A"}
                      </Mui.Typography>
                    </Mui.Stack>
                  </Mui.TableCell>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: 100,
                      textOverflow: "ellipsis",
                    }}
                  >
                    {moment(data.start_time).format(dateFormat)}
                  </Mui.TableCell>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: 100,
                      textOverflow: "ellipsis",
                    }}
                  >
                    {data.start_time_formatted}
                  </Mui.TableCell>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: 100,
                      textOverflow: "ellipsis",
                    }}
                  >
                    {data.end_time_formatted}
                  </Mui.TableCell>
                  <Mui.TableCell
                    align="left"
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: 100,
                      textOverflow: "ellipsis",
                    }}
                  >
                    {data.duration_formatted}
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: 100,
                    }}
                  >
                    <Mui.IconButton
                      onClick={(e) => {
                        handleMenuOpen(e);
                        setEditTimeDetails(data);
                        updateEditDetails(data);
                      }}
                      disabled={
                        userDetails.role === "Team Lead" ||
                        userDetails.role === "Admin"
                          ? false
                          : true
                      }
                    >
                      <MoreVert
                        sx={{ color: themes.blackColor, strokeWidth: 2 }}
                      />
                    </Mui.IconButton>
                    <Mui.Menu
                      anchorEl={actionAnchorEl}
                      open={Boolean(actionAnchorEl)}
                      onClose={handleMenuClose}
                      elevation={1}
                      transformOrigin={{
                        horizontal: "right",
                        vertical: "top",
                      }}
                      anchorOrigin={{
                        horizontal: "right",
                        vertical: "bottom",
                      }}
                    >
                      {drpdwnOption.map((option) => (
                        <Mui.Stack>
                          <Mui.MenuItem
                            key={option}
                            onClick={() => {
                              if (option === "Delete") {
                                setDelExtTime(true);
                                setActionAnchorEl(null);
                              } else {
                                setOptName("Edit");
                                setCreateNewTime(true);
                                setActionAnchorEl(null);
                              }
                            }}
                          >
                            {option}
                          </Mui.MenuItem>
                        </Mui.Stack>
                      ))}
                    </Mui.Menu>
                  </Mui.TableCell>
                </>
              </Mui.TableRow>
            ))}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>
      <GlobalDialogBox
        handleCloseDialog={handleDelCloseDialogBox}
        open={delExtTime}
        title="Delete User Time"
      >
        <Mui.Grid xs={12} sx={{ paddingBottom: 3 }}>
          <Mui.Typography sx={{ fontSize: 24 }}>
            Do you want to delete the time?
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={handleDelCloseDialogBox}
              actionName="No"
              typeName="button"
            />{" "}
            &nbsp;
            <CustomButton
              actionFuntion={handleDeleteTime}
              actionName="Yes"
              typeName="button"
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={createNewTime}
        title={optName !== "Edit" ? "Add User Time" : "Edit User Time"}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Mui.Grid container>
            <Mui.Grid xs={12}>
              <Mui.Typography sx={{ paddingLeft: 1 }}>User Name</Mui.Typography>
              <Mui.Autocomplete
                disablePortal
                id="combo-box-demo"
                fullWidth
                value={userName}
                options={
                  editProject?.projectType === "Private"
                    ? priProjMembers
                    : allUsers
                }
                filterOptions={(options, state) => {
                  return options.filter((option) =>
                    option.username
                      ?.toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  );
                }}
                ListboxProps={{
                  style: {
                    maxHeight: "150px",
                  },
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props}>
                      <Mui.Avatar
                        sx={{ width: 25, height: 25 }}
                        alt={option.username.charAt(0).toUpperCase()}
                        src={option.image_url}
                      />{" "}
                      &nbsp;{option.username}
                    </li>
                  );
                }}
                onChange={(event, value) => {
                  if (value) {
                    setUserName(value.username);
                    setUserId(value.id);
                  } else {
                    setUserName(null);
                    setUserId(null);
                  }
                }}
                sx={{ minWidth: "100%" }}
                renderInput={(params) => (
                  <Mui.TextField
                    {...params}
                    {...register("username", {
                      required: "User name is required",
                    })}
                    value={userName}
                    size="small"
                    sx={{ background: themes.whiteColor, padding: 1 }}
                    name="username"
                    placeholder={projectPlaceholder.selectUser}
                    error={Boolean(errors.username)}
                    helperText={errors.username?.message || ""}
                  />
                )}
              />
            </Mui.Grid>
            <Mui.Grid xs={12}>
              <Mui.Typography sx={{ paddingLeft: 1 }}>Date</Mui.Typography>
              <Mui.TextField
                {...register("stardate", {
                  required: "Start date is required",
                  validate: {
                    noDigitsOrSpecial: (fieldValue) => {
                      if (new Date(date) < new Date(spr_startDate)) {
                        return "Date is lesser then sprint start date";
                      }
                      if (new Date(date) > new Date(spr_endDate)) {
                        return "Date is greater then sprint end date";
                      }
                      return true;
                    },
                  },
                })}
                id="from-date"
                type="date"
                sx={{ background: themes.whiteColor, padding: 1 }}
                value={date}
                size="small"
                onChange={(event) => setDate(event.target.value)}
                error={errors.stardate ? true : false}
                helperText={errors.stardate ? errors.stardate.message : ""}
                fullWidth
                inputProps={{
                  max: "9999-12-31",
                }}
              />
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid xs={6}>
                <Mui.Typography sx={{ paddingLeft: 1 }}>
                  Start Time
                </Mui.Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      value={startTime}
                      onChange={handleStartTimeChange}
                      size="small"
                      renderInput={(params) => (
                        <Mui.TextField
                          size="small"
                          {...params}
                          onBlur={calculateTimeDifference}
                        />
                      )}
                      sx={{ paddingLeft: 1 }}
                      slotProps={{
                        textField: { size: "small", width: 40 },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {startTimeValidationError === "Please enter the start time" ? (
                  <Mui.Typography
                    sx={{
                      color: themes.helperTextColor,
                      paddingLeft: 2.5,
                      paddingTop: "2px",
                      fontWeight: "small",
                      fontSize: 12,
                    }}
                  >
                    {startTimeValidationError}
                  </Mui.Typography>
                ) : null}
                {overallTimeValidation === projectError.startTime ? (
                  <Mui.Typography
                    sx={{
                      color: themes.helperTextColor,
                      paddingLeft: 2.5,
                      paddingTop: "2px",
                      fontWeight: "small",
                      fontSize: 12,
                    }}
                  >
                    {overallTimeValidation}
                  </Mui.Typography>
                ) : null}
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <Mui.Typography sx={{ paddingLeft: 2 }}>
                  End Time
                </Mui.Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      value={endTime}
                      onChange={handleEndTimeChange}
                      onBlur={calculateTimeDifference}
                      renderInput={(params) => (
                        <Mui.TextField size="small" {...params} />
                      )}
                      sx={{
                        paddingLeft: 2,
                      }}
                      slotProps={{
                        textField: { size: "small", width: 40 },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {endTimeValidationError === "Please enter the end time" ? (
                  <Mui.Typography
                    sx={{
                      color: themes.helperTextColor,
                      paddingLeft: 3.5,
                      paddingTop: "2px",
                      fontWeight: "small",
                      fontSize: 12,
                    }}
                  >
                    {endTimeValidationError}
                  </Mui.Typography>
                ) : null}
                {overallTimeValidation === projectError.endTime ? (
                  <Mui.Typography
                    sx={{
                      color: themes.helperTextColor,
                      paddingLeft: 3.5,
                      paddingTop: "2px",
                      fontWeight: "small",
                      fontSize: 12,
                    }}
                  >
                    {overallTimeValidation}
                  </Mui.Typography>
                ) : null}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ mt: 3 }}>
              <Mui.Grid xs={5}></Mui.Grid>
              <Mui.Grid
                xs={7}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 1,
                }}
              >
                <CustomButton
                  actionFuntion={handleCloseDialogBox}
                  actionName="Cancel"
                  typeName="button"
                />
                &nbsp;&nbsp;
                <CustomButton
                  actionName={optName !== "Edit" ? "Add" : "Update"}
                  typeName="submit"
                  disableAction={isDisAddTime}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </form>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
