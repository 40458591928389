import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";
import { loanHistory } from "../Headers";
const keys = [
  "applied_date",
  "department_name",
  "user_name",
  "principal_amount",
  "tenure_months",
  "interest_rate",
  "monthly_installment",
  "loan_type",
  "interest_type",
  "status",
];

export const ActiveLoans = ({setViewLoanDetails, setViewLoan}) => {
  const { domain, globalSearchValue, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [loanData, setLoanData] = useState([]);
  const [updateData, setUpdateData] = useState(true);

  const { result: loanDetails, loading } = useFetch(
    `${domain}active-loans/?selected_user_id=${userDetails.id}`
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
  };

  useEffect(() => {
    if (updateData && loanDetails?.length !== 0 && loanDetails) {
      setLoanData(loanDetails);
      setUpdateData(false);
    }
  }, [loanDetails, updateData]);

  useEffect(() => {
    const filteredResults = loanDetails?.filter((item) =>
      keys.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setLoanData(filteredResults);
    setPage(0);
  }, [globalSearchValue, loanDetails]);

  return loading === true ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.Grid container sx={{padding:1}}>
        {loanData?.length > 0 ? (
          <Mui.TableContainer
            sx={{
              marginTop: 2,
              marginLeft: "auto",
              marginRight: "auto",
              overflow: "auto",
            }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                }}
              >
                <Mui.TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      height: "15px",
                      padding: "6px 35px",
                    },
                  }}
                >
                  {loanHistory.map((head) => (
                    <Mui.TableCell>
                      <Mui.Typography fontWeight="bold">
                        {head.name}
                      </Mui.Typography>
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {loanData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((item) => (
                    <Mui.TableRow
                      sx={{
                        "& .MuiTableCell-root": {
                          height: "15px",
                          padding: "6px 35px",
                        },
                      }}
                    >
                      {loanHistory.map((head) =>
                        head.name !== "Action" ? (
                          <Mui.TableCell>
                            {item[head.key] ? item[head.key] : "N/A"}
                          </Mui.TableCell>
                        ) : (
                          <Mui.TableCell>
                            <Mui.IconButton
                              size="small"
                              onClick={() => {
                                setViewLoanDetails(item);
                                setViewLoan(true);
                              }}
                            >
                              <RemoveRedEyeIcon size="small" />
                            </Mui.IconButton>
                          </Mui.TableCell>
                        )
                      )}
                    </Mui.TableRow>
                  ))}
              </Mui.TableBody>
            </Mui.Table>
            {loanData?.length > 10 && (
              <Mui.TablePagination
                className="custom-pagination"
                rowsPerPageOptions={[10, 20, 50, 100]}
                component="div"
                count={loanData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Mui.TableContainer>
        ) : (
          <Mui.Alert severity="info" sx={{ margin: 2 }}>
            There is no data available
          </Mui.Alert>
        )}
      </Mui.Grid>
    </React.Fragment>
  );
};
