import React, { useEffect, createContext, useState } from "react";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { defaultAllSelection } from "services/constants";

export const ProjectReportContext = createContext({});

export const ProRepContextProvider = ({ children }) => {
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );

  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const response = await fetch(`${domain}user_with_avatar/ `, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const AllUsersData = res.map((obj) => ({
            ...obj,
            label: obj.username,
          }));
          const allUsername = {
            label: "All",
            user_id: defaultAllSelection,
            user_name: "All",
          };
          setAllUsers([allUsername, ...AllUsersData]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchAllUsers();
  }, [domain, token]);

  return (
    <ProjectReportContext.Provider
      value={{
        token,
        domain,
        allUsers,
        userDetails,
      }}
    >
      {children}
    </ProjectReportContext.Provider>
  );
};
