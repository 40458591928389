import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { useFetch } from "services/hooks/useFetch";
import { customAPI } from "services/utils/CustomAPI";
import { Delete } from "@mui/icons-material";
import { rgxWithDot } from "services/constants/patterns";
import { showToast } from "services/utils/Status";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { defaultAllSelection } from "services/constants";
let id = 0;
export const AddGroupUnit = ({
  setShowTopBar,
  companyId,
  setGroupUnit,
  reCallApi,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm();

  const { domain, token } = useSelector((state) => state.tokenReducer);
  const { result: department } = useFetch(`${domain}department/`);
  const { result: workunitname, setLoad: recallWorkUnitApi } = useFetch(
    `${domain}work_units/?department=${
      getValues("department") ? getValues("department") : defaultAllSelection
    }`
  );
  const [workUnitGroupName, setWorkUnitGroupName] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [workUnit, setWorkUnit] = useState(0);
  const [departmentName, setDepartmentName] = useState("");
  const [workUnitName, setWorkUnitName] = useState("");
  const [addedUnitDetails, setAddedUnitDetails] = useState([]);
  const [workUnitUpdate, setWorkUnitUpdate] = useState(true);
  const [workUnitDetails, setWorkUnitDetails] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    if (workUnitUpdate && workunitname.length !== 0) {
      setWorkUnitDetails(workunitname);
      setWorkUnitUpdate(false);
    } else {
      setWorkUnitDetails(workunitname);
    }
  }, [workUnitUpdate, workunitname]);

  const onSubmit = async (data) => {
    if (data) {
      let payload = { ...data, items: addedUnitDetails };
      setButtonDisabled(true);
      const response = await customAPI(
        `${domain}work_unit_groups/`,
        "POST",
        token,
        payload
      );
      if (response.ok) {
        showToast("success", "Work unit group created successfully");
        setShowTopBar(true);
        setGroupUnit(false);
        reCallApi(true);
        reset();
      } else {
        setButtonDisabled(false);
      }
    }
  };

  const updateWorkUnitGroupName = (event) => {
    if (event.target.value.length <= 64) {
      setValue("name", event.target.value);
      setWorkUnitGroupName(event.target.value);
    }
  };

  const numberOfWorkUnit = (event) => {
    if (rgxWithDot.test(event.target.value) && event.target.value.length <= 4) {
      setWorkUnit(event.target.value);
      setValue("workunit", event.target.value);
    }
  };

  const handleDepartment = (event, data) => {
    if (data) {
      setValue("departmentName", data?.label);
      setValue("department", data?.id);
      setDepartmentName(data?.label);
      recallWorkUnitApi(true);
      setTimeout(function () {
        setWorkUnitUpdate(true);
      }, 700);
    } else {
      setValue("departmentName", "");
      setValue("department", null);
      setDepartmentName("");
    }
  };

  const hadleWorkunit = (event, data) => {
    if (data) {
      setValue("work_unit_name", data?.label);
      setValue("id", data?.id);
      setWorkUnitName(data?.label);
    } else {
      setValue("work_unit_name", "");
      setValue("id", null);
      setWorkUnitName("");
    }
  };
  const validateStartDate = (value) => {
    const currentDate = new Date().toISOString().split("T")[0];
    if (!value) {
      return "Date is required.";
    } else if (value < currentDate) {
      return "Date cannot be in the past.";
    }
  };

  const addNewRow = () => {
    setError("");
    if (
      getValues("work_unit_name") !== "" &&
      getValues("workunit") !== "" &&
      getValues("workunit") &&
      getValues("work_unit_name")
    ) {
      const newRow = {
        id: id + 1,
        work_unit_name: getValues("work_unit_name"),
        number_of_units: getValues("workunit"),
        work_unit: getValues("id"),
      };
      id = id + 1;
      setAddedUnitDetails([...addedUnitDetails, newRow]);
      removeAddedWorkUnit([...addedUnitDetails, newRow]);
      setValue("work_unit_name", "");
      setValue("workunit", "");
      setWorkUnitName("");
      setWorkUnit("");
    } else {
      setError("Please enter value");
    }
  };

  const removeAddedWorkUnit = (addedData) => {
    let data = workunitname;
    addedData.forEach((element) => {
      data = data.filter((details) => {
        return element.work_unit_name !== details.name;
      });
    });
    setWorkUnitDetails(data);
  };

  const removeCurrentWorkUnit = (unitDetails) => {
    let remaining = addedUnitDetails.filter((data) => {
      return data.work_unit_name !== unitDetails.work_unit_name;
    });
    setAddedUnitDetails(remaining);
    removeAddedWorkUnit(remaining);
  };

  return (
    <React.Fragment>
      <Mui.Grid container>
        <BackArrow
          actionFunction={() => {
            setShowTopBar(true);
            setGroupUnit(false);
          }}
        />
        <Mui.Typography
          sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 1 }}
        >
          Work Unit / Add WorkUnit Group
        </Mui.Typography>
      </Mui.Grid>
      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 20 }}>
        <Mui.Grid
          container
          sx={{ paddingLeft: 40, paddingRight: 40, paddingTop: 10 }}
        >
          <Mui.Grid
            container
            component={Mui.Paper}
            sx={{ padding: 3, borderRadius: 3 }}
            elevation={5}
          >
            <Mui.Grid container sx={{ padding: 2 }}>
              <Mui.Grid container fullWidth sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.headLine,
                    paddingBottom: 0.5,
                    fontWeight: "bold",
                  }}
                >
                  Department
                </Mui.Typography>
                <CustomAutoComplete
                  dataList={department.map((obj) => ({
                    ...obj,
                    label: obj.department,
                  }))}
                  selectedValue={departmentName}
                  rules={{
                    ...register("departmentName", {
                      required: "Department name is required",
                    }),
                  }}
                  updateDataFunction={handleDepartment}
                  placeHolder="Please select the department name"
                  errorMessage={
                    errors?.departmentName?.message
                      ? errors?.departmentName?.message
                      : ""
                  }
                  textBoxError={errors?.departmentName ? true : false}
                />
              </Mui.Grid>
              <Mui.Grid container fullWidth sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.headLine,
                    paddingBottom: 0.5,
                    fontWeight: "bold",
                  }}
                >
                  WorkUnit Group Name
                </Mui.Typography>
                <CustomTextBox
                  selectedValue={workUnitGroupName}
                  rules={{
                    ...register("name", {
                      required: "Workunit group name is required",
                      validate: {
                        atleastOneCharacterWithSpaceReg: (value) => {
                          if (
                            atleastOneCharacterWithSpace.test(value) ||
                            value === ""
                          ) {
                            return true;
                          } else {
                            return "Atleast one alphabet required";
                          }
                        },
                        employeePhonenumberAvailable: async (fieldValue) => {
                          if (fieldValue !== "") {
                            const response = await fetch(
                              `${domain}unique/work-unit-group/?name=${fieldValue.trim()}`,
                              {
                                method: "GET",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: `token ${token}`,
                                },
                              }
                            );
                            const resp = await response.json();
                            if (resp.exists) {
                              return "Workunit group name already exists";
                            }
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={updateWorkUnitGroupName}
                  placeHolder="Please enter workunit group name"
                  errorMessage={
                    errors?.name?.message ? errors?.name?.message : ""
                  }
                  textBoxError={errors.name ? true : false}
                />
              </Mui.Grid>

              <Mui.Grid
                container
                fullWidth
                xs={12}
                sx={{ padding: 1 }}
                spacing={3}
              >
                <Mui.Grid item xs={5.6}>
                  <Mui.Typography
                    sx={{
                      fontSize: 15,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    WorkUnit Name
                  </Mui.Typography>
                  <CustomAutoComplete
                    dataList={workUnitDetails.map((obj) => ({
                      ...obj,
                      label: obj.name,
                    }))}
                    selectedValue={workUnitName}
                    rules={{
                      ...register("work_unit_name", {
                        required: false,
                        validate: {
                          validateWork: async (fieldValue) => {
                            if (fieldValue) {
                              return "Before submit please add the work unit name";
                            } else {
                              return true;
                            }
                          },
                        },
                      }),
                    }}
                    updateDataFunction={hadleWorkunit}
                    placeHolder="Please select the work name"
                    errorMessage={
                      errors?.work_unit_name?.message
                        ? errors?.work_unit_name?.message
                        : ""
                    }
                    textBoxError={errors?.work_unit_name ? true : false}
                  />
                </Mui.Grid>

                <Mui.Grid item xs={5.5}>
                  <Mui.Typography
                    sx={{
                      fontSize: 15,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Number of WorkUnit
                  </Mui.Typography>
                  <>
                    <CustomTextBox
                      selectedValue={workUnit}
                      rules={{
                        ...register("workunit", {
                          required: false,
                          validate: {
                            validateWork: async (fieldValue) => {
                              if (fieldValue) {
                                return "Before submit please add the work unit";
                              } else {
                                return true;
                              }
                            },
                          },
                        }),
                      }}
                      updateDataFunction={numberOfWorkUnit}
                      placeHolder="Please enter number of workunit"
                      errorMessage={
                        errors?.workunit?.message
                          ? errors?.workunit?.message
                          : ""
                      }
                      textBoxError={errors.workunit ? true : false}
                    />
                    {error === "Please enter value" ? (
                      <Mui.Typography
                        variant="body2"
                        color="error"
                        sx={{ fontSize: 13, paddingLeft: 0.5 }}
                      >
                        Please enter value
                      </Mui.Typography>
                    ) : null}
                  </>
                </Mui.Grid>
                <Mui.Grid item xs={0.5} sx={{ marginTop: 3.5 }}>
                  <CustomButton
                    actionFuntion={() => addNewRow()}
                    actionName="Add"
                    typeName="button"
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container sx={{ padding: 1 }}>
                {addedUnitDetails.map((unit, index) => (
                  <Mui.Grid
                    container
                    sx={{
                      border: `1px solid ${themes.sidebarDivider}`,
                    }}
                  >
                    <Mui.Grid xs={4}>
                      <Mui.Typography
                        sx={{ textAlign: "center", paddingTop: 1 }}
                      >
                        {unit.work_unit_name}
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={4}>
                      <Mui.Typography
                        sx={{ textAlign: "center", paddingTop: 1 }}
                      >
                        {unit.number_of_units}
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={4}>
                      <Mui.IconButton
                        onClick={() => removeCurrentWorkUnit(unit)}
                      >
                        <Delete />
                      </Mui.IconButton>
                    </Mui.Grid>
                  </Mui.Grid>
                ))}
              </Mui.Grid>

              <Mui.Grid container fullWidth sx={{ padding: 1 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 15,
                    color: themes.headLine,
                    paddingBottom: 0.5,
                    fontWeight: "bold",
                  }}
                >
                  Effective From
                </Mui.Typography>
                <Mui.TextField
                  id="from-date"
                  type="date"
                  size="small"
                  {...register("effective_from", {
                    required: "Date is required.",
                    validate: validateStartDate,
                  })}
                  error={Boolean(errors.effective_from)}
                  helperText={
                    errors.effective_from?.message &&
                    errors.effective_from.message
                  }
                  InputProps={{
                    inputProps: {
                      min: new Date().toISOString().split("T")[0],
                    },
                  }}
                  onBlur={() => trigger("effective_from")}
                  fullWidth
                  disableOpenPicker
                />

                <Mui.Grid
                  xs={12}
                  sx={{
                    paddingTop: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <CustomButton
                    actionFuntion={() => {
                      setGroupUnit(false);
                    }}
                    actionName="Cancel"
                    typeName="button"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                  <CustomButton
                    actionName="Add"
                    typeName="submit"
                    disableAction={buttonDisabled}
                  />
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
