import React, { useEffect, useState, useContext, useCallback } from "react";
import { taskPriority } from "services/constants";
import { showToast } from "services/utils/Status";
import * as Mui from "@mui/material";
import { ChangeAssignee } from "../ChangeAssignee";
import "./Status.css";
import styled from "styled-components";
import { History } from "./History";
import { Comments } from "./Comments";
import { Materials } from "./Materials";
import { projectPlaceholder } from "services/constants/PlaceHolder";
import { themes } from "services/constants";
import { projectSuccess } from "services/constants/SuccessMessages";
import { CreateChildTask } from "./CreateChildTask";
import { TaskContext } from "../TaskContext";
import { TaskTimeDetails } from "./TaskTimeDetails";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import moment from "moment";
import { projectError } from "services/constants/ErrorMessages";
import PropTypes from "prop-types";
import { Loader } from "components/Loader";
import { CustomButton } from "components/CustomComponents/CustomButton";
import LoadingButton from "@mui/lab/LoadingButton";
import sessionHandling from "services/utils/notificationUtils";
import {
  AddCircle,
  PauseCircle,
  PlayArrow,
  ModeEdit,
  Add,
  Close,
  KeyboardArrowDown,
  StopCircle,
  OpenInFullRounded,
  CloseFullscreenRounded,
} from "@mui/icons-material";
import { getContrastingTextColor } from "services/utils/colorChecker";

const BootstrapDialog = Mui.styled(Mui.Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    borderRadius: "20px",
  },
}));

function FuncDialogTitle(props) {
  const { children, onClose, title, ...other } = props;

  return (
    <Mui.DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <Mui.IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close sx={{ color: themes.blackColor }} />
        </Mui.IconButton>
      ) : null}
    </Mui.DialogTitle>
  );
}

FuncDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const TaskDetails = ({
  selectedTask,
  setSelectedTask,
  selectedTaskid,
  setSelectedTaskid,
  setActiveTap,
  currentStatus,
}) => {
  const {
    projectDetails,
    openTaskDetails,
    domain,
    token,
    setOpenTaskDetails,
    setFetchTask,
    setFetchMytask,
    allUsers,
    taskLabels,
    workflowStatus,
    setLabelsApi,
    userDetails,
    isActive,
    setIsActive,
    taskStartTime,
    setTaskStartTime,
    currentPlayTaskId,
    setCurrentPlayTaskId,
    editProject,
  } = useContext(TaskContext);
  const [durationTime, setDuration] = useState(null);
  const [parentTask, setParentTask] = useState([]);
  const [childTask, setChildTask] = useState([]);
  const [fetchDetails, setFetchDetails] = useState(true);
  const [openCreateChildTask, setOpenCreateChildTask] = useState(false);
  const [tabIndicator, setTabIndicator] = useState(0);
  const [taskName, setTaskName] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [priority, setPriority] = useState(null);
  const [etacount, setEtaCount] = useState(null);
  const [taskOwnerId, setTaskOwnerId] = useState("");
  const [sprName, setSprName] = useState("");
  const [curSpr, setCurSpr] = useState(null);
  const [taskCurrentAssingeeId, setTaskCurrentAssingeeId] = useState(null);
  const [editField, setEditField] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [descriptions, setDescriptions] = useState("");
  const [checkBox, setCheckBox] = useState(selectedTask?.billable);
  const [showFullView, setShowFullView] = useState(false);
  const [isTagRemove, setIsTagRemove] = useState(false);
  const [selLabelId, setSelLabelId] = useState(null);
  const [openLabelDialog, setOpenLabelDialog] = useState(false);
  const [newLabelName, setNewLabelName] = useState("");
  const [newLabelColor, setNewLabelColor] = useState(themes.primaryIndicator);
  const [newLabelCreation, setNewLabelCreation] = useState("");
  const [editLabelName, setEditLabelName] = useState("");
  const [editLabelId, setEditLabelId] = useState(0);
  const [editLabelColor, setEditLabelColor] = useState(themes.primaryIndicator);
  const [otherStatus, setOtherWorkflowStatus] = useState([]);
  const [changeAssigneeDialog, setChangeAssigneeDialog] = useState(false);
  const [taskStatus, setTaskStatus] = useState(
    selectedTask?.work_flow_status?.name
  );
  const [moveMenuAnchorEl, setMoveMenuAnchorEl] = useState(null);
  const [movProOpen, setMovProOpen] = useState(false);
  const [selMovProject, setSelMovProject] = useState("");
  const [selMovProjectId, setSelMovProjectId] = useState("");
  const [selProjectError, setSelProjectError] = useState(false);
  const [movTaskOpen, setMovTaskOpen] = useState(false);
  const [parTaskList, setParTaskList] = useState([]);
  const [selMovParTask, setSelMovParTask] = useState("");
  const [selMovParTaskId, setSelMovParTaskId] = useState("");
  const [selParTaskError, setSelParTaskError] = useState(false);
  const [makeParBtnLoading, setMakeParBtnLoading] = useState(false);
  const [makeParentOpen, setMakeParentOpen] = useState(false);
  const [priProjMembers, setPriProjMembers] = useState([]);
  const [doAnyAct, setDoAnyAct] = useState(false);
  const [taskDetsLoading, setTaskDetsLoading] = useState(false);
  const [taskSprintDetails, setTaskSprintDetails] = useState(null);
  const [contentLoading, setContentLoading] = useState(false);

  useEffect(() => {
    setDescriptions(selectedTask?.description);
  }, [selectedTask?.description]);

  useEffect(() => {
    const getProjectUnCompletedSprint = async (task) => {
      try {
        const response = await fetch(
          `${domain}sprints/?project=${task.project.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setTaskSprintDetails(
            res.filter((data) => data.is_completed === false)
          );
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };

    getProjectUnCompletedSprint(selectedTask);
  }, [selectedTask, domain, token]);

  const ticks = useCallback(() => {
    let momenttime = 0;
    const startTime = moment(taskStartTime, "HH:mm:ss a");
    let notesTime = moment(new Date()).utcOffset("+05:30").format("hh:mm:ss A");
    const endTime = moment(notesTime, "HH:mm:ss a");
    const duration = moment.duration(endTime.diff(startTime));
    const hours = parseInt(duration.asHours());
    const minutes = parseInt(duration.asMinutes()) % 60;
    const sec = parseInt(duration.asSeconds()) % 60;
    const time = `${hours}:${minutes}:${sec}`;
    momenttime = moment(time, "HH:mm:ss").format("HH:mm:ss");
    setDuration(momenttime);
  }, [taskStartTime]);

  const filterCommonValues = (arr1, arr2) => {
    const valuesInArr2 = arr2.map((item) => item["username"]);
    return arr1.filter((item) => valuesInArr2.includes(item["username"]));
  };

  useEffect(() => {
    if (editProject?.projectType === "Private") {
      const filteredMembers = filterCommonValues(
        allUsers,
        editProject?.members
      );
      setPriProjMembers(filteredMembers);
    }
  }, [editProject?.members, allUsers, editProject?.projectType]);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        ticks();
      }, 1000);
    } else if (!isActive && taskStartTime !== null) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, taskStartTime, ticks]);

  const fetchTaskData = useCallback(async () => {
    try {
      const url = `${domain}custom-task/?project_id=${selectedTask?.project?.id}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        const filtered_data = res.filter(
          (task) =>
            task.id !== selectedTask.id && task.id !== selectedTask.parentaskid
        );
        setParTaskList(filtered_data);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  }, [
    domain,
    token,
    selectedTask?.project?.id,
    selectedTask.id,
    selectedTask.parentaskid,
  ]);

  useEffect(() => {
    fetchTaskData();
  }, [fetchTaskData]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Mui.Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenTaskDetails(open);
    if (open === false) {
      if (doAnyAct) {
        setFetchTask(true);
        setFetchMytask(true);
        setDoAnyAct(false);
      }
      setSelectedTask(null);
      setSelectedTaskid(null);
    }
  };

  useEffect(() => {
    const fetchParentDetails = async () => {
      try {
        const response = await fetch(
          `${domain}task/${selectedTask.parentaskid}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setParentTask(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };

    const fetchChildTaskDetails = async () => {
      try {
        setContentLoading(true);
        const response = await fetch(
          `${domain}detailed_task/?id=${selectedTask.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setChildTask(res[0].child_tasks);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setContentLoading(false);
      }
    };
    if (fetchDetails) {
      fetchChildTaskDetails();
      if (selectedTask.task_type === "Child Task") {
        fetchParentDetails();
      }
      setFetchDetails(false);
    }
  }, [
    domain,
    fetchDetails,
    selectedTask.id,
    token,
    selectedTask.parentaskid,
    selectedTask.task_type,
  ]);

  const fetchTask = async () => {
    try {
      const response = await fetch(`${domain}task/${selectedTask.id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setSelectedTask(res);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const checkDoubleClick = (data, fields) => {
    switch (fields) {
      case "taskname":
        setTaskName(data);
        setEditField(fields);
        break;
      case "startdate":
        setStartDate(data);
        setEditField(fields);
        break;
      case "enddate":
        setEndDate(data);
        setEditField(fields);
        break;
      case "priority":
        setPriority(data);
        setEditField(fields);
        break;
      case "descriptions":
        setEditField(fields);
        break;
      default:
        break;
    }
    if (fields === "taskname") {
      setTaskName(data);
      setEditField(fields);
    }
  };

  const fetchParChildTask = async (selId) => {
    try {
      const response = await fetch(`${domain}task/${selId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setSelectedTask(res);
        setSelectedTaskid(res.id);
        setFetchDetails(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setTaskDetsLoading(false);
    }
  };

  const updateTaskDetails = async () => {
    try {
      setDoAnyAct(true);
      const response = await fetch(`${domain}edit_task/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          task_id: selectedTaskid,
          task_name: taskName,
          due_date: endDate,
          start_date: startDate,
          priority: priority,
          description: descriptions,
          taskOwner: taskOwnerId,
          new_assignee: taskCurrentAssingeeId,
          estimate: etacount,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        updateStatus();
        fetchTask();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const updateStatus = () => {
    setTaskName(null);
    setStartDate(null);
    setEndDate(null);
    setPriority(null);
    setEtaCount(null);
    setEditField(null);
    setTaskCurrentAssingeeId(null);
    setTaskOwnerId(null);
  };

  const updateBillbleTask = async (billableTask) => {
    try {
      setDoAnyAct(true);
      const response = await fetch(`${domain}edit_task/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          task_id: selectedTaskid,
          billable: billableTask,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.message);
        updateStatus();
        fetchTask();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const updateTaskLabel = async () => {
    try {
      setDoAnyAct(true);
      const response = await fetch(`${domain}update_task_label/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          task_id: selectedTaskid,
          label_id: selLabelId,
          action: "remove",
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.message);
        handleTagRemoveClose();
        fetchTask();
        setLabelsApi(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const moveToInProgressWithPay = async (taskid, task) => {
    try {
      const response = await fetch(`${domain}edit_task/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          task_id: taskid,
          status: "InProgress",
          workflow_status_id: task.work_flow_status.workflow,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.message);
        fetchTask();
        updateStatus();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleWorkingTime = async (taskid, action, task) => {
    try {
      setDoAnyAct(true);
      const response = await fetch(`${domain}toggle_timer/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          task_id: taskid,
          action: action,
          user_id: userDetails.id,
          task_status:
            task?.work_flow_status?.name === "ToDo"
              ? "InProgress"
              : task?.work_flow_status?.name,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        if (action === "start") {
          setIsActive(true);
          setTaskStartTime(
            moment(new Date()).utcOffset("+05:30").format("hh:mm:ss A")
          );
          if (task?.work_flow_status?.name === "ToDo") {
            await moveToInProgressWithPay(taskid, task);
          }
          setCurrentPlayTaskId(taskid);
        } else {
          setIsActive(false);
          setCurrentPlayTaskId(0);
        }
        fetchTask();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const updateMoveSprint = async () => {
    const selectedStatus = [
      {
        task_id: selectedTask.id,
      },
    ];
    try {
      const response = await fetch(`${domain}move_sprint_task/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          selected_tasks: selectedStatus,
          new_sprint_id: curSpr.id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.message);
        setEditField("");
        setCurSpr(null);
        setOpenTaskDetails(false);
        setSelectedTask(null);
        setSelectedTaskid(null);
        setFetchTask(true);
        setFetchMytask(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleTagRemoveOpen = (labelId) => {
    setIsTagRemove(true);
    setSelLabelId(labelId);
  };

  const handleTagRemoveClose = () => {
    setIsTagRemove(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createNewLabel = async () => {
    if (newLabelName !== "") {
      try {
        const response = await fetch(`${domain}project_task_label/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            project: selectedTask.project.id,
            label_name: newLabelName,
            color_code: newLabelColor,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", projectSuccess.createLabel);
          setOpenLabelDialog(false);
          setNewLabelCreation("");
          setNewLabelColor(themes.primaryIndicator);
          setLabelsApi(true);
          fetchTask();
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    }
  };

  const updateNewLabel = async (id) => {
    if (editLabelName !== "") {
      try {
        const response = await fetch(`${domain}project_task_label/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            label_id: editLabelId,
            label_name: editLabelName,
            color_code: editLabelColor,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", projectSuccess.createLabel);
          setDoAnyAct(true);
          setOpenLabelDialog(false);
          setNewLabelCreation("");
          setEditLabelName("");
          setEditLabelId(0);
          setEditLabelColor(themes.primaryIndicator);
          setLabelsApi(true);
          fetchTask();
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    }
  };

  const UpdateNewTaskStatus = async () => {
    setDoAnyAct(true);
    if (taskStatus === "Completed") {
      try {
        const response = await fetch(`${domain}edit_task/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            task_id: selectedTask.id,
            status: taskStatus,
            new_assignee: null,
            workflow_status_id: selectedTask.work_flow_status.workflow,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          handleCloseAssigneeDialog();
          showToast("success", res.message);
          fetchTask();
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    } else {
      setChangeAssigneeDialog(true);
    }
  };

  const handleCloseAssigneeDialog = async () => {
    setEditField("");
    setChangeAssigneeDialog(false);
    fetchTask();
  };

  const addTaskLabel = async (lebelId, action, taskid) => {
    try {
      setDoAnyAct(true);
      const response = await fetch(`${domain}update_task_label/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          task_id: taskid,
          label_id: lebelId,
          action: action,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.message);
        handleTagRemoveClose();
        setOpenLabelDialog(false);
        fetchTask();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleMovProClose = () => {
    setSelMovProject("");
    setSelMovProjectId("");
    setSelProjectError(false);
    setMovProOpen(false);
  };

  const handleMovTaskClose = () => {
    setSelMovParTask("");
    setSelMovParTaskId("");
    setSelParTaskError(false);
    setMovTaskOpen(false);
  };

  const handleMakeParentClose = () => {
    setMakeParBtnLoading(false);
    setMakeParentOpen(false);
  };

  const updateMoveProject = async () => {
    if (selMovProjectId) {
      const selectedTask = [
        {
          task_id: selectedTaskid,
        },
      ];
      try {
        const response = await fetch(`${domain}move_project_task/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            selected_tasks: selectedTask,
            new_project_id: selMovProjectId,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", res.message);
          updateStatus();
          setFetchTask(true);
          setFetchMytask(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        handleMovProClose();
      }
    } else {
      setSelProjectError(true);
    }
  };

  const fetchParDetails = async (parentId) => {
    try {
      const response = await fetch(`${domain}task/${parentId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setParentTask(res);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const updateMoveParentTask = async () => {
    if (selMovParTaskId) {
      try {
        setDoAnyAct(true);
        const response = await fetch(`${domain}assign/parent-task`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            source_task_id: selectedTask.id,
            destination_task_id: selMovParTaskId,
            task_type: selectedTask.task_type,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", res.message);
          updateStatus();
          fetchTask();
          fetchParDetails(selMovParTaskId);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        handleMovTaskClose();
      }
    } else {
      setSelParTaskError(true);
    }
  };

  const updateParentTask = async () => {
    try {
      setDoAnyAct(true);
      const response = await fetch(`${domain}make-as-parent`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          task_id: selectedTask.id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.message);
        updateStatus();
        fetchTask();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      handleMakeParentClose();
    }
  };

  const isOnlySpaces = (str) => /^\s*$/.test(str);

  const list = (anchor) => (
    <Mui.Box
      sx={{
        padding: 1,
        pt: 0,
      }}
      role="presentation"
    >
      <Mui.Grid container>
        <Mui.Grid xs={showFullView ? 9.5 : 8}>
          <Mui.Grid container sx={{ paddingTop: 11, paddingBottom: 1 }}>
            <Mui.Grid
              xs={11}
              onDoubleClick={() =>
                checkDoubleClick(selectedTask?.taskname, "taskname")
              }
              sx={{ paddingLeft: 1, paddingRight: 2 }}
            >
              <Mui.Grid
                container
                xs={12}
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Mui.Grid item>
                  <Mui.Stack direction="row" spacing={1}>
                    <Mui.IconButton
                      onClick={toggleDrawer("right", false)}
                      sx={{
                        backgroundColor: themes.headLine,
                        "&:hover": {
                          backgroundColor: themes.headLine,
                        },
                      }}
                      size="small"
                    >
                      <Close sx={{ color: themes.whiteColor }} />
                    </Mui.IconButton>
                    <Mui.IconButton
                      onClick={() => {
                        showFullView
                          ? setShowFullView(false)
                          : setShowFullView(true);
                      }}
                      sx={{
                        backgroundColor: themes.primaryIndicator,
                        "&:hover": {
                          backgroundColor: themes.primaryIndicator,
                        },
                      }}
                      size="small"
                    >
                      {showFullView ? (
                        <CloseFullscreenRounded
                          sx={{ color: themes.headLine }}
                        />
                      ) : (
                        <OpenInFullRounded sx={{ color: themes.headLine }} />
                      )}
                    </Mui.IconButton>
                  </Mui.Stack>
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Stack direction="row">
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                  }}
                >
                  Task Number :{" "}
                  {selectedTask.task_code ? selectedTask.task_code : "N/A"}
                </Mui.Typography>{" "}
                &nbsp;&nbsp;
                <Mui.Typography sx={{ paddingLeft: 4, fontSize: 20 }}>
                  Task Type :{" "}
                  {selectedTask?.task_type === null
                    ? ""
                    : selectedTask?.task_type === "Child Task"
                    ? "Child Task"
                    : "Parent Task"}
                </Mui.Typography>
              </Mui.Stack>
              {editField === "taskname" ? (
                <React.Fragment>
                  <Mui.TextField
                    multiline
                    minRows={2}
                    maxRows={5}
                    value={taskName}
                    onChange={(event) => setTaskName(event.target.value)}
                    onBlur={(event) => {
                      if (
                        event.target.value !== selectedTask?.taskname &&
                        event.target.value !== "" &&
                        event.target.value !== null &&
                        !isOnlySpaces(event.target.value)
                      ) {
                        updateTaskDetails();
                      } else {
                        setEditField("");
                      }
                    }}
                    sx={{ borderRadius: 5 }}
                    autoFocus
                    fullWidth
                    InputProps={{
                      inputProps: { maxLength: 512 },
                    }}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Mui.Typography
                    sx={{
                      fontSize: 25,
                      fontWeight: "bold",
                      wordWrap: "break-word",
                    }}
                  >
                    {selectedTask?.taskname}
                  </Mui.Typography>
                </React.Fragment>
              )}
            </Mui.Grid>
          </Mui.Grid>
          {selectedTask?.labels.length > 0 ? (
            <Mui.Grid container spacing={2} sx={{ padding: 2 }}>
              <Mui.Grid xs={12}>
                {selectedTask?.labels.map((lab) => (
                  <>
                    <Mui.Button
                      sx={{
                        fontSize: 12,
                        margin: 1,
                        textTransform: "capitalize",
                        backgroundColor: lab.color_code
                          ? lab.color_code
                          : themes.primaryIndicator,
                        "&:hover": {
                          backgroundColor: lab.color_code
                            ? lab.color_code
                            : themes.primaryIndicator,
                        },
                        color: getContrastingTextColor(
                          lab.color_code
                            ? lab.color_code
                            : themes.primaryIndicator
                        ),
                      }}
                      size="small"
                      onClick={() => {
                        handleTagRemoveOpen(lab.id);
                      }}
                    >
                      {lab.label_name}
                      <Close
                        sx={{
                          position: "absolute",
                          top: -5,
                          right: -5,
                          borderRadius: 35,
                          backgroundColor: themes.blackColor,
                          fontSize: 14,
                          color: themes.iconColor,
                        }}
                      />
                    </Mui.Button>
                  </>
                ))}
                <Mui.IconButton
                  sx={{
                    backgroundColor: themes.blackColor,
                    "&:hover": {
                      backgroundColor: themes.blackColor,
                    },
                  }}
                  size="small"
                  onClick={() => {
                    if (taskLabels.length <= 1) {
                      setNewLabelCreation("CreateLabel");
                    }
                    setOpenLabelDialog(true);
                  }}
                >
                  <AddCircle sx={{ color: themes.whiteColor }} size="small" />
                </Mui.IconButton>
              </Mui.Grid>
            </Mui.Grid>
          ) : (
            <Mui.Grid container spacing={2} sx={{ padding: 2 }}>
              <Mui.Grid xs={12}>
                <Mui.IconButton
                  sx={{
                    backgroundColor: themes.blackColor,
                    "&:hover": {
                      backgroundColor: themes.blackColor,
                    },
                  }}
                  size="small"
                  onClick={() => {
                    if (taskLabels.length <= 1) {
                      setNewLabelCreation("CreateLabel");
                    }
                    setOpenLabelDialog(true);
                  }}
                >
                  <AddCircle sx={{ color: themes.whiteColor }} size="small" />
                </Mui.IconButton>
              </Mui.Grid>
            </Mui.Grid>
          )}
          <Mui.Grid container spacing={2} sx={{ padding: 2 }}>
            <Mui.Grid xs={12}>
              <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                Description
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid
              xs={12}
              onDoubleClick={() =>
                checkDoubleClick(selectedTask?.description, "descriptions")
              }
            >
              {editField === "descriptions" ||
              selectedTask?.description === "" ? (
                <Mui.TextField
                  multiline
                  minRows={2}
                  maxRows={5}
                  value={descriptions}
                  onChange={(event) => setDescriptions(event.target.value)}
                  onBlur={(event) => {
                    if (event.target.value !== selectedTask?.description) {
                      updateTaskDetails();
                    } else {
                      setEditField("");
                    }
                  }}
                  sx={{ borderRadius: 5 }}
                  fullWidth
                />
              ) : (
                <Mui.Typography sx={{ whiteSpace: "pre-wrap" }}>
                  {selectedTask?.description}
                </Mui.Typography>
              )}
            </Mui.Grid>
          </Mui.Grid>
          <div>
            <BootstrapDialog
              aria-labelledby="customized-dialog-title"
              open={isTagRemove}
            >
              <FuncDialogTitle
                id="customized-dialog-title"
                align="center"
                sx={{
                  minWidth: 580,
                  color: themes.headLine,
                  fontWeight: 1000,
                  fontSize: "23px",
                  background: themes.primary,
                }}
                onClose={handleTagRemoveClose}
                title={"Remove Label"}
              >
                {"Remove Label"}
              </FuncDialogTitle>
              <Mui.DialogContent dividers>
                <Mui.Grid xs={12} sx={{ paddingBottom: 3 }}>
                  <Mui.Typography sx={{ fontSize: 24 }}>
                    Do you want to remove this tag?
                  </Mui.Typography>
                </Mui.Grid>

                <Mui.Grid container>
                  <Mui.Grid xs={5}></Mui.Grid>
                  <Mui.Grid xs={3}>
                    <Mui.Button
                      variant="contained"
                      size="large"
                      sx={{
                        backgroundColor: themes.primaryIndicator,
                        "&:hover": {
                          border: "none",
                          backgroundColor: themes.primaryIndicator,
                        },
                        color: themes.whiteColor,
                        float: "right",
                        width: 150,
                        border: "none",
                        textTransform: "capitalize",
                      }}
                      onClick={handleTagRemoveClose}
                    >
                      No
                    </Mui.Button>
                  </Mui.Grid>
                  <Mui.Grid xs={4}>
                    <Mui.Button
                      variant="contained"
                      size="large"
                      type="submit"
                      sx={{
                        backgroundColor: themes.primaryIndicator,
                        "&:hover": {
                          border: "none",
                          backgroundColor: themes.primaryIndicator,
                        },
                        color: themes.whiteColor,
                        float: "right",
                        width: 150,
                        border: "none",
                        textTransform: "capitalize",
                      }}
                      onClick={updateTaskLabel}
                    >
                      Yes
                    </Mui.Button>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.DialogContent>
            </BootstrapDialog>
          </div>

          {selectedTask.task_type === "Parent Task" ? (
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={12}>
                <Mui.Typography sx={{ fontSize: 25, fontWeight: "bold" }}>
                  Child Task
                </Mui.Typography>
              </Mui.Grid>
              {contentLoading ? (
                <Loader info="Loading..." />
              ) : (
                <>
                  {childTask?.length === 0 ? (
                    <Mui.Alert
                      severity="info"
                      sx={{
                        mt: 1,
                        width: "100%",
                        ml: 1,
                        mr: 1,
                      }}
                      fullWidth
                    >
                      There is a no child task available
                    </Mui.Alert>
                  ) : (
                    <>
                      <Mui.TableContainer
                        sx={{
                          padding: 1,
                          width: "100%",
                          paddingTop: 2,
                        }}
                      >
                        <Mui.Table>
                          <Mui.TableHead>
                            <Mui.TableRow maxWidth="xl" align="left">
                              <Mui.TableCell
                                sx={{
                                  color: themes.blackColor,
                                  fontWeight: "bold",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  maxWidth: 100,
                                  width: "5%",
                                }}
                              >
                                Task No
                              </Mui.TableCell>
                              <Mui.TableCell
                                sx={{
                                  color: themes.blackColor,
                                  fontWeight: "bold",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  maxWidth: 100,
                                  width: showFullView ? "65%" : "75%",
                                }}
                              >
                                Title
                              </Mui.TableCell>
                              <Mui.TableCell
                                sx={{
                                  color: themes.blackColor,
                                  fontWeight: "bold",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  maxWidth: 100,
                                  width: "10%",
                                }}
                              >
                                Task Owner
                              </Mui.TableCell>
                              {showFullView ? (
                                <Mui.TableCell
                                  sx={{
                                    color: themes.blackColor,
                                    fontWeight: "bold",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    maxWidth: 100,
                                    width: "10%",
                                  }}
                                >
                                  Time Booked
                                </Mui.TableCell>
                              ) : null}
                              <Mui.TableCell
                                sx={{
                                  color: themes.blackColor,
                                  fontWeight: "bold",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  maxWidth: 100,
                                  width: "10%",
                                }}
                              >
                                Status
                              </Mui.TableCell>
                            </Mui.TableRow>
                          </Mui.TableHead>
                          <Mui.TableBody>
                            {childTask
                              ?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((item, index) => (
                                <Mui.TableRow
                                  maxWidth="xl"
                                  align="left"
                                  sx={{
                                    border: `1px solid ${themes.formBackground}`,
                                    cursor: "pointer",
                                  }}
                                  hover={true}
                                  onClick={() => {
                                    if (item.task_code) {
                                      setTaskDetsLoading(true);
                                      fetchParChildTask(item.id);
                                    }
                                  }}
                                >
                                  <Mui.TableCell
                                    sx={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      maxWidth: 100,
                                    }}
                                  >
                                    <Mui.Link
                                      onClick={() => {
                                        if (item.task_code) {
                                          setTaskDetsLoading(true);
                                          fetchParChildTask(item.id);
                                        }
                                      }}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <Mui.Typography
                                        sx={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {item.task_code
                                          ? item.task_code
                                          : "N/A"}
                                      </Mui.Typography>
                                    </Mui.Link>
                                  </Mui.TableCell>
                                  <Mui.TableCell
                                    sx={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      maxWidth: 100,
                                    }}
                                  >
                                    <Mui.Tooltip title={item?.taskname}>
                                      <Mui.Typography
                                        sx={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {item?.taskname}
                                      </Mui.Typography>
                                    </Mui.Tooltip>
                                  </Mui.TableCell>
                                  <Mui.TableCell
                                    sx={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      maxWidth: 100,
                                    }}
                                  >
                                    <Mui.Typography
                                      sx={{
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {item?.task_owner?.username}
                                    </Mui.Typography>
                                  </Mui.TableCell>
                                  {showFullView ? (
                                    <Mui.TableCell
                                      sx={{
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        maxWidth: 100,
                                      }}
                                    >
                                      <Mui.Typography
                                        sx={{
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {item?.total_time
                                          ? item?.total_time
                                          : "00:00"}
                                      </Mui.Typography>
                                    </Mui.TableCell>
                                  ) : null}
                                  <Mui.TableCell
                                    sx={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      maxWidth: 100,
                                    }}
                                  >
                                    <Mui.Typography
                                      sx={{
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {item?.work_flow_status?.name}
                                    </Mui.Typography>
                                  </Mui.TableCell>
                                </Mui.TableRow>
                              ))}
                          </Mui.TableBody>
                        </Mui.Table>
                        {childTask.length > 10 ? (
                          <Mui.TablePagination
                            className="custom-pagination"
                            component="div"
                            rowsPerPageOptions={[10, 20, 50, 100]}
                            count={childTask.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        ) : null}
                      </Mui.TableContainer>
                    </>
                  )}
                </>
              )}
            </Mui.Grid>
          ) : (
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={12}>
                <Mui.Typography sx={{ fontSize: 25, fontWeight: "bold" }}>
                  Parent Task
                </Mui.Typography>
              </Mui.Grid>
              {contentLoading ? (
                <Loader info="Loading..." />
              ) : (
                <>
                  {parentTask?.length === 0 ? (
                    <Mui.Alert
                      severity="info"
                      sx={{
                        mt: 1,
                        width: "100%",
                        ml: 1,
                        mr: 1,
                      }}
                      fullWidth
                    >
                      There is a no parent task available
                    </Mui.Alert>
                  ) : (
                    <>
                      <Mui.TableContainer
                        sx={{
                          padding: 1,
                          width: "100%",
                          paddingTop: 2,
                        }}
                      >
                        <Mui.Table>
                          <Mui.TableHead>
                            <Mui.TableRow maxWidth="xl" align="left">
                              <Mui.TableCell
                                sx={{
                                  color: themes.blackColor,
                                  fontWeight: "bold",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  maxWidth: 100,
                                  width: "5%",
                                }}
                              >
                                Task No
                              </Mui.TableCell>
                              <Mui.TableCell
                                sx={{
                                  color: themes.blackColor,
                                  fontWeight: "bold",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  maxWidth: 100,
                                  width: showFullView ? "65%" : "75%",
                                }}
                              >
                                Title
                              </Mui.TableCell>
                              <Mui.TableCell
                                sx={{
                                  color: themes.blackColor,
                                  fontWeight: "bold",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  maxWidth: 100,
                                  width: "10%",
                                }}
                              >
                                Task Owner
                              </Mui.TableCell>
                              {showFullView ? (
                                <Mui.TableCell
                                  sx={{
                                    color: themes.blackColor,
                                    fontWeight: "bold",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    maxWidth: 100,
                                    width: "10%",
                                  }}
                                >
                                  Time Booked
                                </Mui.TableCell>
                              ) : null}
                              <Mui.TableCell
                                sx={{
                                  color: themes.blackColor,
                                  fontWeight: "bold",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  maxWidth: 100,
                                  width: "10%",
                                }}
                              >
                                Status
                              </Mui.TableCell>
                            </Mui.TableRow>
                          </Mui.TableHead>
                          <Mui.TableBody>
                            <Mui.TableRow
                              maxWidth="xl"
                              align="left"
                              sx={{
                                border: `1px solid ${themes.formBackground}`,
                                cursor: "pointer",
                              }}
                              hover={true}
                              onClick={() => {
                                if (parentTask.task_code) {
                                  setTaskDetsLoading(true);
                                  fetchParChildTask(parentTask.id);
                                }
                              }}
                            >
                              <Mui.TableCell
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  maxWidth: 100,
                                }}
                              >
                                <Mui.Link
                                  onClick={() => {
                                    if (parentTask.task_code) {
                                      setTaskDetsLoading(true);
                                      fetchParChildTask(parentTask.id);
                                    }
                                  }}
                                  sx={{ cursor: "pointer" }}
                                >
                                  <Mui.Typography
                                    sx={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {parentTask.task_code
                                      ? parentTask.task_code
                                      : "N/A"}
                                  </Mui.Typography>
                                </Mui.Link>
                              </Mui.TableCell>
                              <Mui.TableCell
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  maxWidth: 100,
                                }}
                              >
                                <Mui.Tooltip title={parentTask?.taskname}>
                                  <Mui.Typography
                                    sx={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {parentTask?.taskname}
                                  </Mui.Typography>
                                </Mui.Tooltip>
                              </Mui.TableCell>
                              <Mui.TableCell
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  maxWidth: 100,
                                }}
                              >
                                <Mui.Typography
                                  sx={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {parentTask?.task_owner?.username}
                                </Mui.Typography>
                              </Mui.TableCell>
                              {showFullView ? (
                                <Mui.TableCell
                                  sx={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    maxWidth: 100,
                                  }}
                                >
                                  <Mui.Typography
                                    sx={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {parentTask?.total_time
                                      ? parentTask?.total_time
                                      : "00:00"}
                                  </Mui.Typography>
                                </Mui.TableCell>
                              ) : null}
                              <Mui.TableCell
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  maxWidth: 100,
                                }}
                              >
                                <Mui.Typography
                                  sx={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {parentTask?.work_flow_status?.name}
                                </Mui.Typography>
                              </Mui.TableCell>
                            </Mui.TableRow>
                          </Mui.TableBody>
                        </Mui.Table>
                      </Mui.TableContainer>
                    </>
                  )}
                </>
              )}
            </Mui.Grid>
          )}
        </Mui.Grid>
        <Mui.Grid xs={showFullView ? 2.5 : 4} sx={{ paddingTop: 8 }}>
          <Mui.Stack
            sx={{
              border: `5px solid ${themes.formBackground}`,
              borderRadius: 3,
            }}
          >
            <Mui.Grid container sx={{ paddingBottom: 3, paddingTop: 3 }}>
              <Mui.Grid xs={1}></Mui.Grid>
              <Mui.Grid xs={4.5}>
                <Mui.Button
                  size="large"
                  variant="contained"
                  sx={{
                    backgroundColor: themes.primaryButton,
                    textTransform: "capitalize",
                    color: themes.headLine,
                    padding: 1,
                    width: "90%",
                    border: "none",
                    "&:hover": {
                      border: "none",
                      backgroundColor: themes.primaryButton,
                    },
                  }}
                  onClick={(event) => setMoveMenuAnchorEl(event.currentTarget)}
                  disabled={selectedTask?.is_running ? true : false}
                >
                  Move
                  <Mui.IconButton
                    size="large"
                    sx={{ color: themes.headLine, margin: 0, padding: 0 }}
                    onClick={(event) =>
                      setMoveMenuAnchorEl(event.currentTarget)
                    }
                  >
                    <KeyboardArrowDown />
                  </Mui.IconButton>
                </Mui.Button>
                <Mui.Popover
                  anchorEl={moveMenuAnchorEl}
                  open={Boolean(moveMenuAnchorEl)}
                  onClose={() => {
                    setMoveMenuAnchorEl(null);
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "bottom",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Mui.MenuItem
                    sx={{
                      borderBottom: `2px solid ${themes.whiteColor}`,
                    }}
                    disabled={
                      projectDetails?.filter(
                        (sts) =>
                          sts?.status?.status !== "Completed" &&
                          sts?.status?.status !== "Inactive" &&
                          sts?.id !== selectedTask.project.id
                      ).length > 0
                        ? false
                        : true
                    }
                    onClick={() => {
                      setMoveMenuAnchorEl(null);
                      setMovProOpen(true);
                    }}
                  >
                    Project
                  </Mui.MenuItem>
                  <Mui.MenuItem
                    sx={{
                      borderBottom: `2px solid ${themes.whiteColor}`,
                    }}
                    onClick={() => {
                      setMoveMenuAnchorEl(null);
                      setMovTaskOpen(true);
                    }}
                    disabled={parTaskList.length > 0 ? false : true}
                  >
                    Move to another task
                  </Mui.MenuItem>
                </Mui.Popover>
              </Mui.Grid>
              <div>
                <BootstrapDialog
                  aria-labelledby="customized-dialog-title"
                  open={movProOpen}
                >
                  <FuncDialogTitle
                    id="customized-dialog-title"
                    align="center"
                    sx={{
                      minWidth: 580,
                      color: themes.headLine,
                      fontWeight: 1000,
                      fontSize: "23px",
                      background: themes.primary,
                    }}
                    onClose={handleMovProClose}
                    title={"Move to Another Project"}
                  >
                    {"Move to Another Project"}
                  </FuncDialogTitle>
                  <Mui.DialogContent dividers>
                    <Mui.Grid xs={12} sx={{ paddingBottom: 3 }}>
                      <Mui.TextField
                        value={selectedTask?.project?.projectname}
                        label="Currrent Project"
                        sx={{
                          background: themes.whiteColor,
                          borderRadius: 2,
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          fontSize: 14,
                          width: "100%",
                        }}
                        size="small"
                        fullWidth
                      />
                    </Mui.Grid>
                    <Mui.Grid xs={12} sx={{ paddingBottom: 3 }}>
                      <Mui.Autocomplete
                        id="combo-box-demo"
                        fullWidth
                        size="small"
                        value={selMovProject}
                        options={projectDetails?.filter(
                          (sts) =>
                            sts?.status?.status !== "Completed" &&
                            sts?.status?.status !== "Inactive" &&
                            sts?.id !== selectedTask.project.id
                        )}
                        style={{ fontSize: 14 }}
                        filterOptions={(options, state) => {
                          return options.filter((option) =>
                            option.label
                              ?.toLowerCase()
                              .includes(state.inputValue.toLowerCase())
                          );
                        }}
                        renderOption={(props, option) => {
                          return <li {...props}>{option.label}</li>;
                        }}
                        onChange={(event, value) => {
                          if (value) {
                            setSelMovProject(value.label);
                            setSelMovProjectId(value.id);
                          }
                        }}
                        renderInput={(params) => (
                          <Mui.TextField
                            {...params}
                            value={selMovProject}
                            label="To"
                            placeholder={projectPlaceholder.selectProject}
                            sx={{
                              background: themes.whiteColor,
                              borderRadius: 2,
                              paddingTop: "0px",
                              paddingBottom: "0px",
                              fontSize: 14,
                              width: "100%",
                            }}
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                      {selProjectError && (
                        <Mui.Typography
                          sx={{
                            color: themes.redColor,
                            fontSize: 14,
                            padding: "2px",
                          }}
                        >
                          {projectError.projectnameError}
                        </Mui.Typography>
                      )}
                    </Mui.Grid>

                    <Mui.Grid container>
                      <Mui.Grid xs={5}></Mui.Grid>
                      <Mui.Grid
                        xs={7}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          paddingRight: 1,
                        }}
                      >
                        <CustomButton
                          actionFuntion={handleMovProClose}
                          actionName="Cancel"
                          typeName="button"
                        />{" "}
                        &nbsp;
                        <CustomButton
                          actionFuntion={updateMoveProject}
                          actionName="Move"
                          typeName="button"
                        />
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.DialogContent>
                </BootstrapDialog>
              </div>
              <div>
                <BootstrapDialog
                  aria-labelledby="customized-dialog-title"
                  open={movTaskOpen}
                >
                  <FuncDialogTitle
                    id="customized-dialog-title"
                    align="center"
                    sx={{
                      minWidth: 580,
                      color: themes.headLine,
                      fontWeight: 1000,
                      fontSize: "23px",
                      background: themes.primary,
                    }}
                    onClose={handleMovTaskClose}
                    title={"Move to Another Task"}
                  >
                    {"Move to Another Task"}
                  </FuncDialogTitle>
                  <Mui.DialogContent dividers>
                    <Mui.Grid xs={12} sx={{ paddingBottom: 3 }}>
                      <Mui.TextField
                        value={
                          selectedTask.task_type === "Parent Task"
                            ? selectedTask?.taskname
                            : parentTask.taskname
                        }
                        label="Currrent Parent"
                        sx={{
                          background: themes.whiteColor,
                          borderRadius: 2,
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          fontSize: 14,
                          width: "100%",
                        }}
                        size="small"
                        fullWidth
                      />
                    </Mui.Grid>
                    <Mui.Grid xs={12} sx={{ paddingBottom: 3 }}>
                      <Mui.Autocomplete
                        id="sprint-select-label"
                        fullWidth
                        size="small"
                        value={selMovParTask}
                        options={parTaskList}
                        style={{ fontSize: 14 }}
                        filterOptions={(options, state) => {
                          return options.filter((option) =>
                            option.taskname
                              ?.toLowerCase()
                              .includes(state.inputValue.toLowerCase())
                          );
                        }}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.taskname}
                            </li>
                          );
                        }}
                        getOptionLabel={(option) => option.taskname || option}
                        onChange={(event, value) => {
                          if (value) {
                            setSelMovParTask(value.taskname);
                            setSelMovParTaskId(value.id);
                          }
                        }}
                        renderInput={(params) => (
                          <Mui.TextField
                            {...params}
                            value={selMovParTask}
                            label="To"
                            placeholder={"Select task"}
                            sx={{
                              background: themes.whiteColor,
                              borderRadius: 2,
                              paddingTop: "0px",
                              paddingBottom: "0px",
                              fontSize: 14,
                              width: "100%",
                            }}
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      />
                      {selParTaskError && (
                        <Mui.Typography
                          sx={{
                            color: themes.redColor,
                            fontSize: 14,
                            padding: "2px",
                          }}
                        >
                          {projectError.taskNameError}
                        </Mui.Typography>
                      )}
                    </Mui.Grid>

                    <Mui.Grid container>
                      <Mui.Grid xs={5}></Mui.Grid>
                      <Mui.Grid
                        xs={7}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <CustomButton
                          actionFuntion={handleMovTaskClose}
                          actionName="Cancel"
                          typeName="button"
                        />
                        &nbsp;
                        <CustomButton
                          actionFuntion={updateMoveParentTask}
                          actionName="Move"
                          typeName="button"
                        />
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.DialogContent>
                </BootstrapDialog>
              </div>
              <Mui.Grid xs={5}>
                {selectedTask?.task_type !== "Child Task" ? (
                  <Mui.Button
                    size="large"
                    variant="contained"
                    sx={{
                      color: themes.headLine,
                      width: "100%",
                      border: "none",
                      textTransform: "capitalize",
                      backgroundColor: themes.primaryButton,
                      "&:hover": {
                        border: "none",
                        backgroundColor: themes.primaryButton,
                      },
                    }}
                    disabled={
                      selectedTask?.sprint?.status === "Completed"
                        ? true
                        : false
                    }
                    onClick={() => setOpenCreateChildTask(true)}
                  >
                    Add Child
                  </Mui.Button>
                ) : (
                  <LoadingButton
                    loading={makeParBtnLoading}
                    size="large"
                    variant="contained"
                    sx={{
                      color: themes.headLine,
                      width: "100%",
                      border: "none",
                      textTransform: "capitalize",
                      backgroundColor: themes.primaryButton,
                      "&:hover": {
                        border: "none",
                        backgroundColor: themes.primaryButton,
                      },
                    }}
                    onClick={() => {
                      setMakeParBtnLoading(true);
                      setMakeParentOpen(true);
                    }}
                  >
                    Make Parent
                  </LoadingButton>
                )}
              </Mui.Grid>
              <div>
                <BootstrapDialog
                  aria-labelledby="customized-dialog-title"
                  open={makeParentOpen}
                >
                  <FuncDialogTitle
                    id="customized-dialog-title"
                    align="center"
                    sx={{
                      minWidth: 580,
                      color: themes.headLine,
                      fontWeight: 1000,
                      fontSize: "23px",
                      background: themes.primary,
                    }}
                    onClose={handleMakeParentClose}
                    title={"Make Parent"}
                  >
                    {"Make Parent"}
                  </FuncDialogTitle>
                  <Mui.DialogContent dividers>
                    <Mui.Grid xs={12} sx={{ paddingBottom: 3 }}>
                      <Mui.Typography sx={{ fontSize: 24 }}>
                        Do you want to make it as parent task?
                      </Mui.Typography>
                    </Mui.Grid>

                    <Mui.Grid container>
                      <Mui.Grid xs={5}></Mui.Grid>
                      <Mui.Grid
                        xs={7}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <CustomButton
                          actionFuntion={handleMakeParentClose}
                          actionName="No"
                          typeName="button"
                        />{" "}
                        &nbsp;
                        <CustomButton
                          actionFuntion={updateParentTask}
                          actionName="Yes"
                          typeName="button"
                        />
                      </Mui.Grid>
                    </Mui.Grid>
                  </Mui.DialogContent>
                </BootstrapDialog>
              </div>
            </Mui.Grid>
            <Mui.Grid container sx={{ paddingBottom: 0.5, paddingTop: 0.5 }}>
              <Mui.Grid item xs={5.8}>
                <Mui.Typography sx={{ paddingLeft: 1, fontSize: 20 }}>
                  Task Status
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={0.4}>
                <Mui.Typography>:</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={5.8}>
                {editField === "status" ? (
                  <Mui.Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    fullWidth
                    options={otherStatus}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.name
                          ?.toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }}
                    renderOption={(props, option) => {
                      return <li {...props}>&nbsp;{option.name}</li>;
                    }}
                    onChange={(event, value) => {
                      if (value) {
                        setTaskStatus(value.name);
                      } else {
                        setTaskStatus(null);
                      }
                    }}
                    onBlur={(event) => {
                      if (
                        event.target.value !==
                          selectedTask?.work_flow_status?.name &&
                        event.target.value !== "" &&
                        event.target.value !== null
                      ) {
                        UpdateNewTaskStatus();
                      } else {
                        setEditField("");
                      }
                    }}
                    sx={{ minWidth: "100%" }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        size="small"
                        sx={{ background: themes.whiteColor }}
                        name="status"
                        placeholder={projectPlaceholder.selectStatus}
                        autoFocus
                      />
                    )}
                  />
                ) : (
                  <Mui.Stack direction="row">
                    <Mui.Typography sx={{ fontSize: 20 }}>
                      {selectedTask.work_flow_status.name}
                    </Mui.Typography>
                    &nbsp;
                    <div>
                      <Mui.IconButton
                        onClick={() => {
                          setOtherWorkflowStatus([]);
                          setEditField("status");
                          setOtherWorkflowStatus(
                            workflowStatus.filter((data) => {
                              return (
                                data.workflow ===
                                selectedTask?.work_flow_status?.workflow
                              );
                            })
                          );
                        }}
                        disabled={selectedTask?.is_running ? true : false}
                      >
                        <ModeEdit
                          size="Medium"
                          sx={{
                            fontSize: 17,
                          }}
                        />
                      </Mui.IconButton>
                    </div>
                  </Mui.Stack>
                )}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid item xs={5.8}>
                <Mui.Typography sx={{ paddingLeft: 1, fontSize: 20 }}>
                  Assignee
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={0.4}>
                <Mui.Typography>:</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={5.8}>
                {editField === "assignee" ? (
                  <Mui.Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    fullWidth
                    options={
                      editProject?.projectType === "Private"
                        ? priProjMembers
                        : allUsers
                    }
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.username
                          ?.toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props}>
                          <Mui.Avatar
                            sx={{ width: 25, height: 25 }}
                            alt={option.username.charAt(0).toUpperCase()}
                            src={option.image_url}
                          />{" "}
                          &nbsp;{option.username}
                        </li>
                      );
                    }}
                    onChange={(event, value) => {
                      if (value) {
                        setTaskCurrentAssingeeId(value.id);
                      } else {
                        setTaskCurrentAssingeeId(null);
                      }
                    }}
                    onBlur={(event) => {
                      if (
                        event.target.value !==
                          selectedTask?.current_assignee?.username &&
                        event.target.value !== "" &&
                        event.target.value !== null
                      ) {
                        updateTaskDetails();
                      } else {
                        setEditField("");
                      }
                    }}
                    sx={{ minWidth: "100%" }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        size="small"
                        sx={{ background: themes.whiteColor }}
                        name="username"
                        placeholder={projectPlaceholder.selectUser}
                        autoFocus
                      />
                    )}
                  />
                ) : (
                  <Mui.Stack direction="row">
                    <Mui.Typography sx={{ fontSize: 20 }}>
                      {selectedTask.current_assignee?.username}
                    </Mui.Typography>
                    &nbsp;
                    <div>
                      <Mui.IconButton
                        onClick={() => setEditField("assignee")}
                        disabled={selectedTask?.is_running ? true : false}
                      >
                        <ModeEdit
                          size="Medium"
                          sx={{
                            fontSize: 17,
                          }}
                        />
                      </Mui.IconButton>
                    </div>
                  </Mui.Stack>
                )}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid item xs={5.8}>
                <Mui.Typography sx={{ paddingLeft: 1, fontSize: 20 }}>
                  Task Owner
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={0.4}>
                <Mui.Typography>:</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={5.8}>
                {editField === "task owner" ? (
                  <Mui.Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    fullWidth
                    options={
                      editProject?.projectType === "Private"
                        ? priProjMembers
                        : allUsers
                    }
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.username
                          ?.toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props}>
                          <Mui.Avatar
                            sx={{ width: 25, height: 25 }}
                            alt={option.username.charAt(0).toUpperCase()}
                            src={option.image_url}
                          />{" "}
                          &nbsp;{option.username}
                        </li>
                      );
                    }}
                    onChange={(event, value) => {
                      if (value) {
                        setTaskOwnerId(value.id);
                      } else {
                        setTaskOwnerId(null);
                      }
                    }}
                    onBlur={(event) => {
                      if (
                        event.target.value !==
                          selectedTask?.task_owner?.username &&
                        event.target.value !== "" &&
                        event.target.value !== null
                      ) {
                        updateTaskDetails();
                      } else {
                        setEditField("");
                      }
                    }}
                    sx={{ minWidth: "100%" }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        size="small"
                        sx={{ background: themes.whiteColor }}
                        name="username"
                        placeholder={projectPlaceholder.selectUser}
                        autoFocus
                      />
                    )}
                  />
                ) : (
                  <Mui.Stack direction="row">
                    <Mui.Typography sx={{ fontSize: 20 }}>
                      {selectedTask?.task_owner?.username}
                    </Mui.Typography>
                    &nbsp;
                    <div>
                      <Mui.IconButton
                        onClick={() => setEditField("task owner")}
                      >
                        <ModeEdit
                          size="Medium"
                          sx={{
                            fontSize: 17,
                          }}
                        />
                      </Mui.IconButton>
                    </div>{" "}
                  </Mui.Stack>
                )}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid item xs={5.8}>
                <Mui.Typography sx={{ paddingLeft: 1, fontSize: 20 }}>
                  Priority
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={0.4}>
                <Mui.Typography>:</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={5.8}>
                {editField === "priority" ? (
                  <Mui.Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    fullWidth
                    value={priority}
                    options={taskPriority}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.priority
                          ?.toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }}
                    renderOption={(props, option) => {
                      return <li {...props}>{option.priority}</li>;
                    }}
                    onChange={(event, value) => {
                      if (value) {
                        setPriority(value.priority);
                      } else {
                        setPriority(null);
                      }
                    }}
                    onBlur={(event) => {
                      if (
                        event.target.value !== selectedTask?.priority?.name &&
                        event.target.value !== "" &&
                        event.target.value !== null
                      ) {
                        updateTaskDetails();
                      } else {
                        setEditField("");
                      }
                    }}
                    sx={{ minWidth: "100%" }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        value={priority}
                        size="small"
                        sx={{ background: themes.whiteColor, padding: 1 }}
                        name="priority"
                        placeholder="Select priority"
                        fullWidth
                        autoFocus
                      />
                    )}
                  />
                ) : (
                  <Mui.Stack direction="row">
                    <Mui.Typography sx={{ fontSize: 20 }}>
                      {selectedTask?.priority?.name}
                    </Mui.Typography>
                    &nbsp;
                    <div>
                      <Mui.IconButton onClick={() => setEditField("priority")}>
                        <ModeEdit
                          size="Medium"
                          sx={{
                            fontSize: 17,
                          }}
                        />
                      </Mui.IconButton>
                    </div>
                  </Mui.Stack>
                )}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Divider />
            <Mui.Grid container sx={{ paddingBottom: 0.5, paddingTop: 0.1 }}>
              <Mui.Grid item xs={5.8}>
                <Mui.Typography
                  sx={{ paddingLeft: 1, fontSize: 20, paddingTop: 1 }}
                >
                  Timer
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={0.4}>
                <Mui.Typography sx={{ paddingTop: 1 }}>:</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={5.8}>
                <Mui.Stack direction="row" sx={{ paddingTop: 1 }}>
                  {currentPlayTaskId === selectedTask.id ? (
                    <Mui.InputBase
                      sx={{ fontSize: 20 }}
                      value={
                        currentPlayTaskId === selectedTask.id
                          ? durationTime
                          : ""
                      }
                      disabled={
                        currentPlayTaskId === selectedTask.id ? false : true
                      }
                    />
                  ) : (
                    ""
                  )}
                  <div style={{ paddingLeft: 2 }}>
                    <Mui.IconButton
                      color="primary"
                      size="small"
                      sx={{
                        padding: "5px",
                        margin: "2px",
                        mr: "45px",
                        borderRadius: 3,
                        backgroundColor:
                          currentPlayTaskId === selectedTask.id
                            ? themes.onleaveTap
                            : themes.primaryIndicator,
                        color: themes.backgroundWhite,
                        ":hover": {
                          backgroundColor:
                            currentPlayTaskId === selectedTask.id
                              ? themes.onleaveTap
                              : themes.primaryIndicator,
                        },
                      }}
                      onClick={() => {
                        if (isActive) {
                          handleWorkingTime(
                            selectedTask.id,
                            "pause",
                            selectedTask
                          );
                        } else {
                          setDuration(null);
                          handleWorkingTime(
                            selectedTask.id,
                            "start",
                            selectedTask
                          );
                        }
                      }}
                      disabled={
                        userDetails.id === selectedTask.current_assignee?.id &&
                        currentStatus !== "Completed" &&
                        !selectedTask.sprint?.is_completed &&
                        selectedTask.sprint?.name !== "Backlog"
                          ? false
                          : true
                      }
                    >
                      {currentPlayTaskId === selectedTask.id ? (
                        <PauseCircle size="small" sx={{ fontSize: 14 }} />
                      ) : !selectedTask.is_running ? (
                        <PlayArrow size="small" sx={{ fontSize: 14 }} />
                      ) : (
                        <Mui.Tooltip title="In Running">
                          <StopCircle
                            size="small"
                            sx={{ fontSize: 18, color: themes.greenColor }}
                          />
                        </Mui.Tooltip>
                      )}
                    </Mui.IconButton>
                  </div>
                </Mui.Stack>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid item xs={5.8}>
                <Mui.Typography sx={{ paddingLeft: 1, fontSize: 20 }}>
                  ETA
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={0.4}>
                <Mui.Typography>:</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={5.8}>
                {editField === "etacount" ? (
                  <Mui.TextField
                    type="number"
                    value={etacount}
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    fullWidth
                    onChange={(event) => {
                      setEtaCount(event.target.value);
                    }}
                    onBlur={(event) => {
                      if (
                        event.target.value !== selectedTask?.estimate &&
                        event.target.value !== "" &&
                        event.target.value !== null
                      ) {
                        updateTaskDetails();
                      } else {
                        setEditField("");
                      }
                    }}
                    sx={{ minWidth: "100%" }}
                    autoFocus
                    placeholder="No. of days"
                  />
                ) : (
                  <Mui.Stack direction="row">
                    <Mui.Typography sx={{ fontSize: 20 }}>
                      {selectedTask?.estimate}
                    </Mui.Typography>
                    &nbsp;
                    <div>
                      <Mui.IconButton onClick={() => setEditField("etacount")}>
                        <ModeEdit
                          size="Medium"
                          sx={{
                            fontSize: 17,
                          }}
                        />
                      </Mui.IconButton>
                    </div>
                  </Mui.Stack>
                )}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid item xs={5.8}>
                <Mui.Typography sx={{ paddingLeft: 1, fontSize: 20 }}>
                  Time Booked
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={0.4}>
                <Mui.Typography>:</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={5.8}>
                <Mui.Typography sx={{ fontSize: 20 }}>
                  {selectedTask?.total_time ? selectedTask?.total_time : "N/A"}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Divider />
            <Mui.Grid container>
              <Mui.Grid item xs={5.8}>
                <Mui.Typography
                  sx={{ paddingLeft: 1, fontSize: 20, paddingTop: 1 }}
                >
                  Billed Task
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={0.4} sx={{ paddingTop: 1 }}>
                <Mui.Typography>:</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={5.8}>
                <Mui.Checkbox
                  sx={{ fontSize: 17 }}
                  checked={checkBox}
                  onChange={() => {
                    updateBillbleTask(!checkBox);
                    setCheckBox(!checkBox);
                  }}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid item xs={5.8}>
                <Mui.Typography sx={{ paddingLeft: 1, fontSize: 20 }}>
                  Start Date
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={0.4}>
                <Mui.Typography>:</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={5.8}>
                {editField === "startdate" ? (
                  <Mui.TextField
                    id="from-date"
                    type="date"
                    sx={{ background: themes.whiteColor, padding: 1 }}
                    value={startDate}
                    size="small"
                    onChange={(event) => setStartDate(event.target.value)}
                    fullWidth
                    autoFocus
                    onBlur={(event) => {
                      if (
                        event.target.value !== "" &&
                        event.target.value !== null
                      ) {
                        updateTaskDetails();
                      } else {
                        setEditField("");
                      }
                    }}
                    inputProps={{
                      max: "9999-12-31",
                    }}
                  />
                ) : (
                  <Mui.Stack direction="row">
                    <Mui.Typography sx={{ fontSize: 20 }}>
                      {selectedTask ? selectedTask.start_date : "N/A"}
                    </Mui.Typography>
                    &nbsp;
                    <div>
                      <Mui.IconButton onClick={() => setEditField("startdate")}>
                        <ModeEdit
                          size="Medium"
                          sx={{
                            fontSize: 17,
                          }}
                        />
                      </Mui.IconButton>
                    </div>
                  </Mui.Stack>
                )}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container>
              <Mui.Grid item xs={5.8}>
                <Mui.Typography sx={{ paddingLeft: 1, fontSize: 20 }}>
                  End date
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={0.4}>
                <Mui.Typography>:</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={5.8}>
                {editField === "enddate" ? (
                  <Mui.TextField
                    id="end-date"
                    type="date"
                    sx={{ background: themes.whiteColor, padding: 1 }}
                    value={endDate}
                    size="small"
                    fullWidth
                    autoFocus
                    onChange={(event) => setEndDate(event.target.value)}
                    onBlur={(event) => {
                      if (
                        event.target.value !== "" &&
                        event.target.value !== null
                      ) {
                        updateTaskDetails();
                      } else {
                        setEditField("");
                      }
                    }}
                    inputProps={{
                      max: "9999-12-31",
                    }}
                  />
                ) : (
                  <Mui.Stack direction="row">
                    <Mui.Typography sx={{ fontSize: 20 }}>
                      {selectedTask ? selectedTask.due_date : "N/A"}
                    </Mui.Typography>
                    &nbsp;
                    <div>
                      <Mui.IconButton onClick={() => setEditField("enddate")}>
                        <ModeEdit
                          size="Medium"
                          sx={{
                            fontSize: 17,
                          }}
                        />
                      </Mui.IconButton>
                    </div>
                  </Mui.Stack>
                )}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Divider />
            <Mui.Grid container sx={{ paddingBottom: 0.5, paddingTop: 0.5 }}>
              <Mui.Grid item xs={5.8}>
                <Mui.Typography sx={{ paddingLeft: 1, fontSize: 20 }}>
                  Sprint Name
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={0.4}>
                <Mui.Typography>:</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={5.8}>
                {editField === "sprintname" ? (
                  <Mui.Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    fullWidth
                    value={sprName}
                    options={taskSprintDetails?.filter(
                      (status) => status?.name !== selectedTask?.sprint?.name
                    )}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.name
                          ?.toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }}
                    renderOption={(props, option) => {
                      return <li {...props}>{option.name}</li>;
                    }}
                    onChange={(event, value) => {
                      if (value) {
                        setCurSpr(value);
                        setSprName(value.name);
                      } else {
                        setSprName(null);
                      }
                    }}
                    onBlur={(event) => {
                      if (
                        event.target.value !== "" &&
                        event.target.value !== null
                      ) {
                        updateMoveSprint();
                      } else {
                        setEditField("");
                      }
                    }}
                    sx={{ minWidth: "100%" }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        value={priority}
                        size="small"
                        sx={{ background: themes.whiteColor, padding: 1 }}
                        name="sprint"
                        placeholder="Select sprint"
                        fullWidth
                        autoFocus
                      />
                    )}
                  />
                ) : (
                  <Mui.Stack direction="row">
                    <Mui.Typography sx={{ fontSize: 20 }}>
                      {selectedTask?.sprint?.name}
                    </Mui.Typography>
                    &nbsp;
                    <div>
                      <Mui.IconButton
                        onClick={() => setEditField("sprintname")}
                        disabled={
                          taskSprintDetails?.filter(
                            (status) =>
                              status?.name !== selectedTask?.sprint?.name
                          ).length === 0
                            ? true
                            : false
                        }
                      >
                        <ModeEdit
                          size="Medium"
                          sx={{
                            fontSize: 17,
                          }}
                        />
                      </Mui.IconButton>
                    </div>
                  </Mui.Stack>
                )}
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container sx={{ paddingBottom: 0.5, paddingTop: 0.5 }}>
              <Mui.Grid item xs={5.8}>
                <Mui.Typography sx={{ paddingLeft: 1, fontSize: 20 }}>
                  Start Date
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={0.4}>
                <Mui.Typography>:</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={5.8}>
                {editField === "startdates" ? (
                  <Mui.TextField
                    id="from-date"
                    type="date"
                    sx={{ background: themes.whiteColor, padding: 1 }}
                    value={startDate}
                    size="small"
                    onChange={(event) => setStartDate(event.target.value)}
                    fullWidth
                    autoFocus
                    onBlur={() => updateTaskDetails()}
                    inputProps={{
                      max: "9999-12-31",
                    }}
                  />
                ) : (
                  <Mui.Stack direction="row">
                    <Mui.Typography sx={{ fontSize: 20 }}>
                      {selectedTask?.sprint?.start_date
                        ? selectedTask?.sprint?.start_date
                        : "N/A"}
                    </Mui.Typography>
                  </Mui.Stack>
                )}
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container sx={{ paddingBottom: 0.5, paddingTop: 0.5 }}>
              <Mui.Grid item xs={5.8}>
                <Mui.Typography sx={{ paddingLeft: 1, fontSize: 20 }}>
                  End date
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={0.4}>
                <Mui.Typography>:</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={5.8}>
                {editField === "enddates" ? (
                  <Mui.TextField
                    id="from-date"
                    type="date"
                    sx={{ background: themes.whiteColor, padding: 1 }}
                    value={endDate}
                    size="small"
                    onChange={(event) => setEndDate(event.target.value)}
                    fullWidth
                    autoFocus
                    onBlur={() => updateTaskDetails()}
                    inputProps={{
                      max: "9999-12-31",
                    }}
                  />
                ) : (
                  <Mui.Stack direction="row">
                    <Mui.Typography sx={{ fontSize: 20 }}>
                      {selectedTask?.sprint?.end_date
                        ? selectedTask?.sprint?.end_date
                        : "N/A"}
                    </Mui.Typography>
                  </Mui.Stack>
                )}
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Divider />
            <Mui.Grid container sx={{ paddingBottom: 0.5, paddingTop: 0.5 }}>
              <Mui.Grid item xs={5.8}>
                <Mui.Typography sx={{ paddingLeft: 1, fontSize: 20 }}>
                  Createdby
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={0.4}>
                <Mui.Typography>:</Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={5.8}>
                <Mui.Typography sx={{ fontSize: 20 }}>
                  {selectedTask?.createdby}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialog}
        open={openLabelDialog}
        minWidth={400}
        title="Add Label"
      >
        <Mui.Grid container>
          <Mui.Grid xs={10}></Mui.Grid>
          <Mui.Grid xs={2}>
            <Mui.Stack direction="row">
              {taskLabels.length > 1 ? (
                <>
                  <Mui.IconButton>
                    <ModeEdit
                      onClick={() => setNewLabelCreation("EditLabel")}
                      size="Medium"
                      sx={{
                        fontSize: 15,
                      }}
                    />
                  </Mui.IconButton>
                  <div style={{ paddingLeft: 2 }}>
                    <Mui.IconButton
                      onClick={() => setNewLabelCreation("CreateLabel")}
                    >
                      <Add
                        size="Medium"
                        sx={{
                          fontSize: 15,
                          backgroundColor: themes.blackColor,
                          color: themes.whiteColor,
                        }}
                      />
                    </Mui.IconButton>
                  </div>
                </>
              ) : (
                ""
              )}
            </Mui.Stack>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid className="grid" sx={{ paddingBottom: 1 }}>
          {taskLabels?.map((label) =>
            label.label_name !== "Add label" ? (
              <Mui.Button
                size="small"
                sx={{
                  backgroundColor: label.color_code
                    ? label.color_code
                    : themes.primaryIndicator,
                  color: getContrastingTextColor(
                    label?.color_code
                      ? label?.color_code
                      : themes.primaryIndicator
                  ),
                  borderBottom: `2px solid ${themes.whiteColor}`,
                  "&:hover": {
                    backgroundColor: label.color_code
                      ? label.color_code
                      : themes.primaryIndicator,
                  },
                  textTransform: "capitalize",
                }}
                onClick={() => addTaskLabel(label?.id, "add", selectedTask.id)}
              >
                {label?.label_name}
              </Mui.Button>
            ) : null
          )}
        </Mui.Grid>
        {newLabelCreation === "CreateLabel" ? (
          <>
            {taskLabels.length > 1 ? <Mui.Divider /> : null}
            <Mui.Stack direction="row" sx={{ paddingTop: 2 }}>
              <Mui.Grid container>
                <Mui.Grid xs={4} sx={{ paddingTop: 1 }}>
                  <Mui.Typography>Label Name</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={0.4} sx={{ paddingTop: 1 }}>
                  <Mui.Typography>:</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={7.6}>
                  <Mui.TextField
                    placeholder="Enter label name"
                    onChange={(event) => setNewLabelName(event.target.value)}
                    InputProps={{
                      inputProps: { maxLength: 32 },
                    }}
                    size="small"
                    fullWidth
                  />
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Stack>
            <br />
            <Mui.Stack direction="row">
              <Mui.Grid container>
                <Mui.Grid xs={4} sx={{ paddingTop: 1 }}>
                  <Mui.Typography>Label Color</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={0.4} sx={{ paddingTop: 1 }}>
                  <Mui.Typography>:</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={7.6}>
                  <Mui.TextField
                    type="color"
                    value={newLabelColor}
                    onChange={(event) => setNewLabelColor(event.target.value)}
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    size="small"
                    fullWidth
                  />
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Stack>
            <Mui.Stack direction="row" sx={{ paddingTop: 2 }}>
              <Mui.Grid container>
                <Mui.Grid xs={6}></Mui.Grid>
                <Mui.Grid
                  xs={6}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <CustomButton
                    actionFuntion={() => {
                      setNewLabelCreation("");
                      setEditLabelName("");
                      setOpenLabelDialog(false);
                      setNewLabelColor(themes.primaryIndicator);
                    }}
                    actionName="Cancel"
                    typeName="button"
                  />
                  &nbsp;
                  <CustomButton
                    actionFuntion={() => createNewLabel()}
                    actionName="Add"
                    typeName="button"
                  />
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Stack>
          </>
        ) : newLabelCreation === "EditLabel" ? (
          <>
            <Mui.Divider />
            <Mui.Stack direction="row" sx={{ paddingTop: 1 }}>
              <Mui.Grid container>
                <Mui.Grid xs={4} sx={{ paddingTop: 1 }}>
                  <Mui.Typography>Select Label</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={0.4} sx={{ paddingTop: 1 }}>
                  <Mui.Typography>:</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={7.6}>
                  <Mui.Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    fullWidth
                    options={taskLabels.filter((data) => {
                      return data.label_name !== "Add label";
                    })}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.label_name
                          ?.toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }}
                    renderOption={(props, option) => {
                      return <li {...props}>{option.label_name}</li>;
                    }}
                    onChange={(event, value) => {
                      if (value) {
                        setEditLabelName(value.label_name);
                        setEditLabelId(value.id);
                        setEditLabelColor(value.color_code);
                      } else {
                        setEditLabelName("");
                        setEditLabelId(0);
                        setEditLabelColor(themes.primaryIndicator);
                      }
                    }}
                    sx={{ minWidth: "100%" }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        size="small"
                        sx={{ background: themes.whiteColor }}
                        name="username"
                        placeholder={projectPlaceholder.selectLabel}
                        autoFocus
                      />
                    )}
                  />
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Stack>
            <br />
            <Mui.Stack direction="row">
              <Mui.Grid container>
                <Mui.Grid xs={4} sx={{ paddingTop: 1 }}>
                  <Mui.Typography>Edit Label Name</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={0.4} sx={{ paddingTop: 1 }}>
                  <Mui.Typography>:</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={7.6}>
                  <Mui.TextField
                    placeholder="Enter label name"
                    value={editLabelName}
                    onChange={(event) => setEditLabelName(event.target.value)}
                    InputProps={{
                      inputProps: { maxLength: 32 },
                    }}
                    size="small"
                    fullWidth
                  />
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Stack>
            <br />
            <Mui.Stack direction="row">
              <Mui.Grid container>
                <Mui.Grid xs={4} sx={{ paddingTop: 1 }}>
                  <Mui.Typography>Change Color</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={0.4} sx={{ paddingTop: 1 }}>
                  <Mui.Typography>:</Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={7.6}>
                  <Mui.TextField
                    type="color"
                    value={editLabelColor}
                    onChange={(event) => setEditLabelColor(event.target.value)}
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    size="small"
                    fullWidth
                  />
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Stack>
            <Mui.Stack direction="row">
              <Mui.Grid container>
                <Mui.Grid xs={6}></Mui.Grid>
                <Mui.Grid xs={6}>
                  <CustomButton
                    actionFuntion={() => {
                      setNewLabelCreation("");
                      setEditLabelName("");
                      setEditLabelId(0);
                      setEditLabelColor(themes.primaryIndicator);
                    }}
                    actionName="Cancel"
                    typeName="button"
                  />
                  &nbsp;
                  <CustomButton
                    actionFuntion={() => updateNewLabel()}
                    actionName="Update"
                    typeName="button"
                  />
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Stack>
          </>
        ) : null}
      </GlobalDialogBox>
      {changeAssigneeDialog ? (
        <ChangeAssignee
          open={changeAssigneeDialog}
          status={taskStatus}
          handleClose={handleCloseAssigneeDialog}
          editTask={selectedTask}
          option={"SelectTask"}
          setDoAnyAct={setDoAnyAct}
        />
      ) : null}
    </Mui.Box>
  );

  const handleChange = (event, newValue) => {
    setTabIndicator(newValue);
  };

  const renderTabContent = () => {
    switch (tabIndicator) {
      case 0:
        return (
          <Comments
            selectedTaskid={selectedTaskid}
            mentionMember={
              editProject?.projectType === "Private" ? priProjMembers : allUsers
            }
          />
        );
      case 1:
        return <History selectedTask={selectedTask} />;
      case 2:
        return (
          <Materials
            selectedTaskid={selectedTaskid}
            setActiveTap={setActiveTap}
          />
        );
      case 3:
        return (
          <TaskTimeDetails
            selectedTaskid={selectedTaskid}
            selectedTask={selectedTask}
            fetchTask={fetchTask}
          />
        );
      default:
        alert("They have no option to view");
    }
  };

  const handleCloseDialog = () => {
    setNewLabelCreation("");
    setOpenLabelDialog(false);
    setNewLabelColor(themes.primaryIndicator);
    setEditLabelName("");
    setEditLabelId(0);
    setEditLabelColor(themes.primaryIndicator);
  };

  return (
    <div>
      <React.Fragment>
        <Mui.Drawer
          anchor="right"
          open={openTaskDetails}
          onClose={toggleDrawer("right", false)}
          PaperProps={{
            sx: {
              width: {
                xs: showFullView ? "100%" : 300,
                sm: showFullView ? "100%" : 360,
                md: showFullView ? "100%" : 670,
                lg: showFullView ? "100%" : 1100,
              },
            },
          }}
        >
          {taskDetsLoading ? (
            <Loader info="Loading..." />
          ) : (
            <>
              {list("right")}
              <StyledGrid container>
                <ContentGrid item xs={12}>
                  <Mui.Tabs
                    selectionFollowsFocus
                    textColor={themes.primaryIndicator}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: themes.primaryIndicator,
                      },
                    }}
                    value={tabIndicator}
                    onChange={handleChange}
                    centered
                    variant="fullWidth"
                  >
                    <Mui.Tab
                      aria-label="phone"
                      label={
                        <Mui.Typography
                          sx={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                            color:
                              tabIndicator === 0
                                ? themes.tagBlurColor
                                : themes.dividerBorder,
                          }}
                        >
                          Comments
                        </Mui.Typography>
                      }
                      iconPosition="end"
                    />
                    <Mui.Tab
                      aria-label="phone"
                      label={
                        <Mui.Typography
                          sx={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                            color:
                              tabIndicator === 1
                                ? themes.tagBlurColor
                                : themes.dividerBorder,
                          }}
                        >
                          History
                        </Mui.Typography>
                      }
                      iconPosition="end"
                    />
                    <Mui.Tab
                      aria-label="phone"
                      label={
                        <Mui.Typography
                          sx={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                            color:
                              tabIndicator === 2
                                ? themes.tagBlurColor
                                : themes.dividerBorder,
                          }}
                        >
                          Materials
                        </Mui.Typography>
                      }
                      iconPosition="end"
                    />
                    <Mui.Tab
                      aria-label="phone"
                      label={
                        <Mui.Typography
                          sx={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                            color:
                              tabIndicator === 3
                                ? themes.tagBlurColor
                                : themes.dividerBorder,
                          }}
                        >
                          Time Spent
                        </Mui.Typography>
                      }
                      iconPosition="end"
                    />
                  </Mui.Tabs>
                  <br />
                  {renderTabContent()}
                </ContentGrid>
              </StyledGrid>
            </>
          )}
        </Mui.Drawer>
      </React.Fragment>
      <CreateChildTask
        openCreateChildTask={openCreateChildTask}
        setOpenCreateChildTask={setOpenCreateChildTask}
        selectedTask={selectedTask}
        setFetchDetails={setFetchDetails}
        workflowStatus={workflowStatus}
        setDoAnyAct={setDoAnyAct}
      />
    </div>
  );
};

const StyledGrid = styled(Mui.Grid)`
  height: 80vh;
`;

const ContentGrid = styled(Mui.Grid)`
  width: 80%;
`;
