import React, { useCallback, useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { themes, yearMonth } from "services/constants";
import { useForm } from "react-hook-form";
import {
  minimum3Characters,
  minimum10Digits,
} from "services/constants/ErrorMessages";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import moment from "moment";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import {
  specialCharacterRestrict,
  onlyNumberRegex,
  specialCharacterRest,
} from "services/constants/patterns";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { customAPI } from "services/utils/CustomAPI";
import { setScreenUserId } from "services/Redux/HRPortal";
import { setEditPage } from "services/Redux/HRPortal";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export const BasicInfo = ({
  pageno,
  employeeDetails,
  setUpdateUserDetails,
  reCallMemberApi,
  updateUserDetails,
  setUserId,
  getSelectedUserDetails,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [checkBoxValue, setCheckBoxValue] = useState("");
  const { result: users } = useFetch(`${domain}list/existing-employee`);
  const { result: lastInsertedEmployeeId } = useFetch(
    `${domain}employee-id/last-used`
  );
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedUserCode, setSelectedUserCode] = useState("");
  //Text and autocomplete States
  const [userNameDetails, setUserNameDetails] = useState(
    employeeDetails.user_name
  );
  const [departmentName, setDepartmentName] = useState("");
  const [shiftGradeList, setShiftGradeList] = useState([]);
  const [shiftGradeName, setShiftGradeName] = useState("");
  const [shiftCatgList, setShiftCatgList] = useState([]);
  const [shiftCatgName, setShiftCatgName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [designationName, setDesignationName] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState(
    moment(new Date()).format(yearMonth)
  );
  const [employeeId, setEmployeeId] = useState(employeeDetails.employee_id);
  const [leaveGroupName, setLeaveGroupName] = useState("");
  const [leaveCount, setLeaveCount] = useState(0);
  const [employmentTypeName, setEmploymentTypeName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [saveAndExit, setSaveAndExit] = useState(false);
  const { result: department } = useFetch(`${domain}department/`);
  const { result: designation } = useFetch(`${domain}designation/`);
  const { result: employmentType } = useFetch(`${domain}employment-type/`);
  const { result: userGroup } = useFetch(`${domain}leave-groups-basics/`);
  const { result: companyList } = useFetch(`${domain}get-active-company/`);

  useEffect(() => {
    const updateData = () => {
      setValue(
        "date_joined",
        employeeDetails?.date_of_joined?.split("-").reverse().join("-")
      );
      setValue("user_id", employeeDetails.user_id);
      setValue("user_code", employeeDetails.user_code);
      setValue("userName", employeeDetails.user_name);
      setValue("user_name", employeeDetails.user_name);
      setValue("employee_id", employeeDetails.employee_id);
      setValue("company", employeeDetails.company_name);
      setValue("company_id", employeeDetails.company_id);
      setValue("departmentName", employeeDetails.department_name);
      setValue("department_id", employeeDetails.department_id);
      setValue("leaveGroup", employeeDetails.leave_group_name);
      setValue("leave_group_id", employeeDetails.leave_group_id);
      setValue("leaveGroupCount", employeeDetails.overall_leave_count);
      setValue("phone_number", employeeDetails.phone_number);
      setValue("email", employeeDetails.email);
      setValue("designation", employeeDetails.designation);
      setValue("designation_id", employeeDetails.designation_id);
      setValue("employmentType", employeeDetails?.employment_type);
      setValue("employment_type_id", employeeDetails?.employment_type_id);
      setValue(
        "date_joined",
        employeeDetails?.date_joined?.split("-").reverse().join("-")
      );
      setValue("shiftGradeName", employeeDetails?.shift_grade_name);
      setValue("shift_grade", employeeDetails?.shift_grade);
      setValue("shiftCatgName", employeeDetails?.shift_category_name);
      setValue("shift_category", employeeDetails?.shift_category);
      setUpdateUserDetails(false);
      setSelectedUserCode(employeeDetails?.user_code);
      setSelectedUserName(employeeDetails?.user_name);
      setUserNameDetails(employeeDetails?.user_name);
      setDepartmentName(employeeDetails?.department_name);
      setShiftGradeName(employeeDetails?.shift_grade_name);
      setShiftCatgName(employeeDetails?.shift_category_name);
      setCompanyName(employeeDetails?.company_name);
      setDesignationName(employeeDetails?.designation);
      setDateOfJoining(
        employeeDetails?.date_joined?.split("-").reverse().join("-")
      );
      setEmployeeId(employeeDetails?.employee_id);
      setCheckBoxValue(employeeDetails?.portal_access);
      setLeaveGroupName(employeeDetails?.leave_group_name);
      setEmploymentTypeName(employeeDetails?.employment_type);
      setPhoneNumber(employeeDetails?.phone_number);
      setLeaveCount(employeeDetails?.overall_leave_count);
    };
    if (updateUserDetails && pageno === 1 && employeeDetails.length !== 0) {
      updateData();
    }
  });

  const fetchShiftGradeData = useCallback(
    async (deptId) => {
      try {
        const response = await fetch(
          `${domain}department-shift-category/?department_id=${deptId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setShiftGradeList(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    },
    [domain, token]
  );

  useEffect(() => {
    const deptId = getValues("department_id");
    if (deptId) {
      fetchShiftGradeData(deptId);
    } else {
      setShiftGradeList([]);
    }
  }, [fetchShiftGradeData, getValues, departmentName]);

  const fetchShiftCatgoryData = useCallback(
    async (sftgrdId) => {
      try {
        const response = await fetch(
          `${domain}shift-grade-shift-category/?shift_grade_id=${sftgrdId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setShiftCatgList(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    },
    [domain, token]
  );

  useEffect(() => {
    const sftgrdId = getValues("shift_grade");
    if (sftgrdId) {
      fetchShiftCatgoryData(sftgrdId);
    } else {
      setShiftCatgList([]);
    }
  }, [fetchShiftCatgoryData, getValues, shiftGradeName]);

  const updateUserName = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 32
    ) {
      setUserNameDetails(event.target.value);
      setValue("edit_user_name", event.target.value);
    }
  };

  const handleEmployeeId = (event) => {
    if (
      specialCharacterRest.test(event.target.value) &&
      event.target.value.length <= 12
    ) {
      setValue("employee_id", event.target.value);
      setEmployeeId(event.target.value);
    }
  };

  const handleCompanyName = (event, data) => {
    if (data) {
      setValue("company", data?.label);
      setValue("company_id", data?.id);
      setCompanyName(data?.label);
    } else {
      setValue("company", "");
      setValue("company_id", null);
      setCompanyName("");
    }
  };
  const handleDepartment = async (event, data) => {
    if (data) {
      setValue("departmentName", data?.label);
      setValue("department_id", data?.id);
      setDepartmentName(data?.label);
      setValue("shiftGradeName", "");
      setValue("shift_grade", null);
      setValue("shiftCatgName", "");
      setValue("shift_category", null);
      setShiftGradeName("");
      setShiftCatgList([]);
      setShiftCatgName("");
    } else {
      setValue("departmentName", "");
      setValue("department_id", null);
      setDepartmentName("");
      setValue("shiftGradeName", "");
      setValue("shift_grade", null);
      setValue("shiftCatgName", "");
      setValue("shift_category", null);
      setShiftGradeName("");
      setShiftCatgList([]);
      setShiftCatgName("");
    }
  };

  const handleShiftGradeName = (event, data) => {
    if (data) {
      setValue("shiftGradeName", data?.label);
      setValue("shift_grade", data?.shift_grade_id);
      setShiftGradeName(data?.label);
      setValue("shiftCatgName", "");
      setValue("shift_category", null);
      setShiftCatgName("");
    } else {
      setValue("shiftGradeName", "");
      setValue("shift_grade", null);
      setShiftGradeName("");
      setValue("shiftCatgName", "");
      setValue("shift_category", null);
      setShiftCatgList([]);
      setShiftCatgName("");
    }
  };

  const handleShiftCatgName = (event, data) => {
    if (data) {
      setValue("shiftCatgName", data?.label);
      setValue("shift_category", data?.id);
      setShiftCatgName(data?.label);
    } else {
      setValue("shiftCatgName", "");
      setValue("shift_category", null);
      setShiftCatgName("");
    }
  };

  const handleDesignation = (event, data) => {
    if (data) {
      setValue("designation", data?.label);
      setValue("designation_id", data?.id);
      setDesignationName(data?.label);
    } else {
      setValue("designation", "");
      setValue("designation_id", null);
      setDesignationName("");
    }
  };

  const handleDateOfJoined = (event) => {
    setValue("date_joined", event.target.value);
    setDateOfJoining(event.target.value);
  };

  const handleEmploymentType = (event, data) => {
    if (data) {
      setValue("employmentType", data?.label);
      setValue("employment_type_id", data?.id);
      setEmploymentTypeName(data?.label);
    } else {
      setValue("employmentType", "");
      setValue("employment_type_id", null);
      setEmploymentTypeName("");
    }
  };

  const handleUserLeaveGroup = (event, data) => {
    if (data) {
      setValue("leaveGroup", data?.label);
      setValue("leave_group_id", data?.id);
      setValue("leaveGroupCount", data?.overall_leave_count);
      setLeaveCount(data?.overall_leave_count);
      setLeaveGroupName(data?.label);
    } else {
      setValue("leaveGroup", "");
      setValue("leave_group_id", null);
      setValue("leaveGroupCount", 0);
      setLeaveCount(0);
      setLeaveGroupName("");
    }
  };

  const handleLeaveGroupCount = (event) => {
    event.preventDefault();
  };

  const updateCheckBoxValue = (event) => {
    setCheckBoxValue(event.target.checked);
  };

  const updatePhoneNumber = (event) => {
    if (
      event.target.value.length <= 15 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setPhoneNumber(event.target.value);
      setValue("phone_number", event.target.value);
    }
  };

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      portal_access: checkBoxValue,
    };
    const response = await customAPI(
      `${domain}employee-onboard`,
      "PUT",
      token,
      payload
    );
    if (response.ok) {
      if (saveAndExit) {
        showToast("success", "Employee details updated successfully");
        history.push("/admin/hr/employee");
        reset();
      } else {
        reset();
        getSelectedUserDetails(data?.user_id);
        dispatch(setEditPage(2));
      }
    } else {
      setSaveAndExit(false);
    }
  };

  const updateUserCode = (event, data) => {
    if (data) {
      setUserId(data?.user_id);
      dispatch(setScreenUserId(data?.user_id));
      getSelectedUserDetails(data?.user_id);
    }
  };

  const selectedUserNameDetails = (event, data) => {
    if (data) {
      setUserId(data?.user_id);
      dispatch(setScreenUserId(data?.user_id));
      getSelectedUserDetails(data?.user_id);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid
        container
        sx={{
          padding: { xs: 0.4, sm: 0.4, md: 0.4, lg: 0.6, xl: 1, xxl: 1 },
        }}
      >
        <Mui.Typography
          sx={{
            fontSize: 20,
            fontWeight: "bold",
            color: themes.headLine,
            paddingLeft: 1,
          }}
        >
          Basic Informations
        </Mui.Typography>
      </Mui.Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.Grid
          container
          sx={{
            border: `1px solid ${themes.sidebarDivider}`,
            borderRadius: 3,
            paddingBottom: 1,
          }}
        >
          <Mui.Grid container>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Company
              </Mui.Typography>

              <CustomAutoComplete
                dataList={companyList.map((obj) => ({
                  ...obj,
                  label: obj.companyname,
                }))}
                selectedValue={companyName}
                rules={{
                  ...register("company", {
                    required: "Company name is required",
                  }),
                }}
                updateDataFunction={handleCompanyName}
                placeHolder="Please select the company name"
                errorMessage={
                  errors?.company?.message ? errors?.company?.message : ""
                }
                textBoxError={errors.company ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Name
              </Mui.Typography>
              <CustomAutoComplete
                dataList={users?.map((obj) => ({
                  ...obj,
                  label: obj.user_name,
                }))}
                selectedValue={selectedUserName}
                rules={{
                  ...register("userName", {
                    required: "User name is required",
                  }),
                }}
                updateDataFunction={selectedUserNameDetails}
                placeHolder="Please select the name"
                errorMessage={
                  errors?.userName?.message ? errors?.userName?.message : ""
                }
                textBoxError={errors.userName ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                User Code
              </Mui.Typography>
              <CustomAutoComplete
                dataList={users.map((obj) => ({
                  ...obj,
                  label: obj?.user_code?.toString(),
                }))}
                selectedValue={selectedUserCode}
                rules={{
                  ...register("user_code", {
                    required: "User code is required",
                  }),
                }}
                updateDataFunction={updateUserCode}
                placeHolder="Please select the user code"
                errorMessage={
                  errors?.user_code?.message ? errors?.user_code?.message : ""
                }
                textBoxError={errors?.user_code ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Name
              </Mui.Typography>
              <CustomTextBox
                selectedValue={userNameDetails}
                rules={{
                  ...register("user_name", {
                    required: "Username is required",
                    maxLength: {
                      value: 32,
                      message: "Maximum 32 characters are required",
                    },
                    validate: {
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else {
                          return true;
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updateUserName}
                placeHolder="Enter the Name"
                errorMessage={
                  errors?.user_name?.message ? errors?.user_name?.message : ""
                }
                textBoxError={errors.user_name ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Grid container>
                <Mui.Grid xs={5}>
                  <Mui.Typography
                    sx={{
                      fontSize: 15,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Employee ID
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid
                  xs={7}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Mui.Typography
                    sx={{ color: themes.primaryIndicator, fontSize: 15 }}
                  >
                    Last Used : {lastInsertedEmployeeId.latest_employee_id}
                  </Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
              <CustomTextBox
                selectedValue={employeeId}
                rules={{
                  ...register("employee_id", {
                    required: "Employee Id is required",
                    minLength: {
                      value: 6,
                      message: "Minimum 6 characters are required",
                    },
                    validate: {
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else {
                          return true;
                        }
                      },
                      employeeIdAvailable: async (fieldValue) => {
                        if (
                          fieldValue !== "" &&
                          fieldValue !== employeeDetails.employee_id
                        ) {
                          const response = await fetch(
                            `${domain}employee-id/check?employee_id=${fieldValue}`,
                            {
                              method: "GET",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: `token ${token}`,
                              },
                            }
                          );
                          const resp = await response.json();
                          if (resp.exists) {
                            return "Employee Id Already Exists";
                          }
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={handleEmployeeId}
                placeHolder="Enter the Employee Id"
                errorMessage={
                  errors?.employee_id?.message
                    ? errors?.employee_id?.message
                    : ""
                }
                textBoxError={errors.employee_id ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Date of Joining
              </Mui.Typography>
              <CustomTextBox
                type="date"
                selectedValue={dateOfJoining}
                rules={{
                  ...register("date_joined", {
                    required: "Date of joined is required",
                  }),
                }}
                updateDataFunction={handleDateOfJoined}
                errorMessage={
                  errors?.date_joined?.message
                    ? errors?.date_joined?.message
                    : ""
                }
                textBoxError={errors.date_joined ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Phone Number
              </Mui.Typography>
              <CustomTextBox
                selectedValue={phoneNumber}
                rules={{
                  ...register("phone_number", {
                    required: "Phone number is required",
                    minLength: {
                      value: 10,
                      message: minimum10Digits,
                    },
                    validate: {
                      employeePhonenumberAvailable: async (fieldValue) => {
                        if (
                          fieldValue !== "" &&
                          fieldValue !== employeeDetails.phone_number
                        ) {
                          const response = await fetch(
                            `${domain}unique/phone-number/?phone_no=${fieldValue}`,
                            {
                              method: "GET",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: `token ${token}`,
                              },
                            }
                          );
                          const resp = await response.json();
                          if (resp.exists) {
                            return "Phone number already exists";
                          }
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updatePhoneNumber}
                placeHolder="Enter Phone Number"
                errorMessage={
                  errors?.phone_number?.message
                    ? errors?.phone_number?.message
                    : ""
                }
                textBoxError={errors.phone_number ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Email Id
              </Mui.Typography>
              <Mui.TextField
                size="small"
                {...register("email", {
                  required: false,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid e-mail address",
                  },
                  validate: {
                    emailAvailable: async (fieldValue) => {
                      if (
                        fieldValue !== "" &&
                        fieldValue !== employeeDetails.email
                      ) {
                        try {
                          const response = await fetch(
                            `${domain}unique/email/?email=${fieldValue}`,
                            {
                              method: "GET",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: `token ${token}`,
                              },
                            }
                          );
                          const res = await response.json();
                          if (response.ok) {
                            if (res.exists) {
                              return "Email Already Exists";
                            } else {
                              return true;
                            }
                          } else if (response.status === 409) {
                            sessionHandling();
                          } else {
                            throw new Error(res.error);
                          }
                        } catch (error) {
                          showToast("error", error.message);
                        }
                      }
                    },
                  },
                })}
                error={Boolean(errors.email)}
                helperText={errors.email ? errors.email.message : null}
                placeholder="Enter your email"
                fullWidth
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Employment Type
              </Mui.Typography>
              <CustomAutoComplete
                dataList={employmentType.map((obj) => ({
                  ...obj,
                  label: obj.employment_type,
                }))}
                selectedValue={employmentTypeName}
                rules={{
                  ...register("employmentType", {
                    required: "Employment Type is required",
                  }),
                }}
                updateDataFunction={handleEmploymentType}
                placeHolder="Please select the employment type"
                errorMessage={
                  errors?.employmentType?.message
                    ? errors?.employmentType?.message
                    : ""
                }
                textBoxError={errors.employmentType ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Department
              </Mui.Typography>
              <CustomAutoComplete
                dataList={department.map((obj) => ({
                  ...obj,
                  label: obj.department,
                }))}
                selectedValue={departmentName}
                rules={{
                  ...register("departmentName", {
                    required: "Department name is required",
                  }),
                }}
                updateDataFunction={handleDepartment}
                placeHolder="Please select the department name"
                errorMessage={
                  errors?.departmentName?.message
                    ? errors?.departmentName?.message
                    : ""
                }
                textBoxError={errors?.departmentName ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Shift Grade
              </Mui.Typography>
              <CustomAutoComplete
                dataList={shiftGradeList.map((obj) => ({
                  ...obj,
                  label: obj.shift_grade_name,
                }))}
                selectedValue={shiftGradeName}
                rules={{
                  ...register("shiftGradeName", {
                    required: "Shift grade is required",
                  }),
                }}
                updateDataFunction={handleShiftGradeName}
                placeHolder="Please select the shift grade"
                errorMessage={
                  errors?.shiftGradeName?.message
                    ? errors?.shiftGradeName?.message
                    : ""
                }
                textBoxError={errors?.shiftGradeName ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Shift Category
              </Mui.Typography>
              <CustomAutoComplete
                dataList={shiftCatgList.map((obj) => ({
                  ...obj,
                  label: obj.shift_category_name,
                }))}
                selectedValue={shiftCatgName}
                rules={{
                  ...register("shiftCatgName", {
                    required: "Department name is required",
                  }),
                }}
                updateDataFunction={handleShiftCatgName}
                placeHolder="Please select the shift category"
                errorMessage={
                  errors?.departmentName?.message
                    ? errors?.departmentName?.message
                    : ""
                }
                textBoxError={errors?.departmentName ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Designation
              </Mui.Typography>
              <CustomAutoComplete
                dataList={designation.map((obj) => ({
                  ...obj,
                  label: obj.designation,
                }))}
                selectedValue={designationName}
                rules={{
                  ...register("designation", {
                    required: "Designation is required",
                  }),
                }}
                updateDataFunction={handleDesignation}
                placeHolder="Please select the designation"
                errorMessage={
                  errors?.designation?.message
                    ? errors?.designation?.message
                    : ""
                }
                textBoxError={errors?.designation ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Leave Group
              </Mui.Typography>
              <CustomAutoComplete
                dataList={userGroup.map((obj) => ({
                  ...obj,
                  label: obj.name,
                }))}
                selectedValue={leaveGroupName}
                rules={{
                  ...register("leaveGroup", {
                    required: "Leave group is required",
                  }),
                }}
                updateDataFunction={handleUserLeaveGroup}
                placeHolder="Please select the leave group"
                errorMessage={
                  errors?.leaveGroup?.message ? errors?.leaveGroup?.message : ""
                }
                textBoxError={errors?.leaveGroup ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Count
              </Mui.Typography>
              <CustomTextBox
                selectedValue={leaveCount}
                rules={{
                  ...register("leaveGroupCount", {
                    required: false,
                  }),
                }}
                updateDataFunction={handleLeaveGroupCount}
                placeHolder="Leave count"
                errorMessage={
                  errors?.leaveGroupCount?.message
                    ? errors?.leaveGroupCount?.message
                    : ""
                }
                textBoxError={errors.leaveGroupCount ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>

          <Mui.Grid
            container
            sx={{
              paddingBottom: { xs: 2, sm: 4, md: 4, lg: 5, xl: 5, xxl: 5 },
            }}
          />

          <Mui.Grid
            container
            alignItems="center"
            spacing={2}
            style={{ paddingLeft: 5, paddingRight: 5 }}
          >
            <Mui.Grid item xs={12} md={2}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: themes.headLine,
                  paddingLeft: 1,
                }}
              >
                Enable Portal Access
              </Mui.Typography>
            </Mui.Grid>

            <Mui.Grid
              item
              xs={2}
              md="auto"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Mui.FormControlLabel
                control={
                  <Mui.Checkbox
                    checked={checkBoxValue}
                    color="primary"
                    size="large"
                    onChange={updateCheckBoxValue}
                  />
                }
                label=""
                sx={{ marginRight: 1 }}
              />
            </Mui.Grid>

            <Mui.Grid item xs={10} md>
              <Mui.Typography
                sx={{
                  fontSize: 16,
                  color: themes.headLine,
                }}
              >
                The employee will be able to submit their leaves, reimbursement
                claims, view, and generate payslips through the employee portal.
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>

        <Mui.Grid
          container
          sx={{
            paddingBottom: { xs: 2, sm: 4, md: 4, lg: 5, xl: 5, xxl: 5 },
          }}
        />
        <Mui.Grid container sx={{ padding: 2 }}>
          <Mui.Grid xs={2}>
            <CustomButton
              actionFuntion={() => {
                setSaveAndExit(true);
              }}
              actionName="Save & Exit"
              typeName="submit"
            />
          </Mui.Grid>
          <Mui.Grid xs={8}></Mui.Grid>
          <Mui.Grid
            xs={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton actionName="Next" typeName="submit" />
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
