import React, { useContext, useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { showToast } from "services/utils/Status";
import { useForm } from "react-hook-form";
import { NewProjectContext } from "./NewProjectContext";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { projectSuccess } from "services/constants/SuccessMessages";
import { projectPlaceholder } from "services/constants/PlaceHolder";
import sessionHandling from "services/utils/notificationUtils";

export const CreateClient = ({ handleCloseDialogBox, openCreateClient }) => {
  const { setFetchClientApi, domain, token } = useContext(NewProjectContext);
  const [yesCheck, setYesCheck] = useState(false);
  const [noCheck, setNoCheck] = useState(true);

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    setValue,
    formState: { errors },
    getValues,
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    setValue("gstno", "");
  }, [setValue]);

  const onSubmit = async (data) => {
    try {
      const response = await fetch(`${domain}client/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          clientname: data.clientname,
          clientid: data.clientcode,
          isdeleted: false,
          gstnumber: data.gstno,
          address: data.address,
          email: data.email,
          phonenumber: data.phoneno,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        reset();
        setFetchClientApi(true);
        setYesCheck(false);
        setNoCheck(true);
        showToast("success", projectSuccess.addClient);
        closeDialog();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const setYesCheckUpdated = () => {
    if (yesCheck) {
      setYesCheck(false);
      trigger("gstno");
    } else {
      setYesCheck(true);
      setNoCheck(false);
    }
  };

  const setNoCheckUpdated = () => {
    if (noCheck) {
      setNoCheck(false);
      trigger("gstno");
    } else {
      trigger("gstno");
      setNoCheck(true);
      setYesCheck(false);
      setValue("gstno", "");
    }
  };

  const handleKeyDown = (e) => {
    const allowedKeys = /^[A-Za-z\s]$/;
    if (!allowedKeys.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const closeDialog = () => {
    handleCloseDialogBox();
    reset();
    setYesCheck(false);
    setNoCheck(true);
  };

  const validateGST = (gstNo) => {
    const regex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
    return regex.test(gstNo);
  };

  return (
    <React.Fragment>
      <GlobalDialogBox
        handleCloseDialog={closeDialog}
        open={openCreateClient}
        title="Add Client"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Mui.Grid container sx={{ paddingBottom: 2 }}>
            <Mui.Grid xs={12} sm={12} md={12} lg={12}>
              <Mui.Typography>Client Name</Mui.Typography>
              <Mui.TextField
                {...register("clientname", {
                  required: "Client name is required",
                  maxLength: {
                    value: 30,
                    message: "Maximum length is 30 characters",
                  },
                  minLength: {
                    value: 3,
                    message: "Minimum length is 3 characters",
                  },
                  validate: {
                    noDigitsOrSpecial: (fieldValue) => {
                      if (!/[A-Za-z]/.test(fieldValue)) {
                        return "Client name must contain at least one alphabet character.";
                      }
                      if (/^\d+$/.test(fieldValue)) {
                        return "Client name should not contain only digits.";
                      }
                      if (!/^[A-Za-z0-9\s&-]+$/.test(fieldValue)) {
                        return "Client name should only contain alphabets, spaces, -,numbers and & symbol.";
                      }
                      return true;
                    },
                  },
                })}
                error={errors.clientname ? true : false}
                helperText={errors.clientname?.message}
                sx={{ background: themes.whiteColor }}
                onKeyPress={handleKeyDown}
                InputProps={{
                  inputProps: { maxLength: 30 },
                }}
                name="clientname"
                placeholder={projectPlaceholder.clientName}
                fullWidth
              />
            </Mui.Grid>
            <Mui.Grid xs={0} sm={0} md={0} lg={0.4}></Mui.Grid>
            <Mui.Grid xs={12} sm={12} md={12} lg={12} sx={{ paddingTop: 1 }}>
              <Mui.Typography>Client Code</Mui.Typography>
              <Mui.TextField
                {...register("clientcode", {
                  required: "Client code is required",
                  minLength: {
                    value: 3,
                    message: "Minimum length is 3 characters",
                  },
                  validate: {
                    clientCodeAvailable: async (fieldValue) => {
                      try {
                        const response = await fetch(
                          `${domain}check_client_name/?client_id=${fieldValue}`,
                          {
                            method: "GET",
                            headers: {
                              "content-type": "application/json",
                              Authorization: `token ${token}`,
                            },
                          }
                        );
                        const res = await response.json();
                        if (response.ok) {
                          if (res?.exists) {
                            return "That client ID is already taken.Please choose a unique ID for your client";
                          } else {
                            return true;
                          }
                        } else if (response.status === 409) {
                          sessionHandling();
                        } else {
                          throw new Error(res.error);
                        }
                      } catch (error) {
                        showToast("error", error.message);
                      }
                    },
                  },
                })}
                error={errors.clientcode ? true : false}
                helperText={errors.clientcode?.message}
                sx={{ background: themes.whiteColor }}
                name="clientcode"
                placeholder={projectPlaceholder.clientCode}
                onKeyPress={handleKeyPress}
                InputProps={{
                  inputProps: { maxLength: 3 },
                }}
                fullWidth
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid xs={12} sm={12} md={12} lg={12} sx={{ marginTop: -2 }}>
              <Mui.Stack direction="row">
                <Mui.Typography sx={{ marginTop: 1 }}>
                  Is GST Registered ?{" "}
                </Mui.Typography>
                <Mui.Grid>
                  <Mui.Checkbox
                    checked={yesCheck}
                    onChange={() => setYesCheckUpdated()}
                  />
                </Mui.Grid>
                <Mui.Typography sx={{ marginTop: 1 }}>Yes</Mui.Typography>
                <Mui.Grid>
                  <Mui.Checkbox
                    checked={noCheck}
                    onChange={() => setNoCheckUpdated()}
                  />
                </Mui.Grid>
                <Mui.Typography sx={{ marginTop: 1 }}>No</Mui.Typography>
              </Mui.Stack>
              <Mui.TextField
                multiline
                minRows={1}
                maxRows={5}
                defaultValue=""
                {...register("gstno", {
                  required: yesCheck ? "Gst no is required" : false,
                  validate: {
                    gstnoAvailable: async (fieldValue) => {
                      if (yesCheck && fieldValue?.length < 15) {
                        return "Minimum length is 15 characters";
                      }
                    },
                    gstNoValidation: async (fieldValue) => {
                      if (fieldValue !== "" && fieldValue !== undefined) {
                        const valid = validateGST(fieldValue);
                        return valid
                          ? true
                          : "Please Gst no in format 22AAAAA0000A1Z5";
                      }
                    },
                  },
                })}
                error={errors.gstno && yesCheck ? true : false}
                helperText={
                  errors.gstno && yesCheck ? errors.gstno?.message : null
                }
                sx={{ background: themes.whiteColor }}
                name="gstno"
                placeholder={projectPlaceholder.gstNo}
                disabled={yesCheck ? false : true}
                InputProps={{
                  inputProps: { maxLength: 15 },
                }}
                fullWidth
              />
            </Mui.Grid>
            <Mui.Grid xs={0} sm={0} md={0} lg={0.4}></Mui.Grid>
            <Mui.Grid xs={12} sm={12} md={12} lg={12} sx={{ paddingTop: 1 }}>
              <Mui.Typography>Address</Mui.Typography>
              <Mui.TextField
                {...register("address", {
                  required: "Address is required",
                })}
                error={errors.address ? true : false}
                helperText={errors.address?.message}
                sx={{ background: themes.whiteColor }}
                name="address"
                placeholder={projectPlaceholder.clientAddress}
                fullWidth
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ paddingBottom: 2 }}>
            <Mui.Grid xs={12} sm={12} md={12} lg={12} sx={{ paddingTop: 1 }}>
              <Mui.Typography>Email</Mui.Typography>
              <Mui.TextField
                id="email"
                variant="outlined"
                {...register("email", {
                  required: "Email id is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                error={Boolean(errors.email)}
                helperText={errors.email?.message || ""}
                placeholder={projectPlaceholder.clientEmail}
                fullWidth
              />
            </Mui.Grid>
            <Mui.Grid xs={0} sm={0} md={0} lg={0.4}></Mui.Grid>
            <Mui.Grid xs={12} sm={12} md={12} lg={12} sx={{ paddingTop: 1 }}>
              <Mui.Typography>Phone Number</Mui.Typography>
              <Mui.TextField
                {...register("phoneno", {
                  required: "Phone no is required",
                  minLength: {
                    value: 10,
                    message: "Minimum length is 10 digits",
                  },
                  maxLength: {
                    value: 15,
                    message: "Maximum length is 15 digits",
                  },
                })}
                error={errors.phoneno ? true : false}
                helperText={errors.phoneno?.message}
                sx={{ background: themes.whiteColor }}
                name="phoneno"
                placeholder={projectPlaceholder.phoneNumber}
                onKeyPress={handleKeyPress}
                fullWidth
                InputProps={{
                  inputProps: { maxLength: 15 },
                }}
                onBlur={() => {
                  setValue(
                    "phoneno",
                    getValues("phoneno").replace(/[^\d-]/g, "")
                  );
                }}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ paddingTop: 2 }}>
            <Mui.Grid xs={12} sm={6} md={6} lg={12}></Mui.Grid>
            <Mui.Grid xs={12} sm={12} md={12} lg={12}>
              <Mui.Button
                size="large"
                variant="contained"
                type="submit"
                sx={{
                  backgroundColor: themes.primaryIndicator,
                  padding: 1,
                  width: "100%",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: themes.primaryIndicator,
                  },
                }}
              >
                Add
              </Mui.Button>
            </Mui.Grid>
          </Mui.Grid>
        </form>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
