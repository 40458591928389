import React, { useState, useEffect } from "react";
import { CustomButton } from "components/CustomComponents/CustomButton";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { useDispatch } from "react-redux";
import { ShiftGrade } from "./ShiftGrade";
import { ShiftCategory } from "./ShiftCategory";
import { ShiftGroup } from "./ShiftGroup";
import { ShiftAssign } from "./ShiftAssignment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { themes, defaultAllSelection } from "services/constants";
import sessionHandling from "services/utils/notificationUtils";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";

export const Shift = () => {
  const { departmentId, departmentName } = useSelector(
    (state) => state.userReducer
  );
  const history = useHistory();
  const [shiftCategory, setshiftCategory] = useState(false);
  const [showTopBar, setShowTopBar] = useState(true);
  const [workUnit, setWorkUnit] = useState("ShiftGrade");
  const [selectComponent, setSelectComponent] = useState("ShiftGrade");
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [payUnit, setPayUnit] = useState(false);
  const [editWorkUnitPay, setEditWorkUnitPay] = useState(false);
  const [departmentData, setDepartmentData] = useState([]);
  const [addShiftGroup, setAddShiftGroup] = useState(false);
  const [editShiftGroup, setEditShiftGroup] = useState(false);
  const [addShiftCategory, setAddShiftCategory] = useState(false);
  const [editShiftCategory, setEditShiftCategory] = useState(false);
  const {
    result: workUnitGroup,
    setLoad: reCallApi,
    loading,
  } = useFetch(`${domain}list_shift_categories/`);
  const dispatch = useDispatch();
  const handleChange = (event, newAlignment) => {
    setWorkUnit(newAlignment);
    dispatch(setGlobalSearchValue(""));
  };

  useEffect(() => {
    dispatch(setDepartmentName("All"));
    dispatch(setDepartmentId(99999));
  }, [dispatch]);

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const response = await fetch(`${domain}department/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const allDepartment = {
            id: defaultAllSelection,
            department: "All",
          };
          setDepartmentData([allDepartment, ...res]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchDepartmentData();
  }, [domain, token]);

  const renderComponent = () => {
    switch (selectComponent) {
      case "ShiftGrade":
        return (
          <ShiftGrade
            payUnit={payUnit}
            setPayUnit={setPayUnit}
            editWorkUnitPay={editWorkUnitPay}
            setEditWorkUnitPay={setEditWorkUnitPay}
            setShowTopBar={setShowTopBar}
          />
        );
      case "Group":
        return (
          <ShiftCategory
            shiftCategory={shiftCategory}
            departmentId={departmentId}
            workUnitGroup={workUnitGroup}
            reCallApi={reCallApi}
            loading={loading}
            setShowTopBar={setShowTopBar}
            setshiftCategory={setshiftCategory}
          />
        );
      case "ShiftGroup":
        return (
          <ShiftGroup
            addShiftGroup={addShiftGroup}
            setAddShiftGroup={setAddShiftGroup}
            setShowTopBar={setShowTopBar}
            editShiftGroup={editShiftGroup}
            setEditShiftGroup={setEditShiftGroup}
          />
        );
      case "ShiftAssign":
        return (
          <ShiftAssign
            addShiftCategory={addShiftCategory}
            setAddShiftCategory={setAddShiftCategory}
            setShowTopBar={setShowTopBar}
            editShiftCategory={editShiftCategory}
            setEditShiftCategory={setEditShiftCategory}
          />
        );

      default:
        break;
    }
  };

  const updateDepartmentName = (event, data) => {
    if (data) {
      dispatch(setDepartmentName(data.label));
      dispatch(setDepartmentId(data.id));
      localStorage.setItem("approveLeaveDepartmentId", data.id);
      localStorage.setItem("approveLeaveDepartmentName", data.label);
      reCallApi(true);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 2, paddingLeft: 1 }}>
        {showTopBar ? (
          <>
            <Mui.Grid item xs={5}>
              <Mui.Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: 715,
                  borderRadius: 3,
                  height: 55,
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Mui.ToggleButtonGroup
                  value={workUnit}
                  exclusive
                  onChange={handleChange}
                  sx={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    borderRadius: 3,
                    backgroundColor: themes.lightBrown,
                  }}
                >
                  <Mui.ToggleButton
                    value="ShiftGrade"
                    sx={{
                      flex: 1,
                      textTransform: "capitalize",
                      fontSize: 18,

                      fontWeight: 600,
                      border: "none",
                      color:
                        workUnit === "ShiftGrade"
                          ? themes.whiteColor
                          : themes.blackColor,
                      backgroundColor:
                        workUnit === "ShiftGrade"
                          ? themes.blackColor
                          : themes.lightBrown,
                      "&.Mui-selected": {
                        backgroundColor: themes.blackColor,
                        color: themes.whiteColor,
                        "&:hover": {
                          backgroundColor: themes.blackColor,
                          color: themes.whiteColor,
                        },
                      },
                      "&:hover": {
                        backgroundColor:
                          workUnit === "ShiftGrade"
                            ? themes.blackColor
                            : themes.lightBrown,
                        color:
                          workUnit === "ShiftGrade"
                            ? themes.whiteColor
                            : themes.blackColor,
                      },
                    }}
                    onClick={() => setSelectComponent("ShiftGrade")}
                  >
                    Shift Grade
                  </Mui.ToggleButton>
                  <Mui.ToggleButton
                    value="Group"
                    sx={{
                      flex: 1,
                      textTransform: "capitalize",
                      fontSize: 18,
                      fontWeight: 600,
                      borderRadius: 3,
                      border: "none",
                      color:
                        workUnit === "Group"
                          ? themes.whiteColor
                          : themes.blackColor,
                      backgroundColor:
                        workUnit === "Group"
                          ? themes.blackColor
                          : themes.lightBrown,
                      "&.Mui-selected": {
                        backgroundColor: themes.blackColor,
                        color: themes.whiteColor,
                        "&:hover": {
                          backgroundColor: themes.blackColor,
                          color: themes.whiteColor,
                        },
                      },
                      "&:hover": {
                        backgroundColor:
                          workUnit === "Group"
                            ? themes.blackColor
                            : themes.lightBrown,
                        color:
                          workUnit === "Group"
                            ? themes.whiteColor
                            : themes.blackColor,
                      },
                    }}
                    onClick={() => setSelectComponent("Group")}
                  >
                    Shift Category
                  </Mui.ToggleButton>
                  <Mui.ToggleButton
                    value="ShiftGroup"
                    sx={{
                      flex: 1,
                      textTransform: "capitalize",
                      fontSize: 18,
                      fontWeight: 600,
                      borderRadius: 3,
                      border: "none",
                      color:
                        workUnit === "ShiftGroup"
                          ? themes.whiteColor
                          : themes.blackColor,
                      backgroundColor:
                        workUnit === "ShiftGroup"
                          ? themes.blackColor
                          : themes.lightBrown,
                      "&.Mui-selected": {
                        backgroundColor: themes.blackColor,
                        color: themes.whiteColor,
                        "&:hover": {
                          backgroundColor: themes.blackColor,
                          color: themes.whiteColor,
                        },
                      },
                      "&:hover": {
                        backgroundColor:
                          workUnit === "ShiftGroup"
                            ? themes.blackColor
                            : themes.lightBrown,
                        color:
                          workUnit === "ShiftGroup"
                            ? themes.whiteColor
                            : themes.blackColor,
                      },
                      "&:focus": {
                        backgroundColor:
                          workUnit === "ShiftGroup"
                            ? themes.blackColor
                            : themes.lightBrown,
                        color:
                          workUnit === "ShiftGroup"
                            ? themes.whiteColor
                            : themes.blackColor,
                      },
                    }}
                    onClick={() => setSelectComponent("ShiftGroup")}
                  >
                    Shift Group
                  </Mui.ToggleButton>
                  <Mui.ToggleButton
                    value="ShiftAssign"
                    sx={{
                      flex: 1,
                      textTransform: "capitalize",
                      fontSize: 18,
                      fontWeight: 600,
                      borderRadius: 3,
                      border: "none",
                      color:
                        workUnit === "ShiftAssign"
                          ? themes.whiteColor
                          : themes.blackColor,
                      backgroundColor:
                        workUnit === "ShiftAssign"
                          ? themes.blackColor
                          : themes.lightBrown,
                      "&.Mui-selected": {
                        backgroundColor: themes.blackColor,
                        color: themes.whiteColor,
                        "&:hover": {
                          backgroundColor: themes.blackColor,
                          color: themes.whiteColor,
                        },
                      },
                      "&:hover": {
                        backgroundColor:
                          workUnit === "ShiftAssign"
                            ? themes.blackColor
                            : themes.lightBrown,
                        color:
                          workUnit === "ShiftAssign"
                            ? themes.whiteColor
                            : themes.blackColor,
                      },
                      "&:focus": {
                        backgroundColor:
                          workUnit === "ShiftAssign"
                            ? themes.blackColor
                            : themes.lightBrown,
                        color:
                          workUnit === "ShiftAssign"
                            ? themes.whiteColor
                            : themes.blackColor,
                      },
                    }}
                    onClick={() => setSelectComponent("ShiftAssign")}
                  >
                    Shift Assignment
                  </Mui.ToggleButton>
                </Mui.ToggleButtonGroup>
              </Mui.Box>
            </Mui.Grid>
            <Mui.Grid xs={selectComponent === "ShiftAssign" ? 4.2 : 5.5} />

            <Mui.Grid
              xs={selectComponent === "ShiftAssign" ? 2.8 : 1.5}
              sx={{ paddingTop: 1.5 }}
            >
              {/* <Mui.Grid xs={6} sx={{ paddingRight: 1 }}>
                      <CustomAutoComplete
                        dataList={departmentData?.map((obj) => ({
                          ...obj,
                          label: obj.department,
                        }))}
                        selectedValue={departmentName}
                        updateDataFunction={updateDepartmentName}
                        placeHolder="Select department name"
                        label="Select Department Name"
                      />
                    </Mui.Grid> */}

              {selectComponent === "ShiftGrade" ? (
                <CustomButton
                  actionFuntion={() => {
                    setPayUnit(true);
                    setShowTopBar(false);
                  }}
                  actionName="Create Shift Grade"
                  typeName="button"
                />
              ) : selectComponent === "Group" ? (
                <CustomButton
                  actionFuntion={() => {
                    setShowTopBar(false);
                    setshiftCategory(true);
                  }}
                  actionName="Create Shift Category"
                  typeName="button"
                />
              ) : selectComponent === "ShiftGroup" ? (
                <CustomButton
                  actionFuntion={() => {
                    setShowTopBar(false);
                    setAddShiftGroup(true);
                  }}
                  actionName="Create Shift Group"
                  typeName="button"
                />
              ) : selectComponent === "ShiftAssign" ? (
                <>
                  <Mui.Grid container>
                    <Mui.Grid item>
                      <CustomButton
                        actionFuntion={() => {
                          history.push("/admin/teamzone/plan-shift");
                        }}
                        actionName="View Shift Plan"
                        typeName="button"
                      />
                    </Mui.Grid>
                    <Mui.Grid xs={0.5}></Mui.Grid>
                    <Mui.Grid item>
                      <CustomButton
                        actionFuntion={() => {
                          setShowTopBar(false);
                          setAddShiftCategory(true);
                        }}
                        actionName="Create Shift Assignment"
                        typeName="button"
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                </>
              ) : null}
            </Mui.Grid>
          </>
        ) : null}
      </Mui.Grid>
      {renderComponent()}
    </React.Fragment>
  );
};
