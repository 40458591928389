import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import { Delete, Edit } from "@mui/icons-material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Loader } from "components/Loader";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { ViewGroupMembers } from "../ViewGroupMembers";
import { AssignGroup } from "./AssignGroup";
// import { EditAssignGroup } from "./EditAssignGroup";
import { EditAssignGroup1 } from "./EditAssignGroup1";
import { AssignUnitTable } from "./AssignUnitTable";
import { AddAssignGroup } from "./AddAssignGroup";

const keys = [
  "amount",
  "criteria_type",
  "criteria_value",
  "cycle",
  "effective_from",
  "incentive_mode",
  "incentive_type",
  "previous_effective_from",
  "select_type",
];

export const AssignUnit = ({
  companyId,
  assignGroupUnit,
  setAssignGroupUnit,
  editAssignUnit,
  setEditAssignUnit,
  setShowTopBar,
}) => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [viewAssignDetails, setViewAssignDetails] = useState({});
  const [renderComponentName, setRenderComponentName] = useState("ListView");
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteIncentiveDetails, setDeleteIncentiveDetails] = useState({});
  const [incentiveDetailsStateData, setIncentiveStateData] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (assignGroupUnit) {
      setRenderComponentName("AddAssignGroup");
      setAssignGroupUnit(false);
    }
  }, [assignGroupUnit, setAssignGroupUnit]);

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  const updateDeleteIncentive = async () => {
    setButtonDisabled(true);
    try {
      const response = await fetch(
        `${domain}incentives/${deleteIncentiveDetails.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            isdeleted: true,
            incentive_type: deleteIncentiveDetails.incentive_type,
            cycle: deleteIncentiveDetails.cycle,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Deleted successfully");
        setOpenDialog(false);
        setButtonDisabled(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  const renderComponent = () => {
    switch (renderComponentName) {
      case "ListView":
        return (
          <AssignUnitTable
            setRenderComponentName={setRenderComponentName}
            setViewAssignDetails={setViewAssignDetails}
            setShowTopBar={setShowTopBar}
            viewAssignDetails={viewAssignDetails}
          />
        );
      case "EditAssignGroup":
        return (
          <EditAssignGroup1
            viewAssignDetails={viewAssignDetails}
            setRenderComponentName={setRenderComponentName}
            setShowTopBar={setShowTopBar}
          />
        );
      case "AddAssignGroup":
        return (
          <AddAssignGroup
            backToAssignScreens={setEditAssignUnit}
            setShowTopBar={setShowTopBar}
            setRenderComponentName={setRenderComponentName}
          />
        );
      case "ViewAssignGroup":
        return (
          <ViewGroupMembers
            viewAssignDetails={viewAssignDetails}
            setShowTopBar={setShowTopBar}
            setRenderComponentName={setRenderComponentName}
          />
        );
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      {renderComponent()}
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openDialog}
        title="Delete Incentive"
      >
        <Mui.Typography sx={{ paddingBottom: 2, fontSize: 22 }}>
          Are you sure ,you want to delete the{" "}
          <span style={{ color: themes.redColor }}>
            {deleteIncentiveDetails.name}
          </span>{" "}
          incentive?
        </Mui.Typography>
        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <CustomButton
              actionFuntion={() => setOpenDialog(false)}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={() => updateDeleteIncentive()}
              actionName="Yes"
              typeName="button"
              disableAction={
                openDialog && buttonDisabled ? buttonDisabled : false
              }
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
