/* eslint-disable indent */
//Icons
import {
  CalendarMonthSharp,
  ContactPageSharp,
  SickSharp,
  AvTimerSharp,
  RotateLeftSharp,
  ConnectWithoutContact,
  DriveFolderUpload,
  TransferWithinAStation,
  PlaylistAddCheckCircle,
  PresentToAll,
  PersonAddAlt1,
  AccountTree,
  Checklist,
  Person,
  Diversity3,
  BusinessCenter,
  SupervisedUserCircle,
  Business,
  LocationCity,
  FilterTiltShift,
  Pool,
  Person4,
  Settings as SettingsIcons,
  Badge,
  MonetizationOn,
  WorkHistory,
  ManageAccounts,
  Diversity2,
  CurrencyRupee,
} from "@mui/icons-material";
//Auth

import { UpgrateNotification } from "pages/UserNotifications/UpgrateNotification";
import { ForgotPassword } from "pages/GateKeeper/ForgotPassword";
import { Login } from "pages/GateKeeper/Login";
import { SendOTP } from "pages/GateKeeper/SendOTP";
//MyZone
import { Calendar } from "pages/MyZone/Calendar";
import { MyLeaves } from "pages/MyZone/MyLeaves";
import { EssStatus } from "pages/MyZone/Status";
import { MyShift } from "pages/MyZone/MyShift";
import { Payslip } from "pages/MyZone/Payslip";
import { IndividualWorkingHours } from "pages/MyZone/WorkingHours";
import { MyZoneApply } from "pages/MyZone/MyZoneApply";
//TeamZone
import { TeamZoneCalendar } from "pages/TeamZone/TeamZoneCalendar";
import { LeaveSummary } from "pages/TeamZone/LeaveSummary";
import { PlanShift } from "pages/TeamZone/PlanShift";
import { ShiftGroupIndex } from "pages/TeamZone/PlanShift/ShiftGroup";
import { Status } from "pages/TeamZone/Attendance/Status";
import { Approve } from "pages/TeamZone/Approve";
// import { ApplyLeave } from "pages/MyZone/ApplyLeave";
import { TeamZoneSettings } from "pages/TeamZone/TeamZoneSettings";
import { Employee } from "pages/TeamZone/Employee";
import { EmployeeOnBoard } from "pages/TeamZone/Employee/EmployeeOnBord";
import { AddNewEmployee } from "pages/TeamZone/Employee/AddNewEmployee";
import { Reports } from "pages/TeamZone/Reports";
import { BulkImport } from "pages/TeamZone/Employee/BulkImport";
//Administration
import { Companies } from "pages/Administration/Companies";
import { Departments } from "pages/Administration/Departments";
import { CreateUser } from "pages/Administration/CreateUser";
import { Master } from "pages/Administration/Master";
import { Designation } from "pages/Administration/Master/Screens/Designation";
import { EmploymentType } from "pages/Administration/Master/Screens/EmploymentType";
import { WorkUnit } from "pages/Administration/WorkUnit";
import { Shift } from "pages/Administration/Shift";
import Holidays from "pages/Administration/Holidays";
import { ShiftCategory } from "pages/Administration/ShiftCategory";
import { Users } from "pages/Administration";
import { AddShiftCategory } from "pages/Administration/ShiftCategory/AddShiftCategory";
import { ViewMembers } from "pages/Administration/Departments/ViewMembers";
import { ViewUserGroup } from "pages/Administration/Companies/UserGroup/ViewUserGroup";
import { ViewCompany } from "pages/Administration/Companies/ViewCompany";
import { EditProfile } from "pages/GateKeeper/EditProfile";
import { NewProjectMain } from "pages/Projects/NewProject";
import { Tasks } from "pages/Projects/Tasks";
import { MyTasks } from "pages/Projects/MyTasks";
import { EditProject } from "pages/Projects/MyProject/EditProject";
import { Project } from "pages/Projects/MyProject";
import { Clients } from "pages/Projects/Clients";
import { ChangePassword } from "pages/GateKeeper/ChangePassword";
import { Attendance } from "pages/TeamZone/Attendance";
import { ProjectDashbard } from "pages/Projects/MyProject/ProjectDashboard";
import { SprintDashboard } from "pages/Projects/MyProject/ProjectDashboard/SprintDashboard";
import { ProjectReports } from "pages/Projects/Reports";
import { ViewGroupMembers } from "pages/Administration/WorkUnit/ViewGroupMembers";
//PayRoll
import { PayrollSettings } from "./pages/PayRoll/PayrollSettings";
import { General } from "pages/PayRoll/PayrollSettings/Screens/General";
import { PaySchedule } from "pages/PayRoll/PaySchedule";
import { PayRollEmployee } from "pages/PayRoll/PayRollEmployee";
import { RunPayRoll } from "pages/PayRoll/RunPayRoll";
import { PayrollReports } from "pages/PayRoll/PayrollReports";
import { ShiftAssignIndex } from "pages/TeamZone/PlanShift/ShiftAssign";
import { NewPassword } from "pages/GateKeeper/SendOTP/NewPassword";
import { PayrollLoan } from "pages/PayRoll/PayrollLoan";
//HR
import { EmployeeDetails } from "pages/HR/EmployeeDetails";
import { CreateEmployee } from "pages/HR/CreateEmployee";
import { EditEmployee } from "pages/HR/EditEmployee";

export const getRoutesByRole = (product) => [
  {
    path: "/myzone/calendar",
    name: "My Zone",
    icon: Person,
    component: Calendar,
    layout: "/admin",
    hideInSidebar: product ? findAccess(product, "MyZone") : true,
    collapse: [
      {
        path: "/admin/myzone/apply-leave",
        name: "Apply",
        icon: ContactPageSharp,
        layout: "/admin",
      },
      {
        path: "/admin/myzone/calendar",
        name: "Calendar",
        icon: CalendarMonthSharp,
        layout: "/admin",
      },
      {
        path: "/admin/myzone/my-leaves",
        name: "My Leaves",
        icon: SickSharp,
        layout: "/admin",
      },
      {
        path: "/admin/myzone/my-shift",
        name: "Shift",
        icon: PresentToAll,
        layout: "/admin",
      },
      {
        path: "/admin/myzone/status",
        name: "Status",
        icon: RotateLeftSharp,
        layout: "/admin",
      },
      {
        path: "/admin/myzone/my-working-hours",
        name: "Working Hours",
        icon: AvTimerSharp,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/teamzone/calendar",
    name: "Team Zone",
    icon: Diversity3,
    component: TeamZoneCalendar,
    layout: "/admin",
    hideInSidebar: product ? findAccess(product, "TeamZone") : true,
    collapse: [
      {
        path: "/admin/teamzone/approve",
        name: "Approve",
        icon: PlaylistAddCheckCircle,
        layout: "/admin",
      },
      {
        path: "/admin/teamzone/attendance",
        name: "Attendance",
        icon: PersonAddAlt1,
        layout: "/admin",
      },
      {
        path: "/admin/teamzone/calendar",
        name: "Calendar",
        icon: CalendarMonthSharp,
        layout: "/admin",
      },
      // {
      //   path: "/admin/teamzone/employee",
      //   name: "Employee",
      //   icon: Badge,
      //   layout: "/admin",
      // },
      {
        path: "/admin/teamzone/leave-summary",
        name: "Leave Summary",
        icon: TransferWithinAStation,
        layout: "/admin",
      },
      {
        path: "/admin/teamzone/plan-shift",
        name: "Shift",
        icon: DriveFolderUpload,
        layout: "/admin",
      },
      {
        path: "/admin/teamzone/report",
        name: "Reports",
        icon: DriveFolderUpload,
        layout: "/admin",
      },
      {
        path: "/admin/teamzone/settings",
        name: "Settings",
        icon: SettingsIcons,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/payroll/settings/salary/statutory",
    name: "Payroll",
    icon: ManageAccounts,
    component: PayrollSettings,
    layout: "/admin",
    hideInSidebar: product ? findAccess(product, "Payroll") : true,
    collapse: [
      {
        path: "/admin/payroll/payschedule",
        name: "Pay Schedule",
        icon: MonetizationOn,
        layout: "/admin",
      },
      {
        path: "/admin/payroll/reports",
        name: "Reports",
        icon: DriveFolderUpload,
        layout: "/admin",
      },
      {
        path: "/admin/payroll/run/payroll",
        name: "Run Payroll",
        icon: WorkHistory,
        layout: "/admin",
      },
      {
        path: "/admin/payroll/employee",
        name: "Payroll Employee",
        icon: ManageAccounts,
        layout: "/admin",
      },
      {
        path: "/admin/payroll/loans",
        name: "Loans",
        icon: CurrencyRupee,
        layout: "/admin",
      },
      {
        path: "/admin/payroll/settings/salary/statutory",
        name: "Settings",
        icon: SettingsIcons,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/project-management",
    name: "Project",
    icon: BusinessCenter,
    component: Project,
    layout: "/admin",
    hideInSidebar: product ? findAccess(product, "Project") : true,
    collapse: [
      {
        path: "/admin/clients",
        name: "Clients",
        icon: ConnectWithoutContact,
        layout: "/admin",
      },
      {
        path: "/admin/project-management",
        name: "Projects",
        icon: AccountTree,
        layout: "/admin",
      },
      {
        path: "/admin/mytask",
        name: "Tasks",
        icon: Checklist,
        layout: "/admin",
      },
      {
        path: "/admin/report",
        name: "Reports",
        icon: DriveFolderUpload,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/user",
    name: "Administration",
    icon: Person,
    component: Users,
    layout: "/admin",
    hideInSidebar: product ? findAccess(product, "Administration") : true,
    collapse: [
      {
        path: "/admin/companies",
        name: "Companies",
        icon: Business,
        layout: "/admin",
      },
      {
        path: "/admin/departments",
        name: "Departments",
        icon: LocationCity,
        layout: "/admin",
      },
      {
        path: "/admin/shift",
        name: "Shift",
        icon: FilterTiltShift,
        layout: "/admin",
      },
      {
        path: "/admin/workunit",
        name: "WorkUnit",
        icon: Diversity2,
        layout: "/admin",
      },
      {
        path: "/admin/holidays",
        name: "Holidays",
        icon: Pool,
        layout: "/admin",
      },
      {
        path: "/admin/master/designation",
        name: "Master",
        icon: Person4,
        layout: "/admin",
      },
      // {
      //   path: "/admin/shiftcategory",
      //   name: "Shift Category",
      //   icon: FilterTiltShift,
      //   layout: "/admin",
      // },
      {
        path: "/admin/user",
        name: "Users",
        icon: SupervisedUserCircle,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/hr/employee",
    name: "HR Portal",
    icon: ManageAccounts,
    component: EmployeeDetails,
    layout: "/admin",
    hideInSidebar: product ? findAccess(product, "TeamZone") : true,
    collapse: [
      {
        path: "/admin/hr/employee",
        name: "Employee",
        icon: Badge,
        layout: "/admin",
      },
      // {
      //   path: "/admin/hr/employee",
      //   name: "Loan",
      //   icon: ContactPageSharp,
      //   layout: "/admin",
      // },
    ],
  },
  {
    path: "/edit/profile",
    name: "Profile",
    component: EditProfile,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/changepassword",
    name: "ChangePassword",
    component: ChangePassword,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
    hideInSidebar: true,
  },
  {
    path: "/send/otp",
    name: "SendOTP",
    component: SendOTP,
    layout: "/auth",
    hideInSidebar: true,
  },
  {
    path: "/otp/newPassword",
    name: "NewPassword",
    component: NewPassword,
    layout: "/auth",
    hideInSidebar: true,
  },
  {
    path: "/forgot-password",
    name: "Register",
    component: ForgotPassword,
    layout: "/auth",
    hideInSidebar: true,
  },
  {
    path: "/maintenance",
    name: "Register",
    component: UpgrateNotification,
    layout: "/auth",
    hideInSidebar: true,
  },
  {
    path: "/new-project",
    name: "NewProject",
    component: NewProjectMain,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/task",
    name: "Task",
    component: Tasks,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/mytask",
    name: "Tasks",
    component: MyTasks,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/report",
    name: "Reports",
    component: ProjectReports,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/edit/project",
    name: "EditProject",
    component: EditProject,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/project/dashboard",
    name: "Projects",
    component: ProjectDashbard,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/project/sprint/dashboard",
    name: "SprintDashboard",
    component: SprintDashboard,
    layout: "/admin",
    hideInSidebar: true,
  },
  //Invoice
  {
    path: "/create/user",
    name: "CreateUser",
    component: CreateUser,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/shift",
    name: "Shift",
    component: Shift,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/workunit",
    name: "WorkUnit",
    component: WorkUnit,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/holidays",
    name: "Holidays",
    component: Holidays,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/shiftcategory",
    name: "Shift",
    component: ShiftCategory,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/add/shift/category",
    name: "Shift",
    component: AddShiftCategory,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/companies",
    name: "Companies",
    component: Companies,
    layout: "/admin",
    hideInSidebar: true,
  },
  //MyZone
  {
    path: "/myzone/apply-leave",
    name: "Apply Leave",
    component: MyZoneApply,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/myzone/my-leaves",
    name: "Apply Leave",
    component: MyLeaves,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/myzone/status",
    name: "Status",
    component: EssStatus,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/myzone/my-shift",
    name: "PlanShift",
    component: MyShift,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/myzone/my-working-hours",
    name: "WorkingHours",
    component: IndividualWorkingHours,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/myzone/pay-slip",
    name: "Payslip",
    component: Payslip,
    layout: "/admin",
    hideInSidebar: true,
  },
  //Administration
  {
    path: "/departments",
    name: "Departments",
    component: Departments,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/department/status",
    name: "DepartmentStatus",
    component: Status,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/department/view/members",
    name: "ViewMembers",
    component: ViewMembers,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/work-unit/members",
    name: "WorkUnit",
    component: ViewGroupMembers,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/view/company/groups",
    name: "ViewCompany",
    component: ViewCompany,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/view/user/groups",
    name: "UserGroup",
    component: ViewUserGroup,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/department/plan-shift",
    name: "Shift",
    component: PlanShift,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/master",
    name: "Master",
    component: Master,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/master/designation",
    name: "Designation",
    component: Designation,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/master/employment/type",
    name: "EmploymentType",
    component: EmploymentType,
    layout: "/admin",
    hideInSidebar: true,
  },
  // Team Zone
  {
    path: "/teamzone/leave-summary",
    name: "Leave Summary",
    component: LeaveSummary,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/teamzone/plan-shift",
    name: "Shift",
    component: PlanShift,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/teamzone/shift-group",
    name: "Shift Group",
    component: ShiftGroupIndex,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/teamzone/shift-assignment",
    name: "Shift Assignment",
    component: ShiftAssignIndex,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/teamzone/attendance",
    name: "Attendance",
    component: Attendance,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/attendance/view/members",
    name: "ViewMembers",
    component: ViewMembers,
    layout: "/admin",
    hideInSidebar: true,
  },

  {
    path: "/teamzone/status",
    name: "Status",
    component: Status,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/teamzone/approve",
    name: "Approve",
    component: Approve,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/teamzone/settings",
    name: "Settings",
    component: TeamZoneSettings,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/teamzone/employee/onborad",
    name: "Employee",
    component: EmployeeOnBoard,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/teamzone/bulk/import",
    name: "BulkImport",
    component: BulkImport,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/teamzone/employee",
    name: "Employee",
    component: Employee,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/teamzone/new/employee",
    name: "NewEmployee",
    component: AddNewEmployee,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/teamzone/report",
    name: "Reports",
    component: Reports,
    layout: "/admin",
    hideInSidebar: true,
  },
  //PayRoll
  {
    path: "/payroll/settings/salary/statutory",
    name: "PayrollSettings",
    component: PayrollSettings,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/payroll/settings/general",
    name: "General",
    component: General,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/payroll/settings/general",
    name: "General",
    component: General,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/payroll/loans",
    name: "Loan",
    component: PayrollLoan,
    layout: "/admin",
    hideInSidebar: true,
  },
  //
  {
    path: "/payroll/payschedule",
    name: "Pay Schedule",
    component: PaySchedule,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/payroll/employee",
    name: "Payroll Employee",
    component: PayRollEmployee,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/payroll/run/payroll",
    name: "Run Payroll",
    component: RunPayRoll,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/payroll/reports",
    name: "Payroll Reports",
    component: PayrollReports,
    layout: "/admin",
    hideInSidebar: true,
  },
  //HR module
  {
    path: "/hr/edit/employee",
    name: "EditEmployee",
    component: EditEmployee,
    layout: "/admin",
    hideInSidebar: true,
  },
  {
    path: "/hr/create/employee",
    name: "CreateEmployee",
    component: CreateEmployee,
    layout: "/admin",
    hideInSidebar: true,
  },
];

const findAccess = (productDetails, productName) => {
  let module = productDetails.filter((data) => {
    return data.product_name === productName;
  });
  return module.length > 0 ? false : true;
};
