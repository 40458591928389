import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { employee_use } from "services/constants";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import {
  minimum3Characters,
  minimum10Digits,
} from "services/constants/ErrorMessages";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { showToast } from "services/utils/Status";
import {
  specialCharacterRestrict,
  onlyNumberRegex,
  aadharRegex,
  panRegex,
  mustbe12Digits,
  pfNumberRegex,
  ifscCodePatterns,
} from "services/constants/patterns";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { setEditPage } from "services/Redux/HRPortal";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { useSelector } from "react-redux";
import { customAPI } from "services/utils/CustomAPI";
import { Info } from "@mui/icons-material";
import { useDispatch } from "react-redux";
export const PersonalInfo = ({
  pageno,
  employeeDetails,
  setUpdateUserDetails,
  getSelectedUserDetails,
  updateUserDetails,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  console.log(employeeDetails);
  const dispatch = useDispatch();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const history = useHistory();
  const [aadharNo, setAadharNo] = useState(
    employeeDetails?.aadhar ? employeeDetails?.aadhar : ""
  );
  const [panNo, setPanNo] = useState(
    employeeDetails?.pan ? employeeDetails?.pan : ""
  );
  const [pfNo, setPFNo] = useState(
    employeeDetails?.pf ? employeeDetails?.pf : ""
  );
  const [esiNo, setESINo] = useState(
    employeeDetails?.esi ? employeeDetails?.esi : ""
  );
  const [uanNo, setUANNo] = useState(
    employeeDetails?.uan ? employeeDetails?.uan : ""
  );
  const [dateOfBirth, setDateOfBirth] = useState(
    employeeDetails?.dob
      ? employeeDetails?.dob?.split("-").reverse().join("-")
      : null
  );
  const [addressDetails, setAddressDetails] = useState(
    employeeDetails?.address ? employeeDetails?.address : ""
  );

  const [bankNameDetails, setBankNameDetails] = useState(
    employeeDetails.bankname ? employeeDetails.bankname : ""
  );
  const [accountNameDetails, setAccountNameDetails] = useState(
    employeeDetails.account_holder_name
      ? employeeDetails.account_holder_name
      : ""
  );
  const [accountNumber, setAccountNumber] = useState(
    employeeDetails.accountno ? employeeDetails.accountno : ""
  );
  const [branchNameDetails, setBranchNameDetails] = useState(
    employeeDetails.branch_name ? employeeDetails.branch_name : ""
  );
  const [ifscCodeDetails, setIFSCcodeDetails] = useState(
    employeeDetails.ifsc ? employeeDetails.ifsc : ""
  );
  const [bankAddress, setBankAddress] = useState(
    employeeDetails?.bank_address ? employeeDetails?.bank_address : ""
  );
  const [saveAndExit, setSaveAndExit] = useState(false);
  const [permanentAddressDetails, setPermanentAddressDetails] = useState(
    employeeDetails?.permanent_address ? employeeDetails?.permanent_address : ""
  );
  const [parentName, setParentName] = useState(
    employeeDetails?.parent_name ? employeeDetails?.parent_name : ""
  );
  const [parentNumber, setParentNumber] = useState(
    employeeDetails?.parent_mobile_number
      ? employeeDetails?.parent_mobile_number
      : ""
  );
  const [contactName1, setContactName1] = useState(
    employeeDetails?.emergency_contacts
      ? employeeDetails?.emergency_contacts[0]?.contact_name
      : ""
  );
  const [relationType1, setRelationType1] = useState(
    employeeDetails?.emergency_contacts
      ? employeeDetails?.emergency_contacts[0]?.relation_type
      : ""
  );
  const [contactNumber1, setContactNumber1] = useState(
    employeeDetails?.emergency_contacts
      ? employeeDetails?.emergency_contacts[0]?.contact_number
      : ""
  );
  const [contactName2, setContactName2] = useState(
    employeeDetails?.emergency_contacts
      ? employeeDetails?.emergency_contacts[1]?.contact_name
      : ""
  );
  const [relationType2Details, setRelationType2Details] = useState(
    employeeDetails?.emergency_contacts
      ? employeeDetails?.emergency_contacts[1]?.relation_type
      : ""
  );
  const [contactNumber2, setContactNumber2] = useState(
    employeeDetails?.emergency_contacts
      ? employeeDetails?.emergency_contacts[1]?.contact_number
      : ""
  );
  const [employeeUse, setEmployeeUse] = useState(
    employeeDetails?.transport_type
      ? employeeDetails?.transport_type
      : "Company accommodation"
  );
  const [blockNo, setBlockNo] = useState(
    employeeDetails?.block_name ? employeeDetails?.block_name : ""
  );
  const [roomNo, setRoomNo] = useState(
    employeeDetails?.room_no ? employeeDetails?.room_no : ""
  );
  const [transportRouteNumber, setTransportRouteNumber] = useState(
    employeeDetails?.transport_route_no
      ? employeeDetails?.transport_route_no
      : ""
  );
  const [communicationAddressCheckBox, setCommunicationAddressCheckBox] =
    useState(
      employeeDetails?.permanent_address === employeeDetails?.address &&
        employeeDetails?.permanent_address
        ? true
        : false
    );

  useEffect(() => {
    const updateData = () => {
      setValue("user_id", employeeDetails.user_id);
      setValue(
        "aadhar",
        employeeDetails?.aadhar ? employeeDetails?.aadhar : ""
      );
      setValue("pan", employeeDetails?.pan ? employeeDetails?.pan : "");
      setValue("pf", employeeDetails?.pf ? employeeDetails?.pf : "");
      setValue("esi", employeeDetails?.esi ? employeeDetails?.esi : "");
      setValue("uan", employeeDetails?.uan ? employeeDetails?.uan : "");
      setValue(
        "dob",
        employeeDetails?.dob
          ? employeeDetails?.dob?.split("-").reverse().join("-")
          : null
      );
      setValue(
        "address",
        employeeDetails?.address ? employeeDetails?.address : ""
      );
      setValue(
        "permanent_address",
        employeeDetails?.permanent_address
          ? employeeDetails?.permanent_address
          : ""
      );
      setValue(
        "transport_type",
        employeeDetails?.transport_type
          ? employeeDetails?.transport_type
          : "Company accommodation"
      );
      setValue(
        "transport_route_no",
        employeeDetails?.transport_route_no
          ? employeeDetails?.transport_route_no
          : ""
      );
      setValue(
        "account_holder_name",
        employeeDetails.account_holder_name
          ? employeeDetails.account_holder_name
          : ""
      );
      setValue(
        "bankname",
        employeeDetails.bankname ? employeeDetails.bankname : ""
      );
      setValue(
        "branch_name",
        employeeDetails.branch_name ? employeeDetails.branch_name : ""
      );
      setValue(
        "accountno",
        employeeDetails.accountno ? employeeDetails.accountno : ""
      );
      setValue("ifsc", employeeDetails.ifsc ? employeeDetails.ifsc : "");
      setValue(
        "address",
        employeeDetails.address ? employeeDetails.address : ""
      );
      setValue(
        "parent_name",
        employeeDetails?.parent_name ? employeeDetails?.parent_name : ""
      );
      setValue(
        "block_name",
        employeeDetails?.block_name ? employeeDetails?.block_name : ""
      );
      setValue(
        "room_no",
        employeeDetails?.room_no ? employeeDetails?.room_no : ""
      );
      setValue(
        "parent_mobile_number",
        employeeDetails?.parent_mobile_number
          ? employeeDetails?.parent_mobile_number
          : ""
      );
      setValue(
        "emergencyContactName",
        employeeDetails?.emergency_contacts
          ? employeeDetails?.emergency_contacts[0]?.contact_name
          : ""
      );
      setValue(
        "relationType",
        employeeDetails?.emergency_contacts
          ? employeeDetails?.emergency_contacts[0]?.relation_type
          : ""
      );
      setValue(
        "emergencyContactNumber",
        employeeDetails?.emergency_contacts
          ? employeeDetails?.emergency_contacts[0]?.contact_number
          : ""
      );
      setValue(
        "emergencyContactName2",
        employeeDetails?.emergency_contacts
          ? employeeDetails?.emergency_contacts[1]?.contact_name
          : ""
      );
      setValue(
        "relationType2",
        employeeDetails?.emergency_contacts
          ? employeeDetails?.emergency_contacts[1]?.relation_type
          : ""
      );
      setValue(
        "emergencyContactNumber2",
        employeeDetails?.emergency_contacts
          ? employeeDetails?.emergency_contacts[1]?.contact_number
          : ""
      );
      setValue(
        "bank_address",
        employeeDetails?.bank_address ? employeeDetails?.bank_address : ""
      );
      setAadharNo(employeeDetails?.aadhar ? employeeDetails?.aadhar : "");
      setPanNo(employeeDetails?.pan ? employeeDetails?.pan : "");
      setPFNo(employeeDetails?.pf ? employeeDetails?.pf : "");
      setESINo(employeeDetails?.esi ? employeeDetails?.esi : "");
      setUANNo(employeeDetails?.uan ? employeeDetails?.uan : "");
      setDateOfBirth(
        employeeDetails?.dob
          ? employeeDetails?.dob?.split("-").reverse().join("-")
          : ""
      );
      setAddressDetails(
        employeeDetails?.address ? employeeDetails?.address : ""
      );
      setBankNameDetails(
        employeeDetails.bankname ? employeeDetails.bankname : ""
      );
      setAccountNameDetails(
        employeeDetails.account_holder_name
          ? employeeDetails.account_holder_name
          : ""
      );
      setAccountNumber(
        employeeDetails.accountno ? employeeDetails.accountno : ""
      );
      setBranchNameDetails(
        employeeDetails.branch_name ? employeeDetails.branch_name : ""
      );
      setIFSCcodeDetails(employeeDetails.ifsc ? employeeDetails.ifsc : "");
      setBankAddress(
        employeeDetails?.bank_address ? employeeDetails?.bank_address : ""
      );
      setPermanentAddressDetails(
        employeeDetails?.permanent_address
          ? employeeDetails?.permanent_address
          : ""
      );
      setParentName(
        employeeDetails?.parent_name ? employeeDetails?.parent_name : ""
      );
      setParentNumber(
        employeeDetails?.parent_mobile_number
          ? employeeDetails?.parent_mobile_number
          : ""
      );
      setContactName1(
        employeeDetails?.emergency_contacts.length !== 0
          ? employeeDetails?.emergency_contacts[0]?.contact_name
          : ""
      );
      setRelationType1(
        employeeDetails?.emergency_contacts.length !== 0
          ? employeeDetails?.emergency_contacts[0]?.relation_type
          : ""
      );
      setContactNumber1(
        employeeDetails?.emergency_contacts.length !== 0
          ? employeeDetails?.emergency_contacts[0]?.contact_number
          : ""
      );
      setContactName2(
        employeeDetails?.emergency_contacts.length !== 0
          ? employeeDetails?.emergency_contacts[1]?.contact_name
          : ""
      );
      setRelationType2Details(
        employeeDetails?.emergency_contacts.length !== 0
          ? employeeDetails?.emergency_contacts[1]?.relation_type
          : ""
      );
      setContactNumber2(
        employeeDetails?.emergency_contacts.length !== 0
          ? employeeDetails?.emergency_contacts[1]?.contact_number
          : ""
      );
      setEmployeeUse(
        employeeDetails?.transport_type
          ? employeeDetails?.transport_type
          : "Company accommodation"
      );
      setBlockNo(
        employeeDetails?.block_name ? employeeDetails?.block_name : ""
      );
      setRoomNo(employeeDetails?.room_no ? employeeDetails?.room_no : "");
      setTransportRouteNumber(
        employeeDetails?.transport_route_no
          ? employeeDetails?.transport_route_no
          : ""
      );
      setCommunicationAddressCheckBox(
        employeeDetails?.permanent_address === employeeDetails?.address &&
          employeeDetails?.permanent_address
          ? true
          : false
      );

      setUpdateUserDetails(false);
    };
    if (updateUserDetails && pageno === 2 && employeeDetails.length !== 0) {
      updateData();
    }
  });

  const onSubmit = async (data) => {
    const contactData = [
      {
        contact_name: data.emergencyContactName,
        relation_type: data.relationType,
        contact_number: data.emergencyContactNumber,
      },
      {
        contact_name: data.emergencyContactName2,
        relation_type: data.relationType2,
        contact_number: data.emergencyContactNumber2,
      },
    ];
    const payload = {
      ...data,
      emergency_contacts: contactData,
    };
    const response = await customAPI(
      `${domain}employee-onboard`,
      "PUT",
      token,
      payload
    );
    if (response.ok) {
      if (saveAndExit) {
        showToast("success", "Employee details updated successfully");
        history.push("/admin/hr/employee");
        reset();
      } else {
        getSelectedUserDetails(data?.user_id);
        dispatch(setEditPage(3));
        reset();
      }
    } else {
      setSaveAndExit(false);
    }
  };

  const updateAadharCode = (event) => {
    if (
      onlyNumberRegex.test(event.target.value) &&
      event.target.value.length <= 12
    ) {
      setValue("aadhar", event.target.value);
      setAadharNo(event.target.value);
    }
  };

  const updatePanNumber = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 10
    ) {
      setValue("pan", event.target.value);
      setPanNo(event.target.value);
    }
  };

  const updatePFNumber = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 22
    ) {
      setValue("pf", event.target.value);
      setPFNo(event.target.value);
    }
  };

  const handleDateOfBirth = (event) => {
    setValue("dob", event.target.value);
    setDateOfBirth(event.target.value);
  };

  const updateAddress = (event) => {
    if (event.target.value.length <= 1024) {
      setValue("address", event.target.value);
      setAddressDetails(event.target.value);
      if (communicationAddressCheckBox) {
        setValue("permanent_address", event.target.value);
        setPermanentAddressDetails(event.target.value);
      }
    }
  };

  const updateBankAddress = (event) => {
    if (event.target.value.length <= 1024) {
      setValue("bank_address", event.target.value);
      setBankAddress(event.target.value);
    }
  };

  const updateUANNumber = (event) => {
    if (
      onlyNumberRegex.test(event.target.value) &&
      event.target.value.length <= 12
    ) {
      setUANNo(event.target.value);
      setValue("uan", event.target.value);
    }
  };

  const updateESINumber = (event) => {
    if (
      onlyNumberRegex.test(event.target.value) &&
      event.target.value.length <= 17
    ) {
      setValue("esi", event.target.value);
      setESINo(event.target.value);
    }
  };

  const handleEmployeeUse = (event, data) => {
    if (data) {
      if (getValues("transport_type") !== data.label) {
        setBlockNo("");
        setTransportRouteNumber("");
        setRoomNo("");
        setValue("block_name", "");
        setValue("room_no", "");
        setValue("transport_route_no", "");
        setValue("transport_type", data.label);
        setEmployeeUse(data.label);
      }
    }
  };

  const updateBlockNumber = (event) => {
    if (event.target.value.length < 32) {
      setValue("block_name", event.target.value);
      setBlockNo(event.target.value);
    }
  };

  const updateRoomNumber = (event) => {
    if (event.target.value.length <= 10) {
      setValue("room_no", event.target.value);
      setRoomNo(event.target.value);
    }
  };

  const updateTransportRouteNumber = (event) => {
    if (event.target.value.length < 32) {
      setValue("transport_route_no", event.target.value);
      setTransportRouteNumber(event.target.value);
    }
  };

  const updatePermanentAddress = (event) => {
    if (event.target.value.length <= 1024) {
      setValue("permanent_address", event.target.value);
      setPermanentAddressDetails(event.target.value);
    }
  };

  const updateParentName = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 32
    ) {
      setParentName(event.target.value);
      setValue("parent_name", event.target.value);
    }
  };

  const updateParentNumber = (event) => {
    if (
      event.target.value.length <= 15 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setParentNumber(event.target.value);
      setValue("parent_mobile_number", event.target.value);
    }
  };

  const updateEmergencyContactName = (event) => {
    if (event.target.value.length <= 32) {
      setContactName1(event.target.value);
      setValue("emergencyContactName", event.target.value);
    }
  };

  const updateRelationType = (event) => {
    if (event.target.value.length <= 32) {
      setRelationType1(event.target.value);
      setValue("relationType", event.target.value);
    }
  };

  const updatedEmergencyContactNumber = (event) => {
    if (
      onlyNumberRegex.test(event.target.value) &&
      event.target.value.length <= 15
    ) {
      setValue("emergencyContactNumber", event.target.value);
      setContactNumber1(event.target.value);
    }
  };

  const updateEmergencyContactName2 = (event) => {
    if (event.target.value.length <= 32) {
      setContactName2(event.target.value);
      setValue("emergencyContactName2", event.target.value);
    }
  };

  const updateRelationType2 = (event) => {
    if (event.target.value.length <= 32) {
      setRelationType2Details(event.target.value);
      setValue("relationType2", event.target.value);
    }
  };

  const updateEmergencyContactNumber2 = (event) => {
    if (
      onlyNumberRegex.test(event.target.value) &&
      event.target.value.length <= 15
    ) {
      setValue("emergencyContactNumber2", event.target.value);
      setContactNumber2(event.target.value);
    }
  };

  const updateBankName = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 64
    ) {
      setValue("bankname", event.target.value);
      setBankNameDetails(event.target.value);
    }
  };

  const updateAccountName = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 64
    ) {
      setValue("account_holder_name", event.target.value);
      setAccountNameDetails(event.target.value);
    }
  };

  const updateBankAccountNumber = (event) => {
    if (
      event.target.value.length <= 17 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setValue("accountno", event.target.value);
      setAccountNumber(event.target.value);
    }
  };

  const updateBranchName = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 32
    ) {
      setValue("branch_name", event.target.value);
      setBranchNameDetails(event.target.value);
    }
  };

  const updateIFSCcode = (event) => {
    if (
      specialCharacterRestrict.test(event.target.value) &&
      event.target.value.length <= 11
    ) {
      setValue("ifsc", event.target.value);
      setIFSCcodeDetails(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingTop: 10 }}>
        <Mui.Grid
          container
          sx={{
            border: `1px solid ${themes.sidebarDivider}`,
            borderRadius: 3,
            paddingBottom: 1,
          }}
        >
          <Mui.Grid
            container
            sx={{
              borderBottom: `1px solid ${themes.sidebarDivider}`,
              padding: { xs: 0.4, sm: 0.4, md: 0.4, lg: 0.6, xl: 1, xxl: 1 },
            }}
          >
            <Mui.Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                color: themes.headLine,
                paddingLeft: 1,
              }}
            >
              Personal Informations
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid
            container
            sx={{ borderBottom: `1px solid ${themes.sidebarDivider}` }}
          >
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Aadhar number
              </Mui.Typography>
              <CustomTextBox
                selectedValue={aadharNo}
                Icons={null}
                rules={{
                  ...register("aadhar", {
                    required: false,
                    pattern: {
                      pattern1: (value) =>
                        aadharRegex.test(value) ||
                        "Enter aadhar in format 123456789012",
                      pattern2: (value) =>
                        mustbe12Digits.test(value) ||
                        "Must be exactly 12 digits",
                    },
                    maxLength: {
                      value: 12,
                      message: "Must be exactly 12 digits",
                    },
                    minLength: {
                      value: 12,
                      message: "Must be exactly 12 digits",
                    },
                  }),
                }}
                updateDataFunction={updateAadharCode}
                placeHolder="Please enter the aadhar number"
                errorMessage={
                  errors?.aadhar?.message ? errors?.aadhar?.message : ""
                }
                textBoxError={errors.aadhar ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Pan number
              </Mui.Typography>
              <CustomTextBox
                selectedValue={panNo}
                Icons={null}
                rules={{
                  ...register("pan", {
                    required: false,
                    pattern: {
                      value: panRegex,
                      message: "Enter PAN in format AAAAA9999A",
                    },
                    minLength: {
                      value: 10,
                      message: "Must be exactly 10 characters",
                    },
                    validate: {
                      employeePANAvailable: async (fieldValue) => {
                        if (
                          fieldValue !== "" &&
                          employeeDetails.pan !== fieldValue
                        ) {
                          const response = await fetch(
                            `${domain}unique/identity-numbers/?pan=${fieldValue}`,
                            {
                              method: "GET",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: `token ${token}`,
                              },
                            }
                          );
                          const resp = await response.json();
                          if (resp.exists) {
                            return "PAN number already exists";
                          }
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updatePanNumber}
                placeHolder="Please enter the PAN number"
                errorMessage={errors?.pan?.message ? errors?.pan?.message : ""}
                textBoxError={errors.pan ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                PF number
              </Mui.Typography>
              <CustomTextBox
                selectedValue={pfNo}
                Icons={null}
                rules={{
                  ...register("pf", {
                    required: false,
                    pattern: {
                      value: pfNumberRegex,
                      message: "Enter PF in format MHBAN00000160000000134",
                    },
                    minLength: {
                      value: 22,
                      message: "Must be exactly 22 characters",
                    },
                    validate: {
                      employeePFAvailable: async (fieldValue) => {
                        if (
                          fieldValue !== "" &&
                          employeeDetails.pf !== fieldValue
                        ) {
                          const response = await fetch(
                            `${domain}unique/identity-numbers/?pf=${fieldValue}`,
                            {
                              method: "GET",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: `token ${token}`,
                              },
                            }
                          );
                          const resp = await response.json();
                          if (resp.exists) {
                            return "PF number Already Exists";
                          }
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updatePFNumber}
                placeHolder="Please enter the PF number"
                errorMessage={errors?.pf?.message ? errors?.pf?.message : ""}
                textBoxError={errors.pf ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                UAN number
              </Mui.Typography>
              <CustomTextBox
                selectedValue={uanNo}
                Icons={null}
                rules={{
                  ...register("uan", {
                    required: false,
                    pattern: {
                      value: mustbe12Digits,
                      message: "Enter UAN in format 123456789012",
                    },
                    minLength: {
                      value: 12,
                      message: "Must be exactly 12 digits",
                    },
                    validate: {
                      employeeUANAvailable: async (fieldValue) => {
                        if (
                          fieldValue !== "" &&
                          employeeDetails.uan !== fieldValue
                        ) {
                          const response = await fetch(
                            `${domain}unique/identity-numbers/?uan=${fieldValue}`,
                            {
                              method: "GET",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: `token ${token}`,
                              },
                            }
                          );
                          const resp = await response.json();
                          if (resp.exists) {
                            return "UAN number already exists";
                          }
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updateUANNumber}
                placeHolder="Please enter the UAN number"
                errorMessage={errors?.uan?.message ? errors?.uan?.message : ""}
                textBoxError={errors.uan ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                ESI number
              </Mui.Typography>
              <CustomTextBox
                selectedValue={esiNo}
                Icons={null}
                rules={{
                  ...register("esi", {
                    required: false,
                    minLength: {
                      value: 17,
                      message: "Must be exactly 17 digits",
                    },
                    validate: {
                      employeeESIAvailable: async (fieldValue) => {
                        if (
                          fieldValue !== "" &&
                          employeeDetails.esi !== fieldValue
                        ) {
                          const response = await fetch(
                            `${domain}unique/identity-numbers/?esi=${fieldValue}`,
                            {
                              method: "GET",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: `token ${token}`,
                              },
                            }
                          );
                          const resp = await response.json();
                          if (resp.exists) {
                            return "ESI number already exists";
                          }
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updateESINumber}
                placeHolder="Please enter the ESI number"
                errorMessage={errors?.esi?.message ? errors?.esi?.message : ""}
                textBoxError={errors.esi ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Date of Birth
              </Mui.Typography>
              <Mui.TextField
                type="date"
                value={dateOfBirth}
                Icons={null}
                inputProps={{
                  max: new Date().toISOString().split("T")[0], // Set the max date to today
                }}
                rules={{
                  ...register("dob", {
                    required: false,
                  }),
                }}
                size="small"
                onChange={(event) => {
                  handleDateOfBirth(event);
                }}
                helperText={errors?.dob?.message ? errors?.dob?.message : ""}
                error={errors.dob ? true : false}
                fullWidth
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Communication address
              </Mui.Typography>
              <CustomTextBox
                type="multiline"
                selectedValue={addressDetails}
                Icons={null}
                rules={{
                  ...register("address", {
                    required: false,
                  }),
                }}
                updateDataFunction={updateAddress}
                placeHolder="Enter Address"
                errorMessage={
                  errors?.address?.message ? errors?.address?.message : ""
                }
                textBoxError={errors.address ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Permanent address
              </Mui.Typography>
              <CustomTextBox
                type="multiline"
                selectedValue={permanentAddressDetails}
                rules={{
                  ...register("permanent_address", {
                    required: false,
                  }),
                }}
                updateDataFunction={updatePermanentAddress}
                placeHolder="Enter Permanent Address"
                errorMessage={
                  errors?.permanent_address?.message
                    ? errors?.permanent_address?.message
                    : ""
                }
                textBoxError={errors.permanent_address ? true : false}
                disabledValue={
                  permanentAddressDetails === addressDetails ? true : false
                }
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}></Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ paddingBottom: 2 }}>
            <Mui.Checkbox
              checked={communicationAddressCheckBox}
              onChange={(event) => {
                setCommunicationAddressCheckBox(event.target.checked);
                if (event.target.checked) {
                  setValue("permanent_address", addressDetails);
                  setPermanentAddressDetails(addressDetails);
                } else {
                  setValue("permanent_address", "");
                  setPermanentAddressDetails("");
                }
              }}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 35 } }}
            />{" "}
            <Mui.Typography
              sx={{ paddingTop: 1.5, fontSize: 18, color: themes.colorShadow }}
            >
              Is communication address same as permanent address
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        {/* Transport */}
        <Mui.Grid
          container
          sx={{
            paddingBottom: { xs: 2, sm: 4, md: 4, lg: 5, xl: 5.5, xxl: 10 },
          }}
        />
        <Mui.Grid
          container
          sx={{
            border: `1px solid ${themes.sidebarDivider}`,
            borderRadius: 3,
          }}
        >
          <Mui.Grid
            container
            sx={{
              borderBottom: `1px solid ${themes.sidebarDivider}`,
              padding: {
                xs: 0.1,
                sm: 0.1,
                md: 0.2,
                lg: 0.3,
                xl: 0.5,
                xxl: 0.5,
              },
            }}
          >
            <Mui.Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                color: themes.headLine,
                paddingLeft: 1,
              }}
            >
              Mode of Stay/Transport
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Divider />
          <Mui.Grid container>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Employee use
              </Mui.Typography>
              <CustomAutoComplete
                dataList={employee_use}
                selectedValue={employeeUse}
                rules={{
                  ...register("transport_type", {
                    required: false,
                  }),
                }}
                updateDataFunction={handleEmployeeUse}
                placeHolder="Select the employee use"
                errorMessage={
                  errors?.transport_type?.message
                    ? errors?.transport_type?.message
                    : ""
                }
                textBoxError={errors?.transport_type ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                {employeeUse === "Company accommodation"
                  ? "Block Name"
                  : employeeUse === "Company transport"
                  ? "Transport root no"
                  : null}
              </Mui.Typography>
              {employeeUse === "Company accommodation" ? (
                <CustomTextBox
                  selectedValue={blockNo}
                  rules={{
                    ...register("block_name", {
                      required: false,
                    }),
                  }}
                  updateDataFunction={updateBlockNumber}
                  placeHolder="Enter block name"
                  errorMessage={
                    errors?.block_name?.message
                      ? errors?.block_name?.message
                      : ""
                  }
                  textBoxError={errors.block_name ? true : false}
                />
              ) : employeeUse === "Company transport" ? (
                <CustomTextBox
                  selectedValue={transportRouteNumber}
                  rules={{
                    ...register("transport_route_no", {
                      required: false,
                    }),
                  }}
                  updateDataFunction={updateTransportRouteNumber}
                  placeHolder="Enter Route number"
                  errorMessage={
                    errors?.transport_route_no?.message
                      ? errors?.transport_route_no?.message
                      : ""
                  }
                  textBoxError={errors.transport_route_no ? true : false}
                />
              ) : null}
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              {employeeUse === "Company accommodation" ? (
                <>
                  <Mui.Typography
                    sx={{
                      fontSize: 15,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Room No
                  </Mui.Typography>
                  <CustomTextBox
                    selectedValue={roomNo}
                    rules={{
                      ...register("room_no", {
                        required: false,
                      }),
                    }}
                    updateDataFunction={updateRoomNumber}
                    placeHolder="Enter room number"
                    errorMessage={
                      errors?.room_no?.message ? errors?.room_no?.message : ""
                    }
                    textBoxError={errors.room_no ? true : false}
                  />
                </>
              ) : null}
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
        {/* Contact Info */}
        <Mui.Grid
          container
          sx={{
            paddingBottom: { xs: 2, sm: 4, md: 4, lg: 5, xl: 5.5, xxl: 10 },
          }}
        />
        <Mui.Grid
          container
          sx={{
            border: `1px solid ${themes.sidebarDivider}`,
            borderRadius: 3,
          }}
        >
          <Mui.Grid
            container
            sx={{
              borderBottom: `1px solid ${themes.sidebarDivider}`,
              padding: {
                xs: 0.1,
                sm: 0.1,
                md: 0.2,
                lg: 0.3,
                xl: 0.5,
                xxl: 0.5,
              },
            }}
          >
            <Mui.Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                color: themes.headLine,
                paddingLeft: 1,
              }}
            >
              Contact Info
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Divider />
          <Mui.Grid
            container
            sx={{
              paddingBottom: 1,
              borderBottom: `1px solid ${themes.sidebarDivider}`,
            }}
          >
            <Mui.Grid xs={6} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Spouse/Guardian/Parent Name
              </Mui.Typography>
              <CustomTextBox
                selectedValue={parentName}
                rules={{
                  ...register("parent_name", {
                    required: false,
                    validate: {
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else {
                          return true;
                        }
                      },
                      atleastOneCharacterWithSpaceReg: (value) => {
                        if (
                          atleastOneCharacterWithSpace.test(value) ||
                          value === ""
                        ) {
                          return true;
                        } else {
                          return "Atleast one alphabet required";
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updateParentName}
                placeHolder="Enter parent name"
                errorMessage={
                  errors?.parent_name?.message
                    ? errors?.parent_name?.message
                    : ""
                }
                textBoxError={errors.parent_name ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={6} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                {" "}
                Spous/Guardian/Parent Number
              </Mui.Typography>
              <CustomTextBox
                selectedValue={parentNumber}
                rules={{
                  ...register("parent_mobile_number", {
                    required: false,
                    minLength: {
                      value: 10,
                      message: minimum10Digits,
                    },
                  }),
                }}
                updateDataFunction={updateParentNumber}
                placeHolder="Enter Parent Number"
                errorMessage={
                  errors?.parent_mobile_number?.message
                    ? errors?.parent_mobile_number?.message
                    : ""
                }
                textBoxError={errors.parent_mobile_number ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
              Emergency Contact 1
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Contact name
              </Mui.Typography>
              <CustomTextBox
                selectedValue={contactName1}
                rules={{
                  ...register("emergencyContactName", {
                    required: false,
                    validate: {
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else {
                          return true;
                        }
                      },
                      atleastOneCharacterWithSpaceReg: (value) => {
                        if (
                          atleastOneCharacterWithSpace.test(value) ||
                          value === ""
                        ) {
                          return true;
                        } else {
                          return "Atleast one alphabet required";
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updateEmergencyContactName}
                placeHolder="Emergency contact name"
                errorMessage={
                  errors?.emergencyContactName?.message
                    ? errors?.emergencyContactName?.message
                    : ""
                }
                textBoxError={errors.emergencyContactName ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Grid container>
                <Mui.Grid xs={12}>
                  <Mui.Stack direction="row">
                    <Mui.Typography
                      sx={{
                        fontSize: 15,
                        color: themes.headLine,
                        paddingBottom: 0.5,
                        fontWeight: "bold",
                      }}
                    >
                      Relation Type
                    </Mui.Typography>{" "}
                    &nbsp;&nbsp;
                    <Mui.Tooltip title="Ex:Brother,Mother,Father    ">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Stack>
                </Mui.Grid>
              </Mui.Grid>
              <CustomTextBox
                selectedValue={relationType1}
                rules={{
                  ...register("relationType", {
                    required: false,
                    validate: {
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else {
                          return true;
                        }
                      },
                      atleastOneCharacterWithSpaceReg: (value) => {
                        if (
                          atleastOneCharacterWithSpace.test(value) ||
                          value === ""
                        ) {
                          return true;
                        } else {
                          return "Atleast one alphabet required";
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updateRelationType}
                placeHolder="Enter RelationType"
                errorMessage={
                  errors?.relationType?.message
                    ? errors?.relationType?.message
                    : ""
                }
                textBoxError={errors.relationType ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Contact number
              </Mui.Typography>
              <CustomTextBox
                selectedValue={contactNumber1}
                rules={{
                  ...register("emergencyContactNumber", {
                    required: false,
                    minLength: {
                      value: 10,
                      message: minimum10Digits,
                    },
                  }),
                }}
                updateDataFunction={updatedEmergencyContactNumber}
                placeHolder="Emergency contact number"
                errorMessage={
                  errors?.emergencyContactNumber?.message
                    ? errors?.emergencyContactNumber?.message
                    : ""
                }
                textBoxError={errors.emergencyContactNumber ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
              Emergency Contact 2
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Contact name
              </Mui.Typography>
              <CustomTextBox
                selectedValue={contactName2}
                rules={{
                  ...register("emergencyContactName2", {
                    required: false,
                    validate: {
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else {
                          return true;
                        }
                      },
                      atleastOneCharacterWithSpaceReg: (value) => {
                        if (
                          atleastOneCharacterWithSpace.test(value) ||
                          value === ""
                        ) {
                          return true;
                        } else {
                          return "Atleast one alphabet required";
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updateEmergencyContactName2}
                placeHolder="Emergency contact name"
                errorMessage={
                  errors?.emergencyContactName2?.message
                    ? errors?.emergencyContactName2?.message
                    : ""
                }
                textBoxError={errors.emergencyContactName2 ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Grid container>
                <Mui.Grid xs={12}>
                  <Mui.Stack direction="row">
                    <Mui.Typography
                      sx={{
                        fontSize: 15,
                        color: themes.headLine,
                        paddingBottom: 0.5,
                        fontWeight: "bold",
                      }}
                    >
                      Relation Type
                    </Mui.Typography>{" "}
                    &nbsp;&nbsp;
                    <Mui.Tooltip title="Ex:Brother,Mother,Father    ">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Stack>
                </Mui.Grid>
              </Mui.Grid>
              <CustomTextBox
                selectedValue={relationType2Details}
                rules={{
                  ...register("relationType2", {
                    required: false,
                    validate: {
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else {
                          return true;
                        }
                      },
                      atleastOneCharacterWithSpaceReg: (value) => {
                        if (
                          atleastOneCharacterWithSpace.test(value) ||
                          value === ""
                        ) {
                          return true;
                        } else {
                          return "Atleast one alphabet required";
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updateRelationType2}
                placeHolder="Enter RelationType"
                errorMessage={
                  errors?.relationType2?.message
                    ? errors?.relationType2?.message
                    : ""
                }
                textBoxError={errors.relationType2 ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Contact number
              </Mui.Typography>
              <CustomTextBox
                selectedValue={contactNumber2}
                rules={{
                  ...register("emergencyContactNumber2", {
                    required: false,
                    minLength: {
                      value: 10,
                      message: minimum10Digits,
                    },
                  }),
                }}
                updateDataFunction={updateEmergencyContactNumber2}
                placeHolder="Emergency contact number"
                errorMessage={
                  errors?.emergencyContactNumber2?.message
                    ? errors?.emergencyContactNumber2?.message
                    : ""
                }
                textBoxError={errors.emergencyContactNumber2 ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
        {/* Bank Info */}
        <Mui.Grid
          container
          sx={{
            paddingBottom: { xs: 2, sm: 4, md: 4, lg: 5, xl: 5.5, xxl: 10 },
          }}
        />
        <Mui.Grid
          container
          sx={{
            border: `1px solid ${themes.sidebarDivider}`,
            borderRadius: 3,
          }}
        >
          <Mui.Grid
            container
            sx={{
              borderBottom: `1px solid ${themes.sidebarDivider}`,
              padding: {
                xs: 0.1,
                sm: 0.1,
                md: 0.2,
                lg: 0.3,
                xl: 0.5,
                xxl: 0.5,
              },
            }}
          >
            <Mui.Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                color: themes.headLine,
                paddingLeft: 1,
              }}
            >
              Bank Info
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Divider />
          <Mui.Grid container>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Account Holder Name
              </Mui.Typography>
              <CustomTextBox
                selectedValue={accountNameDetails}
                rules={{
                  ...register("account_holder_name", {
                    required: false,
                    validate: {
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else {
                          return true;
                        }
                      },
                      atleastOneCharacterWithSpaceReg: (value) => {
                        if (
                          atleastOneCharacterWithSpace.test(value) ||
                          value === ""
                        ) {
                          return true;
                        } else {
                          return "Atleast one alphabet required";
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updateAccountName}
                placeHolder="Enter Account Name"
                errorMessage={
                  errors?.account_holder_name?.message
                    ? errors?.account_holder_name?.message
                    : ""
                }
                textBoxError={errors.account_holder_name ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Bank Name
              </Mui.Typography>
              <CustomTextBox
                selectedValue={bankNameDetails}
                rules={{
                  ...register("bankname", {
                    required: false,
                    validate: {
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else {
                          return true;
                        }
                      },
                      atleastOneCharacterWithSpaceReg: (value) => {
                        if (
                          atleastOneCharacterWithSpace.test(value) ||
                          value === ""
                        ) {
                          return true;
                        } else {
                          return "Atleast one alphabet required";
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updateBankName}
                placeHolder="Enter Bank Name"
                errorMessage={
                  errors?.bankname?.message ? errors?.bankname?.message : ""
                }
                textBoxError={errors.bankname ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Bank Account Number
              </Mui.Typography>
              <CustomTextBox
                selectedValue={accountNumber}
                rules={{
                  ...register("accountno", {
                    required: false,
                  }),
                }}
                updateDataFunction={updateBankAccountNumber}
                placeHolder="Enter Bank Account Number"
                errorMessage={
                  errors?.accountno?.message ? errors?.accountno?.message : ""
                }
                textBoxError={errors.accountno ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Branch Name
              </Mui.Typography>
              <CustomTextBox
                selectedValue={branchNameDetails}
                rules={{
                  ...register("branch_name", {
                    required: false,
                    validate: {
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else {
                          return true;
                        }
                      },
                      atleastOneCharacterWithSpaceReg: (value) => {
                        if (
                          atleastOneCharacterWithSpace.test(value) ||
                          value === ""
                        ) {
                          return true;
                        } else {
                          return "Atleast one alphabet required";
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updateBranchName}
                placeHolder="Enter Branch Name"
                errorMessage={
                  errors?.branch_name?.message
                    ? errors?.branch_name?.message
                    : ""
                }
                textBoxError={errors.branch_name ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                IFSC code
              </Mui.Typography>
              <CustomTextBox
                selectedValue={ifscCodeDetails}
                rules={{
                  ...register("ifsc", {
                    required: false,
                    validate: {
                      minLengthNoSpaces: (value) => {
                        const trimmedValue = value?.trim();
                        if (!trimmedValue) {
                          return true;
                        }
                        if (trimmedValue.length < 11) {
                          return "Minimum 11 characters required";
                        }
                        if (!ifscCodePatterns.test(trimmedValue)) {
                          return "Please enter a valid format: ABCD0123456";
                        }

                        return true;
                      },
                    },
                  }),
                }}
                updateDataFunction={updateIFSCcode}
                placeHolder="Enter IFSC Code"
                errorMessage={
                  errors?.ifsc?.message ? errors?.ifsc?.message : ""
                }
                textBoxError={errors.ifsc ? true : false}
              />
            </Mui.Grid>
            <Mui.Grid xs={4} sx={{ padding: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 15,
                  color: themes.headLine,
                  paddingBottom: 0.5,
                  fontWeight: "bold",
                }}
              >
                Address
              </Mui.Typography>
              <CustomTextBox
                selectedValue={bankAddress}
                Icons={null}
                rules={{
                  ...register("bank_address", {
                    required: false,
                    validate: {
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else {
                          return true;
                        }
                      },
                      atleastOneCharacterWithSpaceReg: (value) => {
                        if (
                          atleastOneCharacterWithSpace.test(value) ||
                          value === ""
                        ) {
                          return true;
                        } else {
                          return "Atleast one alphabet required";
                        }
                      },
                    },
                  }),
                }}
                updateDataFunction={updateBankAddress}
                placeHolder="Enter Address"
                errorMessage={
                  errors?.bank_address?.message
                    ? errors?.bank_address?.message
                    : ""
                }
                textBoxError={errors.bank_address ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            paddingBottom: { xs: 2, sm: 4, md: 4, lg: 5, xl: 5.5, xxl: 10 },
          }}
        />
        <Mui.Grid container sx={{ padding: 2 }}>
          <Mui.Grid xs={1}>
            <CustomButton
              actionFuntion={() => {
                dispatch(setEditPage(1));
                setUpdateUserDetails(true);
              }}
              actionName="Previous"
              typeName="button"
            />
          </Mui.Grid>
          <Mui.Grid xs={1.5}>
            <CustomButton
              actionFuntion={() => {
                setSaveAndExit(true);
              }}
              actionName="Save & Exit"
              typeName="submit"
            />
          </Mui.Grid>
          <Mui.Grid xs={7.5}></Mui.Grid>
          <Mui.Grid
            xs={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton actionName="Next" typeName="submit" />
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
