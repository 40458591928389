import React, { useState } from "react";
import * as Mui from "@mui/material";
import { Box } from "@material-ui/core";
import { Loader } from "components/Loader";
import { themes } from "services/constants";
import moment from "moment";

export const Weekly = ({ loading, weeklyShiftList }) => {
  const [page, setLeavePage] = useState(0);
  const [rowsPerPage, setLeaveRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setLeavePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };

  const checkDateWithMoment = (inputDate) => {
    const givenDate = moment(inputDate, "DD-MM-YYYY");
    const currentDate = moment();
    if (givenDate.isSameOrAfter(currentDate, "day")) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      {loading === true ? (
        <Box>
          <Loader info="Loading..." />
        </Box>
      ) : (
        <Mui.Grid container sx={{ paddingRight: 1 }}>
          <Mui.TableContainer
            component={Mui.Paper}
            sx={{
              mt: 2,
              width: "100%",
              border: 1,
              borderRadius: 5,
              borderColor: themes.primary,
            }}
          >
            <Mui.Table>
              <Mui.TableHead>
                <Mui.TableRow>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      border: 1,
                      borderColor: themes.primary,
                    }}
                    align="center"
                  >
                    Date
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      border: 1,
                      borderColor: themes.primary,
                    }}
                    align="center"
                  >
                    Shift & Time
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      border: 1,
                      borderColor: themes.primary,
                    }}
                    align="center"
                  >
                    Unit
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {weeklyShiftList?.length === 0 ? (
                  <Mui.Alert severity="info" sx={{ width: "100%", margin: 2 }}>
                    No data found
                  </Mui.Alert>
                ) : (
                  weeklyShiftList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((shiftList, index) => (
                      <React.Fragment>
                        {shiftList?.shifts.map((shift, i) => (
                          <Mui.TableRow key={index} hover={true}>
                            <Mui.TableCell
                              sx={{ border: 1, borderColor: themes.primary }}
                            >
                              <Mui.Stack
                                direction="column"
                                sx={{
                                  alignItems: "center",
                                }}
                              >
                                <Mui.Typography
                                  sx={{
                                    color: checkDateWithMoment(shift?.date)
                                      ? themes.blackColor
                                      : "gray",
                                    fontWeight: "bold",
                                    fontSize: 24,
                                  }}
                                >
                                  {moment(shift?.date, "DD-MM-YYYY").format(
                                    "DD"
                                  )}
                                </Mui.Typography>
                                <Mui.Typography
                                  sx={{
                                    color: checkDateWithMoment(shift?.date)
                                      ? themes.blackColor
                                      : "gray",
                                    fontWeight: "bold",
                                    fontSize: 12,
                                  }}
                                >
                                  {moment(shift?.date, "DD-MM-YYYY").format(
                                    "ddd, MMM"
                                  )}
                                </Mui.Typography>
                              </Mui.Stack>
                            </Mui.TableCell>

                            <Mui.TableCell
                              sx={{
                                fontSize: 13,
                                border: 1,
                                borderColor: themes.primary,
                              }}
                              style={{
                                color: checkDateWithMoment(shift?.date)
                                  ? themes.blackColor
                                  : "gray",
                              }}
                            >
                              <Mui.Stack
                                direction="column"
                                width={"100%"}
                                sx={{
                                  alignItems: "center",
                                }}
                              >
                                {shift?.shift_name === null ||
                                shift?.shift_name === "Weekoff" ? (
                                  ""
                                ) : shift?.shift_start_time &&
                                  shift?.shift_end_time ? (
                                  <>
                                    <Mui.Typography sx={{ fontSize: 12 }}>
                                      {moment(
                                        shift?.shift_start_time,
                                        "HH:mm:SS"
                                      ).format("h:mm a")}
                                      {" to "}{" "}
                                      {moment(
                                        shift?.shift_end_time,
                                        "HH:mm:SS"
                                      ).format("h:mm a")}
                                    </Mui.Typography>
                                  </>
                                ) : null}
                                <Mui.Typography
                                  sx={{ fontSize: 12, fontWeight: "bold" }}
                                >
                                  {shift?.shift_name
                                    ? shift?.shift_name
                                    : "Not Joined"}
                                </Mui.Typography>
                              </Mui.Stack>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{ border: 1, borderColor: themes.primary }}
                              align="center"
                            >
                              <Mui.Typography
                                sx={{ fontSize: 12, fontWeight: "bold" }}
                                style={{
                                  color: checkDateWithMoment(shift?.date)
                                    ? themes.blackColor
                                    : "gray",
                                }}
                              >
                                {shift?.unit ? shift?.unit : 0}
                              </Mui.Typography>
                            </Mui.TableCell>
                          </Mui.TableRow>
                        ))}
                      </React.Fragment>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          {weeklyShiftList.length > 10 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={weeklyShiftList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.Grid>
      )}
    </React.Fragment>
  );
};
