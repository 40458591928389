import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Alert,
  IconButton,
  Grid,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TablePagination from "@mui/material/TablePagination";
import { showToast } from "services/utils/Status";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "components/Loader";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import sessionHandling from "services/utils/notificationUtils";

export const Approved = ({ startDate, endDate }) => {
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [loading, setLoading] = useState(false);
  const { departmentId } = useSelector((state) => state.userReducer);
  const [expandedRow, setExpandedRow] = React.useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [rejectOt, setRejectOt] = useState([]);

  const handleRowClick = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };

  useEffect(() => {
    const fetchReject = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${domain}over-time-approve/?user_id=${userDetails.id}&start_date=${startDate}&end_date=${endDate}&status=Rejected&department_id=${departmentId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setFilteredData(res);
          setRejectOt(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
      setLoading(false);
    };
    fetchReject();
  }, [departmentId, domain, endDate, startDate, token, userDetails.id]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const searchFields = [
      "username",
      "date",
      "shift",
      "overtime_start",
      "overtime_end",
      "approvedby",
      "createdat",
    ];
    const filteredResults = rejectOt.filter((item) =>
      searchFields.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setFilteredData(filteredResults);
    setPage(0);
  }, [globalSearchValue, rejectOt]);

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Grid container sx={{ paddingRight: 1 }}>
        <TableContainer
          sx={{
            marginTop: 3,
            marginLeft: "auto",
            marginRight: "auto",
            overflow: "auto",
          }}
          className="w-96 border rounded"
        >
          <Table className="border">
            <TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Employee name</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Date</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Shift Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Working Hours</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Shift Time</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Break</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Over Time</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Rejected by</Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">Rejected At</Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={10}>
                    <Alert sx={{ margin: 2, width: "100%" }} severity="info">
                      No data available
                    </Alert>
                  </TableCell>
                </TableRow>
              ) : (
                filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <>
                      <TableRow>
                        <TableCell>
                          {expandedRow === item.id ? (
                            <IconButton
                              onClick={() => {
                                setExpandedRow(null);
                              }}
                            >
                              <KeyboardArrowDownIcon
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => handleRowClick(item.id)}>
                              <KeyboardArrowRightIcon
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell key={item.id}>
                          {item.username ? item.username : "N/A"}
                        </TableCell>
                        <TableCell>{item?.date}</TableCell>
                        <TableCell>
                          {" "}
                          {item.shift ? item.shift : "N/A"}
                        </TableCell>
                        <TableCell>
                          {item.work_hours ? item.work_hours : "N/A"}
                        </TableCell>
                        <TableCell>
                          {item.shift_time ? item.shift_time : "N/A"}
                        </TableCell>
                        <TableCell>
                          {item.total_break_time
                            ? item.total_break_time
                            : "N/A"}
                        </TableCell>
                        <TableCell>{item.over_time}</TableCell>
                        <TableCell>{item.approvedby}</TableCell>
                        <TableCell>{item.createdat}</TableCell>
                      </TableRow>
                      {expandedRow === item.id && (
                        <TableRow>
                          <TableCell colSpan={9}>
                            <TableContainer
                              sx={{
                                marginTop: 0.5,
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "100%px",
                              }}
                              className="w-96 border rounded"
                            >
                              <Table className="border">
                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  sx={{ marginLeft: 1, marginTop: 0.5 }}
                                >
                                  <strong>Reason for Rejection :</strong>{" "}
                                  <Typography
                                    fontSize={15}
                                    color="textSecondary"
                                    sx={{ marginLeft: 13 }}
                                  >
                                    {item.reason}
                                  </Typography>
                                </Typography>
                              </Table>
                            </TableContainer>
                            {item.title === "Leave" ? null : (
                              <TableContainer
                                sx={{
                                  marginTop: 0.5,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  width: "100%px",
                                }}
                                className="w-96 border rounded"
                              >
                                <Table className="border">
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    sx={{ marginLeft: 1, marginTop: 0.5 }}
                                  >
                                    <strong>
                                      Time Period : {item.over_time}
                                    </strong>
                                  </Typography>
                                </Table>
                              </TableContainer>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  ))
              )}
            </TableBody>
          </Table>
          {filteredData.length > 10 && (
            <TablePagination
              className="custom-pagination"
              rowsPerPageOptions={[10, 20, 50, 100]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </Grid>
    </React.Fragment>
  );
};
