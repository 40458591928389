import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  Alert,
} from "@mui/material";
import { green, red, yellow, blue } from "@mui/material/colors";
import Scrollbar from "react-scrollbar";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import "react-toastify/dist/ReactToastify.css";
import sessionHandling from "services/utils/notificationUtils";
const LeaveRequestCard = ({ leaveRequest }) => {
  const getStatusButtonColor = (status) => {
    switch (status) {
      case "Approved":
        return green[400];
      case "Rejected":
        return red[500];
      case "Pending":
        return yellow[200];
      default:
        return blue[200];
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        backgroundColor: getStatusButtonColor(leaveRequest.status),
        borderRadius: 5,
      }}
    >
      <CardContent>
        <Grid container spacing={5} minHeight={60} alignItems="center">
          <Grid item xs={2}>
            <Avatar
              sx={{
                backgroundColor: "#8A4402",
              }}
              alt={leaveRequest.user.auth_user.username
                ?.charAt(0)
                .toUpperCase()}
              src={leaveRequest.user.image_url}
            >
              {leaveRequest.user.auth_user.username[0].toUpperCase()}
            </Avatar>
          </Grid>
          <Grid item xs={6.8}>
            <Typography variant="body1">
              {leaveRequest.user.auth_user.username}
            </Typography>
            <Typography
              sx={{ fontWeight: "bold" }}
              variant="body2"
              color="textSecondary"
            >
              {leaveRequest?.from_date} {" - "}
              {leaveRequest?.to_date}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className="text-sm" variant="body1">
              {leaveRequest.business_days +
                `${leaveRequest.business_days === 1 ? " day" : " days"}`}
              {/* {format(new Date(leaveRequest.applied_date), "dd/MM/yyyy")} */}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const LeavesCard = ({ setUpdateLeaveCard, updateLeaveCard, departmentId }) => {
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [leaveRequests, setLeaveRequests] = useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        let url =
          userDetails.role === "Team Lead"
            ? `${domain}leave_request_approver/?department_lead_id=${userDetails.id}&department_id=${departmentId}`
            : `${domain}leave_request_approver/?department_id=${departmentId}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setLeaveRequests(
            res.map((leaveRequest, index) => ({
              ...leaveRequest,
              index,
            }))
          );
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (updateLeaveCard) {
      setUpdateLeaveCard(false);
      fetchData();
    }
  }, [
    departmentId,
    domain,
    setUpdateLeaveCard,
    token,
    updateLeaveCard,
    userDetails.id,
    userDetails.role,
  ]);

  const today = new Date();
  const next10Days = new Array(10)
    .fill()
    .map((_, index) => new Date(today.getTime() + 24 * 60 * 60 * 1000 * index));

  const filteredLeaveRequests = leaveRequests.filter((leaveRequest) => {
    const fromDate = new Date(
      leaveRequest?.from_date.split("-")[2],
      leaveRequest?.from_date.split("-")[1] - 1,
      leaveRequest?.from_date.split("-")[0]
    );

    return (
      (fromDate.getDate() === today.getDate() &&
        fromDate.getMonth() === today.getMonth() &&
        fromDate.getFullYear() === today.getFullYear()) ||
      next10Days.some(
        (date) =>
          date.getDate() === fromDate.getDate() &&
          date.getMonth() === fromDate.getMonth() &&
          date.getFullYear() === fromDate.getFullYear()
      )
    );
  });

  const todayLeaveRequests = filteredLeaveRequests.filter((leaveRequest) => {
    const fromDate = new Date(
      leaveRequest?.from_date.split("-")[2],
      leaveRequest?.from_date.split("-")[1] - 1,
      leaveRequest?.from_date.split("-")[0]
    );
    return (
      fromDate.getDate() === today.getDate() &&
      fromDate.getMonth() === today.getMonth() &&
      fromDate.getFullYear() === today.getFullYear()
    );
  });

  const next10DaysLeaveRequests = filteredLeaveRequests.filter(
    (leaveRequest) => {
      const fromDate = new Date(
        leaveRequest?.from_date.split("-")[2],
        leaveRequest?.from_date.split("-")[1] - 1,
        leaveRequest?.from_date.split("-")[0]
      );

      return (
        next10Days.some(
          (date) =>
            date.getDate() === fromDate.getDate() &&
            date.getMonth() === fromDate.getMonth() &&
            date.getFullYear() === fromDate.getFullYear()
        ) &&
        !(
          today.getDate() === fromDate.getDate() &&
          today.getMonth() === fromDate.getMonth() &&
          today.getFullYear() === fromDate.getFullYear()
        )
      );
    }
  );

  return (
    <Container>
      <Grid sx={{ paddingTop: 3 }}></Grid>
      <Scrollbar style={{ height: "calc(120vh - 280px)" }}>
        <Typography variant="h4" sx={{ mt: 2 }}>
          Leave Status
        </Typography>
        <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
          Today:
        </Typography>
        {todayLeaveRequests.length > 0 ? (
          todayLeaveRequests.map((leaveRequest) => (
            <LeaveRequestCard
              key={leaveRequest.id}
              leaveRequest={leaveRequest}
            />
          ))
        ) : (
          <Alert severity="info">No data available</Alert>
        )}
        <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
          Next 10 Days:
        </Typography>
        {next10DaysLeaveRequests.length > 0 ? (
          next10DaysLeaveRequests.map((leaveRequest) => (
            <LeaveRequestCard
              key={leaveRequest.id}
              leaveRequest={leaveRequest}
            />
          ))
        ) : (
          <Alert severity="info">No data available</Alert>
        )}
      </Scrollbar>
    </Container>
  );
};

export default LeavesCard;
