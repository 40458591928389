import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import dayjs from "dayjs";
import {
  loanTypeArray,
  interestType,
  tenureType,
} from "pages/MyZone/MyZoneApply/Headers";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { minimum3Characters } from "services/constants/ErrorMessages";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { rgxWithDot, onlyNumberRegex } from "services/constants/patterns";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { useFetch } from "services/hooks/useFetch";
let departmentId = 99999;
export const Apply = () => {
  const { domain, userDetails, token } = useSelector(
    (state) => state.tokenReducer
  );
  const today = dayjs().format("YYYY-MM-DD");
  const { result: users, setLoad: reCallApi } = useFetch(
    `${domain}list/employee?user_id=${userDetails?.id}&department_id=${departmentId}`
  );
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedInterestType, setSelectedInterestType] = useState("");
  const [tunureNameDetails, setTunureNameDetails] = useState("Monthly");
  const [loanType, setLoanType] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [loanInterestPercentage, setLoanInterestPercentage] = useState("");
  const [tenureMonthCount, setTenureMonthCount] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [startDate, setStartDate] = useState(today);
  const [installmentAmount, setInstallmentAmount] = useState(0);
  const [interestAmount, setInterestAmount] = useState(0);
  const [loanCompletionDate, setLoanCompletionDate] = useState(new Date());
  const [departmentData, setDepartmentData] = useState([]);
  const [departmentName, setDepartmentName] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [calculateAmount, setCalculateAmount] = useState(false);
  
  useEffect(() => {
    setValue("tenure_type", "Monthly");
  }, [setValue]);

  const onSubmit = async (data) => {
    if (calculateAmount) {
      calculateEmiAmount();
    } else {
      setButtonLoader(true);
      try {
        const response = await fetch(`${domain}loans/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            ...data,
            repayment_start_date: startDate,
            action: "Approved",
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast(
            "success",
            "Loan request received! You'll get a notification when it's approved."
          );
          clearAllData();
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setButtonLoader(false);
      }
    }
  };

  const clearAllData = () => {
    setLoanAmount("");
    setStartDate(today);
    setLoanInterestPercentage("");
    setTenureMonthCount("");
    setLoanType("");
    setSelectedInterestType("");
    setInstallmentAmount("");
    setInterestAmount("");
    setLoanCompletionDate("");
    setSelectedUserName("");
    setEmployeeId("");
    setDepartmentName("");
    setButtonLoader(false);
    reset();
  };

  const updateLoanAmount = (event) => {
    if (
      event.target.value.length <= 8 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setValue("principal_amount", event.target.value);
      setLoanAmount(event.target.value);
    }
  };

  const updateInterestPercentage = (event) => {
    if (
      rgxWithDot.test(event.target.value) &&
      event.target.value.length <= 6 &&
      parseInt(event.target.value) < 99
    ) {
      setValue("interest_rate", event.target.value);
      setLoanInterestPercentage(event.target.value);
    } else if (event.target.value === "") {
      setValue("interest_rate", event.target.value);
      setLoanInterestPercentage(event.target.value);
    } else {
      event.preventDefault();
    }
  };

  const updateTenureMonth = (event) => {
    if (
      onlyNumberRegex.test(event.target.value) &&
      event.target.value.length <= 2
    ) {
      setValue("tenure_months", event.target.value);
      setTenureMonthCount(event.target.value);
    } else {
      event.preventDefault();
    }
  };

  const handleLoanType = (event, data) => {
    if (data) {
      setValue("loan_type", data?.label);
      setLoanType(data?.label);
    } else {
      setValue("loan_type", null);
      setLoanType("");
    }
  };

  const handleInterestType = (event, data) => {
    if (data) {
      setValue("interest_type", data?.label);
      setSelectedInterestType(data?.label);
    } else {
      setValue("interest_type", null);
      setSelectedInterestType("");
    }
  };

  const calculateEmiAmount = async () => {
    try {
      const response = await fetch(
        `${domain}calculate-emi/?interest_rate=${loanInterestPercentage}&principal_amount=${loanAmount}&tenure=${tenureMonthCount}&interest_type=${selectedInterestType}&tenure_type=${tunureNameDetails}&loan_start_date=${startDate}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setInstallmentAmount(res?.monthly_installment);
        setInterestAmount(res?.total_interest);
        setLoanCompletionDate(res?.loan_end_date);
        setCalculateAmount(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleTenureType = (event, data) => {
    if (data) {
      setValue("tenure_type", data?.label);
      setTunureNameDetails(data?.label);
    } else {
      setValue("tenure_type", "");
      setTunureNameDetails("");
    }
  };

  const selectedUserNameDetails = (event, data) => {
    if (data) {
      setSelectedUserName(data.label);
      setValue("userName", data?.label);
      setValue("user", data?.user_id);
      setEmployeeId(data?.employee_id);
      
    }
  };

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const response = await fetch(
          `${domain}get-department/?user_id=${userDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setDepartmentData(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchDepartmentData();
  }, [domain, token, userDetails.id]);

  const updateDepartmentName = (event, data) => {
    if (data) {
      setDepartmentName(data.label);
      setValue("department_id", data.id);
      setValue("department_name", data.label);
      departmentId = data.id;
      reCallApi(true);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 10 }}>
      <Mui.Grid
        container
        sx={{
          paddingLeft: { sx: 0, sm: 10, md: 20, lg: 30 },
          paddingRight: { sx: 0, sm: 10, md: 20, lg: 30 },
        }}
      >
        <Mui.Grid
          container
          sx={{ border: `2px solid ${themes.sidebarDivider}`, borderRadius: 2 }}
        >
          <Mui.Grid
            container
            sx={{
              borderBottom: `2px solid ${themes.sidebarDivider}`,
              padding: 1,
              backgroundColor: themes.sidebarDivider,
            }}
          >
            <Mui.Typography
              sx={{ paddingLetf: 2, fontSize: 20, fontWeight: "bold" }}
            >
              Loan Details
            </Mui.Typography>
          </Mui.Grid>
          {/* department */}
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={4}>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                }}
              >
                Department
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <CustomAutoComplete
                dataList={departmentData?.map((obj) => ({
                  ...obj,
                  label: obj.department_name,
                }))}
                selectedValue={departmentName}
                placeHolder="Select department name"
                rules={{
                  ...register("department_name", {
                    required: "Department is required",
                  }),
                }}
                updateDataFunction={updateDepartmentName}
                errorMessage={
                  errors?.department_name?.message
                    ? errors?.department_name?.message
                    : ""
                }
                textBoxError={errors.department_name ? true : false}
              />
            </Mui.Grid>
          </Mui.Grid>
          {/* department */}
          <Mui.Grid container>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Employee Id & Name
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.Grid container>
                  <Mui.Grid xs={4}>
                    <Mui.TextField
                      value={employeeId}
                      size="small"
                      disabled={true}
                    />
                  </Mui.Grid>
                  <Mui.Grid xs={8} sx={{ paddingLeft: 1 }}>
                    <CustomAutoComplete
                      dataList={users?.map((obj) => ({
                        ...obj,
                        label: obj.user_name,
                      }))}
                      selectedValue={selectedUserName}
                      rules={{
                        ...register("userName", {
                          required: "User name is required",
                        }),
                      }}
                      updateDataFunction={selectedUserNameDetails}
                      placeHolder="Please select the name"
                      errorMessage={
                        errors?.userName?.message
                          ? errors?.userName?.message
                          : ""
                      }
                      textBoxError={errors.userName ? true : false}
                    />
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>

            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Loan Type
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomAutoComplete
                  dataList={loanTypeArray}
                  selectedValue={loanType}
                  rules={{
                    ...register("loan_type", {
                      required: "Loan type is required",
                    }),
                  }}
                  updateDataFunction={handleLoanType}
                  placeHolder="Please select the loan type"
                  errorMessage={
                    errors?.loan_type?.message ? errors?.loan_type?.message : ""
                  }
                  textBoxError={errors?.loan_type ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Loan Amount
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomTextBox
                  selectedValue={loanAmount}
                  rules={{
                    ...register("principal_amount", {
                      required: "Loan amount is required",
                    }),
                  }}
                  updateDataFunction={updateLoanAmount}
                  placeHolder="Enter loan amount"
                  errorMessage={
                    errors?.principal_amount?.message
                      ? errors?.principal_amount?.message
                      : ""
                  }
                  textBoxError={errors.principal_amount ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Interest Type
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomAutoComplete
                  dataList={interestType}
                  selectedValue={selectedInterestType}
                  rules={{
                    ...register("interest_type", {
                      required: "Interest type is required",
                    }),
                  }}
                  updateDataFunction={handleInterestType}
                  placeHolder="Please select the interest type"
                  errorMessage={
                    errors?.interest_type?.message
                      ? errors?.interest_type?.message
                      : ""
                  }
                  textBoxError={errors?.interest_type ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Interest
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <CustomTextBox
                  selectedValue={loanInterestPercentage}
                  rules={{
                    ...register("interest_rate", {
                      required: "Interest percentange is required",
                      pattern: {
                        value: /^0|[1-9]\d*(\.\d+)?$|0\.\d+$/,
                      },
                      validate: {
                        checkAmount: (fieldValue) => {
                          const value = parseFloat(fieldValue);
                          if (isNaN(value) || value < 0 || value > 100) {
                            return "Interest must be less then 100 %";
                          } else {
                            return true;
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={updateInterestPercentage}
                  placeHolder="Enter the loan interest percentage"
                  errorMessage={
                    errors?.interest_rate?.message
                      ? errors?.interest_rate?.message
                      : ""
                  }
                  textBoxError={errors.interest_rate ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Tenure({tunureNameDetails})
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={6}>
                <CustomTextBox
                  selectedValue={tenureMonthCount}
                  rules={{
                    ...register("tenure_months", {
                      required: "Tenure month is required",
                      pattern: {
                        value: /^0|[1-9]\d*(\.\d+)?$|0\.\d+$/,
                      },
                      validate: {
                        checkAmount: (fieldValue) => {
                          const value = parseFloat(fieldValue);
                          if (isNaN(value) || value < 0 || value > 100) {
                            return "Tenure month must be less then 100";
                          } else {
                            return true;
                          }
                        },
                      },
                    }),
                  }}
                  updateDataFunction={updateTenureMonth}
                  placeHolder="Enter the tenure month"
                  errorMessage={
                    errors?.tenure_months?.message
                      ? errors?.tenure_months?.message
                      : ""
                  }
                  textBoxError={errors.tenure_months ? true : false}
                />
              </Mui.Grid>
              <Mui.Grid xs={2} sx={{ paddingLeft: 0.5 }}>
                <CustomAutoComplete
                  dataList={tenureType}
                  selectedValue={tunureNameDetails}
                  rules={{
                    ...register("tenure_type", {
                      required: "tenure type is required",
                    }),
                  }}
                  updateDataFunction={handleTenureType}
                  placeHolder="Please select the tenure type"
                  errorMessage={
                    errors?.tenure_type?.message
                      ? errors?.tenure_type?.message
                      : ""
                  }
                  textBoxError={errors?.tenure_type ? true : false}
                />
              </Mui.Grid>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 1 }}>
              <Mui.Grid xs={4}>
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    color: themes.headLine,
                    padding: 1,
                  }}
                >
                  Reason for loan
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid xs={8}>
                <Mui.TextField
                  sx={{
                    marginTop: 1,
                    width: "100%",
                    // marginRight: "auto",
                  }}
                  id="description"
                  {...register("description", {
                    required: "Description is required",
                    validate: {
                      minLengthNoSpaces: (value) => {
                        if (value?.trim().length < 3 && value !== "") {
                          return minimum3Characters;
                        } else {
                          return true;
                        }
                      },
                    },
                  })}
                  label="Provide a reason for loan"
                  multiline
                  rows={3}
                  error={!!errors.description}
                  helperText={errors.description && errors.description.message}
                  //   onBlur={() => {
                  //     trigger("description");
                  //   }}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            spacing={1}
            sx={{
              paddingTop: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
              paddingBottom: 2,
            }}
          >
            <Mui.Grid item>
              <CustomButton
                actionFuntion={() => {
                  setCalculateAmount(true);
                }}
                actionName="Calculate"
                typeName="submit"
                disabledAction={buttonLoader}
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      {/* second forms */}
      <Mui.Grid
        container
        sx={{
          paddingTop: 5,
          paddingLeft: { sx: 0, sm: 10, md: 20, lg: 30 },
          paddingRight: { sx: 0, sm: 10, md: 20, lg: 30 },
        }}
      >
        <Mui.Grid
          container
          sx={{
            border: `2px solid ${themes.sidebarDivider}`,
            borderRadius: 2,
          }}
        >
          <Mui.Grid
            container
            sx={{
              borderBottom: `2px solid ${themes.sidebarDivider}`,
              backgroundColor: themes.sidebarDivider,
              padding: 1,
            }}
          >
            <Mui.Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
              Installment Details
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={4}>
              {" "}
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                }}
              >
                Installment
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <Mui.TextField value={installmentAmount} size="small" fullWidth />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={4}>
              {" "}
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                }}
              >
                Total Principal
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <Mui.TextField value={loanAmount} size="small" fullWidth />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={4}>
              {" "}
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                }}
              >
                Interest Amount
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <Mui.TextField value={interestAmount} size="small" fullWidth />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={4}>
              {" "}
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                }}
              >
                Loan Start Date (Exp)
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <Mui.TextField
                fullWidth
                size="small"
                type="date"
                id="from_date"
                value={startDate}
                error={!!errors.from_date}
                helperText={errors.from_date && errors.from_date.message}
                onChange={(event) => {
                  setStartDate(event.target.value);
                }}
              />
            </Mui.Grid>
          </Mui.Grid>

          <Mui.Grid container sx={{ padding: 1 }}>
            <Mui.Grid xs={4}>
              {" "}
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  color: themes.headLine,
                  padding: 1,
                }}
              >
                Loan Completion Date (Exp)
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={8}>
              <Mui.TextField
                fullWidth
                size="small"
                type="date"
                id="from_date"
                value={loanCompletionDate}
                error={!!errors.from_date}
                helperText={errors.from_date && errors.from_date.message}
                disabled={true}
                onChange={(event) => {
                  setLoanCompletionDate(event.target.value);
                }}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid
            container
            spacing={1}
            sx={{
              paddingTop: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
              paddingBottom: 2,
            }}
          >
            <Mui.Grid item>
              <CustomButton
                actionFuntion={clearAllData}
                actionName="Clear"
                typeName="button"
                disabledAction={buttonLoader}
              />
            </Mui.Grid>
            <Mui.Grid item>
              <CustomButton
                actionName="Apply"
                typeName="submit"
                disabledAction={buttonLoader}
              />
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </form>
    // </Mui.Grid>
  );
};
