import React, { useEffect, useState } from "react";
import { themes } from "services/constants";
import * as Mui from "@mui/material";
import { deductionHeaders } from "pages/PayRoll/PayRollHeaders";
import { MoreVert, CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import { useFetch } from "services/hooks/useFetch";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import sessionHandling from "services/utils/notificationUtils";
import {
  numberWithDecimalRegex,
  rgxWithDot,
} from "services/constants/patterns";
import { Loader } from "components/Loader";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
const keys = ["name", "deduction_type_display"];

export const Deduction = ({
  companyId,
  reloadData,
  selectedComponent,
  setReloadData,
  currentScreen,
  companyChange,
  setCompanyChange,
}) => {
  const {
    control,
    register,
    reset,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const { result: members, setLoad: reloadEarningData } = useFetch(
    companyId !== 0 ? `${domain}earnings/?company=${companyId}` : null
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [statutoryCheckBox, setStatutoryCheckBox] = useState(false);
  const [companyContributionCTC, setCompanyContributionCTC] = useState(false);
  const [componentName, setComponentName] = useState("");
  const [editDeduction, setEditDeduction] = useState(null);
  const [radioButtonOption, setRadioButtonOption] = useState("default");
  const [fixedAmountValue, setFixedAmountValue] = useState(0);
  const [fixedAmount, setFixedAmount] = useState(false);
  const [deductionDetails, setDeductionDetails] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [deleteDeduction, setDeleteDeductions] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [employeeContributionPercentage, setEmployeeContributionPercentage] =
    useState(0);
  const [employerContributionPercentage, setEmployerContributionPercentage] =
    useState(0);
  const {
    result: earningData,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    companyId !== 0 ? `${domain}deductions/?company=${companyId}` : null
  );

  useEffect(() => {
    if (reloadData && selectedComponent === "Deduction") {
      reCallApi(true);
      setReloadData(false);
      reloadEarningData(true);
    }
  });

  useEffect(() => {
    if (
      companyChange === true &&
      currentScreen === "Salary Component" &&
      selectedComponent === "Deduction"
    ) {
      reCallApi(true);
      reloadEarningData(true);
      setCompanyChange(false);
    }
  }, [
    companyChange,
    currentScreen,
    reCallApi,
    reloadEarningData,
    selectedComponent,
    setCompanyChange,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const updateEmployeeContribution = (event) => {
    if (rgxWithDot.test(event.target.value) && event.target.value.length <= 6) {
      setValue("employeeContribution", event.target.value);
      setEmployeeContributionPercentage(event.target.value);
    } else {
      event.preventDefault();
    }
  };

  const updateEmployerContribution = (event) => {
    if (rgxWithDot.test(event.target.value) && event.target.value.length <= 6) {
      setValue("employerContribution", event.target.value);
      setEmployerContributionPercentage(event.target.value);
    } else {
      event.preventDefault();
    }
  };

  const onSubmit = async (data) => {
    let earningId = [];
    data?.earning?.forEach((element) => {
      earningId.push(element.id);
    });
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}deductions/${editDeduction.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          name: data.component_name,
          deduction_type:
            radioButtonOption === "statutory"
              ? "S"
              : radioButtonOption === "default"
              ? "D"
              : "F",
          employee_contribution_percentage:
            radioButtonOption === "statutory"
              ? parseFloat(employeeContributionPercentage).toFixed(2)
              : 0,
          company_contribution_percentage:
            radioButtonOption === "statutory"
              ? parseFloat(employerContributionPercentage).toFixed(2)
              : 0,
          company_contribution_part_of_ctc: companyContributionCTC,
          fixed_amount:
            radioButtonOption === "fixed_amount"
              ? fixedAmountValue !== ""
                ? fixedAmountValue
                : 0
              : 0, // When deduction type is Fixed amopunt that is F then the fixed amount can't be null
          earnings: radioButtonOption === "statutory" ? earningId : [],
          company: companyId,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        handleCloseDialogAndDetails();
        reCallApi(true);
        showToast("success", res.message);
        setButtonLoader(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };

  const updateComponentName = (event) => {
    if (event.target.value.length <= 64) {
      setComponentName(event.target.value);
      setValue("component_name", event.target.value);
      clearErrors("component_name");
    }
  };

  const handleCloseDialogAndDetails = () => {
    setOpenDialog(false);
    setTimeout(function () {
      setEmployeeContributionPercentage(null);
      setEmployerContributionPercentage(null);
      setStatutoryCheckBox(false);
      setComponentName("");
      setFixedAmountValue(0);
      reset();
    }, 100);
  };

  const handleChangeRadioButton = (event) => {
    setStatutoryCheckBox(event.target.value === "statutory" ? true : false);
    setFixedAmount(event.target.value === "fixed_amount" ? true : false);
    setRadioButtonOption(event.target.value);
  };
  useEffect(() => {
    if (updateData && earningData?.length !== 0 && earningData) {
      setDeductionDetails(earningData);
      setUpdateData(false);
    }
  }, [earningData, updateData]);

  useEffect(() => {
    if (earningData?.length !== 0 || earningData?.length === 0) {
      const filteredResults = earningData?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setDeductionDetails(filteredResults);
      setPage(globalSearchValue ? 0 : page);
    }
  }, [earningData, globalSearchValue, page]);

  const handleDeleteDeductions = async () => {
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}deductions/${editDeduction.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          isdeleted: true,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setDeleteDeductions(false);
        reCallApi(true);
        showToast("success", res.message);
        setButtonLoader(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingRight: 1 }}>
        <Mui.TableContainer
          sx={{
            mt: 2,
            width: "100%",
            paddingLeft: 1,
            maxHeight: 850,
          }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow>
                {deductionHeaders.map((data) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {data?.header}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {deductionDetails?.length === 0 ? (
                <Mui.TableRow>
                  <Mui.TableCell colSpan={3}>
                    <Mui.Alert
                      severity="info"
                      sx={{ width: "100%", margin: 2 }}
                    >
                      No data found
                    </Mui.Alert>
                  </Mui.TableCell>
                </Mui.TableRow>
              ) : (
                deductionDetails
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((deduction, index) => (
                    <React.Fragment>
                      <Mui.TableRow>
                        {deductionHeaders.map((data) =>
                          data.header !== "Action" ? (
                            <Mui.TableCell>{deduction[data.key]}</Mui.TableCell>
                          ) : (
                            <Mui.TableCell>
                              <Mui.IconButton
                                onClick={(e) => {
                                  setAnchorEl(e.currentTarget);
                                  setEditDeduction(deduction);
                                  setComponentName(deduction.name);
                                  setValue("component_name", deduction.name);
                                  setValue("Amount", deduction.fixed_amount);
                                  setFixedAmountValue(deduction.fixed_amount);
                                  setEmployeeContributionPercentage(
                                    deduction?.employee_contribution_percentage
                                      ? deduction?.employee_contribution_percentage
                                      : 0
                                  );
                                  setEmployerContributionPercentage(
                                    deduction?.company_contribution_percentage
                                      ? deduction?.company_contribution_percentage
                                      : 0
                                  );
                                  setRadioButtonOption(
                                    deduction.deduction_type === "S"
                                      ? "statutory"
                                      : deduction.deduction_type === "F"
                                      ? "fixed_amount"
                                      : "default"
                                  );
                                  setStatutoryCheckBox(
                                    deduction.deduction_type === "S"
                                      ? true
                                      : false
                                  );
                                  setFixedAmount(
                                    deduction.deduction_type === "F"
                                      ? true
                                      : false
                                  );
                                  setSelectedMembers(deduction.earnings_detail);
                                  setValue(
                                    "employerContribution",
                                    deduction?.company_contribution_percentage
                                  );
                                  setValue(
                                    "employeeContribution",
                                    deduction?.employee_contribution_percentage
                                  );
                                  setCompanyContributionCTC(
                                    deduction?.company_contribution_part_of_ctc
                                  );
                                }}
                              >
                                <MoreVert
                                  sx={{
                                    color: themes.blackColor,
                                    strokeWidth: 2,
                                  }}
                                />
                              </Mui.IconButton>
                              <Mui.Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                elevation={1}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                                anchorOrigin={{
                                  horizontal: "right",
                                  vertical: "bottom",
                                }}
                              >
                                <Mui.MenuItem
                                  onClick={() => {
                                    setAnchorEl(null);
                                    setOpenDialog(true);
                                  }}
                                >
                                  Edit
                                </Mui.MenuItem>
                                <Mui.MenuItem
                                  onClick={() => {
                                    setDeleteDeductions(true);
                                    setAnchorEl(null);
                                  }}
                                >
                                  Delete
                                </Mui.MenuItem>
                              </Mui.Menu>
                            </Mui.TableCell>
                          )
                        )}
                      </Mui.TableRow>
                    </React.Fragment>
                  ))
              )}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      </Mui.Grid>
      <GlobalDialogBox
        handleCloseDialog={() => {
          handleCloseDialogAndDetails();
        }}
        open={openDialog}
        title="Edit Deduction"
        minWidth={600}
        minHeight={400}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Mui.Grid container sx={{ paddingBottom: 1 }}>
            <Mui.Typography>Component Name</Mui.Typography>
            <CustomTextBox
              selectedValue={componentName}
              rules={{
                ...register("component_name", {
                  required: "Component name is required",
                  minLength: {
                    value: 2,
                    message: "Minium length is 2 characters",
                  },
                  validate: {
                    atleastOneCharacterWithSpaceReg: (value) => {
                      if (
                        atleastOneCharacterWithSpace.test(value) ||
                        value === ""
                      ) {
                        return true;
                      } else {
                        return "Atleast one alphabet required";
                      }
                    },
                    minLengthNoSpaces: (value) => {
                      if (value?.trim().length < 2 && value !== "") {
                        return "Minimum 2 characters required";
                      } else {
                        return true;
                      }
                    },
                    uniqueNameCheck: async (value) => {
                      if (
                        errors?.component_name?.message ===
                        "Deduction name already exists"
                      ) {
                        return "Deduction name already exists";
                      }
                      if (editDeduction.name !== value) {
                        const response = await fetch(
                          `${domain}deduction/unique`,
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `token ${token}`,
                            },
                            body: JSON.stringify({
                              company: companyId,
                              name: value.trim(),
                            }),
                          }
                        );
                        const res = await response.json();
                        if (res.exists) {
                          return "Deduction name already exists";
                        }
                      }
                    },
                  },
                }),
              }}
              updateDataFunction={updateComponentName}
              placeHolder="Enter the component name"
              errorMessage={
                errors?.component_name?.message
                  ? errors?.component_name?.message
                  : ""
              }
              textBoxError={errors.component_name ? true : false}
            />
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid container>
              <Mui.RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={handleChangeRadioButton}
                value={radioButtonOption}
              >
                <Mui.FormControlLabel
                  value="statutory"
                  control={<Mui.Radio />}
                  label="Statutory components"
                />
                <Mui.FormControlLabel
                  value="fixed_amount"
                  control={<Mui.Radio />}
                  label="Fixed amount"
                />
                <Mui.FormControlLabel
                  value="default"
                  control={<Mui.Radio />}
                  label="Default"
                />
              </Mui.RadioGroup>
            </Mui.Grid>
            {statutoryCheckBox ? (
              <>
                <Mui.Grid container>
                  <Mui.Grid xs={6} sx={{ padding: 1 }}>
                    <Mui.Typography>
                      Default employee contribution %
                    </Mui.Typography>
                    <CustomTextBox
                      selectedValue={employeeContributionPercentage}
                      rules={{
                        ...register("employeeContribution", {
                          required: "Please enter employee contribution",
                          pattern: {
                            value: /^0|[1-9]\d*(\.\d+)?$|0\.\d+$/,
                          },
                          validate: {
                            checkAmount: (fieldValue) => {
                              const value = parseFloat(fieldValue);
                              if (isNaN(value) || value < 0 || value > 100) {
                                return "Employer contribution must be less then 100";
                              } else {
                                return true;
                              }
                            },
                          },
                        }),
                      }}
                      updateDataFunction={updateEmployeeContribution}
                      placeHolder="Enter employee contribution in percentage"
                      errorMessage={
                        errors?.employeeContribution?.message
                          ? errors?.employeeContribution?.message
                          : ""
                      }
                      textBoxError={errors.employeeContribution ? true : false}
                    />
                  </Mui.Grid>
                  <Mui.Grid xs={6} sx={{ padding: 1 }}>
                    <Mui.Typography>
                      Default company contribution %
                    </Mui.Typography>
                    <CustomTextBox
                      selectedValue={employerContributionPercentage}
                      rules={{
                        ...register("employerContribution", {
                          required: "Please enter employer contribution",
                          pattern: {
                            value: /^0|[1-9]\d*(\.\d+)?$|0\.\d+$/,
                          },
                          validate: {
                            checkAmount: (fieldValue) => {
                              const value = parseFloat(fieldValue);
                              if (isNaN(value) || value < 0 || value > 100) {
                                return "Employer contribution must be less then 100";
                              } else {
                                return true;
                              }
                            },
                          },
                        }),
                      }}
                      updateDataFunction={updateEmployerContribution}
                      placeHolder="Enter employer contribution in percentage"
                      errorMessage={
                        errors?.employerContribution?.message
                          ? errors?.employerContribution?.message
                          : ""
                      }
                      textBoxError={errors.employerContribution ? true : false}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container>
                  <Mui.Stack direction="row">
                    <Mui.Checkbox
                      checked={companyContributionCTC}
                      onChange={(event) =>
                        setCompanyContributionCTC(event.target.checked)
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Mui.Typography sx={{ paddingTop: 1 }}>
                      Company contribution part of CTC
                    </Mui.Typography>
                  </Mui.Stack>
                </Mui.Grid>
                <Mui.Grid container>
                  <Mui.Typography sx={{ paddingTop: 1 }}>
                    Choose all related earning components
                  </Mui.Typography>
                </Mui.Grid>
                <Controller
                  name="earning"
                  control={control}
                  defaultValue={selectedMembers}
                  rules={{
                    required: "Please select at least one earning ",
                  }}
                  render={({ field }) => (
                    <Mui.Autocomplete
                      {...field}
                      size="small"
                      fullWidth
                      multiline
                      multiple
                      limitTags={1}
                      disableCloseOnSelect
                      options={members}
                      getOptionLabel={(option) =>
                        option?.name ? option?.name : ""
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      ListboxProps={{ style: { maxHeight: "150px" } }}
                      onChange={(event, value) => {
                        if (value) {
                          setSelectedMembers(value.name);
                          field.onChange(value);
                        } else {
                          setSelectedMembers([]);
                          field.onChange([]);
                        }
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Mui.Chip
                            variant="outlined"
                            label={option?.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderOption={(props, option, { selected }) => {
                        return (
                          <li {...props}>
                            <Mui.Checkbox
                              icon={<CheckBoxOutlineBlank />}
                              checkedIcon={<CheckBox />}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            &nbsp;{option.name}
                          </li>
                        );
                      }}
                      componentsProps={{
                        popper: { style: { width: "fit-content" } },
                      }}
                      renderInput={(params) => (
                        <Mui.TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select earning"
                          error={!!errors.earning}
                          helperText={
                            errors.earning ? errors.earning.message : ""
                          }
                        />
                      )}
                    />
                  )}
                ></Controller>
              </>
            ) : fixedAmount ? (
              <Mui.Grid container>
                <Mui.Typography>Amount</Mui.Typography>
                <Mui.TextField
                  value={fixedAmountValue}
                  onChange={(event) => {
                    if (numberWithDecimalRegex.test(event.target.value)) {
                      setValue("Amount", event.target.value);
                      setFixedAmountValue(event.target.value);
                    }
                  }}
                  size="small"
                  fullWidth
                />
              </Mui.Grid>
            ) : null}
            <Mui.Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingTop: 2,
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setOpenDialog(false);
                  setTimeout(function () {
                    setComponentName("");
                    setFixedAmountValue(0);
                    reset();
                  }, 100);
                }}
                actionName="Cancel"
                typeName="button"
              />{" "}
              &nbsp;
              <CustomButton
                actionFuntion={handleSubmit(onSubmit)}
                actionName="Update"
                typeName="submit"
                disableAction={buttonLoader}
              />
            </Mui.Grid>
          </Mui.Grid>
        </form>
      </GlobalDialogBox>
      <GlobalDialogBox
        handleCloseDialog={() => setDeleteDeductions(false)}
        open={deleteDeduction}
        title="Delete Deductions"
        minWidth={600}
      >
        <Mui.Grid container>
          <Mui.Typography sx={{ fontSize: 23 }}>
            Do you want to delete the {editDeduction?.name} ?
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <CustomButton
            actionFuntion={() => setDeleteDeductions(false)}
            actionName="No"
            typeName="button"
          />{" "}
          &nbsp;
          <CustomButton
            actionFuntion={() => handleDeleteDeductions()}
            actionName="Yes"
            typeName="button"
            disableAction={
              deleteDeduction && buttonLoader ? buttonLoader : false
            }
          />
        </Mui.Grid>
      </GlobalDialogBox>
      <Mui.Grid
        container
        sx={{
          paddingTop: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          paddingRight: 1,
        }}
      >
        {deductionDetails.length > 10 && (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={deductionDetails.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.Grid>
    </React.Fragment>
  );
};
