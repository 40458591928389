import React, { useState, useEffect } from "react";
import { SettingsContextProvider } from "./SettingsContextProvider";
import { LeaveGroup } from "./Screens/LeaveGroup";
import { LeavePeriod } from "./Screens/LeavePeriod";
import { LeaveType } from "./Screens/LeaveType";
import { SettingsMenu } from "./SettingsMenu";
import { Grid, Paper } from "@mui/material";
import { SiteSettings } from "./Screens/SiteSettings";
import { useDispatch, useSelector } from "react-redux";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";

export const TeamZoneSettings = () => {
  const { teamZoneSettingScreen } = useSelector((state) => state.teamZoneReducer);
  const [currentScreen, setCurrentScreen] = useState(teamZoneSettingScreen);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDepartmentName("All"));
    dispatch(setDepartmentId(99999));
  }, [dispatch]);

  return (
    <React.Fragment>
      <SettingsContextProvider>
        <Grid container>
          <Grid xs={2}>
            <SettingsMenu
              setCurrentScreen={setCurrentScreen}
              currentScreen={currentScreen}
            />
          </Grid>
          <Grid xs={10} sx={{ padding: 2 }}>
            <Paper
              sx={{
                boxShadow: 3,
                minHeight: 850,
              }}
            >
              {currentScreen === "Leave Period" ? <LeavePeriod /> : null}
              {currentScreen === "Leave Type" ? <LeaveType /> : null}
              {currentScreen === "Leave Group" ? <LeaveGroup /> : null}
              {currentScreen === "Site Settings" ? <SiteSettings /> : null}
            </Paper>
          </Grid>
        </Grid>
      </SettingsContextProvider>
    </React.Fragment>
  );
};
