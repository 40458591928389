import React from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { BasicInfo } from "./BasicInfo";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const CreateEmployee = () => {
  const history = useHistory();

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 3, paddingRight: 3 }}>
        {" "}
      </Mui.Grid>
      <Mui.Grid container sx={{ paddingRight: 3 }}>
        <Mui.Stack direction="row">
          <BackArrow
            actionFunction={() => history.push("/admin/hr/employee")}
          />{" "}
          &nbsp;
          <Mui.Typography
            sx={{
              paddingTop: 1,
              fontSize: 23,
              fontWeight: "bold",
              color: themes.headLine,
              paddingLeft: 1,
            }}
          >
            Create New Employee
          </Mui.Typography>
        </Mui.Stack>
      </Mui.Grid>
      <Mui.Grid
        container
        sx={{
          padding: 1,
          width: "99.5%",
          height: "100%",
          borderRadius: 5,
          border: `5px solid ${themes.sidebarDivider}`,
        }}
        component={Mui.Paper}
      >
        <Mui.Grid container></Mui.Grid>
        <BasicInfo />
      </Mui.Grid>
    </React.Fragment>
  );
};
