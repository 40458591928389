import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { salaryRevisionKeyPair } from "pages/PayRoll/PayRollHeaders";
const keys = [
  "user_name",
  "employee_id",
  "paid_days",
  "gross_pay",
  "total_deduction",
  "net_pay",
  "per_day_salary",
];

export const SalaryRevision = ({
  viewPayrunDetails,
  downloadReportDetails,
  summaryName,
}) => {
  const { domain, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listViewPage, setListViewPage] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [salaryRevisionDetails, setSalaryRevisionDetails] = useState([]);
  const [updateData, setUpdateData] = useState(true);

  const { result: payrunData } = useFetch(
    `${domain}payrun/retrieve/?pay_schedule_id=${viewPayrunDetails.id}`
  );
  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  useEffect(() => {
    if (
      updateData &&
      payrunData?.payroll_run_details?.length !== 0 &&
      payrunData
    ) {
      setSalaryRevisionDetails(payrunData?.payroll_run_details);
      setUpdateData(false);
    }
  }, [payrunData, updateData]);

  useEffect(() => {
    if (payrunData?.length !== 0 || payrunData?.length === 0) {
      const filteredResults = payrunData?.payroll_run_details?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setSalaryRevisionDetails(filteredResults);
      setListViewPage(0);
    }
  }, [globalSearchValue, payrunData]);

  return (
    <React.Fragment>
      {salaryRevisionDetails?.length === 0 ? (
        <Mui.Alert severity="info" sx={{ width: "100%", marginTop: 2 }}>
          No data available
        </Mui.Alert>
      ) : (
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ mt: 8, width: "100%", maxHeight: 850 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow>
                {salaryRevisionKeyPair.map((header) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {header.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {salaryRevisionDetails
                ?.slice(
                  listViewPage * rowsPerPage,
                  listViewPage * rowsPerPage + rowsPerPage
                )
                .map((payschedule) => (
                  <Mui.TableRow>
                    <Mui.TableCell>
                      <Mui.Stack direction="Row">
                        <Mui.Avatar
                          sx={{ width: 25, height: 25 }}
                          alt="Remy Sharp"
                          src={payschedule.image_url}
                        />
                        <Mui.Typography
                          sx={{
                            paddingLeft: 1,
                          }}
                        >
                          {payschedule.user_name}
                        </Mui.Typography>
                      </Mui.Stack>
                    </Mui.TableCell>
                    <Mui.TableCell>{payschedule?.employee_id}</Mui.TableCell>
                    <Mui.TableCell>{payschedule?.paid_days}</Mui.TableCell>
                    <Mui.TableCell>{payschedule?.gross_pay}</Mui.TableCell>
                    <Mui.TableCell>
                      {payschedule?.total_deduction}
                    </Mui.TableCell>
                  </Mui.TableRow>
                ))}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      )}
      <Mui.Grid
        container
        sx={{
          paddingTop: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          paddingRight: 1,
        }}
      >
        {salaryRevisionDetails?.length > 10 && (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={salaryRevisionDetails?.length}
            rowsPerPage={rowsPerPage}
            page={listViewPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.Grid>
      <GlobalDialogBox
        handleCloseDialog={() => {
          setOpenDialog(false);
        }}
        open={openDialog}
        title="Delete payrun period"
      >
        <Mui.Grid container>
          <Mui.Typography sx={{ paddingBottom: 2 }}>
            Are you sure,you want to delete the ?
          </Mui.Typography>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setOpenDialog(false);
              }}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton actionName="Yes" typeName="button" />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
