import React from "react";
import { ShiftAssignContextProvider } from "../ShiftManageContext/ShiftAssignContext";
import { ShiftAssignments } from "./ShiftAssignment";

export const ShiftAssignIndex = () => {
  return (
    <React.Fragment>
      <ShiftAssignContextProvider>
        <ShiftAssignments />
      </ShiftAssignContextProvider>
    </React.Fragment>
  );
};
