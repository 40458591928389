import React from "react";
import * as Mui from "@mui/material";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { themes } from "services/constants";
import { viewTableData } from "pages/MyZone/MyZoneApply/Headers";
export const ViewLoan = ({
  viewLoanDetails,
  setViewLoan,
  selectedComponent,
}) => {
  return (
    <React.Fragment>
      <Mui.Grid container>
        <Mui.Stack direction="row">
          <BackArrow
            actionFunction={() => {
              setViewLoan(false);
            }}
          />
          &nbsp;&nbsp;
          <Mui.Typography
            sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 0.5 }}
          >
            {selectedComponent === "history" ? "History / " : "Active Loans / "}
            Loan Details
          </Mui.Typography>
        </Mui.Stack>
      </Mui.Grid>
      <Mui.Grid container sx={{ padding: 1 }}>
        <Mui.Grid
          container
          sx={{
            paddingLeft: 2,
            border: `2px solid ${themes.sidebarDivider}`,
            padding: 0.5,
          }}
        >
          <Mui.Grid xs={12} sm={6} md={1} lg={1} xl={1}>
            <Mui.Avatar
              sx={{ width: 70, height: 70 }}
              alt="Remy Sharp"
              src={viewLoanDetails?.image_url}
            />
          </Mui.Grid>
          <Mui.Grid xs={12} sm={6} md={2} lg={2} xl={2}>
            <Mui.Typography
              sx={{
                paddingTop: 3,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                fontSize: 20,
                color: themes.shadowText,
              }}
            >
              {viewLoanDetails.user_name}
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={12} sm={6} md={3} lg={3} xl={3}>
            <Mui.Stack direction="row">
              <Mui.Typography
                sx={{
                  paddingTop: 3,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  fontSize: 20,
                  color: themes.shadowText,
                }}
              >
                Employee Id :
              </Mui.Typography>
              <Mui.Typography
                sx={{
                  paddingTop: 3,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  fontSize: 20,
                  color: themes.headLine,
                  paddingLeft: 1,
                }}
              >
                {viewLoanDetails?.employee_id}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={12} sm={6} md={3} lg={3} xl={3}>
            <Mui.Stack direction="row">
              <Mui.Typography
                sx={{
                  paddingTop: 3,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  fontSize: 20,
                  color: themes.shadowText,
                }}
              >
                Application Id:
              </Mui.Typography>
              <Mui.Typography
                sx={{
                  paddingTop: 3,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  fontSize: 20,
                  color: themes.headLine,
                  paddingLeft: 1,
                }}
              >
                {viewLoanDetails?.application_id
                  ? viewLoanDetails?.application_id
                  : "N/A"}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={12} sm={6} md={3} lg={3} xl={3}>
            <Mui.Grid
              container
              sx={{
                paddingTop: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 2,
              }}
            >
              <Mui.Box
                sx={{
                  width: 120,
                  height: 15,
                  backgroundColor: themes.primaryIndicator,
                  borderRadius: "6px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: themes.headLine,
                  p: 2,
                  fontSize: 13,
                }}
              >
                {viewLoanDetails?.status}
              </Mui.Box>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      {/* Loan details */}
      <Mui.Grid container sx={{ padding: 1 }}>
        <Mui.Grid
          container
          sx={{ padding: 2, backgroundColor: themes.sidebarDivider }}
        >
          <Mui.Grid xs={1.5}>
            <Mui.Stack>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                Disbursement date
              </Mui.Typography>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                {viewLoanDetails.disbursement_date
                  ? viewLoanDetails.disbursement_date
                  : "N/A"}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={1.5}>
            <Mui.Stack>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                Principal amount{" "}
              </Mui.Typography>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                {viewLoanDetails.principal_amount
                  ? viewLoanDetails.principal_amount
                  : "N/A"}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={1.5}>
            <Mui.Stack>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                Interest
              </Mui.Typography>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                {viewLoanDetails.interest_rate
                  ? viewLoanDetails.interest_rate
                  : "N/A"}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={1.5}>
            <Mui.Stack>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                Interest Paid
              </Mui.Typography>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                {viewLoanDetails.total_interest_paid
                  ? viewLoanDetails.total_interest_paid
                  : "N/A"}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={1.5}>
            <Mui.Stack>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                Principal Paid
              </Mui.Typography>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                {viewLoanDetails?.total_principal_paid
                  ? viewLoanDetails?.total_principal_paid
                  : "N/A"}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={1.5}>
            <Mui.Stack>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                Principal Balance
              </Mui.Typography>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                {viewLoanDetails?.remaining_principal_balance
                  ? viewLoanDetails?.remaining_principal_balance
                  : "N/A"}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={1.5}>
            <Mui.Stack>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                Tenure
              </Mui.Typography>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                {viewLoanDetails?.tenure_months}&nbsp;&nbsp;
                {viewLoanDetails?.tenure_type}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={1.5}>
            <Mui.Stack>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                End date
              </Mui.Typography>
              <Mui.Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                {viewLoanDetails?.repayment_end_date
                  ? viewLoanDetails?.repayment_end_date
                  : "N/A"}
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      {viewLoanDetails.status !== "Rejected" ? (
        <Mui.Grid container sx={{ padding: 1 }}>
          <Mui.TableContainer
            sx={{
              marginTop: 2,
              marginLeft: "auto",
              marginRight: "auto",
              overflow: "auto",
            }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.sidebarDivider,
                }}
              >
                <Mui.TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      height: "15px",
                      padding: "6px 35px",
                    },
                  }}
                >
                  {viewTableData.map((head) => (
                    <Mui.TableCell>
                      <Mui.Typography fontWeight="bold">
                        {head.name}
                      </Mui.Typography>
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {viewLoanDetails.monthly_payments?.map((item) => (
                  <Mui.TableRow
                    sx={{
                      "& .MuiTableCell-root": {
                        height: "15px",
                        padding: "6px 35px",
                      },
                    }}
                  >
                    {viewTableData.map((head) => (
                      <Mui.TableCell>
                        {item[head.key] ? item[head.key] : "N/A"}
                      </Mui.TableCell>
                    ))}
                  </Mui.TableRow>
                ))}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
        </Mui.Grid>
      ) : (
        <>
          <Mui.Typography>Reject Reason</Mui.Typography>
          <Mui.Grid container sx={{ padding: 2 }}>
            <Mui.Box
              sx={{
                paddingLeft: 10,
                width: "100%",
                backgroundColor: themes.whiteColor,
                borderRadius: "6px",
                color: themes.headLine,
                p: 2,
                fontSize: 18,
                border: `2px solid ${themes.sidebarDivider}`,
              }}
            >
              {viewLoanDetails?.reject_reason
                ? viewLoanDetails?.reject_reason
                : "N/A"}
            </Mui.Box>
          </Mui.Grid>
        </>
      )}
    </React.Fragment>
  );
};
