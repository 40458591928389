import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { Info } from "@mui/icons-material";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { customAPI } from "services/utils/CustomAPI";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { useFetch } from "services/hooks/useFetch";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import sessionHandling from "services/utils/notificationUtils";

export const EditShiftGroup = ({
  reCallApi,
  setShowTopBar,
  setEditGroup,
  editShiftGroup,
  departmentId,
  setDepartmentId,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [shiftCategoryName, setShiftCategoryName] = useState(
    editShiftGroup.shift_category_name
  );
  const [departmentApi, setDepartmentApi] = useState(true);
  const [nameApi, setNameApi] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const { result: shiftCategoryList } = useFetch(`${domain}shift_category/`);
  const [shiftCategoryId, setShiftCategoryId] = useState(
    editShiftGroup.shift_category_id
  );

  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [departmentError, setDepartmentError] = useState(false);
  const [userError, setUserError] = useState(false);
  const [autoGeneratedName, setAutoGeneratedName] = useState();

  const { result: membersData, setLoad: reCallMemberApi } = useFetch(
    `${domain}unassign-shift-group-user/?department_ids=[${departmentId}]`
  );

  // const [memberApiUrl, setMemberApiUrl] = useState("");
  // const { result: membersData, setLoad: reCallMemberApi } =
  //   useFetch(memberApiUrl);

  // useEffect(() => {
  //   if (departmentId.length !== 0 && departmentList.length > 0) {
  //     const initialDepartmentIds = departmentList
  //       .map((dept) => dept.id)
  //       .join(",");
  //     setMemberApiUrl(
  //       `${domain}get-department-based-user/?department_ids=[${initialDepartmentIds}]`
  //     );
  //     reCallMemberApi(true);
  //   }
  // }, [departmentId.length, departmentList, domain, reCallMemberApi]);

  useEffect(() => {
    setValue("shift_category_name ", editShiftGroup?.shift_category_name);
    setValue("shift_category_id", editShiftGroup?.shift_category_id);
    setValue("name", editShiftGroup?.name);
    setShiftCategoryName(editShiftGroup?.shift_category_name);
    setAutoGeneratedName(editShiftGroup?.name);
    const departments =
      editShiftGroup.departments?.map((dept) => ({
        id: dept.id,
        department_name: dept.department,
      })) || [];
    const users =
      editShiftGroup?.departments
        ?.flatMap((dept) => dept.users || [])
        .map((user) => ({
          user_id: user.id,
          user_name: user.username,
        })) || [];
    setSelectedDepartments(departments);
    setValue("departments", departments);
    setSelectedUsers(users);
    setValue("users", users);
  }, [
    editShiftGroup.departments,
    editShiftGroup?.name,
    editShiftGroup?.shift_category_id,
    editShiftGroup?.shift_category_name,
    setValue,
  ]);

  const handleBlur = () => {
    if (selectedDepartments.length > 0) {
      let data = [];
      selectedDepartments.forEach((element) => {
        data.push(element.id);
      });
      setDepartmentId(data);
      setTimeout(function () {
        reCallMemberApi(true);
      }, 300);
    } else {
      setDepartmentError(true);
    }
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const validateDepartments = () => {
    if (!selectedDepartments || selectedDepartments.length === 0) {
      setDepartmentError(true);
      return false;
    } else if (!selectedUsers || selectedUsers.length === 0) {
      setUserError(true);
      return false;
    }
    setDepartmentError(false);
    setUserError(false);
    return true;
  };

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await fetch(
          `${domain}department-based-shiftcategory/?shift_category_id=${shiftCategoryId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setDepartmentList(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (departmentApi && shiftCategoryId) {
      fetchDepartment();
      setDepartmentApi(false);
    }
  }, [departmentApi, domain, shiftCategoryId, token]);

  const onSubmit = async (data) => {
    const isValid = validateDepartments();
    if (isValid) {
      setButtonDisabled(true);
      let departmentId = [];
      selectedDepartments.forEach((element) => {
        departmentId.push(element.id);
      });

      let userID = [];
      selectedUsers.forEach((element) => {
        userID.push(element.user_id);
      });

      let payload = {
        ...data,
        departments: departmentId,
        users: userID,
        shift_group_id: editShiftGroup?.id,
        name: autoGeneratedName,
      };
      const response = await customAPI(
        `${domain}shift-group/`,
        "PUT",
        token,
        payload
      );
      if (response.ok) {
        showToast("success", "Shift group updated successfully");
        setEditGroup(false);
        setShowTopBar(true);
        reCallApi(true);
        reset();
      } else {
        setButtonDisabled(false);
      }
    }
  };

  const updateCategoryName = (event, data) => {
    if (data) {
      setValue("shift_category", data?.label);
      setValue("shift_category_id", data?.id);
      setShiftCategoryId(data?.id);
      setShiftCategoryName(data?.label);
      setSelectedDepartments([]);
      setSelectedUsers([]);
      setDepartmentApi(true);
      setNameApi(true);
    }
  };

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const response = await fetch(
          `${domain}create-shift-group-name/?shift_category_id=${shiftCategoryId}&shift_category_name=${shiftCategoryName}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setAutoGeneratedName(res.shift_group_name);
          setValue("shift_group_name", res.shift_group_name);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (nameApi && shiftCategoryId) {
      fetchDepartment();
      setNameApi(false);
    }
  }, [domain, nameApi, setValue, shiftCategoryId, shiftCategoryName, token]);

  // const handleDepartmentChange = (event, value) => {
  //   const selectedDeptIds = value.map((item) => item.id); // Ensure matching `id`
  //   setSelectedDepartments(selectedDeptIds);
  //   setDepartmentError(false);

  //   if (selectedDeptIds.length > 0) {
  //     setMemberApiUrl(
  //       `${domain}get-department-based-user/?department_ids=[${selectedDeptIds.join(
  //         ","
  //       )}]`
  //     );
  //     reCallMemberApi(true);
  //   }
  // };

  const nameUpdate = (event) => {
    if (event.target.value.length < 32) {
      setValue("name", event.target.value);
      setAutoGeneratedName(event.target.value);
    }
  };

  return (
    <>
      <Mui.Grid container>
        <BackArrow
          actionFunction={() => {
            setEditGroup(false);
            setShowTopBar(true);
          }}
        />
        <Mui.Typography
          sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 1 }}
        >
          Shift Group / Edit Shift Group
        </Mui.Typography>
      </Mui.Grid>
      <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 20 }}>
        <Mui.Grid
          container
          sx={{ paddingLeft: 25, paddingRight: 25, paddingTop: 10 }}
        >
          <Mui.Grid container component={Mui.Paper} elevation={5}>
            <Mui.Grid
              xs={12}
              sx={{
                backgroundColor: themes.primary,
                padding: 2,
                textAlign: "center",
              }}
            >
              <Mui.Typography
                sx={{ fontSize: 24, fontWeight: 800, font: "bold" }}
              >
                Edit Shift Group
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid container sx={{ padding: 4 }}>
              <Mui.Grid
                container
                fullWidth
                sx={{ padding: 1, borderRadius: 3 }}
              >
                <Mui.Grid item xs={4} sx={{ paddingTop: 1.5 }}>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      color: themes.headLine,
                      fontWeight: "bold",
                    }}
                  >
                    Shift Category &nbsp;
                    <Mui.Tooltip title="This is the Shift Category field. Update the shift type for this group, such as Day, Night, or Rotational, to reflect any changes in the work schedule.">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={1} sx={{ paddingTop: 1.5 }}>
                  :
                </Mui.Grid>
                <Mui.Grid item xs={6}>
                  <CustomAutoComplete
                    dataList={shiftCategoryList?.map((obj) => ({
                      ...obj,
                      label: obj?.name,
                    }))}
                    // rules={{
                    //   ...register("shift_category_name", {
                    //     required: "Shift category name is required",
                    //   }),
                    // }}
                    selectedValue={shiftCategoryName}
                    updateDataFunction={updateCategoryName}
                    placeHolder="Select shift category"
                    errorMessage={
                      errors?.shift_category_name?.message
                        ? errors?.shift_category_name?.message
                        : ""
                    }
                    textBoxError={errors?.shift_category_name ? true : false}
                  />
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Grid container fullWidth sx={{ padding: 1 }}>
                <Mui.Grid item xs={4} sx={{ paddingTop: 2 }}>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Department &nbsp;
                    <Mui.Tooltip title="This is the Department field. Modify the department associated with this shift group, ensuring it aligns with the current organizational structure.">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid item xs={1} sx={{ paddingTop: 3 }}>
                  :
                </Mui.Grid>
                <Mui.Grid xs={6}>
                  <Mui.Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    size="small"
                    value={selectedDepartments}
                    options={departmentList}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.department_name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, value) => {
                      setSelectedDepartments(value);
                      setDepartmentError(false);
                      reCallMemberApi(true);
                    }}
                    onBlur={handleBlur}
                    renderOption={(props, option, { selected }) => {
                      const { key, ...optionProps } = props;
                      return (
                        <li key={key} {...optionProps}>
                          <Mui.Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                          {option.department_name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        multiline
                        rows={3}
                        maxRows={5}
                        placeholder={
                          selectedDepartments.length === 0
                            ? "Add department"
                            : ""
                        }
                        error={departmentError}
                        helperText={
                          departmentError
                            ? "Select at least one department"
                            : ""
                        }
                      />
                    )}
                  />
                  {/* <Mui.Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    size="small"
                    value={selectedDepartments}
                    options={departmentList}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.department_name}
                    isOptionEqualToValue={(option, value) => {
                      if (!value) return false;
                      return option.id === value.id;
                    }}
                    onChange={(event, value) => {
                      const selectedDepartmentIds = value.map(
                        (item) => item.id
                      );

                      setSelectedDepartments([...selectedDepartments,value]);
                      if (selectedDepartmentIds.length === 0) {
                        setMemberApiUrl(
                          `${domain}get-department-based-user/?department_ids=[99999]`
                        );
                      } else {
                        const formattedIds = selectedDepartmentIds.join(",");
                        setMemberApiUrl(
                          `${domain}get-department-based-user/?department_ids=[${formattedIds}]`
                        );
                      }
                      reCallMemberApi(true);
                      setDepartmentError(false);
                    }}
                    renderOption={(props, option, { selected }) => {
                      const { key, ...optionProps } = props;
                      return (
                        <li key={key} {...optionProps}>
                          <Mui.Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                          {option.department_name}
                        </li>
                      );
                    }}
                    onBlur={() => {
                      if (!selectedDepartments.length) {
                        setDepartmentError(true);
                      }
                    }}
                    renderInput={(params) => (
                      <Mui.TextField
                        multiline
                        rows={3}
                        maxRows={5}
                        {...params}
                        placeholder={
                          selectedDepartments.length === 0
                            ? "Add department"
                            : ""
                        }
                        error={departmentError}
                        helperText={
                          departmentError
                            ? "Select at least one department"
                            : ""
                        }
                      />
                    )}
                  /> */}
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container fullWidth sx={{ padding: 1 }}>
                <Mui.Grid item xs={3.5} sx={{ paddingTop: 2 }}>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Employee &nbsp;
                    <Mui.Tooltip
                      title="This is the Employee field. Add or remove employees from this shift group as needed to ensure accurate group assignments.
"
                    >
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>{" "}
                </Mui.Grid>
                <Mui.Grid xs={0.5}></Mui.Grid>
                <Mui.Grid item xs={1} sx={{ paddingTop: 2 }}>
                  :
                </Mui.Grid>
                <Mui.Grid item xs={6}>
                  <Mui.Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    size="small"
                    options={membersData}
                    value={selectedUsers}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.user_name}
                    isOptionEqualToValue={(option, value) =>
                      option.user_id === value.user_id
                    }
                    onChange={(event, value) => {
                      setSelectedUsers(value);
                      setUserError(false);
                    }}
                    onBlur={() => {
                      if (!selectedUsers.length) {
                        setUserError(true);
                      }
                    }}
                    renderOption={(props, option, { selected }) => {
                      const { key, ...optionProps } = props;
                      return (
                        <li key={key} {...optionProps}>
                          <Mui.Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                          {option.user_name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        multiline
                        rows={3}
                        maxRows={5}
                        placeholder={
                          selectedUsers.length === 0 ? "Add user" : ""
                        }
                        error={userError}
                        helperText={userError ? "Select at least one user" : ""}
                      />
                    )}
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container sx={{ padding: 1 }}>
                <Mui.Grid xs={4}>
                  <Mui.Typography
                    sx={{
                      fontSize: 18,
                      color: themes.headLine,
                      paddingBottom: 0.5,
                      fontWeight: "bold",
                    }}
                  >
                    Name &nbsp;
                    <Mui.Tooltip title="This is the Name field. Edit the name of the shift group to better represent its purpose, such as 'Updated Night Shift' or 'Team A Rotational Shift.">
                      <Info />
                    </Mui.Tooltip>
                  </Mui.Typography>{" "}
                </Mui.Grid>
                <Mui.Grid xs={1} sx={{ paddingTop: 1 }}>
                  :
                </Mui.Grid>
                <Mui.Grid xs={6}>
                  <CustomTextBox
                    selectedValue={autoGeneratedName}
                    rules={{
                      ...register("name", {
                        required: "Shift group name is required",
                        // validate: {
                        //   shiftGradeAviliable: async (shiftName) => {
                        //     if (shiftName !== "") {
                        //       const response = await fetch(
                        //         `${domain}check-shiftgroup-name/?shift_group_name=${shiftName}`,
                        //         {
                        //           method: "GET",
                        //           headers: {
                        //             "Content-Type": "application/json",
                        //             Authorization: `token ${token}`,
                        //           },
                        //         }
                        //       );
                        //       const resp = await response.json();
                        //       if (resp.exists) {
                        //         return "Shift group name already exists";
                        //       }
                        //     }
                        //   },
                        // },
                      }),
                    }}
                    updateDataFunction={nameUpdate}
                    errorMessage={
                      errors?.name?.message ? errors?.name?.message : ""
                    }
                    textBoxError={errors.name ? true : false}
                  />
                </Mui.Grid>
              </Mui.Grid>

              <Mui.Grid
                container
                sx={{
                  paddingTop: 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 10,
                }}
              >
                <CustomButton
                  actionName="Cancel"
                  typeName="button"
                  actionFuntion={() => {
                    setEditGroup(false);
                    setShowTopBar(true);
                  }}
                />
                &nbsp;&nbsp;
                <CustomButton
                  actionName="Update"
                  actionFuntion={() => {
                    if (!selectedDepartments.length) {
                      setDepartmentError(true);
                    } else if (!selectedUsers.length) {
                      setUserError(true);
                    }
                  }}
                  typeName="submit"
                  disableAction={buttonDisabled}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </form>
    </>
  );
};
