import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { Weekly } from "./Weekly";
import Monthly from "./Monthly";
import { showToast } from "services/utils/Status";
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { startOfMonth, endOfMonth } from "date-fns";
import moment from "moment";
import sessionHandling from "services/utils/notificationUtils";

export const MyShift = () => {
  const [dataVisualMethod, setDataVisualMethod] = useState("Monthly");
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [weeklyselectedDate, setWeeklySelectedDate] = useState(new Date());
  const [formattedDate, setFormattedDate] = useState(new Date(), "yyyy-MM");
  const [loading, setIsLoading] = useState(false);
  const [fetchWeekly, setFetchWeekly] = useState(false);
  const [monthlyShiftList, setMonthlyShiftList] = useState([]);
  const [weeklyShiftList, setWeeklyShiftList] = useState([]);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [listOfDate, setListOfDate] = useState([]);
  const minDate = new Date(2020, 0, 1);

  const getCurrentWeek = () => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days =
      Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000)) +
      startDate.getDay();
    const weekNumber = Math.ceil(days / 7);
    const weekValue = `${currentDate.getFullYear()}-W${weekNumber
      .toString()
      .padStart(2, "0")}`;
    return weekValue;
  };

  const [selectedWeek, setSelectedWeek] = useState(getCurrentWeek);
  const [tempWeek, setTempWeek] = useState(getCurrentWeek);

  const handleWeeklyDateOnChange = (event) => {
    setTempWeek(event.target.value);
  };
  const handleWeeklyDateBlur = () => {
    const year = parseInt(tempWeek.split("-W")[0], 10);
    if (year.toString().length < 4) {
      showToast("error", "Please enter a valid week in YYYY-WXX format");
    } else if (year < 2020) {
      showToast("error", "Year must be 01/01/2020 or later");
    } else {
      setSelectedWeek(tempWeek);
    }
  };

  const handleMonthlyDateOnChange = (date) => {
    if (
      date instanceof Date &&
      !isNaN(date) &&
      date.getFullYear().toString().length === 4 &&
      date >= minDate
    ) {
      setWeeklySelectedDate(date);
    } else if (date.getFullYear().toString().length === 4 && date < minDate) {
      showToast("error", "Year must be 01/01/2020 or later");
    }
  };

  const getWeekDays = (startDate) => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(startDate);
      day.setDate(startDate.getDate() + i);
      days.push(day);
    }
    return days;
  };

  const getMonthDays = (startDate) => {
    const days = [];
    const year = startDate.getFullYear();
    const month = startDate.getMonth();

    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);

    let currentDate = firstDayOfMonth;

    while (currentDate <= lastDayOfMonth) {
      days.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return days;
  };

  useEffect(() => {
    if (selectedWeek && dataVisualMethod) {
      if (dataVisualMethod === "Weekly") {
        const startDate = moment(selectedWeek).startOf("isoWeek").toDate();
        const endDate = moment(selectedWeek).endOf("isoWeek").toDate();
        const dates = getWeekDays(startDate);
        const formatDates = dates?.map((value) =>
          moment(value).format("YYYY-MM-DD")
        );
        setListOfDate([]);
        setStartDate("");
        setEndDate("");
        setListOfDate(formatDates);
        setStartDate(moment(startDate).format("YYYY-MM-DD"));
        setEndDate(moment(endDate).format("YYYY-MM-DD"));
        if (startDate !== "" && endDate !== "") {
          setFetchWeekly(true);
        }
      } else {
        const start = startOfMonth(weeklyselectedDate);
        const end = endOfMonth(weeklyselectedDate);
        const dates = getMonthDays(start);
        const formatDates = dates?.map((value) =>
          moment(value).format("YYYY-MM-DD")
        );
        setListOfDate([]);
        setStartDate("");
        setEndDate("");
        setListOfDate(formatDates);
        setStartDate(moment(start).format("YYYY-MM-DD"));
        setEndDate(moment(end).format("YYYY-MM-DD"));
        if (start !== "" && end !== "") {
          setFetchWeekly(true);
        }
      }
    }
  }, [dataVisualMethod, selectedWeek, weeklyselectedDate, startDate, endDate]);

  useEffect(() => {
    const formatDate = () => {
      if (weeklyselectedDate) {
        const formattedDate =
          weeklyselectedDate?.getFullYear() +
          "-" +
          ("0" + (weeklyselectedDate?.getMonth() + 1)).slice(-2);
        setFormattedDate(formattedDate);
      }
    };
    formatDate();
    if (formattedDate && dataVisualMethod === "Monthly") {
      setFetchWeekly(true);
    }
  }, [dataVisualMethod, formattedDate, weeklyselectedDate]);

  useEffect(() => {
    const fetchWeeklyShiftList = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${domain}daily_user_shift_assignments/?user_id=${userDetails?.id}&from_date=${startDate}&to_date=${endDate}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setMasterDataForSearch([]);
          dataVisualMethod === "Monthly"
            ? setMonthlyShiftList([])
            : setWeeklyShiftList([]);

          if (dataVisualMethod === "Monthly") {
            if (res?.data) {
              const adminLeaveEvents = res?.data[0]?.shifts.map(
                (request, index) => {
                  const from_Date = new Date(
                    request.date.split("-").reverse().join("-")
                  );
                  return {
                    id: `myshift_${index}`,
                    title: request?.shift_name
                      ? request?.shift_name
                      : "Not Joined",
                    start: from_Date,
                    end: from_Date,
                    shift_start_time: request.shift_start_time,
                    shift_end_time: request.shift_end_time,
                    is_custom: request.is_custom,
                    is_weekoff: request.is_weekoff,
                  };
                }
              );
              setMonthlyShiftList([...adminLeaveEvents]);
              setMasterDataForSearch([...adminLeaveEvents]);
            }
          } else {
            setWeeklyShiftList(res?.data);
            setMasterDataForSearch(res?.data);
          }

          setIsLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        setIsLoading(false);
      }
    };
    if (
      fetchWeekly === true &&
      startDate !== "" &&
      endDate !== "" &&
      userDetails?.id !== ""
    ) {
      fetchWeeklyShiftList();
      setFetchWeekly(false);
    }
  }, [
    domain,
    endDate,
    fetchWeekly,
    startDate,
    token,
    userDetails?.id,
    dataVisualMethod,
  ]);

  useEffect(() => {
    const FilterWeeklyData = () => {
      const cols =
        masterDataForSearch[0] && Object.keys(masterDataForSearch[0]);
      return masterDataForSearch.filter((r) =>
        cols.some((c) =>
          r[c]
            ? r[c]
                .toString()
                .toLowerCase()
                .indexOf(globalSearchValue.toLowerCase()) > -1
            : null
        )
      );
    };
    if (globalSearchValue && dataVisualMethod === "Weekly") {
      setWeeklyShiftList(FilterWeeklyData());
    }
  }, [globalSearchValue, masterDataForSearch, dataVisualMethod]);

  return (
    <React.Fragment>
      <Mui.Grid container spacing={1} sx={{ mt: 3 }}>
        <Mui.Grid item xs={12} sm={6} md={4} lg={2}>
          <Mui.ButtonGroup size="large" aria-label="Large button group">
            <Mui.Button
              variant={
                dataVisualMethod === "Monthly" ? "contained" : "outlined"
              }
              onClick={() => {
                setDataVisualMethod("Monthly");
              }}
              sx={{
                background:
                  dataVisualMethod === "Monthly"
                    ? themes.primaryButton
                    : "outlined",
                textTransform: "capitalize",
                border: "none",
                "&:hover": {
                  border: "none",
                  backgroundColor:
                    dataVisualMethod === "Monthly"
                      ? themes.primaryButton
                      : "outlined",
                },
                color: themes.headLine,
              }}
            >
              Monthly
            </Mui.Button>
            <Mui.Button
              variant={dataVisualMethod === "Weekly" ? "contained" : "outlined"}
              onClick={() => {
                setDataVisualMethod("Weekly");
                setSelectedWeek(getCurrentWeek);
              }}
              sx={{
                background:
                  dataVisualMethod === "Weekly"
                    ? themes.primaryButton
                    : "outlined",
                textTransform: "capitalize",
                border: "none",
                "&:hover": {
                  border: "none",
                  backgroundColor:
                    dataVisualMethod === "Weekly"
                      ? themes.primaryButton
                      : "outlined",
                },
                color: themes.headLine,
              }}
            >
              Weekly
            </Mui.Button>
          </Mui.ButtonGroup>
        </Mui.Grid>
        <Mui.Grid item md={4} lg={7.4}></Mui.Grid>
        <Mui.Grid item xs={12} sm={5} md={4} lg={2}>
          <Mui.Stack
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: 10,
              alignSelf: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            {dataVisualMethod === "Monthly" ? (
              <>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    size="small"
                    label="Select Date"
                    views={["year", "month"]}
                    value={weeklyselectedDate}
                    minDate={minDate}
                    onChange={handleMonthlyDateOnChange}
                    slotProps={{ textField: { size: "small" } }}
                    style={{
                      width: 250,
                    }}
                    renderInput={(params) => (
                      <Mui.TextField {...params} size="small" />
                    )}
                  />
                </LocalizationProvider>
              </>
            ) : (
              <>
                <Mui.TextField
                  label="Select Week"
                  size="small"
                  type="week"
                  value={tempWeek}
                  onChange={handleWeeklyDateOnChange}
                  style={{
                    width: 250,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
          </Mui.Stack>
        </Mui.Grid>
        {dataVisualMethod === "Weekly" ? (
          <Mui.Grid sx={{ paddingTop: 1 }}>
            <CustomButton
              actionFuntion={handleWeeklyDateBlur}
              actionName="Filter"
              typeName="button"
              onClick={handleWeeklyDateBlur}
            />
          </Mui.Grid>
        ) : null}
      </Mui.Grid>
      <Mui.Grid>
        {dataVisualMethod === "Weekly" ? (
          <Weekly
            loading={loading}
            weeklyShiftList={weeklyShiftList}
            listOfDate={listOfDate}
          />
        ) : (
          <Monthly
            loading={loading}
            monthlyShiftList={monthlyShiftList}
            listOfDate={listOfDate}
            startDate={startDate}
          />
        )}
      </Mui.Grid>
    </React.Fragment>
  );
};
