import React, { useContext, useState, useCallback, useEffect } from "react";
import * as Mui from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { CustomButton } from "components/CustomComponents/CustomButton";
import MemberShiftGroup from "./MembersShiftGroup";
import { themes } from "services/constants";
import { ShiftGroupContext } from "../ShiftManageContext/ShiftGroupContext";
import { Loader } from "components/Loader";
import {
  DeleteSharp,
  KeyboardArrowDown,
  NavigateNext,
  RemoveRedEye,
} from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
import { MoreVert } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { filterShiftGrp } from "services/constants";
import { useDispatch } from "react-redux";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { GlobalDialogBox } from "components/GlobalDialogBox";
let multiApiCallRestriction = true;
export const ShiftGroups = () => {
  const history = useHistory();
  const {
    domain,
    token,
    isLoading,
    setReldSftGrpApi,
    allSftGrp,
    allSftCategoryDet,
    setAllSftGrp,
    masterDataForSearch,
  } = useContext(ShiftGroupContext);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    trigger,
    getValues,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { globalSearchValue } = useSelector((state) => state.tokenReducer);
  const [showAddShiftGrp, setShowAddShiftGrp] = useState(false);
  const [showEmpShiftGrp, setShowEmpShiftGrp] = useState(false);
  const [sftCatName, setSftCatName] = useState("");
  const [sftCatId, setSftCatId] = useState("");
  const [sftCatDept, setSftCatDept] = useState([]);
  const [selSftCatDept, setSelSftCatDept] = useState([]);
  const [sftCatDeptId, setSftCatDeptId] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandDepDet, setExpandDepDet] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewSftGrpDet, setViewSftGrpDet] = useState([]);
  const [viewSftGrpDepDet, setViewSftGrpDepDet] = useState([]);
  const [viewGrpName, setViewGrpName] = useState("");
  const [viewDepName, setViewDepName] = useState("");
  const [optName, setOptName] = useState("");
  const [showDelSftDep, setShowDelSftDep] = useState(false);
  const [delDepIds, setDelDepIds] = useState([]);

  useEffect(() => {
    const filterShiftGroupData = masterDataForSearch.filter((item) =>
      filterShiftGrp.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setAllSftGrp(filterShiftGroupData);
    setPage(0);
  }, [globalSearchValue, masterDataForSearch, setAllSftGrp]);

  const getSftCatDepartments = useCallback(
    async (sftId) => {
      try {
        const response = await fetch(
          `${domain}department-based-shiftcategory/?shift_category_id=${sftId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const labels = res.map((obj) => ({
            ...obj,
            label: obj.department_name,
          }));
          setSftCatDept([...labels]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    },
    [domain, token]
  );

  const handleClose = useCallback(() => {
    setShowAddShiftGrp(false);
    setOptName("");
    setSftCatName("");
    setSftCatId("");
    setSftCatDept([]);
    setSelSftCatDept([]);
    setSftCatDeptId([]);
    reset();
  }, [reset, setShowAddShiftGrp]);

  const createShiftGroup = async (data) => {
    if (multiApiCallRestriction) {
      multiApiCallRestriction = false;
      try {
        const apiActionMethod = optName !== "Edit" ? "POST" : "PUT";
        const payloadData =
          optName !== "Edit"
            ? {
                name: data.groupname,
                shift_category_id: sftCatId,
                departments: sftCatDeptId,
              }
            : {
                name: data.groupname,
                shift_group_id: viewSftGrpDet?.id,
                departments: sftCatDeptId,
              };

        const response = await fetch(`${domain}shift-group/`, {
          method: apiActionMethod,
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify(payloadData),
        });
        const res = await response.json();
        if (response.ok) {
          handleClose();
          setValue("uniquename_check", "");
          showToast("success", res.message);
          multiApiCallRestriction = true;
          setReldSftGrpApi(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        multiApiCallRestriction = true;
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateEditDetails = (data) => {
    setValue("groupname", data?.name);
    setValue("shiftcategory", data?.shift_category_name);
    setSftCatName(data?.shift_category_name);
    setSftCatId(data?.shift_category_id);
    setValue("shiftcatdepartment", data?.departments);
    setSftCatDeptId(data?.departments?.map((dep) => dep.id));
    setSelSftCatDept(data?.departments);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelCloseDialogBox = () => {
    setDelDepIds([]);
    setViewSftGrpDet([]);
    setOptName("");
    setShowDelSftDep(false);
  };

  const handleDeleteDepartment = async () => {
    try {
      const response = await fetch(`${domain}shift-group/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          shift_group_id: viewSftGrpDet?.id,
          shift_category_id: viewSftGrpDet?.shift_category_id,
          departments: delDepIds,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.message);
        handleDelCloseDialogBox();
        setReldSftGrpApi(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  return (
    <>
      {!showEmpShiftGrp ? (
        <>
          <Mui.Grid container spacing={1} sx={{ mt: 0 }}>
            <Mui.Grid item xs={12} sm={12} md={10} lg={10}>
              <Mui.Stack direction="row" sx={{ mt: 1 }}>
                <BackArrow
                  actionFunction={() => {
                    history.goBack();
                    dispatch(setGlobalSearchValue(""));
                  }}
                />
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    mt: 1.5,
                  }}
                >
                  Shift Groups
                </Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid
              xs={12}
              sm={12}
              md={2}
              lg={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 2,
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  reset();
                  setSftCatName("");
                  setSftCatId("");
                  setSelSftCatDept([]);
                  setShowAddShiftGrp(true);
                }}
                actionName="Add Shift Group"
                typeName="button"
              />
            </Mui.Grid>
          </Mui.Grid>
          {isLoading ? (
            <Loader info="Loading..." />
          ) : (
            <Mui.TableContainer
              component={Mui.Paper}
              sx={{ mt: 2, width: "100%", mr: 2 }}
            >
              <Mui.Table>
                <Mui.TableHead
                  sx={{
                    backgroundColor: themes.primary,
                  }}
                >
                  <Mui.TableRow>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    ></Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Group Name
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Shift Category
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Employee Count
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Department
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Action
                    </Mui.TableCell>
                  </Mui.TableRow>
                </Mui.TableHead>

                {allSftGrp?.length !== 0 && allSftGrp ? (
                  <Mui.TableBody>
                    {allSftGrp
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <>
                          <Mui.TableRow
                            maxWidth="xl"
                            align="left"
                            sx={{
                              border: `1px solid ${themes.formBackground}`,
                              cursor: "pointer",
                            }}
                            hover={true}
                          >
                            <Mui.TableCell align="left">
                              <Mui.IconButton
                                size="small"
                                sx={{ marginRight: 2 }}
                                onClick={() => {
                                  setExpandDepDet(
                                    item?.id === expandDepDet ? 0 : item?.id
                                  );
                                }}
                              >
                                {expandDepDet === item?.id ? (
                                  <KeyboardArrowDown
                                    size="small"
                                    sx={{ stroke: 2, color: themes.blackColor }}
                                  />
                                ) : (
                                  <NavigateNext
                                    size="small"
                                    sx={{ stroke: 2, color: themes.blackColor }}
                                  />
                                )}
                              </Mui.IconButton>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.name ? item?.name : "N/A"}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.shift_category_name
                                  ? item?.shift_category_name
                                  : "N/A"}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.group_user_count
                                  ? item?.group_user_count
                                  : 0}
                              </Mui.Typography>
                            </Mui.TableCell>

                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.department_count
                                  ? item?.department_count
                                  : 0}
                              </Mui.Typography>
                            </Mui.TableCell>

                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.IconButton
                                onClick={(e) => {
                                  setAnchorEl(e.currentTarget);
                                  setViewGrpName(item?.name);
                                  setViewDepName("");
                                  setViewSftGrpDet(item);
                                  setViewSftGrpDepDet([]);
                                  updateEditDetails(item);
                                }}
                              >
                                <MoreVert />
                              </Mui.IconButton>
                              <Mui.Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                elevation={1}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                                anchorOrigin={{
                                  horizontal: "right",
                                  vertical: "bottom",
                                }}
                              >
                                <Mui.MenuItem
                                  onClick={() => {
                                    handleMenuClose();
                                    setShowEmpShiftGrp(true);
                                    dispatch(setGlobalSearchValue(""));
                                  }}
                                >
                                  View
                                </Mui.MenuItem>
                                <Mui.MenuItem
                                  onClick={() => {
                                    handleMenuClose();
                                    setOptName("Edit");
                                    setShowAddShiftGrp(true);
                                  }}
                                >
                                  Edit
                                </Mui.MenuItem>
                              </Mui.Menu>
                            </Mui.TableCell>
                          </Mui.TableRow>

                          {expandDepDet === item?.id && (
                            <Mui.TableRow>
                              <Mui.TableCell colSpan={7}>
                                <Mui.TableContainer
                                  sx={{
                                    paddingRight: 5,
                                    paddingLeft: 5,
                                  }}
                                >
                                  {item?.departments?.length !== 0 ? (
                                    <Mui.Table className="border">
                                      <Mui.TableHead
                                        sx={{
                                          backgroundColor: themes.primary,
                                        }}
                                      >
                                        <Mui.TableRow>
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.blackColor,
                                              fontWeight: "bold",
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              maxWidth: 100,
                                              width: "34%",
                                            }}
                                          >
                                            Department Name
                                          </Mui.TableCell>
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.blackColor,
                                              fontWeight: "bold",
                                              overflow: "hidden",
                                              width: "33%",
                                              whiteSpace: "nowrap",
                                              maxWidth: 100,
                                            }}
                                          >
                                            Employee Count
                                          </Mui.TableCell>
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.blackColor,
                                              fontWeight: "bold",
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              maxWidth: 100,
                                              width: "33%",
                                            }}
                                          >
                                            Action
                                          </Mui.TableCell>
                                        </Mui.TableRow>
                                      </Mui.TableHead>
                                      <Mui.TableBody>
                                        {item?.departments?.map(
                                          (rowentry, index) => (
                                            <Mui.TableRow
                                              key={index}
                                              hover={true}
                                            >
                                              <Mui.TableCell
                                                sx={{
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                  maxWidth: 100,
                                                }}
                                              >
                                                <Mui.Typography
                                                  sx={{
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                  }}
                                                >
                                                  {rowentry?.department
                                                    ? rowentry?.department
                                                    : "N/A"}
                                                </Mui.Typography>
                                              </Mui.TableCell>
                                              <Mui.TableCell
                                                sx={{
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                  textOverflow: "ellipsis",
                                                }}
                                              >
                                                {rowentry?.user_count
                                                  ? rowentry?.user_count
                                                  : 0}
                                              </Mui.TableCell>
                                              <Mui.TableCell
                                                sx={{
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                  textOverflow: "ellipsis",
                                                }}
                                              >
                                                <Mui.IconButton
                                                  onClick={() => {
                                                    setViewGrpName(item?.name);
                                                    setViewDepName(
                                                      rowentry?.department
                                                    );
                                                    setViewSftGrpDet(item);
                                                    setViewSftGrpDepDet(
                                                      rowentry
                                                    );
                                                    setShowEmpShiftGrp(true);
                                                    dispatch(
                                                      setGlobalSearchValue("")
                                                    );
                                                  }}
                                                >
                                                  <RemoveRedEye />
                                                </Mui.IconButton>
                                                <Mui.IconButton
                                                  onClick={() => {
                                                    setDelDepIds(
                                                      item?.departments
                                                        ?.filter(
                                                          (dep) =>
                                                            dep.department_id !==
                                                            rowentry.department_id
                                                        )
                                                        .map(
                                                          (dep) =>
                                                            dep.department_id
                                                        )
                                                    );
                                                    setViewSftGrpDet(item);
                                                    setShowDelSftDep(true);
                                                  }}
                                                  disabled={
                                                    item?.departments
                                                      ?.length === 1
                                                      ? true
                                                      : rowentry?.user_count !==
                                                        0
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  <DeleteSharp
                                                    disabled={
                                                      item?.departments
                                                        ?.length > 1 ||
                                                      rowentry?.user_count === 0
                                                        ? false
                                                        : true
                                                    }
                                                  />
                                                </Mui.IconButton>
                                              </Mui.TableCell>
                                            </Mui.TableRow>
                                          )
                                        )}
                                      </Mui.TableBody>
                                    </Mui.Table>
                                  ) : (
                                    <Mui.Stack
                                      sx={{ padding: 0.5 }}
                                      spacing={2}
                                      fullWidth
                                    >
                                      <Mui.Alert severity="info">
                                        No data available
                                      </Mui.Alert>
                                    </Mui.Stack>
                                  )}
                                </Mui.TableContainer>
                              </Mui.TableCell>
                            </Mui.TableRow>
                          )}
                        </>
                      ))}
                  </Mui.TableBody>
                ) : (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={6}>
                      <Mui.Stack sx={{ padding: 0.5 }} spacing={2} fullWidth>
                        <Mui.Alert severity="info">No data available</Mui.Alert>
                      </Mui.Stack>
                    </Mui.TableCell>
                  </Mui.TableRow>
                )}
              </Mui.Table>
            </Mui.TableContainer>
          )}
          {allSftGrp.length > 10 ? (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={allSftGrp.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </>
      ) : (
        <MemberShiftGroup
          updateShowEmpShiftGrp={setShowEmpShiftGrp}
          viewGrpName={viewGrpName}
          viewDepName={viewDepName}
          viewSftGrpDet={viewSftGrpDet}
          viewSftGrpDepDet={viewSftGrpDepDet}
        />
      )}
      <GlobalDialogBox
        handleCloseDialog={handleClose}
        open={showAddShiftGrp}
        title={optName !== "Edit" ? "Add Shift Group" : "Edit Shift Group"}
      >
        <form onSubmit={handleSubmit(createShiftGroup)}>
          <Mui.Grid xs={12} sx={{ paddingBottom: 1 }}>
            <Mui.Typography>Group Name</Mui.Typography>
            <Mui.TextField
              type="text"
              size="medium"
              style={{
                paddingTop: "0px",
                paddingBottom: "0px",
                fontSize: 14,
                width: "100%",
              }}
              {...register("groupname", {
                required: "Group name is required",
                maxLength: {
                  value: 30,
                  message: "Maximum length is 30 characters",
                },
                minLength: {
                  value: 3,
                  message: "Minium length is 3 characters",
                },
                validate: {
                  noDigitsOrSpecial: (fieldValue) => {
                    if (!/[A-Za-z]/.test(fieldValue)) {
                      return "Group name must contain at least one alphabet character.";
                    }
                    if (/^\d+$/.test(fieldValue)) {
                      return "Group name should not contain only digits.";
                    }
                    if (!/^[A-Za-z0-9\s&-]+$/.test(fieldValue)) {
                      return "Group name should only contain alphabets, spaces, -,numbers and & symbol.";
                    }
                    return true;
                  },
                  shiftGroupAvailable: async (fieldValue) => {
                    if (
                      fieldValue.trim() !== "" &&
                      getValues("uniquename_check") !== fieldValue &&
                      fieldValue?.toUpperCase() !==
                        viewSftGrpDet?.name?.toUpperCase()
                    ) {
                      try {
                        setValue("uniquename_check", fieldValue);
                        const response = await fetch(
                          `${domain}shift-group-name-check/?shift_group_name=${fieldValue.trim()}`,
                          {
                            method: "GET",
                            headers: {
                              "content-type": "application/json",
                              Authorization: `token ${token}`,
                            },
                          }
                        );
                        const res = await response.json();
                        if (response.ok) {
                          if (res?.exists) {
                            return "Group name already taken. Choose a unique name";
                          } else {
                            return true;
                          }
                        } else if (response.status === 409) {
                          sessionHandling();
                        } else {
                          throw new Error(res.error);
                        }
                      } catch (error) {
                        showToast("error", error.message);
                      }
                    } else if (errors.groupname) {
                      return errors?.groupname?.message;
                    }
                  },
                },
              })}
              name="groupname"
              InputProps={{
                inputProps: { maxLength: 30 },
              }}
              error={errors.groupname ? true : false}
              sx={{ background: themes.whiteColor }}
              placeholder={"Enter group name"}
              helperText={errors.groupname ? errors.groupname.message : ""}
              onBlur={() => trigger("groupname")}
            />
          </Mui.Grid>
          <Mui.Grid xs={12} sx={{ paddingBottom: 1 }}>
            <Mui.Typography>Shift Category</Mui.Typography>
            <Mui.Autocomplete
              id="combo-box-demo"
              size="small"
              value={sftCatName}
              options={allSftCategoryDet}
              filterOptions={(options, state) => {
                return options.filter((option) =>
                  option.label
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                );
              }}
              renderOption={(props, option) => {
                return <li {...props}>{option.label}</li>;
              }}
              onChange={(event, value) => {
                if (value) {
                  setSftCatName(value.label);
                  setSftCatId(value.id);
                  getSftCatDepartments(value.id);
                  setValue("shiftcategory", value.label);
                  trigger("shiftcategory");
                  setSelSftCatDept([]);
                  setSftCatDeptId([]);
                  setValue("shiftcatdepartment", []);
                }
              }}
              sx={{ width: "100%" }}
              disabled={optName !== "Edit" ? false : true}
              renderInput={(params) => (
                <Mui.TextField
                  {...params}
                  {...register("shiftcategory", {
                    required: "Shift category is required",
                  })}
                  value={sftCatName}
                  sx={{ background: themes.whiteColor }}
                  placeholder={"Select shift category"}
                  size="medium"
                  error={errors.shiftcategory ? true : false}
                  helperText={
                    errors.shiftcategory ? errors.shiftcategory.message : ""
                  }
                />
              )}
            />
          </Mui.Grid>

          <Mui.Grid xs={12} sx={{ paddingBottom: 1 }}>
            <Mui.Typography>Department</Mui.Typography>
            <Controller
              name="shiftcatdepartment"
              control={control}
              defaultValue={selSftCatDept}
              rules={{
                required: "Select any one department",
              }}
              render={({ field }) => (
                <Mui.Autocomplete
                  multiple
                  size="small"
                  value={selSftCatDept}
                  options={sftCatDept}
                  getOptionLabel={(option) =>
                    option?.label ? option?.label : ""
                  }
                  filterSelectedOptions
                  onChange={(event, value) => {
                    const id = value?.map((e) => e?.id);
                    setSelSftCatDept(value);
                    setSftCatDeptId(id);
                    field.onChange(value);
                  }}
                  sx={{ width: "100%" }}
                  disabled={optName !== "Edit" ? false : true}
                  renderInput={(params) => (
                    <Mui.TextField
                      {...params}
                      sx={{ background: themes.whiteColor }}
                      placeholder="Select departments"
                      size="medium"
                      error={!!errors.shiftcatdepartment}
                      helperText={
                        errors.shiftcatdepartment
                          ? errors.shiftcatdepartment.message
                          : ""
                      }
                    />
                  )}
                />
              )}
            />
          </Mui.Grid>

          <Mui.Grid container>
            <Mui.Grid xs={8}></Mui.Grid>
            <Mui.Grid
              xs={4}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <CustomButton
                actionFuntion={() => handleClose()}
                actionName="Cancel"
                typeName="button"
              />
              &nbsp;
              <CustomButton
                actionName={optName !== "Edit" ? "Add" : "Update"}
                typeName="submit"
              />
            </Mui.Grid>
          </Mui.Grid>
        </form>
      </GlobalDialogBox>

      <GlobalDialogBox
        handleCloseDialog={handleDelCloseDialogBox}
        open={showDelSftDep}
        title="Delete Shift Group Department"
      >
        <Mui.Grid xs={12} sx={{ paddingBottom: 3 }}>
          <Mui.Typography sx={{ fontSize: 24 }}>
            Do you want to delete the department in this shift group?
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={handleDelCloseDialogBox}
              actionName="No"
              typeName="button"
            />{" "}
            &nbsp;
            <CustomButton
              actionFuntion={handleDeleteDepartment}
              actionName="Yes"
              typeName="button"
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </>
  );
};
