import React, { useEffect, useState, useContext, useCallback } from "react";
import * as Mui from "@mui/material";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import { themes } from "services/constants";
import { ShiftGroupContext } from "./ShiftGroupContext";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
import { getContrastingTextColor } from "services/utils/colorChecker";

function not(a, b, key = "user_id") {
  return Array.isArray(a) && Array.isArray(b)
    ? a.filter((value) => !b.some((bValue) => bValue[key] === value[key]))
    : [];
}

function intersection(a, b, key = "user_id") {
  return Array.isArray(a) && Array.isArray(b)
    ? a.filter((value) => b.some((bValue) => bValue[key] === value[key]))
    : [];
}

function union(a, b) {
  return [...(Array.isArray(a) ? a : []), ...not(b, a)];
}

const AddMemberGroup = ({
  updateShowAddorMoveEmployee,
  viewGrpName,
  viewDepName,
  viewSftGrpDet,
  setReloadMemberApi,
}) => {
  const { domain, token } = useContext(ShiftGroupContext);
  const [grpDepMemChecked, setGrpDepMemChecked] = useState([]);
  const [grpDepartments, setGrpDepartments] = useState([]);
  const [selGrpDept, setSelGrpDept] = useState("");
  const [selGrpDeptId, setSelGrpDeptId] = useState("");
  const [departmentSelections, setDepartmentSelections] = useState({});

  const left = departmentSelections[selGrpDept]?.left || [];
  const right = departmentSelections[selGrpDept]?.right || [];

  const leftChecked = intersection(grpDepMemChecked, left);
  const rightChecked = intersection(grpDepMemChecked, right);

  useEffect(() => {
    if (
      viewSftGrpDet?.departments &&
      Array.isArray(viewSftGrpDet.departments)
    ) {
      const depArr = [];
      if (viewDepName) {
        viewSftGrpDet.departments
          .filter((dep) => dep.department === viewDepName)
          .forEach((dep) => depArr.push(dep.department));
      } else {
        viewSftGrpDet.departments.forEach((dep) => depArr.push(dep.department));
      }
      if (depArr?.length > 0) {
        setGrpDepartments(depArr);
        setSelGrpDept(depArr[0]);
        let depFil = viewSftGrpDet?.departments?.filter(
          (dep) => dep.department === depArr[0]
        );
        setSelGrpDeptId(depFil[0]?.id || "");
      }
    }
  }, [viewSftGrpDet, viewDepName]);

  const getAllSelectedUserIds = () => {
    const allRightUserIds = [];
    Object.keys(departmentSelections).forEach((dept) => {
      const rightSideUsers = departmentSelections[dept]?.right || [];
      rightSideUsers.forEach((user) => allRightUserIds.push(user?.user_id));
    });

    return allRightUserIds;
  };

  const selectedUserIds = getAllSelectedUserIds();

  const fetchDepEmployeeData = useCallback(
    async (dept, deptId) => {
      if (dept) {
        try {
          const response = await fetch(
            `${domain}unassign-shift-group-user/?shift_group_id=${viewSftGrpDet?.id}&department_id=${deptId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `token ${token}`,
              },
            }
          );
          const res = await response.json();
          if (response.ok) {
            setDepartmentSelections((prev) => ({
              ...prev,
              [dept]: { left: res || [], right: [] },
            }));
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        }
      }
    },
    [domain, token, viewSftGrpDet?.id]
  );

  useEffect(() => {
    if (!departmentSelections[selGrpDept]) {
      fetchDepEmployeeData(selGrpDept, selGrpDeptId);
    }
  }, [selGrpDept, selGrpDeptId, fetchDepEmployeeData, departmentSelections]);

  const handleToggle = (value) => () => {
    const currentIndex = grpDepMemChecked.findIndex(
      (item) => item.user_id === value.user_id
    );
    const newChecked = [...grpDepMemChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setGrpDepMemChecked(newChecked);
  };

  const numberOfChecked = (items) =>
    intersection(grpDepMemChecked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setGrpDepMemChecked(not(grpDepMemChecked, items));
    } else {
      setGrpDepMemChecked(union(grpDepMemChecked, items));
    }
  };

  const handleCheckedRight = () => {
    const newLeft = not(left, leftChecked);
    const newRight = right.concat(leftChecked);

    setDepartmentSelections((prev) => ({
      ...prev,
      [selGrpDept]: { left: newLeft, right: newRight },
    }));
    setGrpDepMemChecked(not(grpDepMemChecked, leftChecked));
  };

  const handleCheckedLeft = () => {
    const newRight = not(right, rightChecked);
    const newLeft = left.concat(rightChecked);

    setDepartmentSelections((prev) => ({
      ...prev,
      [selGrpDept]: { left: newLeft, right: newRight },
    }));
    setGrpDepMemChecked(not(grpDepMemChecked, rightChecked));
  };

  const handleAddEmployee = async () => {
    try {
      const response = await fetch(`${domain}add-employee-shift-group/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          shift_group_id: viewSftGrpDet?.id,
          user_ids: selectedUserIds,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        updateShowAddorMoveEmployee(false);
        showToast("success", res.message);
        setReloadMemberApi(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const customSelectedEmployeeList = (title, items) => (
    <Mui.Card>
      <Mui.CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Mui.Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length}`}
      />
      <Mui.Divider />
      <Mui.List
        sx={{
          width: "100%",
          height: 600,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value.user_id}-label`;

          return (
            <Mui.ListItemButton
              key={value.user_id}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <Mui.ListItemIcon>
                <Mui.Checkbox
                  checked={grpDepMemChecked.some(
                    (item) => item.user_id === value.user_id
                  )}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </Mui.ListItemIcon>
              <Mui.ListItemAvatar>
                <Mui.Avatar
                  alt={value?.user_name?.charAt(0)}
                  src={value?.image_url}
                />
              </Mui.ListItemAvatar>
              <Mui.ListItemText id={labelId} primary={`${value.user_name}`} />
            </Mui.ListItemButton>
          );
        })}
      </Mui.List>
    </Mui.Card>
  );

  return (
    <>
      <Mui.Grid container spacing={1} sx={{ mt: 0 }}>
        <Mui.Grid item xs={12} sm={12} md={12} lg={12}>
          <Mui.Stack direction="row" sx={{ mt: 1 }}>
            <BackArrow
              actionFunction={() => updateShowAddorMoveEmployee(false)}
            />
            <Mui.Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                mt: 1.5,
              }}
            >
              {viewDepName
                ? `${viewGrpName} / ${viewDepName} / Add Employee`
                : `${viewGrpName} / Add Employee`}
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>

      <Mui.Grid container spacing={1} sx={{ mt: 2, p: 2 }}>
        <Mui.Grid item xs={12} sm={12} md={5} lg={5}>
          <>
            <Mui.FormControl fullWidth sx={{ mb: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                Department
              </Mui.Typography>
              <Mui.Select
                value={selGrpDept}
                sx={{ border: themes.primary }}
                onChange={(e) => {
                  let depFil = viewSftGrpDet?.departments?.filter(
                    (dep) => dep.department === e.target.value
                  );
                  setSelGrpDeptId(depFil[0].id);
                  setSelGrpDept(e.target.value);
                }}
              >
                {grpDepartments.map((dept) => (
                  <Mui.MenuItem key={dept} value={dept}>
                    {dept}
                  </Mui.MenuItem>
                ))}
              </Mui.Select>
            </Mui.FormControl>
            {customSelectedEmployeeList("Select Employee", left)}
          </>
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={12} md={1.5} lg={1.5}>
          <Mui.Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <Mui.IconButton
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              sx={{
                textTransform: "capitalize",
                color: themes.headLine,
              }}
              variant="contained"
              size="medium"
            >
              <ArrowCircleRight color={themes.headLine} fontSize="large" />
            </Mui.IconButton>
            <Mui.IconButton
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              sx={{
                textTransform: "capitalize",
                color: themes.headLine,
              }}
              variant="contained"
              size="medium"
            >
              <ArrowCircleLeft color={themes.headLine} fontSize="large" />
            </Mui.IconButton>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={12} md={5} lg={5}>
          <>
            <Mui.FormControl fullWidth sx={{ mb: 1 }}>
              <Mui.Typography
                sx={{
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                Department
              </Mui.Typography>
              <Mui.Select disabled={true} value={selGrpDept}>
                {grpDepartments.map((dept) => (
                  <Mui.MenuItem key={dept} value={dept}>
                    {dept}
                  </Mui.MenuItem>
                ))}
              </Mui.Select>
            </Mui.FormControl>
            {customSelectedEmployeeList("Selected Employees", right)}
          </>
        </Mui.Grid>
        <Mui.Grid container sx={{ mt: 2 }}>
          <Mui.Grid xs={8.5}></Mui.Grid>
          <Mui.Grid
            xs={3}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Mui.Button
              sx={{
                textTransform: "capitalize",
                backgroundColor: themes.primaryIndicator,
                "&:hover": {
                  backgroundColor: themes.primaryIndicator,
                },
                color: getContrastingTextColor(themes.primaryIndicator),
              }}
              size="large"
              disabled={selectedUserIds?.length === 0}
              onClick={handleAddEmployee}
            >
              Add
            </Mui.Button>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
};

export default AddMemberGroup;
