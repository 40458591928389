import React, { useEffect, useState, useContext } from "react";
import * as Mui from "@mui/material";
import { themes, weeks } from "services/constants";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { useForm } from "react-hook-form";
import { projectSuccess } from "services/constants/SuccessMessages";
import { projectPlaceholder } from "services/constants/PlaceHolder";
import { ProjectDashboardContext } from "../DashboardContext";
import sessionHandling from "services/utils/notificationUtils";
import { CustomButton } from "components/CustomComponents/CustomButton";

export const CreateSprint = ({
  open,
  setOpen,
  option,
  sprintDetails,
  setReloadSprDash,
}) => {
  const { setReloadProDash } = useContext(ProjectDashboardContext);
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const editProject = useSelector((state) => state.leaveReducer.editproject);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [dueDate, setDueDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [selectedWeeks, setSelectedWeeks] = useState("Custom");
  const [editSprintname, setEditSprintname] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    if (editProject?.sprints?.length) {
      const initialName = editProject?.sprints?.length
        ? `Sprint ${editProject?.sprints?.length}`
        : `Sprint 1`;
      setValue("sprintname", initialName);
    }
  }, [editProject, setValue]);

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (option === "Edit") {
      const spr_startDate = sprintDetails?.start_date
        ? new Date(formatDate(sprintDetails?.start_date))
            .toISOString()
            .substring(0, 10)
        : new Date().toISOString().substring(0, 10);
      const spr_endDate = sprintDetails?.end_date
        ? new Date(formatDate(sprintDetails?.end_date))
            .toISOString()
            .substring(0, 10)
        : new Date().toISOString().substring(0, 10);
      setEditSprintname(sprintDetails?.name);
      setValue("sprintname", sprintDetails?.name);
      setValue("goal", sprintDetails?.description);
      setValue("startdate", spr_startDate);
      setValue("enddate", spr_endDate);
      setStartDate(spr_startDate);
      setDueDate(spr_endDate);
      setSelectedWeeks(sprintDetails?.duration);
    } else {
      const initialName = editProject?.sprints?.length
        ? `Sprint ${editProject?.sprints?.length}`
        : `Sprint 1`;
      setValue("sprintname", initialName);
      setValue("goal", "");
      setValue("startdate", new Date().toISOString().substring(0, 10));
      setValue("enddate", new Date().toISOString().substring(0, 10));
      setStartDate(new Date().toISOString().substring(0, 10));
      setDueDate(new Date().toISOString().substring(0, 10));
    }
  }, [open, sprintDetails, option, setValue, editProject]);

  const isOnlySpaces = (str) => /^\s*$/.test(str);

  const handleCloseDialog = () => {
    setOpen(false);
    reset();
    const initialName = editProject?.sprints?.length
      ? `Sprint ${editProject?.sprints?.length}`
      : `Sprint 1`;
    setValue("sprintname", initialName);
    setStartDate(new Date().toISOString().substring(0, 10));
    setDueDate(new Date().toISOString().substring(0, 10));
    setSelectedWeeks("Custom");
  };

  const handleKeyDown = (e) => {
    if (!/[0-9a-zA-Z ""]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const onSubmit = async (data) => {
    const apiActionMethod = option === "Edit" ? "PUT" : "POST";
    const apiUrl =
      option === "Edit"
        ? `${domain}sprints/${sprintDetails?.id}/`
        : `${domain}sprints/`;

    try {
      const response = await fetch(apiUrl, {
        method: apiActionMethod,
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          name: data.sprintname,
          start_date: startDate,
          end_date: dueDate,
          is_default: false,
          description: data.goal,
          project: editProject.id,
          duration: selectedWeeks,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast(
          "success",
          option !== "Edit"
            ? projectSuccess.sprintCreation
            : projectSuccess.sprintUpdate
        );
        handleCloseDialog();
        if (option !== "Edit") {
          setReloadProDash(true);
        } else {
          setReloadSprDash(true);
        }
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  return (
    <React.Fragment>
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialog}
        open={open}
        title={option !== "Edit" ? "Add Sprint" : "Edit Sprint"}
        minWidth={800}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Mui.Grid container sx={{ paddingBottom: 1 }}>
            <Mui.Grid item xs={2.5}>
              <Mui.Typography sx={{ fontWeight: "bold", paddingTop: 2 }}>
                Sprint Name
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={0.3}>
              <Mui.Typography sx={{ fontWeight: "bold", paddingTop: 2 }}>
                :
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={9.2}>
              <Mui.TextField
                type="text"
                size="small"
                InputProps={{
                  inputProps: { maxLength: 100 },
                }}
                {...register("sprintname", {
                  required: "Sprint name is required",
                  validate: {
                    sprNameExist: async (fieldValue) => {
                      if (fieldValue !== "" && fieldValue !== editSprintname) {
                        try {
                          const response = await fetch(
                            `${domain}check-sprint-name/?name=${fieldValue}&project_id=${editProject?.id}`,
                            {
                              method: "GET",
                              headers: {
                                "content-type": "application/json",
                                Authorization: `token ${token}`,
                              },
                            }
                          );
                          const res = await response.json();
                          if (response.ok) {
                            if (res?.exists) {
                              return "Sprint name must be unique";
                            } else {
                              return true;
                            }
                          } else if (response.status === 409) {
                            sessionHandling();
                          } else {
                            throw new Error(res.error);
                          }
                        } catch (error) {
                          showToast("error", error.message);
                        }
                      }
                    },
                  },
                })}
                name="sprintname"
                error={errors?.sprintname ? true : false}
                helperText={errors?.sprintname?.message}
                sx={{ background: themes.whiteColor, paddingTop: 1 }}
                fullWidth
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ paddingBottom: 1 }}>
            <Mui.Grid item xs={2.5}>
              <Mui.Typography sx={{ fontWeight: "bold", paddingTop: 2 }}>
                Goal
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={0.3}>
              <Mui.Typography sx={{ fontWeight: "bold", paddingTop: 2 }}>
                :
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={9.2}>
              <Mui.TextField
                multiline
                rows={4}
                maxRows={20}
                {...register("goal", {
                  required: "Please enter the goal",
                  validate: (value) =>
                    !isOnlySpaces(value) || "Goal cannot be only spaces",
                })}
                name="goal"
                placeholder={projectPlaceholder.sprintGoal}
                error={errors?.goal ? true : false}
                helperText={errors?.goal?.message}
                sx={{ background: themes.whiteColor, paddingTop: 1 }}
                onKeyPress={handleKeyDown}
                fullWidth
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container>
            <Mui.Grid item xs={2.5}>
              <Mui.Typography sx={{ fontWeight: "bold", paddingTop: 2 }}>
                Duration
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={0.3}>
              <Mui.Typography sx={{ fontWeight: "bold", paddingTop: 2 }}>
                :
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={9.2}>
              <Mui.Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                value={selectedWeeks}
                options={weeks}
                sx={{ width: "100%", paddingTop: 1 }}
                ListboxProps={{ style: { maxHeight: "150px" } }}
                filterOptions={(options, state) => {
                  return options.filter((option) =>
                    option.label
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  );
                }}
                renderOption={(props, option) => {
                  return <li {...props}>{option.label}</li>;
                }}
                onChange={(event, value) => {
                  if (value) {
                    setSelectedWeeks(value.label);
                    if (value.label !== "Custom") {
                      let newDay = new Date(startDate);
                      setDueDate(
                        new Date(
                          new Date(startDate).setDate(
                            newDay.getDate() + value.id * 7
                          )
                        )
                          .toISOString()
                          .substring(0, 10)
                      );
                    } else {
                      setDueDate(new Date().toISOString().substring(0, 10));
                    }
                  }
                }}
                renderInput={(params) => (
                  <Mui.TextField
                    {...params}
                    value={selectedWeeks}
                    sx={{ background: themes.whiteColor }}
                    placeholder={projectPlaceholder.selectDuration}
                    size="small"
                  />
                )}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ paddingTop: 1 }}>
            <Mui.Grid item xs={2.5} sx={{ fontWeight: "bold", paddingTop: 2 }}>
              Start Date
            </Mui.Grid>
            <Mui.Grid item xs={0.3} sx={{ fontWeight: "bold", paddingTop: 2 }}>
              :
            </Mui.Grid>
            <Mui.Grid item xs={9.2}>
              <Mui.TextField
                id="from-date"
                type="date"
                size="small"
                {...register("startdate", {
                  required: false,
                  validate: {
                    noDigitsOrSpecial: (fieldValue) => {
                      if (parseInt(dueDate.split("-")[0]) > 2999) {
                        return "please check the date, its too future date";
                      }
                      return true;
                    },
                  },
                })}
                value={startDate}
                error={errors.startdate ? true : false}
                helperText={errors.startdate ? errors.startdate.message : ""}
                sx={{ background: themes.whiteColor, paddingTop: 1 }}
                onChange={(event) => {
                  if (event.target.value === startDate) {
                    setStartDate(event.target.value);
                  } else {
                    setStartDate(event.target.value);
                    setSelectedWeeks("Custom");
                  }
                }}
                fullWidth
                disableOpenPicker
                inputProps={{
                  max: "9999-12-31",
                }}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.Grid container sx={{ paddingTop: 1 }}>
            <Mui.Grid item xs={2.5} sx={{ fontWeight: "bold", paddingTop: 2 }}>
              End Date<Mui.Typography></Mui.Typography>
            </Mui.Grid>
            <Mui.Grid item xs={0.3} sx={{ fontWeight: "bold", paddingTop: 2 }}>
              :
            </Mui.Grid>
            <Mui.Grid item xs={9.2}>
              <Mui.TextField
                id="to-date"
                type="date"
                size="small"
                {...register("enddate", {
                  required: false,
                  validate: {
                    noDigitsOrSpecial: (fieldValue) => {
                      if (new Date(dueDate) <= new Date(startDate)) {
                        return "Due date is greater then start date";
                      }
                      if (parseInt(dueDate.split("-")[0]) > 2999) {
                        return "please check the date, its too future date";
                      }
                      return true;
                    },
                  },
                })}
                value={dueDate}
                sx={{ background: themes.whiteColor, paddingTop: 1 }}
                onChange={(event) => {
                  if (event.target.value === startDate) {
                    setDueDate(event.target.value);
                  } else {
                    setDueDate(event.target.value);
                    setSelectedWeeks("Custom");
                  }
                }}
                error={errors.enddate ? true : false}
                helperText={errors.enddate ? errors.enddate.message : ""}
                fullWidth
                disableOpenPicker
                inputProps={{
                  max: "9999-12-31",
                }}
              />
            </Mui.Grid>
          </Mui.Grid>
          <Mui.DialogActions>
            <Mui.Grid container sx={{ mt: 2 }}>
              <Mui.Grid xs={6}></Mui.Grid>
              <Mui.Grid
                xs={6}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <CustomButton
                  actionFuntion={handleCloseDialog}
                  actionName="Cancel"
                  typeName="button"
                />
                &nbsp;
                <CustomButton
                  actionName={option !== "Edit" ? "Add" : "Update"}
                  typeName="submit"
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.DialogActions>
        </form>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
