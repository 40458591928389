import React from "react";
import { ShiftGroups } from "./ShiftGroups";
import { ShiftGroupContextProvider } from "../ShiftManageContext/ShiftGroupContext";

export const ShiftGroupIndex = () => {
  return (
    <React.Fragment>
      <ShiftGroupContextProvider>
        <ShiftGroups />
      </ShiftGroupContextProvider>
    </React.Fragment>
  );
};
