import React, { useEffect, createContext, useState } from "react";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";

export const ShiftGroupContext = createContext({});

export const ShiftGroupContextProvider = ({ children }) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [reldSftGrpApi, setReldSftGrpApi] = useState(true);
  const [allSftGrp, setAllSftGrp] = useState([]);
  const [allSftCategoryDet, setAllSftCategoryDet] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);

  useEffect(() => {
    const fetchShiftGroupDetails = async () => {
      try {
        const response = await fetch(`${domain}shift-group/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setAllSftGrp(res);
          setMasterDataForSearch(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (reldSftGrpApi) {
      setIsLoading(true);
      setReldSftGrpApi(false);
      fetchShiftGroupDetails();
    }
  }, [domain, reldSftGrpApi, token]);

  useEffect(() => {
    const fetchShiftCategoryDetails = async () => {
      try {
        const response = await fetch(`${domain}shift_category/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const labels = res.map((obj) => ({
            ...obj,
            label: obj.name,
          }));
          setAllSftCategoryDet([...labels]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };

    if (reldSftGrpApi) {
      fetchShiftCategoryDetails();
    }
  }, [domain, reldSftGrpApi, token]);

  return (
    <ShiftGroupContext.Provider
      value={{
        domain,
        token,
        isLoading,
        setReldSftGrpApi,
        allSftGrp,
        allSftCategoryDet,
        setAllSftGrp,
        masterDataForSearch,
      }}
    >
      {children}
    </ShiftGroupContext.Provider>
  );
};
