import * as React from "react";
import { useState, useRef, useEffect } from "react";
import LoginImage from "../../../assets/img/brand/loginHalf.png";
import LoginLogo from "../../../assets/img/brand/organflow 2.0 logo.png";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  getAuth,
} from "firebase/auth";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import * as Mui from "@mui/material";
import { InputAdornment } from "@material-ui/core";
import { Mail } from "@mui/icons-material";
import { showToast } from "services/utils/Status";
import { useSelector } from "react-redux";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "./phonenumber.css";
import { initializeApp } from "firebase/app";
import { parsePhoneNumber } from "libphonenumber-js";
import sessionHandling from "services/utils/notificationUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const firebaseConfig = {
  apiKey: "AIzaSyBmyL25iSMeSEwzxwW7z0L2n9cKtG5xhjM",
  authDomain: "organflowprod.firebaseapp.com",
  projectId: "organflowprod",
  storageBucket: "organflowprod.firebasestorage.app",
  messagingSenderId: "1044068366550",
  appId: "1:1044068366550:web:3f5b6fe81e07ac449f3177",
  measurementId: "G-D1SFPGH4W3",
};
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
const auth = getAuth(app);

export const SendOTP = () => {
  const history = useHistory();
  const { domain } = useSelector((state) => state.tokenReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [sendOTP, setSendOTP] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [groupButtonActiveTap, setGroupButtonActiveTap] = useState(null);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(90);
  const [otpVerification, setOtpVerification] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Change array length based on OTP length
  const inputRefs = useRef([]);
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log("reCAPTCHA verified successfully");
          },
          "expired-callback": (error) => {
            console.log(error);
            showToast("error", "reCAPTCHA expired, please verify again.");
          },
        }
      );

      // Render the reCAPTCHA
      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      });
    }
  }, []);

  useEffect(() => {
    if (!groupButtonActiveTap) {
      const tag = localStorage.getItem("LoginTag")
        ? localStorage.getItem("LoginTag")
        : "Email";
      setGroupButtonActiveTap(tag);
    }
  }, [groupButtonActiveTap]);

  useEffect(() => {
    let timer;
    if (isTimerActive && secondsRemaining > 0) {
      timer = setInterval(() => {
        setSecondsRemaining((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }
    if (secondsRemaining === 0) {
      setIsTimerActive(false);
      setSecondsRemaining(90);
    }
    return () => clearInterval(timer);
  }, [isTimerActive, secondsRemaining]);

  const onSubmit = async (data) => {
    setOtp(["", "", "", "", "", ""]);
    setIsTimerActive(true);
    if (groupButtonActiveTap === "Email") {
      let url =
        groupButtonActiveTap === "Email"
          ? `${domain}email/send/otp/?email=${data.email}`
          : `${domain}email/send/otp/?phone_no=${data.phoneno}`;
      setIsLoading(true);
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const res = await response.json();
        if (response.ok) {
          showToast(
            "success",
            groupButtonActiveTap === "Email"
              ? "Sending the OTP verification email was successful."
              : "Sending the OTP verification phone number was successful."
          );
          setSendOTP(true);
          setIsLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        setIsLoading(false);
        showToast("error", error.message);
      }
    } else {
      const phone = parsePhoneNumber(userPhoneNumber);
      const response = await fetch(
        `${domain}unique/phone-number/?phone_no=${phone.nationalNumber}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resp = await response.json();
      if (resp.exists) {
        const appVerifier = window.recaptchaVerifier;
        const phoneNumber = userPhoneNumber;
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
          .then((result) => {
            setConfirmationResult(result);
            console.log("OTP sent to phone.");
            showToast(
              "success",
              "OTP was sended successfully to your mobile number,Please check it"
            );
            setSendOTP(true);
          })
          .catch((error) => {
            console.error("Error sending OTP:", error);
            showToast("error", error.message);
          });
      } else {
        showToast(
          "error",
          "The given phone number has not been used. Please contact your admin"
        );
      }
    }
  };


  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value)) {
      // Allow only numeric input
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to next input field if there is one
      if (index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
      if (otp[index] === "") {
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      }
    }
  };

  const verifyOTP = async () => {
    let otpNumber = otp[0] + otp[1] + otp[2] + otp[3] + otp[4] + otp[5];
    if (otpNumber.length === 6 && groupButtonActiveTap !== "PhoneNo") {
      try {
        const response = await fetch(
          `${domain}email/send/otp/?email=${getValues(
            "email"
          )}&otp=${otpNumber}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          showToast("success", res.message);
          localStorage.setItem("resetPasswordLink", res.reset_password_url);
          history.push("/auth/otp/newPassword");
          reset();
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        setOtpVerification(false);
        showToast("error", error.message);
      }
    } else if (otpNumber.length === 6 && groupButtonActiveTap === "PhoneNo") {
      const phone = parsePhoneNumber(userPhoneNumber);
      if (confirmationResult) {
        confirmationResult
          .confirm(otpNumber)
          .then((result) => {
            const user = result.user;
            console.log("User signed in:", user);
            generateLinkToResetPassword(
              `${domain}email/send/otp/?phone_no=${phone.nationalNumber}&otp=${otpNumber}`
            );
          })
          .catch((error) => {
            showToast("error", error.message);
            console.error("Error verifying OTP:", error);
          });
      }
    } else {
      setOtpVerification(true);
    }
  };

  const generateLinkToResetPassword = async (url) => {
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.message);
        localStorage.setItem("resetPasswordLink", res.reset_password_url);
        history.push("/auth/otp/newPassword");
        reset();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      setOtpVerification(false);
      showToast("error", error.message);
    }
  };

  const modifyPhonenumber = (value) => {
    setUserPhoneNumber(value);
  };

  return (
    <>
      <Mui.Grid
        container
        style={{
          overflowY: "hidden",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        <Mui.Grid item xs={6}>
          <Mui.Paper style={{ height: "100%" }}>
            <img
              src={LoginImage}
              alt="Login"
              style={{
                height: "100%",
                width: "100%",
              }}
            />
            <a href="https://stringconsultants.com/">
              <div
                style={{
                  position: "absolute",
                  left: "25%",
                  bottom: "0.2%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                © 2024 String Consultants. All Rights Reserved
              </div>
            </a>
          </Mui.Paper>
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={5} lg={6}>
          <Mui.Paper style={{ height: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Mui.Grid
                container
                justifyContent="flex-end"
                sx={{ paddingTop: 2, paddingRight: 2 }}
              >
                <img
                  src={LoginLogo}
                  alt="LoginLogo"
                  style={{ height: "22%", width: "22%" }}
                />
              </Mui.Grid>
              <Mui.Grid
                container
                spacing={2}
                direction="column"
                dispay="flex"
                alignItems="center"
              >
                <Mui.Grid
                  item
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ minHeight: "14vh" }}
                ></Mui.Grid>
                {sendOTP ? (
                  <>
                    <Mui.Grid item>
                      <Mui.Typography
                        sx={{
                          fontWeight:
                            groupButtonActiveTap === "Email" ? 800 : 600,
                          fontSize: "38px",
                          paddingLeft: 6,
                        }}
                      >
                        {groupButtonActiveTap === "Email"
                          ? "Email"
                          : "Phone Number"}{" "}
                        OTP Verification
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item>
                      {" "}
                      <Mui.Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          paddingLeft: 6,
                          color: themes.shadowText,
                        }}
                      >
                        Enter the verification code we just sent to your <br />{" "}
                        {groupButtonActiveTap === "Email"
                          ? `email ${getValues("email")}`
                          : `phone number ${userPhoneNumber}`}
                      </Mui.Typography>
                    </Mui.Grid>
                  </>
                ) : (
                  <>
                    <Mui.Grid item>
                      {" "}
                      <Mui.Typography
                        sx={{
                          fontWeight: 800,
                          fontSize: "38px",
                          paddingLeft: 6,
                        }}
                      >
                        {localStorage.getItem("PasswordHandling") ===
                        "ForgotPassword"
                          ? "Reset Password"
                          : "Create Password"}
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item>
                      {" "}
                      <Mui.Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          paddingLeft: 6,
                          color: themes.shadowText,
                        }}
                      >
                        Enter the{" "}
                        {groupButtonActiveTap === "Email"
                          ? "email address"
                          : "phone number"}{" "}
                        associated with your <br /> account , and We'll send a
                        password{" "}
                        {localStorage.getItem("PasswordHandling") ===
                        "ForgotPassword"
                          ? "reset"
                          : "create"}
                        code <br />{" "}
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.ButtonGroup
                        size="large"
                        aria-label="Large button group"
                        sx={{ border: `1px solid ${themes.loginImage}` }}
                      >
                        <Mui.Button
                          variant={
                            groupButtonActiveTap === "Email"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => {
                            setSendOTP(false);
                            reset();
                            setGroupButtonActiveTap("Email");
                            localStorage.setItem("LoginTag", "Email");
                            setOtp(["", "", "", "", "", ""]);
                          }}
                          sx={{
                            background:
                              groupButtonActiveTap === "Email"
                                ? themes.loginImage
                                : "outlined",
                            textTransform: "capitalize",
                            border: "none",
                            "&:hover": {
                              border: "none",
                              background:
                                groupButtonActiveTap === "Email"
                                  ? themes.loginImage
                                  : "outlined",
                            },
                            color:
                              groupButtonActiveTap === "Email"
                                ? themes.whiteColor
                                : themes.headLine,
                            width: 203,
                          }}
                        >
                          Email
                        </Mui.Button>
                        <Mui.Button
                          variant={
                            groupButtonActiveTap === "PhoneNo"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => {
                            setSendOTP(false);
                            reset();
                            setGroupButtonActiveTap("PhoneNo");
                            localStorage.setItem("LoginTag", "PhoneNo");
                            setOtp(["", "", "", "", "", ""]);
                          }}
                          sx={{
                            background:
                              groupButtonActiveTap === "PhoneNo"
                                ? themes.loginImage
                                : "outlined",
                            textTransform: "capitalize",
                            border: "none",
                            "&:hover": {
                              border: "none",
                              background:
                                groupButtonActiveTap === "PhoneNo"
                                  ? themes.loginImage
                                  : "outlined",
                            },
                            color:
                              groupButtonActiveTap === "Email"
                                ? themes.headLine
                                : themes.whiteColor,
                            width: 203,
                          }}
                        >
                          Phone No
                        </Mui.Button>
                      </Mui.ButtonGroup>
                    </Mui.Grid>
                  </>
                )}
                <Mui.Grid item xs={12} sx={{ marginTop: 2 }}>
                  {groupButtonActiveTap === "Email" && !sendOTP ? (
                    <React.Fragment>
                      <Mui.Typography
                        sx={{ fontSize: 20, paddingRight: 43, fontWeight: 600 }}
                      >
                        Email
                      </Mui.Typography>
                      <Mui.TextField
                        sx={{
                          minWidth: 407,
                        }}
                        size="medium"
                        type="text"
                        placeholder="Please enter e-mail"
                        {...register("email", {
                          required:
                            groupButtonActiveTap === "Email"
                              ? "Email is required"
                              : false,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Mui.Badge color="secondary" badgeContent={0}>
                                <Mail color="primary" />
                              </Mui.Badge>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </React.Fragment>
                  ) : !sendOTP ? (
                    <React.Fragment>
                      <Mui.Typography
                        sx={{
                          fontSize: 20,
                          paddingRight: 35,
                          fontWeight: 600,
                          paddingBottom: 2,
                        }}
                      >
                        Phone No
                      </Mui.Typography>
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={userPhoneNumber}
                        onChange={(event) => modifyPhonenumber(event)}
                        defaultCountry={"IN"}
                        addInternationalOption={false}
                        style={{ width: "400px" }}
                        inputStyle={{
                          background: "lightblue",
                          height: "100px",
                        }}
                        countryCallingCodeEditable={false}
                      />
                    </React.Fragment>
                  ) : null}
                  {!sendOTP ? (
                    <Mui.Grid
                      sx={{
                        paddingTop: 1,
                        maxWidth: 407,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <a className="text-dark" href="/auth/login">
                        <Mui.Typography
                          fontSize={15}
                          style={{ fontWeight: "bold", paddingLeft: 270 }}
                        >
                          Back to login ?
                        </Mui.Typography>
                      </a>
                    </Mui.Grid>
                  ) : null}
                  {sendOTP ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: 5,
                        }}
                      >
                        {otp.map((_, index) => (
                          <input
                            key={index}
                            ref={(el) => (inputRefs.current[index] = el)} // Store refs to all inputs
                            type="text"
                            maxLength="1" // Ensure only one character is entered
                            value={otp[index]}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            style={{
                              width: "50px",
                              height: "50px",
                              textAlign: "center",
                              fontSize: "25px",
                              margin: "0 5px",
                              borderWidth: 2,
                              border:
                                otp[index] === "" && otpVerification
                                  ? `1px solid ${themes.txtErrorColor}`
                                  : false,
                            }}
                          />
                        ))}
                      </div>
                      <div style={{ paddingTop: 5 }}>
                        {isTimerActive ? (
                          <Mui.Typography
                            style={{
                              color: themes.shadowText,
                              textAlign: "right",
                              paddingRight: 25,
                              textDecorationLine: isTimerActive
                                ? "none"
                                : "underline",
                            }}
                            onClick={{}}
                          >
                            {isTimerActive
                              ? `Resend in ${secondsRemaining}s`
                              : ""}
                          </Mui.Typography>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: 20,
                            }}
                          >
                            <Mui.Button
                              sx={{ textTransform: "none" }}
                              type={"submit"}
                            >
                              Resend ?
                            </Mui.Button>
                          </div>
                        )}
                      </div>
                    </>
                  ) : null}
                  <Mui.Grid
                    item
                    xs={12}
                    sx={{
                      marginTop: 5,
                      minWidth: 407,
                      marginRight: "auto",
                    }}
                  >
                    <Mui.Grid justifyContent="center">
                      {sendOTP ? (
                        <Mui.Button
                          variant="contained"
                          type={"button"}
                          size="large"
                          sx={{
                            minWidth: 407,
                            borderRadius: 3,
                            backgroundColor: themes.loginImage,
                            "&:hover": {
                              border: "none",
                              background: themes.loginImage,
                            },
                            color: themes.whiteColor,
                            textTransform: "none",
                            fontSize: 20,
                          }}
                          onClick={() => {
                            verifyOTP();
                          }}
                        >
                          {isLoading ? (
                            <Mui.CircularProgress size={24} color="inherit" />
                          ) : (
                            "Verify"
                          )}
                        </Mui.Button>
                      ) : (
                        <Mui.Button
                          variant="contained"
                          type={sendOTP ? "button" : "submit"}
                          size="large"
                          sx={{
                            minWidth: 407,
                            borderRadius: 3,
                            backgroundColor: themes.loginImage,
                            "&:hover": {
                              border: "none",
                              background: themes.loginImage,
                            },
                            color: themes.whiteColor,
                            textTransform: "none",
                            fontSize: 20,
                          }}
                        >
                          {isLoading ? (
                            <Mui.CircularProgress size={24} color="inherit" />
                          ) : (
                            "Send OTP"
                          )}
                        </Mui.Button>
                      )}
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </form>
            <div id="recaptcha-container"></div>
          </Mui.Paper>
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
};
