import React, { useContext } from "react";
import { useDrop } from "react-dnd";
import { useSelector } from "react-redux";
import { KeyboardArrowDown, ChevronRight } from "@mui/icons-material";
import { themes } from "services/constants";
import * as Mui from "@mui/material";
import { makeStyles } from "@mui/styles";
import DraggableCard from "./DraggableCard";
import { TaskContext } from "./TaskContext";
import { useDispatch } from "react-redux";
import {
  hideToDo,
  hidePending,
  hideInProgress,
  hideOnHold,
  hideReview,
  hideCompleted,
  hideTesting,
} from "services/Redux/projectManagement";

const useStyles = makeStyles({
  tableCell: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
});

const DroppableRow = ({ status, tasks, moveCard, setActiveTap }) => {
  const classes = useStyles();
  const { setStikyTableSize } = useContext(TaskContext);
  const Task = useSelector((state) => state.projectReducer);
  const dispatch = useDispatch();

  const [, ref] = useDrop({
    accept: "CARD",
    drop: (item) => {
      moveCard(item.task, status);
    },
  });

  const updateHideAndShow = () => {
    switch (status) {
      case "ToDo":
        setStikyTableSize(Task[status] ? "98%" : "100%");
        dispatch(hideToDo(!Task[status]));
        break;
      case "Pending":
        dispatch(hidePending(!Task[status]));
        break;
      case "InProgress":
        dispatch(hideInProgress(!Task[status]));
        break;
      case "OnHold":
        dispatch(hideOnHold(!Task[status]));
        break;
      case "Review":
        dispatch(hideReview(!Task[status]));
        break;
      case "Testing":
        dispatch(hideTesting(!Task[status]));
        break;
      case "Completed":
        dispatch(hideCompleted(!Task[status]));
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid
        container
        sx={{
          paddingRight: 1,
        }}
      >
        <Mui.TableContainer
          style={{ paddingTop: 2, paddingBottom: 2 }}
          sx={{ mt: 2 }}
          ref={ref}
        >
          <Mui.Table
            fixedHeader={false}
            style={{
              tableLayout: "auto",
              borderBottom: "1.5px solid transparent",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Mui.TableHead>
              <Mui.TableRow
                maxWidth="xl"
                align="left"
                sx={{
                  "& .MuiTableCell-root": {
                    height: "15px",
                    padding: "2px 15px",
                  },
                }}
              >
                <Mui.TableCell
                  className={classes.tableCell}
                  sx={{
                    width: "30%",
                    fontSize: 14,
                    color: themes.blackColor,
                    fontWeight: "bold",
                    borderRight:
                      Task[status] === false && tasks.length !== 0 ? 1 : 0,
                    borderColor: themes.taskBorder,
                  }}
                >
                  <Mui.Stack
                    direction="row"
                    style={{ paddingTop: "0px", paddingBottom: "0px" }}
                  >
                    {Task[status] ? (
                      <Mui.IconButton
                        onClick={() => updateHideAndShow()}
                        style={{
                          padding: "0px",
                        }}
                      >
                        <ChevronRight />
                      </Mui.IconButton>
                    ) : (
                      <Mui.IconButton
                        onClick={() => updateHideAndShow()}
                        style={{ padding: "0px" }}
                      >
                        <KeyboardArrowDown />
                      </Mui.IconButton>
                    )}
                    <Mui.Typography
                      sx={{
                        paddingLeft: 2,
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      {status}
                    </Mui.Typography>
                    <Mui.Typography
                      sx={{
                        paddingLeft: 2,
                        color: themes.taskHeader,
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      ({tasks.length})
                    </Mui.Typography>
                  </Mui.Stack>
                </Mui.TableCell>
                <Mui.TableCell
                  className={classes.tableCell}
                  sx={{
                    width: "13%",
                    borderRight:
                      Task[status] === false && tasks.length !== 0 ? 1 : 0,
                    borderColor: themes.taskBorder,
                  }}
                ></Mui.TableCell>
                <Mui.TableCell
                  className={classes.tableCell}
                  sx={{
                    width: "13%",
                    borderRight:
                      Task[status] === false && tasks.length !== 0 ? 1 : 0,
                    borderColor: themes.taskBorder,
                  }}
                ></Mui.TableCell>
                <Mui.TableCell
                  className={classes.tableCell}
                  sx={{
                    width: "11%",
                    borderRight:
                      Task[status] === false && tasks.length !== 0 ? 1 : 0,
                    borderColor: themes.taskBorder,
                  }}
                ></Mui.TableCell>
                <Mui.TableCell
                  className={classes.tableCell}
                  sx={{
                    width: "13%",
                    borderRight:
                      Task[status] === false && tasks.length !== 0 ? 1 : 0,
                    borderColor: themes.taskBorder,
                  }}
                ></Mui.TableCell>
                <Mui.TableCell
                  className={classes.tableCell}
                  sx={{
                    width: "10%",
                    borderRight:
                      Task[status] === false && tasks.length !== 0 ? 1 : 0,
                    borderColor: themes.taskBorder,
                  }}
                ></Mui.TableCell>
                <Mui.TableCell
                  className={classes.tableCell}
                  sx={{
                    width: "10%",
                    borderRight:
                      Task[status] === false && tasks.length !== 0 ? 1 : 0,
                    borderColor: themes.taskBorder,
                  }}
                ></Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {Task[status]
                ? null
                : tasks.map((data, index) => (
                    <DraggableCard
                      key={data.id}
                      task={data}
                      status={status}
                      setActiveTap={setActiveTap}
                    />
                  ))}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      </Mui.Grid>
    </React.Fragment>
  );
};

export default DroppableRow;
