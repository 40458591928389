import React, { useState } from "react";
import * as Mui from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { themes } from "services/constants";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import {
  salaryStatementReportPart1,
  salaryStatementReportPart2,
} from "pages/PayRoll/PayRollHeaders";

export const SalaryStatementTable = ({
  salaryDetails,
  listViewPage,
  setListViewPage,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  return (
    <React.Fragment>
      <Mui.TableContainer
        component={Mui.Paper}
        sx={{ mt: 2, width: "100%", paddingRight: 1, maxHeight: 850 }}
      >
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
              position: "sticky",
              top: -1,
              zIndex: 10,
            }}
          >
            <Mui.TableRow>
              {salaryStatementReportPart1.map((header) => (
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  {header.name}
                </Mui.TableCell>
              ))}
              {salaryDetails
                ? salaryDetails[0]?.earnings_detail?.map((name) => (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      {name?.earning_name}
                    </Mui.TableCell>
                  ))
                : null}
              {salaryDetails
                ? salaryDetails[0]?.deductions_detail?.map((name) => (
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      {name?.deduction_name}
                    </Mui.TableCell>
                  ))
                : null}
              {salaryStatementReportPart2.map((header) => (
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  {header.name}
                </Mui.TableCell>
              ))}
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {salaryDetails?.length === 0 ? (
              <Mui.TableRow>
                <Mui.TableCell
                  colSpan={
                    salaryStatementReportPart1?.length +
                    salaryStatementReportPart2?.length
                  }
                >
                  <Mui.Alert
                    severity="info"
                    sx={{ width: "100%", marginTop: 1 }}
                  >
                    No data available
                  </Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              salaryDetails
                ?.slice(
                  listViewPage * rowsPerPage,
                  listViewPage * rowsPerPage + rowsPerPage
                )
                .map((salary) => (
                  <Mui.TableRow>
                    {salaryStatementReportPart1.map((header) => (
                      <Mui.TableCell sx={{ color: themes.blackColor }}>
                        {salary[header.key] || salary[header.key] === 0
                          ? salary[header.key]
                          : "N/A"}
                      </Mui.TableCell>
                    ))}
                    {salary?.earnings_detail?.map((earning_details_amount) => (
                      <Mui.TableCell sx={{ color: themes.blackColor }}>
                        {earning_details_amount?.amount ||
                        earning_details_amount?.amount === 0
                          ? earning_details_amount.amount
                          : "N/A"}
                      </Mui.TableCell>
                    ))}
                    {salary?.deductions_detail?.map((deduction_amount) => (
                      <Mui.TableCell sx={{ color: themes.blackColor }}>
                        {deduction_amount?.amount ||
                        deduction_amount?.amount === 0
                          ? deduction_amount.amount
                          : "N/A"}
                      </Mui.TableCell>
                    ))}
                    {salaryStatementReportPart2.map((header) =>
                      header.name !== "Action" ? (
                        <Mui.TableCell sx={{ color: themes.blackColor }}>
                          {salary[header.key] || salary[header.key] === 0
                            ? salary[header.key]
                            : "N/A"}
                        </Mui.TableCell>
                      ) : (
                        <Mui.TableCell>
                          <Mui.IconButton
                          // onClick={(event) => {
                          //   setAnchorEl(event.currentTarget);
                          //   setEditPayScheduleDetails(department);
                          // }}
                          >
                            <MoreVertIcon
                              sx={{ color: themes.blackColor, strokeWidth: 2 }}
                            />
                          </Mui.IconButton>
                          <Mui.Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            elevation={1}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            {/* <Mui.MenuItem
                              onClick={() => setEditPaySchedule(true)}
                            >
                              Edit
                            </Mui.MenuItem> */}
                            <Mui.MenuItem onClick={() => setOpenDialog(true)}>
                              Delete
                            </Mui.MenuItem>
                          </Mui.Menu>
                        </Mui.TableCell>
                      )
                    )}
                  </Mui.TableRow>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>

      {salaryDetails?.length > 10 && (
        <Mui.TablePagination
          className="custom-pagination"
          component="div"
          rowsPerPageOptions={[10, 20, 50, 100]}
          count={salaryDetails?.length}
          rowsPerPage={rowsPerPage}
          page={listViewPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <GlobalDialogBox
        handleCloseDialog={() => {
          setOpenDialog(false);
        }}
        open={openDialog}
        title="Delete payrun period"
      >
        <Mui.Grid container>
          <Mui.Typography sx={{ paddingBottom: 2 }}>
            Are you sure,you want to delete the ?
          </Mui.Typography>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setOpenDialog(false);
              }}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton actionName="Yes" typeName="button" />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
