import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";
import { teamZoneLoanHistory } from "pages/TeamZone/Approve/Headers";
const keys = [
  "applied_date",
  "department_name",
  "user_name",
  "principal_amount",
  "tenure_months",
  "interest_rate",
  "monthly_installment",
  "loan_type",
  "interest_type",
  "status",
];

export const History = ({
  departmentChange,
  setDepartmentChange,
  userChange,
  setUserChange,
  departmentId,
  userId,
  loanScreen,
  setViewLoanDetails,
  setViewLoan,
}) => {
  const { domain, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [loanHistory, setLoanHistory] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const {
    result: loanDetails,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    `${domain}disbursed-history/?department_id=${departmentId}&selected_user_id=${userId}`
  );
  console.log(loanDetails);

  useEffect(() => {
    if (
      (departmentChange === true || userChange === true) &&
      loanScreen === "History"
    ) {
      reCallApi(true);
      setDepartmentChange(false);
      setUserChange(false);
    }
  }, [
    departmentChange,
    loanScreen,
    reCallApi,
    setDepartmentChange,
    setUserChange,
    userChange,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (updateData && loanDetails?.length !== 0 && loanDetails) {
      setLoanHistory(loanDetails);
      setUpdateData(false);
    }
  }, [loanDetails, updateData]);

  useEffect(() => {
    if (loanDetails?.length !== 0 || loanDetails?.length === 0) {
      const filteredResults = loanDetails?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setLoanHistory(filteredResults);
      setPage(0);
    }
  }, [globalSearchValue, loanDetails]);

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.TableContainer
        sx={{
          padding: 0.5,
          marginTop: 2,
          marginLeft: "auto",
          marginRight: "auto",
          overflow: "auto",
        }}
      >
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
              position: "sticky",
              top: -1,
              zIndex: 10,
              "& .MuiTableCell-root": {
                height: "15px",
                padding: "3px 5px",
              },
            }}
          >
            <Mui.TableRow>
              {teamZoneLoanHistory.map((head) => (
                <Mui.TableCell>
                  <Mui.Typography fontWeight="bold">{head.name}</Mui.Typography>
                </Mui.TableCell>
              ))}
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {loanHistory.length === 0 ? (
              <Mui.TableRow>
                <Mui.TableCell colSpan={12}>
                  <Mui.Alert sx={{ paddingTop: 1 }} severity="info">
                    No data available
                  </Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              loanHistory
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <Mui.TableRow
                    sx={{
                      "& .MuiTableCell-root": {
                        height: "15px",
                        padding: "7px 5px",
                      },
                    }}
                  >
                    {teamZoneLoanHistory.map((head) =>
                      head.name !== "Action" ? (
                        <Mui.TableCell>
                          {item[head.key] ? item[head.key] : "N/A"}
                        </Mui.TableCell>
                      ) : (
                        <Mui.TableCell>
                          <Mui.IconButton
                            size="small"
                            onClick={() => {
                              setViewLoanDetails(item);
                              setViewLoan(true);
                            }}
                          >
                            <RemoveRedEyeIcon size="small" />
                          </Mui.IconButton>
                        </Mui.TableCell>
                      )
                    )}
                  </Mui.TableRow>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>

      {loanHistory.length > 25 && (
        <Mui.TablePagination
          className="custom-pagination"
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={loanHistory.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};
