import * as React from "react";
import { useState } from "react";
import * as Mui from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { themes } from "services/constants";
import dayjs from "dayjs";
import { CalendarMonth } from "@mui/icons-material";
import { HrmsPermission } from "./HrmsPermission";
import { Loader } from "components/Loader";
import { showToast } from "services/utils/Status";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { format, parse } from "date-fns";
import "./style.css";
import sessionHandling from "services/utils/notificationUtils";
import { BackArrow } from "components/CustomComponents/BackArrow";

export const HrmsLeave = ({ handleClose }) => {
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);
  const [employeeNameOptions, setEmployeeOptions] = useState([]);
  const [selectedHalfDay, setSelectedHalfDay] = useState(null);
  const [checkboxChecked, setcheckboxChecked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [firstHalfChecked, setFirstHalfChecked] = useState(false);
  const [secondHalfChecked, setSecondHalfChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPermissionForm, setShowPermissionForm] = useState(false);
  const [showLeaveFile, setShowLeaveFile] = useState(true);
  const [leaveDate, setLeaveDate] = useState([]);
  const [filteredLabel, setFilteredLabel] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leaveStartDate, setleaveStartDate] = useState("");
  const [leaveEndDate, setleaveEndDate] = useState("");

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    trigger,
    formState,
  } = useForm({ mode: "onBlur" });
  const { errors, isValid } = formState;

  useEffect(() => {
    const fetchEmployeeName = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${domain}list/employee?user_id=${userDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          Array.isArray(setEmployeeOptions(res));
          setIsLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchEmployeeName();
  }, [domain, token, userDetails.id]);

  useEffect(() => {
    const fetchLeaveTypeOptions = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${domain}leavetypefilter/?user_id=${userDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          Array.isArray(setLeaveTypeOptions(res));
          setIsLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLeaveTypeOptions();
  }, [domain, token, userDetails.id]);

  const checkFromDate = watch("from_date");
  const checkToDate = watch("to_date");

  const validateStartDate = (value) => {
    const startDate = new Date(value);
    const endDate = checkToDate ? new Date(checkToDate) : null;
    if (firstHalfChecked || secondHalfChecked) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      if (endDate && startDate > endDate) {
        return "Start date cannot be after the end date";
      }
    }
    return true;
  };

  const formatDate = (dateString) => {
    const date = parse(dateString, "dd-MM-yyyy", new Date());
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  };

  const validateEndDate = (value) => {
    if (checked) {
      return true;
    }
    const endDate = new Date(value);
    const startDate = checkFromDate ? new Date(checkFromDate) : null;
    if (startDate && endDate < startDate) {
      return "End date cannot be before the start date";
    }
    return true;
  };
  const resetForm = () => {
    reset();
    setcheckboxChecked(false);
    setChecked(false);
    setFirstHalfChecked(false);
    setSecondHalfChecked(false);
  };

  const handleClearButtonClick = () => {
    resetForm();
  };

  const onSubmit = async (data) => {
    const currentUserDetails = employeeNameOptions?.filter((emp) => {
      return emp.user_name === data.user_name;
    });
    const currentLeaveType = leaveTypeOptions?.filter((leave) => {
      return leave.leave_type === data.leave_type;
    });
    try {
      setIsLoading(true);
      const halfDay = firstHalfChecked || secondHalfChecked;
      const whichHalf = firstHalfChecked
        ? "1st"
        : secondHalfChecked
        ? "2nd"
        : null;

      const response = await fetch(`${domain}create/leave-request`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          ...data,
          is_half_day: halfDay,
          half_day_period: whichHalf,
          user: currentUserDetails[0].user_id,
          status: "Pending",
          applied_date: new Date().toISOString().split("T")[0],
          createdby: userDetails.first_name,
          createdat: new Date().toISOString(),
          modifiedby: userDetails.first_name,
          modifiedat: new Date().toISOString(),
          leave_type_id: currentLeaveType[0].id,
          action: "Approved",
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast(
          "success",
          `You created ${
            currentUserDetails[0].user_name
          } leave request on ${checkFromDate
            .split("-")
            .reverse()
            .join("-")} to ${checkToDate
            .split("-")
            .reverse()
            .join("-")} and it has been approved.`
        );
        reset();
        setcheckboxChecked(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setIsLoading(false);
      setStartDate(leaveStartDate);
      setEndDate(leaveEndDate);
    }
  };

  useEffect(() => {
    const fetchLeaveDate = async () => {
      try {
        const response = await fetch(`${domain}leave-years/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const leaveDate = res.map((value) => ({
            label: value.name,
            start_date: value.start_date,
            end_date: value.end_date,
            filteredValue: `${value.start_date} - ${value.end_date}`,
          }));
          const sDate = formatDate(res[0]?.start_date);
          const eDate = formatDate(res[0]?.end_date);
          setLeaveDate(leaveDate);
          setFilteredLabel(res[0]?.name);
          setFilteredValue(`${res[0]?.start_date} - ${res[0]?.end_date}`);
          setStartDate(sDate);
          setEndDate(eDate);
          setleaveEndDate(eDate);
          setleaveStartDate(sDate);
          setValue("from_date", sDate);
          setValue("to_date", eDate);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchLeaveDate();
  }, [domain, setValue, token]);

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setcheckboxChecked(checked);
    if (checked) {
      setChecked(false);
      setFirstHalfChecked(false);
      setSecondHalfChecked(false);
      const tomorrow = dayjs().add(1, "day").format("YYYY-MM-DD");
      setValue("from_date", tomorrow, { shouldValidate: true });
      setValue("to_date", tomorrow, { shouldValidate: true });
      setStartDate(tomorrow);
      setEndDate(tomorrow);
    } else {
      setValue("from_date", startDate, { shouldValidate: true });
      setValue("to_date", endDate, { shouldValidate: true });
    }
  };

  const handleCheckboxChanged = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    if (isChecked) {
      setEndDate(startDate);
    } else {
      setEndDate(endDate);
    }
  };

  const handleStartDateChange = (event) => {
    const date = event.target.value;
    setStartDate(date);
    if (checked) {
      setEndDate(date);
    }
  };

  const handleSetValue = () => {
    if (checkboxChecked) {
      setValue("from_date", "", { shouldValidate: true });
      setValue("to_date", "", { shouldValidate: true });
    } else {
      setValue("from_date", formattedDate, { shouldValidate: true });
      setValue("to_date", formattedDate, { shouldValidate: true });
    }
  };

  const handleFirstHalfCheckboxChanged = (event) => {
    const checked = event.target.checked;
    setFirstHalfChecked(checked);
    if (checked) {
      setSecondHalfChecked(false);
      setSelectedHalfDay("1st-half");
      setcheckboxChecked(false);
    } else {
      setSelectedHalfDay(null);
    }
  };

  const handleSecondHalfCheckboxChanged = (event) => {
    const checked = event.target.checked;
    setSecondHalfChecked(checked);
    if (checked) {
      setFirstHalfChecked(false);
      setSelectedHalfDay("2nd-half");
      setcheckboxChecked(false);
    } else {
      setSelectedHalfDay(null);
    }
  };

  const date = dayjs().add(1, "day");
  const formattedDate = date.format("YYYY-MM-DD");

  const togglePermissionForm = () => {
    setShowPermissionForm(!showPermissionForm);
    setShowLeaveFile(!showLeaveFile);
  };

  const handleSelectEmployee = (event, newValue) => {
    if (newValue) {
      const selectedEmployee = employeeNameOptions.find(
        (emp) => emp.user_name === newValue
      );
      setValue("user_name", newValue);
      setValue("leave_type", "");
      getLeaveType(selectedEmployee.user_id);
    }
  };

  const getLeaveType = async (id) => {
    try {
      const response = await fetch(`${domain}leavetypefilter/?user_id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setLeaveTypeOptions(res);
        setIsLoading(false);
      } else if (response.status === 409) {
        setLeaveTypeOptions([]);
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      setLeaveTypeOptions([]);
      showToast("error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeLeavePeriod = (event, value) => {
    if (value && value.filteredValue) {
      setFilteredLabel(value.label);
      setFilteredValue(value.filteredValue);
      setStartDate(formatDate(value.start_date));
      setEndDate(formatDate(value.end_date));
      setleaveStartDate(formatDate(value.start_date));
      setleaveEndDate(formatDate(value.end_date));
    } else {
      setFilteredLabel("");
      setFilteredValue("");
      setleaveStartDate("");
      setleaveEndDate("");
    }
  };

  return (
    <>
      {isLoading === true ? (
        <Mui.Box>
          <Loader info="Loading..." />
        </Mui.Box>
      ) : (
        <React.Fragment>
          <Mui.Grid container sx={{ paddingBottom: 1 }}>
            <Mui.Grid xs={1.2}>
              <BackArrow actionFunction={() => handleClose()} />
            </Mui.Grid>
            <Mui.Grid xs={10.8}></Mui.Grid>
          </Mui.Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
            }}
          >
            {showPermissionForm ? (
              <HrmsPermission
                onClose={togglePermissionForm}
                filteredLabel={filteredLabel}
                setFilteredLabel={setFilteredLabel}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setFilteredValue={setFilteredValue}
                filteredValue={filteredValue}
                leaveDate={leaveDate}
                leaveStartDate={leaveStartDate}
                setleaveStartDate={setleaveStartDate}
                leaveEndDate={leaveEndDate}
                setleaveEndDate={setleaveEndDate}
                startDate={startDate}
              />
            ) : (
              <>
                <Mui.Card
                  content="center"
                  sx={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    boxShadow: 5,
                    borderRadius: 5,
                  }}
                >
                  <Mui.CardContent sx={{ marginTop: 2 }}>
                    <Mui.Grid
                      container
                      spacing={2}
                      justifyContent={"space-between"}
                    >
                      <Mui.Grid xs={4} sx={{ paddingLeft: 2 }}>
                        <Mui.ButtonGroup
                          size="large"
                          aria-label="Large button group"
                        >
                          <Mui.Button
                            variant={
                              !showPermissionForm ? "contained" : "outlined"
                            }
                            sx={{
                              background: !showPermissionForm
                                ? themes.primaryButton
                                : "outlined",
                              textTransform: "capitalize",
                              border: "none",
                              "&:hover": {
                                border: "none",
                                background: !showPermissionForm
                                  ? themes.primaryButton
                                  : "outlined",
                              },
                              color: themes.headLine,
                            }}
                          >
                            Leave
                          </Mui.Button>
                          <Mui.Button
                            variant={
                              showPermissionForm === "Daily"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => {
                              togglePermissionForm();
                            }}
                            sx={{
                              background: showPermissionForm
                                ? themes.primaryButton
                                : "outlined",
                              textTransform: "capitalize",
                              border: "none",
                              "&:hover": {
                                border: "none",
                              },
                              color: themes.headLine,
                            }}
                          >
                            Permission
                          </Mui.Button>
                        </Mui.ButtonGroup>
                      </Mui.Grid>

                      <Mui.Grid
                        container
                        xs={6.4}
                        sx={{ justifyContent: "flex-end", paddingTop: 1 }}
                      >
                        <Mui.Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          size="small"
                          value={
                            leaveDate?.find(
                              (option) =>
                                option?.filteredValue === filteredValue
                            ) || ""
                          }
                          options={leaveDate}
                          style={{
                            width: 305,
                          }}
                          getOptionLabel={(option) =>
                            option?.filteredValue ?? filteredValue ?? ""
                          }
                          isOptionEqualToValue={(option, value) =>
                            option?.filteredValue === value?.filteredValue
                          }
                          filterOptions={(options, state) => {
                            return options.filter((option) =>
                              option.filteredValue
                                .toLowerCase()
                                .includes(state.inputValue.toLowerCase())
                            );
                          }}
                          renderOption={(props, option) => {
                            return <li {...props}>{option.filteredValue}</li>;
                          }}
                          ListboxProps={{
                            style: {
                              maxHeight: 150,
                            },
                          }}
                          onChange={handleChangeLeavePeriod}
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              sx={{ background: themes.whiteColor }}
                              placeholder="Select leave period"
                              label={
                                filteredLabel ? filteredLabel : "Leave Period"
                              }
                              size="small"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <>
                                    <CalendarMonth
                                      style={{
                                        margin: "0 8px",
                                        color: "#a6a6a6",
                                      }}
                                    />
                                    {params.InputProps.startAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      </Mui.Grid>
                      <Mui.Grid xs={1}></Mui.Grid>
                    </Mui.Grid>
                    <Mui.Typography
                      sx={{
                        marginTop: 3,
                        marginLeft: 3,
                        fontSize: 20,
                      }}
                    >
                      APPLY LEAVE
                    </Mui.Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Mui.Grid container>
                        <Mui.Grid item xs={6}>
                          <Controller
                            name="user_name"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Choose employee name.",
                            }}
                            render={({ field }) => (
                              <>
                                <Mui.Typography
                                  sx={{ marginTop: 4, marginLeft: 25 }}
                                  htmlFor="from_date"
                                  style={{
                                    fontSize: 20,
                                  }}
                                >
                                  Employee Name
                                </Mui.Typography>
                                <Controller
                                  name="user_name"
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required: "Choose employee name.",
                                  }}
                                  render={({ field }) => (
                                    <Mui.Autocomplete
                                      {...field}
                                      sx={{
                                        marginTop: 1,
                                        width: 280,
                                        marginLeft: 25,
                                        marginRight: "auto",
                                      }}
                                      size="medium"
                                      options={employeeNameOptions.map(
                                        (option) => option.user_name
                                      )}
                                      ListboxProps={{
                                        style: {
                                          maxHeight: 200,
                                        },
                                      }}
                                      renderInput={(params) => (
                                        <Mui.TextField
                                          {...params}
                                          label="Select employee name *"
                                          variant="outlined"
                                          error={!!errors.user_name}
                                          helperText={
                                            errors.user_name &&
                                            errors.user_name.message
                                          }
                                        />
                                      )}
                                      value={field.value}
                                      onChange={handleSelectEmployee}
                                    />
                                  )}
                                />
                              </>
                            )}
                          />
                        </Mui.Grid>
                        <Mui.Grid item xs={6}>
                          <Controller
                            name="leave_type"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Choose a Leave Type.",
                            }}
                            render={({ field }) => (
                              <>
                                <Mui.Typography
                                  sx={{ marginTop: 4, marginLeft: 10.5 }}
                                  htmlFor="from_date"
                                  style={{
                                    fontSize: 20,
                                  }}
                                >
                                  Leave Type
                                </Mui.Typography>
                                <Mui.TextField
                                  {...field}
                                  sx={{
                                    marginTop: 1,
                                    width: 280,
                                    marginLeft: 10.5,
                                    marginRight: "auto",
                                  }}
                                  size="medium"
                                  label="Select Leave Type *"
                                  id="leave_type"
                                  variant="outlined"
                                  error={!!errors.leave_type}
                                  helperText={
                                    errors.leave_type &&
                                    errors.leave_type.message
                                  }
                                  select
                                  SelectProps={{
                                    MenuProps: {
                                      PaperProps: {
                                        style: {
                                          maxHeight: 300,
                                          width: 280,
                                        },
                                      },
                                    },
                                  }}
                                >
                                  {leaveTypeOptions?.map((option) => (
                                    <Mui.MenuItem
                                      key={option?.id}
                                      value={option?.leave_type}
                                    >
                                      {option?.leave_type}
                                    </Mui.MenuItem>
                                  ))}
                                </Mui.TextField>
                              </>
                            )}
                          />
                        </Mui.Grid>
                      </Mui.Grid>

                      <br />
                      <Mui.Grid container>
                        <Mui.Grid itex xs={4}>
                          {" "}
                        </Mui.Grid>
                        <Mui.Grid itex xs={4}>
                          <Mui.FormControlLabel
                            sx={{ marginLeft: -15 }}
                            control={<Mui.Checkbox />}
                            label="Only For Tomorrow"
                            checked={checkboxChecked}
                            onChange={handleCheckboxChange}
                            onClick={handleSetValue}
                          />
                        </Mui.Grid>
                        <Mui.Grid item xs={4}>
                          <Mui.FormControlLabel
                            sx={{ marginLeft: -10 }}
                            control={
                              <Mui.Checkbox
                                checked={checked}
                                onChange={handleCheckboxChanged}
                              />
                            }
                            label="Half Day"
                          />
                        </Mui.Grid>
                        {checked && (
                          <>
                            <Mui.Grid item xs={4}></Mui.Grid>
                            <Mui.Grid item xs={4}>
                              <Mui.FormControlLabel
                                sx={{ marginLeft: -15 }}
                                control={
                                  <Mui.Checkbox
                                    checked={firstHalfChecked}
                                    onChange={handleFirstHalfCheckboxChanged}
                                  />
                                }
                                label="1st-half"
                              />
                            </Mui.Grid>
                            <Mui.Grid item xs={4}>
                              <Mui.FormControlLabel
                                sx={{ marginLeft: -10 }}
                                control={
                                  <Mui.Checkbox
                                    checked={secondHalfChecked}
                                    onChange={handleSecondHalfCheckboxChanged}
                                  />
                                }
                                label="2nd-half"
                              />
                            </Mui.Grid>
                          </>
                        )}
                      </Mui.Grid>
                      <br />

                      <Mui.Grid container spacing={2}>
                        <Mui.Grid item xs={5.5}>
                          <Mui.Typography
                            sx={{ marginTop: -2, marginLeft: 25 }}
                            htmlFor="from_date"
                            style={{
                              fontSize: 20,
                            }}
                          >
                            Start Date
                          </Mui.Typography>

                          <Mui.TextField
                            sx={{
                              marginTop: 1,
                              width: 280,
                              marginLeft: 25,
                            }}
                            size="medium"
                            type="date"
                            value={startDate}
                            id="from_date"
                            inputProps={{
                              min: leaveStartDate,
                              max: leaveEndDate,
                            }}
                            {...register("from_date", {
                              required: "start date is required.",
                              validate: {
                                dateNotBeforeEnd: validateStartDate,
                              },
                            })}
                            error={!!errors.from_date}
                            onChange={handleStartDateChange}
                            helperText={
                              errors.from_date && errors.from_date.message
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={checkboxChecked}
                          />
                        </Mui.Grid>
                        <Mui.Grid item xs={1}>
                          <Mui.Typography
                            sx={{ marginTop: 5, marginLeft: 9 }}
                            style={{
                              fontSize: 20,
                            }}
                          >
                            To
                          </Mui.Typography>
                        </Mui.Grid>

                        <Mui.Grid item xs={5.5}>
                          <Mui.Typography
                            sx={{ marginTop: -2, marginLeft: 5 }}
                            htmlFor="to_date"
                            style={{
                              fontSize: 20,
                            }}
                          >
                            End Date
                          </Mui.Typography>
                          <Mui.TextField
                            sx={{
                              marginTop: 1,
                              width: 280,
                              marginLeft: 5,
                            }}
                            size="medium"
                            type="date"
                            id="to_date"
                            value={endDate}
                            inputProps={{
                              min: leaveStartDate,
                              max: leaveEndDate,
                            }}
                            {...register("to_date", {
                              required: "End date is required.",
                              validate: {
                                dateNotBeforeStart: validateEndDate,
                              },
                            })}
                            onChange={(event) => {
                              setEndDate(event.target.vaule);
                            }}
                            // onChange={(event) => setEndDate(event.target.value)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={
                              checked ? checked : false || checkboxChecked
                            }
                            error={!!errors.to_date}
                            helperText={
                              errors.to_date && errors.to_date.message
                            }
                          />
                        </Mui.Grid>
                      </Mui.Grid>

                      <br />
                      <br />
                      <Mui.Typography
                        sx={{ marginTop: -2, marginLeft: 25 }}
                        htmlFor="from_date"
                        style={{
                          fontSize: 20,
                        }}
                      >
                        Reason for Leave
                      </Mui.Typography>
                      <Mui.TextField
                        sx={{
                          marginTop: 1,
                          width: "68.7%",
                          marginLeft: 25,
                          marginRight: "auto",
                        }}
                        id="description"
                        {...register("description", {
                          required: "description is required",
                        })}
                        label="Provide a reason for your time off"
                        multiline
                        minRows={3}
                        onBlur={() => trigger()}
                      />

                      <br />
                      <br />
                      <Mui.Grid container spacing={5}>
                        <Mui.Grid item xs={7}></Mui.Grid>
                        <Mui.Grid
                          item
                          xs={5}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                          }}
                        >
                          <CustomButton
                            actionFuntion={handleClearButtonClick}
                            actionName="Clear"
                            typeName="button"
                          />
                          &nbsp;&nbsp;
                          <Mui.Button
                            sx={{
                              width: "100px",
                              height: "36px",
                              marginRight: 3,
                              display: "block",
                              backgroundColor: themes.primaryButton,
                              "&:hover": {
                                backgroundColor: themes.primaryButton,
                              },
                              textTransform: "capitalize",
                              color: themes.headLine,
                            }}
                            type="submit"
                            variant="contained"
                            disabled={
                              !isValid ||
                              (checked && !selectedHalfDay) ||
                              (checked &&
                                !firstHalfChecked &&
                                !secondHalfChecked) ||
                              isLoading
                            }
                          >
                            Approve
                          </Mui.Button>
                        </Mui.Grid>
                      </Mui.Grid>
                    </form>
                  </Mui.CardContent>
                </Mui.Card>
              </>
            )}
          </div>
        </React.Fragment>
      )}
    </>
  );
};
