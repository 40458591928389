import React, { useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { EPFReportKeys } from "pages/PayRoll/PayRollHeaders";

export const EPFReportTable = ({
  PFDetails,
  listViewPage,
  setListViewPage,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  return (
    <React.Fragment>
      <Mui.TableContainer
        component={Mui.Paper}
        sx={{ mt: 2, width: "100%", paddingRight: 1, maxHeight: 850 }}
      >
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
              position: "sticky",
              top: -1,
              zIndex: 10,
            }}
          >
            <Mui.TableRow>
              {EPFReportKeys.map((header) => (
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  {header.name}
                </Mui.TableCell>
              ))}
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {PFDetails?.length === 0 ? (
              <Mui.TableRow>
                <Mui.TableCell colSpan={8}>
                  <Mui.Alert
                    severity="info"
                    sx={{ width: "100%", marginTop: 2 }}
                  >
                    No data available
                  </Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              PFDetails?.slice(
                listViewPage * rowsPerPage,
                listViewPage * rowsPerPage + rowsPerPage
              ).map((salary) => (
                <Mui.TableRow>
                  {EPFReportKeys.map((header) => (
                    <Mui.TableCell sx={{ color: themes.blackColor }}>
                      {salary[header.key] || salary[header.key] === 0
                        ? salary[header.key]
                        : "N/A"}
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              ))
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>

      {PFDetails?.length > 10 && (
        <Mui.TablePagination
          className="custom-pagination"
          component="div"
          rowsPerPageOptions={[10, 20, 50, 100]}
          count={PFDetails?.length}
          rowsPerPage={rowsPerPage}
          page={listViewPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <GlobalDialogBox
        handleCloseDialog={() => {
          setOpenDialog(false);
        }}
        open={openDialog}
        title="Delete payrun period"
      >
        <Mui.Grid container>
          <Mui.Typography sx={{ paddingBottom: 2 }}>
            Are you sure,you want to delete the ?
          </Mui.Typography>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setOpenDialog(false);
              }}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton actionName="Yes" typeName="button" />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
