export const teamZoneLoanHistory = [
  { name: "Date", key: "applied_date" },
  { name: "Department", key: "department_name" },
  { name: "Employee Name", key: "user_name" },
  { name: "Loan Amount", key: "principal_amount" },
  { name: "Tenure", key: "tenure_months" },
  { name: "Interest", key: "interest_rate" },
  { name: "Installment Amount", key: "monthly_installment" },
  { name: "Loan Type", key: "loan_type" },
  { name: "Interest Type", key: "interest_type" },
  { name: "Status", key: "status" },
  { name: "Action" },
];
