import React, { useEffect, createContext, useState } from "react";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";

export const NewProjectContext = createContext({});

export const NewProjectContextProvider = ({ children }) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [fetchClientApi, setFetchClientApi] = useState(true);
  const [allClients, setAllClient] = useState([]);
  const [allClientsId, setAllClientId] = useState([]);
  const [clientName, setClientName] = useState("");
  const [clientId, setClientId] = useState(0);

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(`${domain}client/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const allClients = res.map((obj) => ({
            ...obj,
            label: obj.clientname,
          }));
          const allClientsWithId = res.map((obj) => ({
            ...obj,
            label: obj.clientid,
          }));
          const createButton = {
            clientid: 9999,
            label: "Create Client",
            clientname: "Create Client",
          };
          setAllClientId([...allClientsWithId, createButton]);
          setAllClient([...allClients, createButton]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (fetchClientApi) {
      setFetchClientApi(false);
      fetchClientDetails();
    }
  }, [domain, fetchClientApi, token]);

  return (
    <NewProjectContext.Provider
      value={{
        token,
        domain,
        allClients,
        allClientsId,
        setFetchClientApi,
        clientId,
        setClientId,
        clientName,
        setClientName,
      }}
    >
      {children}
    </NewProjectContext.Provider>
  );
};
