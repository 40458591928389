import React, { useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { ViewPayRun } from "./ViewPayRun";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
const payRunKeyPair = [
  {
    name: "Payrun name",
  },
  {
    name: "Payrun period",
  },
  {
    name: "Payment date",
  },
  {
    name: "No of employee",
  },
  {
    name: "Employee'net pay",
  },
  {
    name: "Status",
  },
];

export const PayRunTable = ({
  payrunDetails,
  companyId,
  companyName,
  setViewPayRunScreen,
  setListViewPage,
  listViewPage,
  viewPayrunScreen,
  reCallApi,
}) => {
  const [viewPayrunDetails, setViewPayRunDetails] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setListViewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setListViewPage(0);
  };

  return (
    <React.Fragment>
      {viewPayrunScreen ? (
        <ViewPayRun
          setViewPayRunScreen={setViewPayRunScreen}
          companyName={companyName}
          companyId={companyId}
          viewPayrunDetails={viewPayrunDetails}
          setViewPayRunDetails={setViewPayRunDetails}
          reCallApi={reCallApi}
        />
      ) : (
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ mt: 8, width: "100%", maxHeight: 850 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow>
                {payRunKeyPair.map((header) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {header.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {payrunDetails?.length === 0 ? (
                <Mui.TableRow>
                  <Mui.TableCell colSpan={6}>
                    <Mui.Alert severity="info">No data available</Mui.Alert>
                  </Mui.TableCell>
                </Mui.TableRow>
              ) : (
                payrunDetails
                  ?.slice(
                    listViewPage * rowsPerPage,
                    listViewPage * rowsPerPage + rowsPerPage
                  )
                  .map((payschedule) => (
                    <Mui.TableRow
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setViewPayRunDetails(payschedule);
                        setViewPayRunScreen(true);
                        dispatch(setGlobalSearchValue(""));
                      }}
                    >
                      <Mui.TableCell>{payschedule.name}</Mui.TableCell>
                      <Mui.TableCell>
                        {payschedule.start_date + " to " + payschedule.end_date}
                      </Mui.TableCell>
                      <Mui.TableCell>{payschedule.run_date}</Mui.TableCell>
                      <Mui.TableCell>
                        {payschedule.num_of_employees}
                      </Mui.TableCell>
                      <Mui.TableCell>{payschedule.total_net_pay}</Mui.TableCell>
                      <Mui.TableCell>{payschedule.status}</Mui.TableCell>
                    </Mui.TableRow>
                  ))
              )}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      )}
      {!viewPayrunScreen && payrunDetails.length > 10 && (
        <Mui.TablePagination
          className="custom-pagination"
          component="div"
          rowsPerPageOptions={[10, 20, 50, 100]}
          count={payrunDetails.length}
          rowsPerPage={rowsPerPage}
          page={listViewPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <GlobalDialogBox
        handleCloseDialog={() => {
          setOpenDialog(false);
        }}
        open={openDialog}
        title="Delete payrun period"
      >
        <Mui.Grid container>
          <Mui.Typography sx={{ paddingBottom: 2 }}>
            Are you sure,you want to delete the ?
          </Mui.Typography>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={() => {
                setOpenDialog(false);
              }}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton actionName="Yes" typeName="button" />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
