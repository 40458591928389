import React, { useEffect, createContext, useState } from "react";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";

export const ShiftAssignContext = createContext({});

export const ShiftAssignContextProvider = ({ children }) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [reldSftAsgnApi, setReldSftAsgnApi] = useState(true);
  const [allSftAsgn, setAllSftAsgn] = useState([]);
  const [allSftCategoryDet, setAllSftCategoryDet] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);

  useEffect(() => {
    const fetchShiftAssignmentDetails = async () => {
      try {
        const response = await fetch(`${domain}shift-assignment/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setAllSftAsgn(res);
          setMasterDataForSearch(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (reldSftAsgnApi) {
      setIsLoading(true);
      setReldSftAsgnApi(false);
      fetchShiftAssignmentDetails();
    }
  }, [domain, reldSftAsgnApi, token]);

  useEffect(() => {
    const fetchShiftCategoryDetails = async () => {
      try {
        const response = await fetch(`${domain}shift_category/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const labels = res.map((obj) => ({
            ...obj,
            label: obj.name,
          }));
          setAllSftCategoryDet([...labels]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };

    if (reldSftAsgnApi) {
      fetchShiftCategoryDetails();
    }
  }, [domain, reldSftAsgnApi, token]);

  return (
    <ShiftAssignContext.Provider
      value={{
        domain,
        token,
        isLoading,
        setReldSftAsgnApi,
        allSftAsgn,
        allSftCategoryDet,
        setAllSftAsgn,
        masterDataForSearch,
      }}
    >
      {children}
    </ShiftAssignContext.Provider>
  );
};
